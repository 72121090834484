import { CreatePostMortemModal } from "@incident-shared/postmortems/CreatePostMortemModal";
import { ExportPostmortemDrawer } from "@incident-shared/postmortems/ExportPostmortemDrawer";
import { SharePostMortemModal } from "@incident-shared/postmortems/SharePostMortemModal";
import { createContext, FunctionComponent, useContext, useState } from "react";

import { ScheduleDebriefModal } from "../../debriefs/ScheduleDebriefModal";
import { EditRoleAssignmentsModal } from "../../header/EditRoleAssignmentsModal";
import { EditTimestampsModal } from "../../header/EditTimestampsModal";
import { GiveShoutoutModal } from "../../header/GiveShoutoutModal";
import { EditSpecificCustomFieldEntriesModal } from "../../sidebar/EditCustomFieldEntriesModal";
import {
  PostIncidentActionModal,
  PostIncidentModalProps,
} from "../task-items/types";

type ActionModalState = {
  modal: PostIncidentActionModal;
  props: PostIncidentModalProps;
} | null;

export const PostIncidentModalConfigs: {
  [key in PostIncidentActionModal]: {
    render: FunctionComponent<
      PostIncidentModalProps & {
        onClose: () => void;
      }
    >;
    isDrawer?: boolean;
  };
} = {
  [PostIncidentActionModal.CreatePostMortem]: { render: CreatePostMortemModal },
  [PostIncidentActionModal.ExportPostMortem]: {
    render: ExportPostmortemDrawer,
    isDrawer: true,
  },
  [PostIncidentActionModal.SharePostMortem]: { render: SharePostMortemModal },
  [PostIncidentActionModal.ScheduleDebrief]: { render: ScheduleDebriefModal },
  [PostIncidentActionModal.EditRoleAssignments]: {
    render: EditRoleAssignmentsModal,
  },
  [PostIncidentActionModal.EditCustomFieldEntries]: {
    render: EditSpecificCustomFieldEntriesModal,
  },
  [PostIncidentActionModal.EditTimestamps]: { render: EditTimestampsModal },
  [PostIncidentActionModal.GiveShoutout]: { render: GiveShoutoutModal },
};

type PostIncidentModalContextType = {
  showingModal: ActionModalState;
  setShowingModal: (a: ActionModalState) => void;
};

// PostIncidentModalContext allows other children of the page to open
// modals and drawers from the PINC flow.
export const PostIncidentModalContext =
  createContext<PostIncidentModalContextType>({
    showingModal: null,
    setShowingModal: () => null,
  });

export const PostIncidentModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [showingModal, setShowingModal] = useState<ActionModalState>(null);

  return (
    <PostIncidentModalContext.Provider
      value={{
        showingModal,
        setShowingModal,
      }}
    >
      {children}
    </PostIncidentModalContext.Provider>
  );
};

export const usePostIncidentModalContext = (): {
  showingModal: ActionModalState;
  setShowingModal: (a: ActionModalState) => void;
} => {
  const context = useContext(PostIncidentModalContext);
  if (!context) {
    throw new Error(
      `usePostIncidentModalContext must be used as a child of a PostIncidentModalContext provider`,
    );
  }

  return context;
};

export const PostIncidentActionModals = () => {
  const { showingModal, setShowingModal } = usePostIncidentModalContext();

  if (!showingModal) {
    return null;
  }

  const Modal = PostIncidentModalConfigs[showingModal.modal].render;

  return (
    <Modal onClose={() => setShowingModal(null)} {...showingModal.props} />
  );
};
