/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FeatureGates,
    FeatureGatesFromJSON,
    FeatureGatesFromJSONTyped,
    FeatureGatesToJSON,
} from './FeatureGates';

/**
 * 
 * @export
 * @interface StaffGetFeatureGatesResponseBody
 */
export interface StaffGetFeatureGatesResponseBody {
    /**
     * 
     * @type {FeatureGates}
     * @memberof StaffGetFeatureGatesResponseBody
     */
    feature_gates: FeatureGates;
    /**
     * Whether their current gates match their plan's gates
     * @type {boolean}
     * @memberof StaffGetFeatureGatesResponseBody
     */
    gates_match_plan: boolean;
    /**
     * 
     * @type {FeatureGates}
     * @memberof StaffGetFeatureGatesResponseBody
     */
    plan_feature_gates: FeatureGates;
    /**
     * Name of the current plan
     * @type {string}
     * @memberof StaffGetFeatureGatesResponseBody
     */
    plan_name: string;
}

export function StaffGetFeatureGatesResponseBodyFromJSON(json: any): StaffGetFeatureGatesResponseBody {
    return StaffGetFeatureGatesResponseBodyFromJSONTyped(json, false);
}

export function StaffGetFeatureGatesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaffGetFeatureGatesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feature_gates': FeatureGatesFromJSON(json['feature_gates']),
        'gates_match_plan': json['gates_match_plan'],
        'plan_feature_gates': FeatureGatesFromJSON(json['plan_feature_gates']),
        'plan_name': json['plan_name'],
    };
}

export function StaffGetFeatureGatesResponseBodyToJSON(value?: StaffGetFeatureGatesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feature_gates': FeatureGatesToJSON(value.feature_gates),
        'gates_match_plan': value.gates_match_plan,
        'plan_feature_gates': FeatureGatesToJSON(value.plan_feature_gates),
        'plan_name': value.plan_name,
    };
}

