import { CustomField, SelectOptionGroup } from "@incident-io/api";
import {
  SelectOption,
  SelectOptionGroup as SelectOptionGroupType,
  SelectOptionOrGroup,
  SharedDynamicSelectProps,
} from "@incident-ui/Select/types";
import { captureException } from "@sentry/react";
import cloneDeep from "lodash/cloneDeep";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import {
  ClientType,
  CustomFieldEntryPayload,
  CustomFieldFieldTypeEnum,
  useClient,
} from "src/contexts/ClientContext";

import { InputElementProps } from "./formsv2";
import {
  DynamicMultiSelectV2,
  DynamicSingleSelectV2,
} from "./inputs/DynamicSelectV2";

type Props<TFormType extends FieldValues> = {
  formMethods: UseFormReturn<TFormType>;
  customField: CustomField;
  fieldKeyPrefix: Path<TFormType>;
  includeNoValue: boolean;
  entryPayloads: CustomFieldEntryPayload[];
} & InputElementProps<
  TFormType,
  // Don't ask for props that we build inside this component
  Omit<SharedDynamicSelectProps, "loadOptions" | "hydrateOptions">
>;

export const CUSTOM_FIELD_NO_VALUE = "__NOVALUE__";

export const CustomFieldDynamicSelect = <FormType extends FieldValues>({
  customField,
  fieldKeyPrefix,
  includeNoValue,
  entryPayloads,
  formMethods,
  ...props
}: Props<FormType>) => {
  const apiClient = useClient();

  let fieldKey: Path<FormType> = fieldKeyPrefix;
  const isMulti =
    customField.field_type === CustomFieldFieldTypeEnum.MultiSelect;
  if (isMulti) {
    // We need to fix this 'in post' - we can't get our multiselect
    // to put the values in the right place (without some complex hijinks)
    // so we fix this when marshalling to the request struct.
    fieldKey = fieldKeyPrefix as unknown as Path<FormType>;
  } else {
    fieldKey = `${fieldKeyPrefix}.0` as unknown as Path<FormType>;
  }

  const Component = isMulti ? DynamicMultiSelectV2 : DynamicSingleSelectV2;

  // If it's a dependent field, re-render the component whenever that field's value changes.
  let key = customField.id;
  if (customField.filter_by !== undefined) {
    const filterByEntry = entryPayloads.find(
      (payload) =>
        payload.custom_field_id === customField.filter_by?.custom_field_id,
    );
    if (filterByEntry) {
      key = `${customField.id}-${filterByEntry.values
        .map((val) => val.value_catalog_entry_id)
        .join("-")}`;
    }
  }

  return (
    <Component
      {...props}
      key={key}
      formMethods={formMethods}
      loadOptions={getCustomFieldTypeaheadOptions({
        apiClient,
        customFieldID: customField.id,
        includeDynamicOptions: customField.dynamic_options,
        includeNoValue,
        entryPayloads,
      })}
      hydrateOptions={hydrateInitialCustomFieldOptions({
        apiClient,
        customFieldID: customField.id,
      })}
      name={fieldKey}
    />
  );
};

const customFieldTypeaheadOptions = async ({
  apiClient,
  customFieldID,
  includeDynamicOptions,
  includeNoValue,
  query,
  entryPayloads,
  viaInsightsAPI = false,
}: {
  apiClient: ClientType;
  customFieldID: string;
  includeDynamicOptions: boolean;
  includeNoValue: boolean;
  query: string;
  entryPayloads: CustomFieldEntryPayload[];
  viaInsightsAPI?: boolean;
}): Promise<SelectOptionOrGroup[]> => {
  try {
    let groups: SelectOptionGroup[] = [];

    if (viaInsightsAPI) {
      const res = await apiClient.insightsListCustomFieldOptions({
        customFieldId: customFieldID,
        query,
      });
      groups = res.option_groups;
    } else {
      const res = await apiClient.customFieldsListOptions({
        id: customFieldID,
        listOptionsRequestBody: {
          query,
          custom_field_entries: entryPayloads,
        },
      });
      groups = res.option_groups;
    }

    const optionGroups = augmentWithCreationOptionNoValueAndHelptext({
      option_groups: groups,
      includeDynamicOptions,
      query,
      includeNoValue,
    });

    if (optionGroups.length === 1 && optionGroups[0].label === "") {
      return optionGroups[0].options;
    }
    return optionGroups;
  } catch (error) {
    captureException(error);
    console.error(error);
  }
  // @ts-expect-error this expects a promise, but this is an error case anyway so it's probably OK?
  return undefined;
};

const augmentWithCreationOptionNoValueAndHelptext = ({
  option_groups,
  query,
  includeDynamicOptions,
  includeNoValue,
}: {
  option_groups: SelectOptionGroup[];
  query: string;
  includeDynamicOptions: boolean;
  includeNoValue: boolean;
}): SelectOptionGroupType[] => {
  // Map over all the options in all of the groups and define the render_fn so it displays helptext
  let groups = option_groups.map((group) => {
    return {
      label: group.label,
      options: group.options.map((opt) => {
        if (!opt.helptext) {
          return opt;
        }

        const selectOption: SelectOption = cloneDeep(opt);
        selectOption.renderFn = (option) => {
          return (
            <div>
              <div className={"text-content-primary font-medium"}>
                {option.label}
              </div>
              <div className={"text-slate-700 max-w-[320px]"}>
                {opt.helptext}
              </div>
            </div>
          );
        };
        return selectOption;
      }),
    };
  });

  if (includeDynamicOptions) {
    groups = augmentWithDynamicOptions({ query, groups });
  }

  if (includeNoValue) {
    groups = augmentWithNoValue({ query, groups });
  }

  return groups;
};

// Option value limits
const MAX_OPTION_LENGTH = 50;
const WARNING_OPTIONLENGTH = 30;

function updateTitle(query: string) {
  if (query.length > MAX_OPTION_LENGTH) {
    return `Create a new option (max ${MAX_OPTION_LENGTH} characters)`;
  } else if (query.length > WARNING_OPTIONLENGTH) {
    return `Create a new option (${
      MAX_OPTION_LENGTH - query.length
    } characters left)`;
  }
  return "Create a new option";
}

const augmentWithDynamicOptions = ({
  groups,
  query,
}: {
  groups: SelectOptionGroupType[];
  query: string;
}): SelectOptionGroupType[] => {
  if (query === "") {
    return groups;
  }

  const allOptionLabels = groups.flatMap(({ options }) =>
    options.map(({ label }) => label),
  );

  if (
    !allOptionLabels.find(
      (label) => label.toLowerCase() === query.toLowerCase(),
    )
  ) {
    // If there's not an exact match, add a "create new" section/option
    const truncatedQuery = query.substring(0, MAX_OPTION_LENGTH);
    groups.push({
      label: updateTitle(query),
      options: [
        {
          value: `CREATE:::${truncatedQuery}`,
          label: truncatedQuery,
        },
      ],
    });
  }

  return groups;
};

const augmentWithNoValue = ({
  groups,
  query,
}: {
  groups: SelectOptionGroupType[];
  query: string;
}): SelectOptionGroupType[] => {
  const noValueSearchString = "no value nothing empty";

  const noValueOption = {
    value: CUSTOM_FIELD_NO_VALUE,
    label: "No value",
  };

  if (query === "" || noValueSearchString.includes(query.toLowerCase())) {
    // add the 'no value' option
    if (groups.length === 0) {
      // "No value" is the only match, add it without a group label
      groups.push({ label: "", options: [noValueOption] });
    } else {
      if (groups[0].label) {
        // There are group labels, add "No value" in a new group
        groups.push({
          label: "No value",
          options: [noValueOption],
        });
      } else {
        // There are no group labels, add "No value" to the existing group
        groups[0].options.push(noValueOption);
      }
    }
  }

  return groups;
};

export const getCustomFieldTypeaheadOptions = ({
  apiClient,
  customFieldID,
  includeDynamicOptions,
  includeNoValue,
  entryPayloads,
  viaInsightsAPI = false,
}: {
  apiClient: ClientType;
  customFieldID: string;
  includeDynamicOptions: boolean;
  includeNoValue: boolean;
  entryPayloads: CustomFieldEntryPayload[];
  viaInsightsAPI?: boolean;
}) => {
  return async function (inputValue: string): Promise<SelectOptionOrGroup[]> {
    try {
      const typeaheadOptions = await customFieldTypeaheadOptions({
        apiClient,
        customFieldID,
        includeDynamicOptions,
        includeNoValue,
        query: inputValue,
        entryPayloads,
        viaInsightsAPI,
      });

      return typeaheadOptions || [];
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};

export const hydrateInitialCustomFieldOptions = ({
  apiClient,
  customFieldID,
  viaInsightsAPI = false,
}: {
  apiClient: ClientType;
  customFieldID: string;
  viaInsightsAPI?: boolean;
}) => {
  return async (initialValue: string | string[]): Promise<SelectOption[]> => {
    try {
      if (viaInsightsAPI) {
        const { option_groups } =
          await apiClient.insightsListCustomFieldOptions({
            customFieldId: customFieldID,
            optionIds: Array.isArray(initialValue)
              ? initialValue
              : [initialValue],
          });
        return option_groups.flatMap((grp) => grp.options);
      } else {
        const { options } = await apiClient.customFieldsHydrateOptions({
          id: customFieldID,
          idList: Array.isArray(initialValue) ? initialValue : [initialValue],
        });
        return options;
      }
    } catch (error) {
      captureException(error);
      console.error(error);
    }
    return Promise.resolve([]);
  };
};
