import {
  ExternalIssueReferenceProviderEnum as IssueTrackerProviderEnum,
  IntegrationSettings,
} from "@incident-io/api";

export { ExternalIssueReferenceProviderEnum as IssueTrackerProviderEnum } from "@incident-io/api";

export type IssueTrackerProviderEnabled = {
  provider: IssueTrackerProviderEnum;
  disabled: boolean;
};

export const GetInstalledIssueTrackers = (
  integrations: IntegrationSettings[] | undefined,
): IssueTrackerProviderEnabled[] => {
  if (integrations === undefined) {
    return [];
  }

  // We know that IssueTrackerProvider is a subset of
  // IntegrationProvider, but TS doesn't, so we do some filthy casting here.
  const installedProviders: IssueTrackerProviderEnabled[] = integrations
    .filter((x) => x.installed)
    .filter((x) =>
      Object.values(IssueTrackerProviderEnum).includes(
        x.provider as unknown as IssueTrackerProviderEnum,
      ),
    )
    .map((integration) => {
      return {
        provider: integration.provider as unknown as IssueTrackerProviderEnum,
        disabled: integration.reconnection_reason !== "",
      };
    });

  return installedProviders;
};

export const ISSUE_TRACKER_PROVIDERS: {
  [key in IssueTrackerProviderEnum]: {
    issueNoun: string;
    issueUrlExample: string;
  };
} = {
  [IssueTrackerProviderEnum.Asana]: {
    issueNoun: "task",
    issueUrlExample: "https://app.asana.com/0/1234567890/1234567890",
  },
  [IssueTrackerProviderEnum.AzureDevops]: {
    issueNoun: "issue",
    issueUrlExample:
      "https://dev.azure.com/my-org/my-project/_workitems/edit/123",
  },
  [IssueTrackerProviderEnum.ClickUp]: {
    issueNoun: "task",
    issueUrlExample: "https://app.clickup.com/t/1234567",
  },
  [IssueTrackerProviderEnum.Github]: {
    issueNoun: "issue",
    issueUrlExample: "https://github.com/my-org/my-repo/issue/123",
  },
  [IssueTrackerProviderEnum.Gitlab]: {
    issueNoun: "issue",
    issueUrlExample:
      "https://gitlab.example.com/my-group/my-project/-/issues/123",
  },
  [IssueTrackerProviderEnum.Jira]: {
    issueNoun: "ticket",
    issueUrlExample: "https://example.atlassian.net/browse/TEST-123",
  },
  [IssueTrackerProviderEnum.JiraServer]: {
    issueNoun: "ticket",
    issueUrlExample: "https://jira.example.com/browse/TEST-123",
  },
  [IssueTrackerProviderEnum.Linear]: {
    issueNoun: "issue",
    issueUrlExample: "https://linear.app/my-team/issue/TEST-123/fix-this-bug",
  },
  [IssueTrackerProviderEnum.Shortcut]: {
    issueNoun: "story",
    issueUrlExample: "https://app.shortcut.com/my-org/story/1234/fix-this-bug",
  },
};
