import { yaml } from "@codemirror/lang-yaml";
import { EditorState } from "@codemirror/state";
import { basicSetup, EditorView } from "codemirror";
import React, { useEffect, useRef } from "react";
import { getColor } from "src/utils/twConfig";

interface YAMLEditorProps {
  height?: string;
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

export const YAMLEditor = ({
  height,
  disabled,
  value,
  onChange,
}: YAMLEditorProps): React.ReactElement => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editorViewRef = useRef<EditorView | null>(null);

  // Initialize editor
  useEffect(() => {
    // Skip if we don't have a ref or editor is already initialized
    if (!editorRef.current || editorViewRef.current) {
      return;
    }

    const startingDoc = value || "";

    const state = EditorState.create({
      doc: startingDoc,
      extensions: [
        basicSetup,
        yaml(),
        EditorState.tabSize.of(2),
        EditorView.theme({
          "&": {
            height: height || "300px",
          },
          ".cm-content": {
            backgroundColor: getColor("slate", "50"),
            color: getColor("slate", "900"), // Match the text color
            padding: "16px",
          },
          ".cm-content, .cm-content *": {
            fontFamily: `"Source Code Pro", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
            fontSize: "12px",
          },
          ".cm-gutters": {
            backgroundColor: "white",
            color: "#333",
            border: "none",
          },
          ".cm-activeLine": {
            backgroundColor: getColor("slate", "200"), // Slightly darker than background for contrast
          },
          "&.cm-focused": {
            outline: "none",
          },
          ".cm-line": {
            padding: "0 4px",
          },
          // Match the selection color to be consistent
          "&.cm-focused .cm-selectionBackground, .cm-selectionBackground": {
            background: getColor("slate", "50"),
          },
        }),
        EditorView.updateListener.of((update) => {
          if (update.docChanged) {
            onChange(update.state.doc.toString());
          }
        }),
        EditorState.readOnly.of(!!disabled),
      ],
    });

    const view = new EditorView({
      state,
      parent: editorRef.current,
    });

    editorViewRef.current = view;

    // Cleanup function
    // eslint-disable-next-line consistent-return
    return () => {
      if (editorViewRef.current) {
        editorViewRef.current.destroy();
        editorViewRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, disabled, height]);

  // Update editor content if form value changes externally
  useEffect(() => {
    const view = editorViewRef.current;
    if (view && value !== view.state.doc.toString()) {
      view.dispatch({
        changes: {
          from: 0,
          to: view.state.doc.length,
          insert: value || "",
        },
      });
    }
  }, [value]);

  return (
    <div className="max-h-96 overflow-y-auto rounded-b-2">
      <div
        ref={editorRef}
        className="min-h-[100px] w-full max-w-full overflow-auto"
      />
    </div>
  );
};
