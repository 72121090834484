/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AIStaffAggregateInvestigationScorecardsRequestBody,
    AIStaffAggregateInvestigationScorecardsRequestBodyFromJSON,
    AIStaffAggregateInvestigationScorecardsRequestBodyToJSON,
    AIStaffAggregateInvestigationScorecardsResponseBody,
    AIStaffAggregateInvestigationScorecardsResponseBodyFromJSON,
    AIStaffAggregateInvestigationScorecardsResponseBodyToJSON,
    AIStaffBackfillProcessorRequestBody,
    AIStaffBackfillProcessorRequestBodyFromJSON,
    AIStaffBackfillProcessorRequestBodyToJSON,
    AIStaffCalculateDatasetDimensionsResponseBody,
    AIStaffCalculateDatasetDimensionsResponseBodyFromJSON,
    AIStaffCalculateDatasetDimensionsResponseBodyToJSON,
    AIStaffCreateAIRequestEvalOverrideRequestBody,
    AIStaffCreateAIRequestEvalOverrideRequestBodyFromJSON,
    AIStaffCreateAIRequestEvalOverrideRequestBodyToJSON,
    AIStaffCreateAIRequestEvalOverrideResponseBody,
    AIStaffCreateAIRequestEvalOverrideResponseBodyFromJSON,
    AIStaffCreateAIRequestEvalOverrideResponseBodyToJSON,
    AIStaffCreateEvaluationBacktestResponseBody,
    AIStaffCreateEvaluationBacktestResponseBodyFromJSON,
    AIStaffCreateEvaluationBacktestResponseBodyToJSON,
    AIStaffCreateEvaluationDatasetRequestBody,
    AIStaffCreateEvaluationDatasetRequestBodyFromJSON,
    AIStaffCreateEvaluationDatasetRequestBodyToJSON,
    AIStaffCreateEvaluationDatasetResponseBody,
    AIStaffCreateEvaluationDatasetResponseBodyFromJSON,
    AIStaffCreateEvaluationDatasetResponseBodyToJSON,
    AIStaffCreateEvaluationNoteRequestBody,
    AIStaffCreateEvaluationNoteRequestBodyFromJSON,
    AIStaffCreateEvaluationNoteRequestBodyToJSON,
    AIStaffCreateEvaluationNoteResponseBody,
    AIStaffCreateEvaluationNoteResponseBodyFromJSON,
    AIStaffCreateEvaluationNoteResponseBodyToJSON,
    AIStaffCreateIncidentGroundTruthRequestBody,
    AIStaffCreateIncidentGroundTruthRequestBodyFromJSON,
    AIStaffCreateIncidentGroundTruthRequestBodyToJSON,
    AIStaffCreateIncidentGroundTruthResponseBody,
    AIStaffCreateIncidentGroundTruthResponseBodyFromJSON,
    AIStaffCreateIncidentGroundTruthResponseBodyToJSON,
    AIStaffCreateInvestigationScorecardRequestBody,
    AIStaffCreateInvestigationScorecardRequestBodyFromJSON,
    AIStaffCreateInvestigationScorecardRequestBodyToJSON,
    AIStaffCreateInvestigationScorecardResponseBody,
    AIStaffCreateInvestigationScorecardResponseBodyFromJSON,
    AIStaffCreateInvestigationScorecardResponseBodyToJSON,
    AIStaffCurateEvaluationDatasetRequestBody,
    AIStaffCurateEvaluationDatasetRequestBodyFromJSON,
    AIStaffCurateEvaluationDatasetRequestBodyToJSON,
    AIStaffCurateEvaluationDatasetResponseBody,
    AIStaffCurateEvaluationDatasetResponseBodyFromJSON,
    AIStaffCurateEvaluationDatasetResponseBodyToJSON,
    AIStaffGetTokenForAIRequestsResponseBody,
    AIStaffGetTokenForAIRequestsResponseBodyFromJSON,
    AIStaffGetTokenForAIRequestsResponseBodyToJSON,
    AIStaffGroupDatasetEntriesBySegmentResponseBody,
    AIStaffGroupDatasetEntriesBySegmentResponseBodyFromJSON,
    AIStaffGroupDatasetEntriesBySegmentResponseBodyToJSON,
    AIStaffListAIRequestsResponseBody,
    AIStaffListAIRequestsResponseBodyFromJSON,
    AIStaffListAIRequestsResponseBodyToJSON,
    AIStaffListAISpansResponseBody,
    AIStaffListAISpansResponseBodyFromJSON,
    AIStaffListAISpansResponseBodyToJSON,
    AIStaffListCopilotThreadsResponseBody,
    AIStaffListCopilotThreadsResponseBodyFromJSON,
    AIStaffListCopilotThreadsResponseBodyToJSON,
    AIStaffListEvaluationBacktestsResponseBody,
    AIStaffListEvaluationBacktestsResponseBodyFromJSON,
    AIStaffListEvaluationBacktestsResponseBodyToJSON,
    AIStaffListEvaluationDatasetsResponseBody,
    AIStaffListEvaluationDatasetsResponseBodyFromJSON,
    AIStaffListEvaluationDatasetsResponseBodyToJSON,
    AIStaffListEvaluationNotesResponseBody,
    AIStaffListEvaluationNotesResponseBodyFromJSON,
    AIStaffListEvaluationNotesResponseBodyToJSON,
    AIStaffListIncidentGroundTruthsResponseBody,
    AIStaffListIncidentGroundTruthsResponseBodyFromJSON,
    AIStaffListIncidentGroundTruthsResponseBodyToJSON,
    AIStaffListInvestigationScorecardsResponseBody,
    AIStaffListInvestigationScorecardsResponseBodyFromJSON,
    AIStaffListInvestigationScorecardsResponseBodyToJSON,
    AIStaffListProcessorsResponseBody,
    AIStaffListProcessorsResponseBodyFromJSON,
    AIStaffListProcessorsResponseBodyToJSON,
    AIStaffListPromptHealthReportsResponseBody,
    AIStaffListPromptHealthReportsResponseBodyFromJSON,
    AIStaffListPromptHealthReportsResponseBodyToJSON,
    AIStaffListPromptNamesResponseBody,
    AIStaffListPromptNamesResponseBodyFromJSON,
    AIStaffListPromptNamesResponseBodyToJSON,
    AIStaffListToolsResponseBody,
    AIStaffListToolsResponseBodyFromJSON,
    AIStaffListToolsResponseBodyToJSON,
    AIStaffListVectorsResponseBody,
    AIStaffListVectorsResponseBodyFromJSON,
    AIStaffListVectorsResponseBodyToJSON,
    AIStaffPreviewProcessorBackfillRequestBody,
    AIStaffPreviewProcessorBackfillRequestBodyFromJSON,
    AIStaffPreviewProcessorBackfillRequestBodyToJSON,
    AIStaffPreviewProcessorBackfillResponseBody,
    AIStaffPreviewProcessorBackfillResponseBodyFromJSON,
    AIStaffPreviewProcessorBackfillResponseBodyToJSON,
    AIStaffRegradeEvaluationBacktestEntriesRequestBody,
    AIStaffRegradeEvaluationBacktestEntriesRequestBodyFromJSON,
    AIStaffRegradeEvaluationBacktestEntriesRequestBodyToJSON,
    AIStaffRegradeEvaluationBacktestEntriesResponseBody,
    AIStaffRegradeEvaluationBacktestEntriesResponseBodyFromJSON,
    AIStaffRegradeEvaluationBacktestEntriesResponseBodyToJSON,
    AIStaffResumeEvaluationBacktestRequestBody,
    AIStaffResumeEvaluationBacktestRequestBodyFromJSON,
    AIStaffResumeEvaluationBacktestRequestBodyToJSON,
    AIStaffResumeEvaluationBacktestResponseBody,
    AIStaffResumeEvaluationBacktestResponseBodyFromJSON,
    AIStaffResumeEvaluationBacktestResponseBodyToJSON,
    AIStaffRunPromptHealthReportRequestBody,
    AIStaffRunPromptHealthReportRequestBodyFromJSON,
    AIStaffRunPromptHealthReportRequestBodyToJSON,
    AIStaffSearchAISpansResponseBody,
    AIStaffSearchAISpansResponseBodyFromJSON,
    AIStaffSearchAISpansResponseBodyToJSON,
    AIStaffShowAIRequestEvalResponseBody,
    AIStaffShowAIRequestEvalResponseBodyFromJSON,
    AIStaffShowAIRequestEvalResponseBodyToJSON,
    AIStaffShowAIRequestResponseBody,
    AIStaffShowAIRequestResponseBodyFromJSON,
    AIStaffShowAIRequestResponseBodyToJSON,
    AIStaffShowCopilotInteractionThreadContextResponseBody,
    AIStaffShowCopilotInteractionThreadContextResponseBodyFromJSON,
    AIStaffShowCopilotInteractionThreadContextResponseBodyToJSON,
    AIStaffShowCopilotThreadResponseBody,
    AIStaffShowCopilotThreadResponseBodyFromJSON,
    AIStaffShowCopilotThreadResponseBodyToJSON,
    AIStaffShowEvaluationBacktestResponseBody,
    AIStaffShowEvaluationBacktestResponseBodyFromJSON,
    AIStaffShowEvaluationBacktestResponseBodyToJSON,
    AIStaffShowEvaluationDatasetResponseBody,
    AIStaffShowEvaluationDatasetResponseBodyFromJSON,
    AIStaffShowEvaluationDatasetResponseBodyToJSON,
    AIStaffShowPromptHealthReportResponseBody,
    AIStaffShowPromptHealthReportResponseBodyFromJSON,
    AIStaffShowPromptHealthReportResponseBodyToJSON,
    AIStaffSuggestFixedEvalResultRequestBody,
    AIStaffSuggestFixedEvalResultRequestBodyFromJSON,
    AIStaffSuggestFixedEvalResultRequestBodyToJSON,
    AIStaffSuggestFixedEvalResultResponseBody,
    AIStaffSuggestFixedEvalResultResponseBodyFromJSON,
    AIStaffSuggestFixedEvalResultResponseBodyToJSON,
    AIStaffUpdateEvaluationDatasetRequestBody,
    AIStaffUpdateEvaluationDatasetRequestBodyFromJSON,
    AIStaffUpdateEvaluationDatasetRequestBodyToJSON,
    AIStaffUpdateEvaluationDatasetResponseBody,
    AIStaffUpdateEvaluationDatasetResponseBodyFromJSON,
    AIStaffUpdateEvaluationDatasetResponseBodyToJSON,
    AIStaffUpdateEvaluationNoteRequestBody,
    AIStaffUpdateEvaluationNoteRequestBodyFromJSON,
    AIStaffUpdateEvaluationNoteRequestBodyToJSON,
    AIStaffUpdateEvaluationNoteResponseBody,
    AIStaffUpdateEvaluationNoteResponseBodyFromJSON,
    AIStaffUpdateEvaluationNoteResponseBodyToJSON,
    AIStaffUpdateIncidentGroundTruthRequestBody,
    AIStaffUpdateIncidentGroundTruthRequestBodyFromJSON,
    AIStaffUpdateIncidentGroundTruthRequestBodyToJSON,
    AIStaffUpdateIncidentGroundTruthResponseBody,
    AIStaffUpdateIncidentGroundTruthResponseBodyFromJSON,
    AIStaffUpdateIncidentGroundTruthResponseBodyToJSON,
    EvaluationBacktestPayload,
    EvaluationBacktestPayloadFromJSON,
    EvaluationBacktestPayloadToJSON,
} from '../models';

export interface AIStaffAggregateInvestigationScorecardsRequest {
    aggregateInvestigationScorecardsRequestBody: AIStaffAggregateInvestigationScorecardsRequestBody;
}

export interface AIStaffBackfillProcessorRequest {
    backfillProcessorRequestBody: AIStaffBackfillProcessorRequestBody;
}

export interface AIStaffCalculateDatasetDimensionsRequest {
    entryIds: Array<string>;
    id: string;
}

export interface AIStaffCreateAIRequestEvalOverrideRequest {
    createAIRequestEvalOverrideRequestBody: AIStaffCreateAIRequestEvalOverrideRequestBody;
}

export interface AIStaffCreateEvaluationBacktestRequest {
    createEvaluationBacktestRequestBody: EvaluationBacktestPayload;
}

export interface AIStaffCreateEvaluationDatasetRequest {
    createEvaluationDatasetRequestBody: AIStaffCreateEvaluationDatasetRequestBody;
}

export interface AIStaffCreateEvaluationNoteRequest {
    createEvaluationNoteRequestBody: AIStaffCreateEvaluationNoteRequestBody;
}

export interface AIStaffCreateIncidentGroundTruthRequest {
    createIncidentGroundTruthRequestBody: AIStaffCreateIncidentGroundTruthRequestBody;
}

export interface AIStaffCreateInvestigationScorecardRequest {
    createInvestigationScorecardRequestBody: AIStaffCreateInvestigationScorecardRequestBody;
}

export interface AIStaffCurateEvaluationDatasetRequest {
    id: string;
    curateEvaluationDatasetRequestBody: AIStaffCurateEvaluationDatasetRequestBody;
}

export interface AIStaffDestroyEvaluationDatasetRequest {
    id: string;
}

export interface AIStaffDestroyEvaluationNoteRequest {
    id: string;
}

export interface AIStaffGetTokenForAIRequestsRequest {
    ids: Array<string>;
}

export interface AIStaffGroupDatasetEntriesBySegmentRequest {
    id: string;
}

export interface AIStaffListAIRequestsRequest {
    traceId?: string;
    prompt?: string;
    copilotInteractionId?: string;
    threadId?: string;
    investigationCheckId?: string;
    calledTool?: string;
}

export interface AIStaffListAISpansRequest {
    id?: string;
    traceId?: string;
    type?: AIStaffListAISpansTypeEnum;
    name?: string;
    resourceId?: string;
    includeChildSpans?: boolean;
}

export interface AIStaffListCopilotThreadsRequest {
    prompt?: string;
    tag?: string;
    score?: string;
    classification?: string;
    traceId?: string;
}

export interface AIStaffListEvaluationNotesRequest {
    resourceType: AIStaffListEvaluationNotesResourceTypeEnum;
    resourceId: string;
}

export interface AIStaffListIncidentGroundTruthsRequest {
    incidentId: string;
}

export interface AIStaffListInvestigationScorecardsRequest {
    investigationId: string;
}

export interface AIStaffListVectorsRequest {
    type: string;
}

export interface AIStaffPreviewProcessorBackfillRequest {
    previewProcessorBackfillRequestBody: AIStaffPreviewProcessorBackfillRequestBody;
}

export interface AIStaffRegradeEvaluationBacktestEntriesRequest {
    id: string;
    regradeEvaluationBacktestEntriesRequestBody: AIStaffRegradeEvaluationBacktestEntriesRequestBody;
}

export interface AIStaffResumeEvaluationBacktestRequest {
    id: string;
    resumeEvaluationBacktestRequestBody: AIStaffResumeEvaluationBacktestRequestBody;
}

export interface AIStaffRunPromptHealthReportRequest {
    runPromptHealthReportRequestBody: AIStaffRunPromptHealthReportRequestBody;
}

export interface AIStaffSearchAISpansRequest {
    spanIds: Array<string>;
    query: string;
}

export interface AIStaffShowAIRequestRequest {
    id: string;
}

export interface AIStaffShowAIRequestEvalRequest {
    fixturePath: string;
    fixtureKeys: Array<string>;
    caseName: string;
    id: string;
    authorization: string;
    resultOverrideId?: string;
}

export interface AIStaffShowCopilotInteractionThreadContextRequest {
    slackChannelId: string;
    threadTs: string;
}

export interface AIStaffShowCopilotThreadRequest {
    slackChannelId: string;
    threadTs: string;
}

export interface AIStaffShowEvaluationBacktestRequest {
    id: string;
}

export interface AIStaffShowEvaluationDatasetRequest {
    id: string;
}

export interface AIStaffShowPromptHealthReportRequest {
    id: string;
}

export interface AIStaffSuggestFixedEvalResultRequest {
    id: string;
    suggestFixedEvalResultRequestBody: AIStaffSuggestFixedEvalResultRequestBody;
}

export interface AIStaffUpdateEvaluationDatasetRequest {
    id: string;
    updateEvaluationDatasetRequestBody: AIStaffUpdateEvaluationDatasetRequestBody;
}

export interface AIStaffUpdateEvaluationNoteRequest {
    id: string;
    updateEvaluationNoteRequestBody: AIStaffUpdateEvaluationNoteRequestBody;
}

export interface AIStaffUpdateIncidentGroundTruthRequest {
    id: string;
    updateIncidentGroundTruthRequestBody: AIStaffUpdateIncidentGroundTruthRequestBody;
}

/**
 * 
 */
export class AIStaffApi extends runtime.BaseAPI {

    /**
     * Create an aggregate scorecard from multiple investigation scorecards
     * AggregateInvestigationScorecards AI Staff
     */
    async aIStaffAggregateInvestigationScorecardsRaw(requestParameters: AIStaffAggregateInvestigationScorecardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffAggregateInvestigationScorecardsResponseBody>> {
        if (requestParameters.aggregateInvestigationScorecardsRequestBody === null || requestParameters.aggregateInvestigationScorecardsRequestBody === undefined) {
            throw new runtime.RequiredError('aggregateInvestigationScorecardsRequestBody','Required parameter requestParameters.aggregateInvestigationScorecardsRequestBody was null or undefined when calling aIStaffAggregateInvestigationScorecards.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/investigation_aggregate_scorecards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffAggregateInvestigationScorecardsRequestBodyToJSON(requestParameters.aggregateInvestigationScorecardsRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffAggregateInvestigationScorecardsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create an aggregate scorecard from multiple investigation scorecards
     * AggregateInvestigationScorecards AI Staff
     */
    async aIStaffAggregateInvestigationScorecards(requestParameters: AIStaffAggregateInvestigationScorecardsRequest, initOverrides?: RequestInit): Promise<AIStaffAggregateInvestigationScorecardsResponseBody> {
        const response = await this.aIStaffAggregateInvestigationScorecardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a backfill for a processor
     * BackfillProcessor AI Staff
     */
    async aIStaffBackfillProcessorRaw(requestParameters: AIStaffBackfillProcessorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.backfillProcessorRequestBody === null || requestParameters.backfillProcessorRequestBody === undefined) {
            throw new runtime.RequiredError('backfillProcessorRequestBody','Required parameter requestParameters.backfillProcessorRequestBody was null or undefined when calling aIStaffBackfillProcessor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/processors/backfill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffBackfillProcessorRequestBodyToJSON(requestParameters.backfillProcessorRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Run a backfill for a processor
     * BackfillProcessor AI Staff
     */
    async aIStaffBackfillProcessor(requestParameters: AIStaffBackfillProcessorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aIStaffBackfillProcessorRaw(requestParameters, initOverrides);
    }

    /**
     * Calculate dimensions for a given list of incident IDs, for a given dataset.
     * CalculateDatasetDimensions AI Staff
     */
    async aIStaffCalculateDatasetDimensionsRaw(requestParameters: AIStaffCalculateDatasetDimensionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCalculateDatasetDimensionsResponseBody>> {
        if (requestParameters.entryIds === null || requestParameters.entryIds === undefined) {
            throw new runtime.RequiredError('entryIds','Required parameter requestParameters.entryIds was null or undefined when calling aIStaffCalculateDatasetDimensions.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffCalculateDatasetDimensions.');
        }

        const queryParameters: any = {};

        if (requestParameters.entryIds) {
            queryParameters['entry_ids'] = requestParameters.entryIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets/{id}/dimensions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCalculateDatasetDimensionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Calculate dimensions for a given list of incident IDs, for a given dataset.
     * CalculateDatasetDimensions AI Staff
     */
    async aIStaffCalculateDatasetDimensions(requestParameters: AIStaffCalculateDatasetDimensionsRequest, initOverrides?: RequestInit): Promise<AIStaffCalculateDatasetDimensionsResponseBody> {
        const response = await this.aIStaffCalculateDatasetDimensionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new AI request eval override
     * CreateAIRequestEvalOverride AI Staff
     */
    async aIStaffCreateAIRequestEvalOverrideRaw(requestParameters: AIStaffCreateAIRequestEvalOverrideRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateAIRequestEvalOverrideResponseBody>> {
        if (requestParameters.createAIRequestEvalOverrideRequestBody === null || requestParameters.createAIRequestEvalOverrideRequestBody === undefined) {
            throw new runtime.RequiredError('createAIRequestEvalOverrideRequestBody','Required parameter requestParameters.createAIRequestEvalOverrideRequestBody was null or undefined when calling aIStaffCreateAIRequestEvalOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/ai_request_eval_overrides`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCreateAIRequestEvalOverrideRequestBodyToJSON(requestParameters.createAIRequestEvalOverrideRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateAIRequestEvalOverrideResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new AI request eval override
     * CreateAIRequestEvalOverride AI Staff
     */
    async aIStaffCreateAIRequestEvalOverride(requestParameters: AIStaffCreateAIRequestEvalOverrideRequest, initOverrides?: RequestInit): Promise<AIStaffCreateAIRequestEvalOverrideResponseBody> {
        const response = await this.aIStaffCreateAIRequestEvalOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new backtest to evaluate investigation performance.
     * CreateEvaluationBacktest AI Staff
     */
    async aIStaffCreateEvaluationBacktestRaw(requestParameters: AIStaffCreateEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateEvaluationBacktestResponseBody>> {
        if (requestParameters.createEvaluationBacktestRequestBody === null || requestParameters.createEvaluationBacktestRequestBody === undefined) {
            throw new runtime.RequiredError('createEvaluationBacktestRequestBody','Required parameter requestParameters.createEvaluationBacktestRequestBody was null or undefined when calling aIStaffCreateEvaluationBacktest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EvaluationBacktestPayloadToJSON(requestParameters.createEvaluationBacktestRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateEvaluationBacktestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new backtest to evaluate investigation performance.
     * CreateEvaluationBacktest AI Staff
     */
    async aIStaffCreateEvaluationBacktest(requestParameters: AIStaffCreateEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<AIStaffCreateEvaluationBacktestResponseBody> {
        const response = await this.aIStaffCreateEvaluationBacktestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new dataset for evaluating investigation performance.
     * CreateEvaluationDataset AI Staff
     */
    async aIStaffCreateEvaluationDatasetRaw(requestParameters: AIStaffCreateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateEvaluationDatasetResponseBody>> {
        if (requestParameters.createEvaluationDatasetRequestBody === null || requestParameters.createEvaluationDatasetRequestBody === undefined) {
            throw new runtime.RequiredError('createEvaluationDatasetRequestBody','Required parameter requestParameters.createEvaluationDatasetRequestBody was null or undefined when calling aIStaffCreateEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCreateEvaluationDatasetRequestBodyToJSON(requestParameters.createEvaluationDatasetRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new dataset for evaluating investigation performance.
     * CreateEvaluationDataset AI Staff
     */
    async aIStaffCreateEvaluationDataset(requestParameters: AIStaffCreateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AIStaffCreateEvaluationDatasetResponseBody> {
        const response = await this.aIStaffCreateEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new evaluation note
     * CreateEvaluationNote AI Staff
     */
    async aIStaffCreateEvaluationNoteRaw(requestParameters: AIStaffCreateEvaluationNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateEvaluationNoteResponseBody>> {
        if (requestParameters.createEvaluationNoteRequestBody === null || requestParameters.createEvaluationNoteRequestBody === undefined) {
            throw new runtime.RequiredError('createEvaluationNoteRequestBody','Required parameter requestParameters.createEvaluationNoteRequestBody was null or undefined when calling aIStaffCreateEvaluationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_notes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCreateEvaluationNoteRequestBodyToJSON(requestParameters.createEvaluationNoteRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateEvaluationNoteResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new evaluation note
     * CreateEvaluationNote AI Staff
     */
    async aIStaffCreateEvaluationNote(requestParameters: AIStaffCreateEvaluationNoteRequest, initOverrides?: RequestInit): Promise<AIStaffCreateEvaluationNoteResponseBody> {
        const response = await this.aIStaffCreateEvaluationNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new ground truth for an incident
     * CreateIncidentGroundTruth AI Staff
     */
    async aIStaffCreateIncidentGroundTruthRaw(requestParameters: AIStaffCreateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateIncidentGroundTruthResponseBody>> {
        if (requestParameters.createIncidentGroundTruthRequestBody === null || requestParameters.createIncidentGroundTruthRequestBody === undefined) {
            throw new runtime.RequiredError('createIncidentGroundTruthRequestBody','Required parameter requestParameters.createIncidentGroundTruthRequestBody was null or undefined when calling aIStaffCreateIncidentGroundTruth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/incident_ground_truths`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCreateIncidentGroundTruthRequestBodyToJSON(requestParameters.createIncidentGroundTruthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateIncidentGroundTruthResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new ground truth for an incident
     * CreateIncidentGroundTruth AI Staff
     */
    async aIStaffCreateIncidentGroundTruth(requestParameters: AIStaffCreateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<AIStaffCreateIncidentGroundTruthResponseBody> {
        const response = await this.aIStaffCreateIncidentGroundTruthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new scorecard using most recent investigation data
     * CreateInvestigationScorecard AI Staff
     */
    async aIStaffCreateInvestigationScorecardRaw(requestParameters: AIStaffCreateInvestigationScorecardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCreateInvestigationScorecardResponseBody>> {
        if (requestParameters.createInvestigationScorecardRequestBody === null || requestParameters.createInvestigationScorecardRequestBody === undefined) {
            throw new runtime.RequiredError('createInvestigationScorecardRequestBody','Required parameter requestParameters.createInvestigationScorecardRequestBody was null or undefined when calling aIStaffCreateInvestigationScorecard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/investigation_scorecards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCreateInvestigationScorecardRequestBodyToJSON(requestParameters.createInvestigationScorecardRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCreateInvestigationScorecardResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a new scorecard using most recent investigation data
     * CreateInvestigationScorecard AI Staff
     */
    async aIStaffCreateInvestigationScorecard(requestParameters: AIStaffCreateInvestigationScorecardRequest, initOverrides?: RequestInit): Promise<AIStaffCreateInvestigationScorecardResponseBody> {
        const response = await this.aIStaffCreateInvestigationScorecardRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add or remove entries from a dataset.
     * CurateEvaluationDataset AI Staff
     */
    async aIStaffCurateEvaluationDatasetRaw(requestParameters: AIStaffCurateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffCurateEvaluationDatasetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffCurateEvaluationDataset.');
        }

        if (requestParameters.curateEvaluationDatasetRequestBody === null || requestParameters.curateEvaluationDatasetRequestBody === undefined) {
            throw new runtime.RequiredError('curateEvaluationDatasetRequestBody','Required parameter requestParameters.curateEvaluationDatasetRequestBody was null or undefined when calling aIStaffCurateEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets/{id}/actions/curate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffCurateEvaluationDatasetRequestBodyToJSON(requestParameters.curateEvaluationDatasetRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffCurateEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Add or remove entries from a dataset.
     * CurateEvaluationDataset AI Staff
     */
    async aIStaffCurateEvaluationDataset(requestParameters: AIStaffCurateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AIStaffCurateEvaluationDatasetResponseBody> {
        const response = await this.aIStaffCurateEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive an evaluation dataset.
     * DestroyEvaluationDataset AI Staff
     */
    async aIStaffDestroyEvaluationDatasetRaw(requestParameters: AIStaffDestroyEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffDestroyEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an evaluation dataset.
     * DestroyEvaluationDataset AI Staff
     */
    async aIStaffDestroyEvaluationDataset(requestParameters: AIStaffDestroyEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aIStaffDestroyEvaluationDatasetRaw(requestParameters, initOverrides);
    }

    /**
     * Archive an evaluation note
     * DestroyEvaluationNote AI Staff
     */
    async aIStaffDestroyEvaluationNoteRaw(requestParameters: AIStaffDestroyEvaluationNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffDestroyEvaluationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an evaluation note
     * DestroyEvaluationNote AI Staff
     */
    async aIStaffDestroyEvaluationNote(requestParameters: AIStaffDestroyEvaluationNoteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aIStaffDestroyEvaluationNoteRaw(requestParameters, initOverrides);
    }

    /**
     * Get a jwt that can be used to authenticate a request for multiple AI requests
     * GetTokenForAIRequests AI Staff
     */
    async aIStaffGetTokenForAIRequestsRaw(requestParameters: AIStaffGetTokenForAIRequestsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffGetTokenForAIRequestsResponseBody>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling aIStaffGetTokenForAIRequests.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_requests/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffGetTokenForAIRequestsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a jwt that can be used to authenticate a request for multiple AI requests
     * GetTokenForAIRequests AI Staff
     */
    async aIStaffGetTokenForAIRequests(requestParameters: AIStaffGetTokenForAIRequestsRequest, initOverrides?: RequestInit): Promise<AIStaffGetTokenForAIRequestsResponseBody> {
        const response = await this.aIStaffGetTokenForAIRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of which entries are in which segment (note this is quite an expensive operation).
     * GroupDatasetEntriesBySegment AI Staff
     */
    async aIStaffGroupDatasetEntriesBySegmentRaw(requestParameters: AIStaffGroupDatasetEntriesBySegmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffGroupDatasetEntriesBySegmentResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffGroupDatasetEntriesBySegment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets/{id}/entries_by_segment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffGroupDatasetEntriesBySegmentResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get a list of which entries are in which segment (note this is quite an expensive operation).
     * GroupDatasetEntriesBySegment AI Staff
     */
    async aIStaffGroupDatasetEntriesBySegment(requestParameters: AIStaffGroupDatasetEntriesBySegmentRequest, initOverrides?: RequestInit): Promise<AIStaffGroupDatasetEntriesBySegmentResponseBody> {
        const response = await this.aIStaffGroupDatasetEntriesBySegmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show YAML evals for given AI requests
     * ListAIRequests AI Staff
     */
    async aIStaffListAIRequestsRaw(requestParameters: AIStaffListAIRequestsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListAIRequestsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        if (requestParameters.copilotInteractionId !== undefined) {
            queryParameters['copilot_interaction_id'] = requestParameters.copilotInteractionId;
        }

        if (requestParameters.threadId !== undefined) {
            queryParameters['thread_id'] = requestParameters.threadId;
        }

        if (requestParameters.investigationCheckId !== undefined) {
            queryParameters['investigation_check_id'] = requestParameters.investigationCheckId;
        }

        if (requestParameters.calledTool !== undefined) {
            queryParameters['called_tool'] = requestParameters.calledTool;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_requests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListAIRequestsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show YAML evals for given AI requests
     * ListAIRequests AI Staff
     */
    async aIStaffListAIRequests(requestParameters: AIStaffListAIRequestsRequest, initOverrides?: RequestInit): Promise<AIStaffListAIRequestsResponseBody> {
        const response = await this.aIStaffListAIRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show spans for a given trace
     * ListAISpans AI Staff
     */
    async aIStaffListAISpansRaw(requestParameters: AIStaffListAISpansRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListAISpansResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }

        if (requestParameters.includeChildSpans !== undefined) {
            queryParameters['include_child_spans'] = requestParameters.includeChildSpans;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_spans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListAISpansResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show spans for a given trace
     * ListAISpans AI Staff
     */
    async aIStaffListAISpans(requestParameters: AIStaffListAISpansRequest, initOverrides?: RequestInit): Promise<AIStaffListAISpansResponseBody> {
        const response = await this.aIStaffListAISpansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lists recent threads between Copilot and users
     * ListCopilotThreads AI Staff
     */
    async aIStaffListCopilotThreadsRaw(requestParameters: AIStaffListCopilotThreadsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListCopilotThreadsResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.prompt !== undefined) {
            queryParameters['prompt'] = requestParameters.prompt;
        }

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.score !== undefined) {
            queryParameters['score'] = requestParameters.score;
        }

        if (requestParameters.classification !== undefined) {
            queryParameters['classification'] = requestParameters.classification;
        }

        if (requestParameters.traceId !== undefined) {
            queryParameters['trace_id'] = requestParameters.traceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_threads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListCopilotThreadsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists recent threads between Copilot and users
     * ListCopilotThreads AI Staff
     */
    async aIStaffListCopilotThreads(requestParameters: AIStaffListCopilotThreadsRequest, initOverrides?: RequestInit): Promise<AIStaffListCopilotThreadsResponseBody> {
        const response = await this.aIStaffListCopilotThreadsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all evaluation backtests.
     * ListEvaluationBacktests AI Staff
     */
    async aIStaffListEvaluationBacktestsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListEvaluationBacktestsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListEvaluationBacktestsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all evaluation backtests.
     * ListEvaluationBacktests AI Staff
     */
    async aIStaffListEvaluationBacktests(initOverrides?: RequestInit): Promise<AIStaffListEvaluationBacktestsResponseBody> {
        const response = await this.aIStaffListEvaluationBacktestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all incident datasets.
     * ListEvaluationDatasets AI Staff
     */
    async aIStaffListEvaluationDatasetsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListEvaluationDatasetsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListEvaluationDatasetsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all incident datasets.
     * ListEvaluationDatasets AI Staff
     */
    async aIStaffListEvaluationDatasets(initOverrides?: RequestInit): Promise<AIStaffListEvaluationDatasetsResponseBody> {
        const response = await this.aIStaffListEvaluationDatasetsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List evaluation notes for a given resource
     * ListEvaluationNotes AI Staff
     */
    async aIStaffListEvaluationNotesRaw(requestParameters: AIStaffListEvaluationNotesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListEvaluationNotesResponseBody>> {
        if (requestParameters.resourceType === null || requestParameters.resourceType === undefined) {
            throw new runtime.RequiredError('resourceType','Required parameter requestParameters.resourceType was null or undefined when calling aIStaffListEvaluationNotes.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling aIStaffListEvaluationNotes.');
        }

        const queryParameters: any = {};

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resource_type'] = requestParameters.resourceType;
        }

        if (requestParameters.resourceId !== undefined) {
            queryParameters['resource_id'] = requestParameters.resourceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_notes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListEvaluationNotesResponseBodyFromJSON(jsonValue));
    }

    /**
     * List evaluation notes for a given resource
     * ListEvaluationNotes AI Staff
     */
    async aIStaffListEvaluationNotes(requestParameters: AIStaffListEvaluationNotesRequest, initOverrides?: RequestInit): Promise<AIStaffListEvaluationNotesResponseBody> {
        const response = await this.aIStaffListEvaluationNotesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List ground truths for an incident
     * ListIncidentGroundTruths AI Staff
     */
    async aIStaffListIncidentGroundTruthsRaw(requestParameters: AIStaffListIncidentGroundTruthsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListIncidentGroundTruthsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling aIStaffListIncidentGroundTruths.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/incident_ground_truths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListIncidentGroundTruthsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List ground truths for an incident
     * ListIncidentGroundTruths AI Staff
     */
    async aIStaffListIncidentGroundTruths(requestParameters: AIStaffListIncidentGroundTruthsRequest, initOverrides?: RequestInit): Promise<AIStaffListIncidentGroundTruthsResponseBody> {
        const response = await this.aIStaffListIncidentGroundTruthsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the most recent scorecards for an investigation (up to 5)
     * ListInvestigationScorecards AI Staff
     */
    async aIStaffListInvestigationScorecardsRaw(requestParameters: AIStaffListInvestigationScorecardsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListInvestigationScorecardsResponseBody>> {
        if (requestParameters.investigationId === null || requestParameters.investigationId === undefined) {
            throw new runtime.RequiredError('investigationId','Required parameter requestParameters.investigationId was null or undefined when calling aIStaffListInvestigationScorecards.');
        }

        const queryParameters: any = {};

        if (requestParameters.investigationId !== undefined) {
            queryParameters['investigation_id'] = requestParameters.investigationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/investigation_scorecards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListInvestigationScorecardsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List the most recent scorecards for an investigation (up to 5)
     * ListInvestigationScorecards AI Staff
     */
    async aIStaffListInvestigationScorecards(requestParameters: AIStaffListInvestigationScorecardsRequest, initOverrides?: RequestInit): Promise<AIStaffListInvestigationScorecardsResponseBody> {
        const response = await this.aIStaffListInvestigationScorecardsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all registered processors
     * ListProcessors AI Staff
     */
    async aIStaffListProcessorsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListProcessorsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/processors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListProcessorsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all registered processors
     * ListProcessors AI Staff
     */
    async aIStaffListProcessors(initOverrides?: RequestInit): Promise<AIStaffListProcessorsResponseBody> {
        const response = await this.aIStaffListProcessorsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show recent prompt health reports
     * ListPromptHealthReports AI Staff
     */
    async aIStaffListPromptHealthReportsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListPromptHealthReportsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/health_reports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListPromptHealthReportsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show recent prompt health reports
     * ListPromptHealthReports AI Staff
     */
    async aIStaffListPromptHealthReports(initOverrides?: RequestInit): Promise<AIStaffListPromptHealthReportsResponseBody> {
        const response = await this.aIStaffListPromptHealthReportsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Lists all registered prompts in the system.
     * ListPromptNames AI Staff
     */
    async aIStaffListPromptNamesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListPromptNamesResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/prompts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListPromptNamesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Lists all registered prompts in the system.
     * ListPromptNames AI Staff
     */
    async aIStaffListPromptNames(initOverrides?: RequestInit): Promise<AIStaffListPromptNamesResponseBody> {
        const response = await this.aIStaffListPromptNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all registered tools
     * ListTools AI Staff
     */
    async aIStaffListToolsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListToolsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/registered_tools`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListToolsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all registered tools
     * ListTools AI Staff
     */
    async aIStaffListTools(initOverrides?: RequestInit): Promise<AIStaffListToolsResponseBody> {
        const response = await this.aIStaffListToolsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show all vectors of a given type for an Organisation
     * ListVectors AI Staff
     */
    async aIStaffListVectorsRaw(requestParameters: AIStaffListVectorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffListVectorsResponseBody>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling aIStaffListVectors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/vectors/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffListVectorsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show all vectors of a given type for an Organisation
     * ListVectors AI Staff
     */
    async aIStaffListVectors(requestParameters: AIStaffListVectorsRequest, initOverrides?: RequestInit): Promise<AIStaffListVectorsResponseBody> {
        const response = await this.aIStaffListVectorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview what a processor backfill might do
     * PreviewProcessorBackfill AI Staff
     */
    async aIStaffPreviewProcessorBackfillRaw(requestParameters: AIStaffPreviewProcessorBackfillRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffPreviewProcessorBackfillResponseBody>> {
        if (requestParameters.previewProcessorBackfillRequestBody === null || requestParameters.previewProcessorBackfillRequestBody === undefined) {
            throw new runtime.RequiredError('previewProcessorBackfillRequestBody','Required parameter requestParameters.previewProcessorBackfillRequestBody was null or undefined when calling aIStaffPreviewProcessorBackfill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/processors/backfill/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffPreviewProcessorBackfillRequestBodyToJSON(requestParameters.previewProcessorBackfillRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffPreviewProcessorBackfillResponseBodyFromJSON(jsonValue));
    }

    /**
     * Preview what a processor backfill might do
     * PreviewProcessorBackfill AI Staff
     */
    async aIStaffPreviewProcessorBackfill(requestParameters: AIStaffPreviewProcessorBackfillRequest, initOverrides?: RequestInit): Promise<AIStaffPreviewProcessorBackfillResponseBody> {
        const response = await this.aIStaffPreviewProcessorBackfillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Re-run the graders on a list of provided entries.
     * RegradeEvaluationBacktestEntries AI Staff
     */
    async aIStaffRegradeEvaluationBacktestEntriesRaw(requestParameters: AIStaffRegradeEvaluationBacktestEntriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffRegradeEvaluationBacktestEntriesResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffRegradeEvaluationBacktestEntries.');
        }

        if (requestParameters.regradeEvaluationBacktestEntriesRequestBody === null || requestParameters.regradeEvaluationBacktestEntriesRequestBody === undefined) {
            throw new runtime.RequiredError('regradeEvaluationBacktestEntriesRequestBody','Required parameter requestParameters.regradeEvaluationBacktestEntriesRequestBody was null or undefined when calling aIStaffRegradeEvaluationBacktestEntries.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests/{id}/actions/regrade`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffRegradeEvaluationBacktestEntriesRequestBodyToJSON(requestParameters.regradeEvaluationBacktestEntriesRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffRegradeEvaluationBacktestEntriesResponseBodyFromJSON(jsonValue));
    }

    /**
     * Re-run the graders on a list of provided entries.
     * RegradeEvaluationBacktestEntries AI Staff
     */
    async aIStaffRegradeEvaluationBacktestEntries(requestParameters: AIStaffRegradeEvaluationBacktestEntriesRequest, initOverrides?: RequestInit): Promise<AIStaffRegradeEvaluationBacktestEntriesResponseBody> {
        const response = await this.aIStaffRegradeEvaluationBacktestEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resume a paused backtest, optionally setting new credit limit.
     * ResumeEvaluationBacktest AI Staff
     */
    async aIStaffResumeEvaluationBacktestRaw(requestParameters: AIStaffResumeEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffResumeEvaluationBacktestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffResumeEvaluationBacktest.');
        }

        if (requestParameters.resumeEvaluationBacktestRequestBody === null || requestParameters.resumeEvaluationBacktestRequestBody === undefined) {
            throw new runtime.RequiredError('resumeEvaluationBacktestRequestBody','Required parameter requestParameters.resumeEvaluationBacktestRequestBody was null or undefined when calling aIStaffResumeEvaluationBacktest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests/{id}/actions/resume`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffResumeEvaluationBacktestRequestBodyToJSON(requestParameters.resumeEvaluationBacktestRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffResumeEvaluationBacktestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Resume a paused backtest, optionally setting new credit limit.
     * ResumeEvaluationBacktest AI Staff
     */
    async aIStaffResumeEvaluationBacktest(requestParameters: AIStaffResumeEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<AIStaffResumeEvaluationBacktestResponseBody> {
        const response = await this.aIStaffResumeEvaluationBacktestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Run a prompt health report
     * RunPromptHealthReport AI Staff
     */
    async aIStaffRunPromptHealthReportRaw(requestParameters: AIStaffRunPromptHealthReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.runPromptHealthReportRequestBody === null || requestParameters.runPromptHealthReportRequestBody === undefined) {
            throw new runtime.RequiredError('runPromptHealthReportRequestBody','Required parameter requestParameters.runPromptHealthReportRequestBody was null or undefined when calling aIStaffRunPromptHealthReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/health_reports/actions/run`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffRunPromptHealthReportRequestBodyToJSON(requestParameters.runPromptHealthReportRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Run a prompt health report
     * RunPromptHealthReport AI Staff
     */
    async aIStaffRunPromptHealthReport(requestParameters: AIStaffRunPromptHealthReportRequest, initOverrides?: RequestInit): Promise<void> {
        await this.aIStaffRunPromptHealthReportRaw(requestParameters, initOverrides);
    }

    /**
     * Find a given string in a list of AI spans (and their associated completion requests)
     * SearchAISpans AI Staff
     */
    async aIStaffSearchAISpansRaw(requestParameters: AIStaffSearchAISpansRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffSearchAISpansResponseBody>> {
        if (requestParameters.spanIds === null || requestParameters.spanIds === undefined) {
            throw new runtime.RequiredError('spanIds','Required parameter requestParameters.spanIds was null or undefined when calling aIStaffSearchAISpans.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling aIStaffSearchAISpans.');
        }

        const queryParameters: any = {};

        if (requestParameters.spanIds) {
            queryParameters['span_ids'] = requestParameters.spanIds;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_spans/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffSearchAISpansResponseBodyFromJSON(jsonValue));
    }

    /**
     * Find a given string in a list of AI spans (and their associated completion requests)
     * SearchAISpans AI Staff
     */
    async aIStaffSearchAISpans(requestParameters: AIStaffSearchAISpansRequest, initOverrides?: RequestInit): Promise<AIStaffSearchAISpansResponseBody> {
        const response = await this.aIStaffSearchAISpansRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show YAML evals for given AI request, including the AI span
     * ShowAIRequest AI Staff
     */
    async aIStaffShowAIRequestRaw(requestParameters: AIStaffShowAIRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowAIRequestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowAIRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/ai_requests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowAIRequestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show YAML evals for given AI request, including the AI span
     * ShowAIRequest AI Staff
     */
    async aIStaffShowAIRequest(requestParameters: AIStaffShowAIRequestRequest, initOverrides?: RequestInit): Promise<AIStaffShowAIRequestResponseBody> {
        const response = await this.aIStaffShowAIRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show evals for given AI request, with large inputs split into separate files
     * ShowAIRequestEval AI Staff
     */
    async aIStaffShowAIRequestEvalRaw(requestParameters: AIStaffShowAIRequestEvalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowAIRequestEvalResponseBody>> {
        if (requestParameters.fixturePath === null || requestParameters.fixturePath === undefined) {
            throw new runtime.RequiredError('fixturePath','Required parameter requestParameters.fixturePath was null or undefined when calling aIStaffShowAIRequestEval.');
        }

        if (requestParameters.fixtureKeys === null || requestParameters.fixtureKeys === undefined) {
            throw new runtime.RequiredError('fixtureKeys','Required parameter requestParameters.fixtureKeys was null or undefined when calling aIStaffShowAIRequestEval.');
        }

        if (requestParameters.caseName === null || requestParameters.caseName === undefined) {
            throw new runtime.RequiredError('caseName','Required parameter requestParameters.caseName was null or undefined when calling aIStaffShowAIRequestEval.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowAIRequestEval.');
        }

        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling aIStaffShowAIRequestEval.');
        }

        const queryParameters: any = {};

        if (requestParameters.resultOverrideId !== undefined) {
            queryParameters['result_override_id'] = requestParameters.resultOverrideId;
        }

        if (requestParameters.fixturePath !== undefined) {
            queryParameters['fixture_path'] = requestParameters.fixturePath;
        }

        if (requestParameters.fixtureKeys) {
            queryParameters['fixture_keys'] = requestParameters.fixtureKeys;
        }

        if (requestParameters.caseName !== undefined) {
            queryParameters['case_name'] = requestParameters.caseName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        const response = await this.request({
            path: `/api/ai_staff/ai_requests/{id}/eval`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowAIRequestEvalResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show evals for given AI request, with large inputs split into separate files
     * ShowAIRequestEval AI Staff
     */
    async aIStaffShowAIRequestEval(requestParameters: AIStaffShowAIRequestEvalRequest, initOverrides?: RequestInit): Promise<AIStaffShowAIRequestEvalResponseBody> {
        const response = await this.aIStaffShowAIRequestEvalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the context for a given Copilot interaction
     * ShowCopilotInteractionThreadContext AI Staff
     */
    async aIStaffShowCopilotInteractionThreadContextRaw(requestParameters: AIStaffShowCopilotInteractionThreadContextRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowCopilotInteractionThreadContextResponseBody>> {
        if (requestParameters.slackChannelId === null || requestParameters.slackChannelId === undefined) {
            throw new runtime.RequiredError('slackChannelId','Required parameter requestParameters.slackChannelId was null or undefined when calling aIStaffShowCopilotInteractionThreadContext.');
        }

        if (requestParameters.threadTs === null || requestParameters.threadTs === undefined) {
            throw new runtime.RequiredError('threadTs','Required parameter requestParameters.threadTs was null or undefined when calling aIStaffShowCopilotInteractionThreadContext.');
        }

        const queryParameters: any = {};

        if (requestParameters.slackChannelId !== undefined) {
            queryParameters['slack_channel_id'] = requestParameters.slackChannelId;
        }

        if (requestParameters.threadTs !== undefined) {
            queryParameters['thread_ts'] = requestParameters.threadTs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_interaction_thread_context`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowCopilotInteractionThreadContextResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show the context for a given Copilot interaction
     * ShowCopilotInteractionThreadContext AI Staff
     */
    async aIStaffShowCopilotInteractionThreadContext(requestParameters: AIStaffShowCopilotInteractionThreadContextRequest, initOverrides?: RequestInit): Promise<AIStaffShowCopilotInteractionThreadContextResponseBody> {
        const response = await this.aIStaffShowCopilotInteractionThreadContextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a Copilot thread
     * ShowCopilotThread AI Staff
     */
    async aIStaffShowCopilotThreadRaw(requestParameters: AIStaffShowCopilotThreadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowCopilotThreadResponseBody>> {
        if (requestParameters.slackChannelId === null || requestParameters.slackChannelId === undefined) {
            throw new runtime.RequiredError('slackChannelId','Required parameter requestParameters.slackChannelId was null or undefined when calling aIStaffShowCopilotThread.');
        }

        if (requestParameters.threadTs === null || requestParameters.threadTs === undefined) {
            throw new runtime.RequiredError('threadTs','Required parameter requestParameters.threadTs was null or undefined when calling aIStaffShowCopilotThread.');
        }

        const queryParameters: any = {};

        if (requestParameters.slackChannelId !== undefined) {
            queryParameters['slack_channel_id'] = requestParameters.slackChannelId;
        }

        if (requestParameters.threadTs !== undefined) {
            queryParameters['thread_ts'] = requestParameters.threadTs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/copilot_thread`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowCopilotThreadResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a Copilot thread
     * ShowCopilotThread AI Staff
     */
    async aIStaffShowCopilotThread(requestParameters: AIStaffShowCopilotThreadRequest, initOverrides?: RequestInit): Promise<AIStaffShowCopilotThreadResponseBody> {
        const response = await this.aIStaffShowCopilotThreadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single backtest including all its entries.
     * ShowEvaluationBacktest AI Staff
     */
    async aIStaffShowEvaluationBacktestRaw(requestParameters: AIStaffShowEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowEvaluationBacktestResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowEvaluationBacktest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_backtests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowEvaluationBacktestResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single backtest including all its entries.
     * ShowEvaluationBacktest AI Staff
     */
    async aIStaffShowEvaluationBacktest(requestParameters: AIStaffShowEvaluationBacktestRequest, initOverrides?: RequestInit): Promise<AIStaffShowEvaluationBacktestResponseBody> {
        const response = await this.aIStaffShowEvaluationBacktestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single dataset including its dimensions.
     * ShowEvaluationDataset AI Staff
     */
    async aIStaffShowEvaluationDatasetRaw(requestParameters: AIStaffShowEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowEvaluationDatasetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a single dataset including its dimensions.
     * ShowEvaluationDataset AI Staff
     */
    async aIStaffShowEvaluationDataset(requestParameters: AIStaffShowEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AIStaffShowEvaluationDatasetResponseBody> {
        const response = await this.aIStaffShowEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a prompt health report
     * ShowPromptHealthReport AI Staff
     */
    async aIStaffShowPromptHealthReportRaw(requestParameters: AIStaffShowPromptHealthReportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffShowPromptHealthReportResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffShowPromptHealthReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ai_staff/health_reports/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffShowPromptHealthReportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a prompt health report
     * ShowPromptHealthReport AI Staff
     */
    async aIStaffShowPromptHealthReport(requestParameters: AIStaffShowPromptHealthReportRequest, initOverrides?: RequestInit): Promise<AIStaffShowPromptHealthReportResponseBody> {
        const response = await this.aIStaffShowPromptHealthReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Suggests a fixed eval result for an AI request based on a provided prompt
     * SuggestFixedEvalResult AI Staff
     */
    async aIStaffSuggestFixedEvalResultRaw(requestParameters: AIStaffSuggestFixedEvalResultRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffSuggestFixedEvalResultResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffSuggestFixedEvalResult.');
        }

        if (requestParameters.suggestFixedEvalResultRequestBody === null || requestParameters.suggestFixedEvalResultRequestBody === undefined) {
            throw new runtime.RequiredError('suggestFixedEvalResultRequestBody','Required parameter requestParameters.suggestFixedEvalResultRequestBody was null or undefined when calling aIStaffSuggestFixedEvalResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/ai_requests/{id}/suggest_fixed_eval_result`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffSuggestFixedEvalResultRequestBodyToJSON(requestParameters.suggestFixedEvalResultRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffSuggestFixedEvalResultResponseBodyFromJSON(jsonValue));
    }

    /**
     * Suggests a fixed eval result for an AI request based on a provided prompt
     * SuggestFixedEvalResult AI Staff
     */
    async aIStaffSuggestFixedEvalResult(requestParameters: AIStaffSuggestFixedEvalResultRequest, initOverrides?: RequestInit): Promise<AIStaffSuggestFixedEvalResultResponseBody> {
        const response = await this.aIStaffSuggestFixedEvalResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing incident dataset.
     * UpdateEvaluationDataset AI Staff
     */
    async aIStaffUpdateEvaluationDatasetRaw(requestParameters: AIStaffUpdateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffUpdateEvaluationDatasetResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffUpdateEvaluationDataset.');
        }

        if (requestParameters.updateEvaluationDatasetRequestBody === null || requestParameters.updateEvaluationDatasetRequestBody === undefined) {
            throw new runtime.RequiredError('updateEvaluationDatasetRequestBody','Required parameter requestParameters.updateEvaluationDatasetRequestBody was null or undefined when calling aIStaffUpdateEvaluationDataset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_datasets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffUpdateEvaluationDatasetRequestBodyToJSON(requestParameters.updateEvaluationDatasetRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffUpdateEvaluationDatasetResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing incident dataset.
     * UpdateEvaluationDataset AI Staff
     */
    async aIStaffUpdateEvaluationDataset(requestParameters: AIStaffUpdateEvaluationDatasetRequest, initOverrides?: RequestInit): Promise<AIStaffUpdateEvaluationDatasetResponseBody> {
        const response = await this.aIStaffUpdateEvaluationDatasetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing evaluation note
     * UpdateEvaluationNote AI Staff
     */
    async aIStaffUpdateEvaluationNoteRaw(requestParameters: AIStaffUpdateEvaluationNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffUpdateEvaluationNoteResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffUpdateEvaluationNote.');
        }

        if (requestParameters.updateEvaluationNoteRequestBody === null || requestParameters.updateEvaluationNoteRequestBody === undefined) {
            throw new runtime.RequiredError('updateEvaluationNoteRequestBody','Required parameter requestParameters.updateEvaluationNoteRequestBody was null or undefined when calling aIStaffUpdateEvaluationNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/evaluation_notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffUpdateEvaluationNoteRequestBodyToJSON(requestParameters.updateEvaluationNoteRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffUpdateEvaluationNoteResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing evaluation note
     * UpdateEvaluationNote AI Staff
     */
    async aIStaffUpdateEvaluationNote(requestParameters: AIStaffUpdateEvaluationNoteRequest, initOverrides?: RequestInit): Promise<AIStaffUpdateEvaluationNoteResponseBody> {
        const response = await this.aIStaffUpdateEvaluationNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing ground truth
     * UpdateIncidentGroundTruth AI Staff
     */
    async aIStaffUpdateIncidentGroundTruthRaw(requestParameters: AIStaffUpdateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AIStaffUpdateIncidentGroundTruthResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling aIStaffUpdateIncidentGroundTruth.');
        }

        if (requestParameters.updateIncidentGroundTruthRequestBody === null || requestParameters.updateIncidentGroundTruthRequestBody === undefined) {
            throw new runtime.RequiredError('updateIncidentGroundTruthRequestBody','Required parameter requestParameters.updateIncidentGroundTruthRequestBody was null or undefined when calling aIStaffUpdateIncidentGroundTruth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/ai_staff/incident_ground_truths/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AIStaffUpdateIncidentGroundTruthRequestBodyToJSON(requestParameters.updateIncidentGroundTruthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AIStaffUpdateIncidentGroundTruthResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update an existing ground truth
     * UpdateIncidentGroundTruth AI Staff
     */
    async aIStaffUpdateIncidentGroundTruth(requestParameters: AIStaffUpdateIncidentGroundTruthRequest, initOverrides?: RequestInit): Promise<AIStaffUpdateIncidentGroundTruthResponseBody> {
        const response = await this.aIStaffUpdateIncidentGroundTruthRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum AIStaffListAISpansTypeEnum {
    Prompt = 'prompt',
    Processor = 'processor',
    CopilotInteraction = 'copilot_interaction',
    Scorecard = 'scorecard',
    Grader = 'grader',
    InvestigationCheck = 'investigation_check',
    CompletionRequest = 'completion_request',
    ToolCall = 'tool_call'
}
/**
    * @export
    * @enum {string}
    */
export enum AIStaffListEvaluationNotesResourceTypeEnum {
    Alert = 'alert',
    Incident = 'incident',
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    Suggestion = 'suggestion'
}
