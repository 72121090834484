/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TextDocument,
    TextDocumentFromJSON,
    TextDocumentFromJSONTyped,
    TextDocumentToJSON,
} from './TextDocument';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EvaluationNote
 */
export interface EvaluationNote {
    /**
     * 
     * @type {TextDocument}
     * @memberof EvaluationNote
     */
    content: TextDocument;
    /**
     * When the note was created
     * @type {Date}
     * @memberof EvaluationNote
     */
    created_at: Date;
    /**
     * Unique identifier
     * @type {string}
     * @memberof EvaluationNote
     */
    id: string;
    /**
     * When the note was last edited
     * @type {Date}
     * @memberof EvaluationNote
     */
    last_edited_at?: Date;
    /**
     * Organisation that owns this resource
     * @type {string}
     * @memberof EvaluationNote
     */
    organisation_id: string;
    /**
     * ID of the resource this note is attached to
     * @type {string}
     * @memberof EvaluationNote
     */
    resource_id: string;
    /**
     * Type of resource this note is attached to
     * @type {string}
     * @memberof EvaluationNote
     */
    resource_type: EvaluationNoteResourceTypeEnum;
    /**
     * When the note was last updated
     * @type {Date}
     * @memberof EvaluationNote
     */
    updated_at: Date;
    /**
     * 
     * @type {User}
     * @memberof EvaluationNote
     */
    user: User;
}

/**
* @export
* @enum {string}
*/
export enum EvaluationNoteResourceTypeEnum {
    Alert = 'alert',
    Incident = 'incident',
    Investigation = 'investigation',
    CopilotThread = 'copilot_thread',
    Suggestion = 'suggestion'
}

export function EvaluationNoteFromJSON(json: any): EvaluationNote {
    return EvaluationNoteFromJSONTyped(json, false);
}

export function EvaluationNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': TextDocumentFromJSON(json['content']),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'last_edited_at': !exists(json, 'last_edited_at') ? undefined : (new Date(json['last_edited_at'])),
        'organisation_id': json['organisation_id'],
        'resource_id': json['resource_id'],
        'resource_type': json['resource_type'],
        'updated_at': (new Date(json['updated_at'])),
        'user': UserFromJSON(json['user']),
    };
}

export function EvaluationNoteToJSON(value?: EvaluationNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': TextDocumentToJSON(value.content),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'last_edited_at': value.last_edited_at === undefined ? undefined : (value.last_edited_at.toISOString()),
        'organisation_id': value.organisation_id,
        'resource_id': value.resource_id,
        'resource_type': value.resource_type,
        'updated_at': (value.updated_at.toISOString()),
        'user': UserToJSON(value.user),
    };
}

