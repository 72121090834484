import {
  CustomField,
  PostIncidentTask,
  PostIncidentTaskSlim,
} from "@incident-io/api";
import { Incident } from "src/contexts/ClientContext";

export type ActionMode = "dropdown" | "button";

export enum PostIncidentActionModal {
  CreatePostMortem = "create-post-mortem",
  ExportPostMortem = "export-post-mortem",
  SharePostMortem = "share-post-mortem",
  ScheduleDebrief = "schedule-debrief",
  EditRoleAssignments = "edit-role-assignments",
  EditCustomFieldEntries = "edit-custom-field-entries",
  EditTimestamps = "edit-timestamps",
  GiveShoutout = "give-shoutout",
}

export type PostIncidentModalProps = {
  incident: Incident;
  shownRoleIds?: string[];
  filterToCustomFieldIDs?: string[];
  filterToTimestampIds?: string[];
  allCustomFields: CustomField[];
  onTaskComplete: () => void;
};

export type ItemProps = {
  incident: Incident;
  allCustomFields: CustomField[];
  incidentTask: PostIncidentTaskSlim | PostIncidentTask;
  mode: ActionMode;
  onError: (error: string) => void;
  onTaskComplete: () => void;
};
