/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';

/**
 * 
 * @export
 * @interface AIConfig
 */
export interface AIConfig {
    /**
     * Whether or not to enable the AI assistant
     * @type {boolean}
     * @memberof AIConfig
     */
    assistant_enabled: boolean;
    /**
     * Whether or not to enable the AI copilot
     * @type {boolean}
     * @memberof AIConfig
     */
    copilot_enabled: boolean;
    /**
     * Whether or not to enable AI-generated incident similarity
     * @type {boolean}
     * @memberof AIConfig
     */
    incident_similarity_enabled: boolean;
    /**
     * Lable to use for the causes section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_causes_label: string;
    /**
     * What condition groups must be met for the incident to have summaries suggested by AI?
     * @type {Array<ConditionGroup>}
     * @memberof AIConfig
     */
    incident_summaries_condition_groups: Array<ConditionGroup>;
    /**
     * Whether or not to enable AI-generated summaries for incidents
     * @type {boolean}
     * @memberof AIConfig
     */
    incident_summaries_enabled: boolean;
    /**
     * Lable to use for the impact section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_impact_label: string;
    /**
     * Lable to use for the problem section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_problem_label: string;
    /**
     * Label to use for the steps section in incident summaries
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_steps_label: string;
    /**
     * Content to use when a section is unknown in a summary
     * @type {string}
     * @memberof AIConfig
     */
    incident_summaries_unknown_label: string;
    /**
     * Whether or not the organisation has opted in to sending their data to OpenAI
     * @type {boolean}
     * @memberof AIConfig
     */
    openai_subprocessor_enabled: boolean;
    /**
     * Whether or not to enable call transcription
     * @type {boolean}
     * @memberof AIConfig
     */
    scribe_enabled: boolean;
    /**
     * Whether or not to enable AI-generated followups
     * @type {boolean}
     * @memberof AIConfig
     */
    suggest_followups_enabled: boolean;
}

export function AIConfigFromJSON(json: any): AIConfig {
    return AIConfigFromJSONTyped(json, false);
}

export function AIConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assistant_enabled': json['assistant_enabled'],
        'copilot_enabled': json['copilot_enabled'],
        'incident_similarity_enabled': json['incident_similarity_enabled'],
        'incident_summaries_causes_label': json['incident_summaries_causes_label'],
        'incident_summaries_condition_groups': ((json['incident_summaries_condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'incident_summaries_enabled': json['incident_summaries_enabled'],
        'incident_summaries_impact_label': json['incident_summaries_impact_label'],
        'incident_summaries_problem_label': json['incident_summaries_problem_label'],
        'incident_summaries_steps_label': json['incident_summaries_steps_label'],
        'incident_summaries_unknown_label': json['incident_summaries_unknown_label'],
        'openai_subprocessor_enabled': json['openai_subprocessor_enabled'],
        'scribe_enabled': json['scribe_enabled'],
        'suggest_followups_enabled': json['suggest_followups_enabled'],
    };
}

export function AIConfigToJSON(value?: AIConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assistant_enabled': value.assistant_enabled,
        'copilot_enabled': value.copilot_enabled,
        'incident_similarity_enabled': value.incident_similarity_enabled,
        'incident_summaries_causes_label': value.incident_summaries_causes_label,
        'incident_summaries_condition_groups': ((value.incident_summaries_condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'incident_summaries_enabled': value.incident_summaries_enabled,
        'incident_summaries_impact_label': value.incident_summaries_impact_label,
        'incident_summaries_problem_label': value.incident_summaries_problem_label,
        'incident_summaries_steps_label': value.incident_summaries_steps_label,
        'incident_summaries_unknown_label': value.incident_summaries_unknown_label,
        'openai_subprocessor_enabled': value.openai_subprocessor_enabled,
        'scribe_enabled': value.scribe_enabled,
        'suggest_followups_enabled': value.suggest_followups_enabled,
    };
}

