/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertInsightsDatapoint,
    AlertInsightsDatapointFromJSON,
    AlertInsightsDatapointFromJSONTyped,
    AlertInsightsDatapointToJSON,
} from './AlertInsightsDatapoint';
import {
    MetricSubject,
    MetricSubjectFromJSON,
    MetricSubjectFromJSONTyped,
    MetricSubjectToJSON,
} from './MetricSubject';
import {
    Trend,
    TrendFromJSON,
    TrendFromJSONTyped,
    TrendToJSON,
} from './Trend';

/**
 * 
 * @export
 * @interface OverviewKeyInsight
 */
export interface OverviewKeyInsight {
    /**
     * 
     * @type {Array<AlertInsightsDatapoint>}
     * @memberof OverviewKeyInsight
     */
    datapoints: Array<AlertInsightsDatapoint>;
    /**
     * 
     * @type {string}
     * @memberof OverviewKeyInsight
     */
    headline_metric_type: OverviewKeyInsightHeadlineMetricTypeEnum;
    /**
     * 
     * @type {MetricSubject}
     * @memberof OverviewKeyInsight
     */
    subject: MetricSubject;
    /**
     * 
     * @type {Trend}
     * @memberof OverviewKeyInsight
     */
    trend: Trend;
}

/**
* @export
* @enum {string}
*/
export enum OverviewKeyInsightHeadlineMetricTypeEnum {
    NumberAlerts = 'number_alerts',
    NumberIncidents = 'number_incidents',
    NumberIncidentsDeclined = 'number_incidents_declined',
    NumberIncidentsReal = 'number_incidents_real',
    DeclineRate = 'decline_rate',
    WorkloadTotalIncidents = 'workload_total_incidents',
    WorkloadRealIncidents = 'workload_real_incidents',
    WorkloadDeclinedIncidents = 'workload_declined_incidents',
    WakeUps = 'wake_ups'
}

export function OverviewKeyInsightFromJSON(json: any): OverviewKeyInsight {
    return OverviewKeyInsightFromJSONTyped(json, false);
}

export function OverviewKeyInsightFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewKeyInsight {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': ((json['datapoints'] as Array<any>).map(AlertInsightsDatapointFromJSON)),
        'headline_metric_type': json['headline_metric_type'],
        'subject': MetricSubjectFromJSON(json['subject']),
        'trend': TrendFromJSON(json['trend']),
    };
}

export function OverviewKeyInsightToJSON(value?: OverviewKeyInsight | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': ((value.datapoints as Array<any>).map(AlertInsightsDatapointToJSON)),
        'headline_metric_type': value.headline_metric_type,
        'subject': MetricSubjectToJSON(value.subject),
        'trend': TrendToJSON(value.trend),
    };
}

