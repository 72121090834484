import { ScopeNameEnum } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  ButtonTheme,
  Card,
  FullPageEmptyState,
  IconEnum,
  IconSize,
} from "@incident-ui";
import {
  CatalogWizardCard,
  ChevronFooter,
} from "src/components/legacy/incidents-list/IncidentsListEmptyState";

import { useAPI } from "../../../utils/swr";
import { ReactComponent as Graphic } from "./empty-state-orange.svg";

export const AlertsListEmptyState = () => {
  const {
    data: { alert_source_configs },
    isLoading: configsLoading,
  } = useAPI("alertsListSourceConfigs", undefined, {
    fallbackData: { alert_source_configs: [] },
  });

  const {
    data: { alert_routes },
    isLoading: alertRoutesLoading,
  } = useAPI("alertRoutesListAlertRoutes", undefined, {
    fallbackData: { alert_routes: [] },
  });

  if (configsLoading || alertRoutesLoading) {
    return null;
  }

  return (
    <FullPageEmptyState
      title="Create incidents and escalations from alerts"
      graphic={
        // Hack to add a little negative margin, otherwise
        // the default height of this can be a little too big for a laptop screen
        // and scrolls awkwardly
        <div className={"-mb-4"}>
          <Graphic />
        </div>
      }
      subtitle="Connect your alerting tools to incident.io and use alert routes to create incidents and escalations from your alerts."
      cta={
        <GatedButton
          analyticsTrackingId="alerts-empty-state-get-started"
          href={"/alerts/routes/create"}
          theme={ButtonTheme.Primary}
        >
          Get started
        </GatedButton>
      }
      helpcenterArticleId={"4007103429"}
      gradientClasses="from-alarmalade-50 to-white from-20% via-80%"
      className="w-full"
      cards={
        <>
          <CatalogWizardCard domain="alerts" />
          <AlertsGetStartedCard
            resourceType={"alert-source"}
            completed={alert_source_configs.length > 0}
          />
          <AlertsGetStartedCard
            resourceType={"alert-route"}
            completed={alert_routes.length > 0}
          />
        </>
      }
    />
  );
};

type AlertsGetStartedCardProps = {
  resourceType: "alert-source" | "alert-route";
  completed?: boolean;
};

const CARD_CONTENTS = {
  "alert-source": {
    title: "Connect alert sources",
    description:
      "Connect your external monitoring tools to bring your alerts into incident.io",
    icon: IconEnum.Bolt,
    scope: ScopeNameEnum.AlertSourceCreate,
    href: "/alerts/sources/create",
  },
  "alert-route": {
    title: "Set up an alert route",
    description:
      "Create incidents and escalations from your alerts inside incident.io",
    icon: IconEnum.AlertRoute,
    scope: ScopeNameEnum.AlertRouteCreate,
    href: "/alerts/routes/create",
  },
};

const AlertsGetStartedCard = ({
  resourceType,
  completed,
}: AlertsGetStartedCardProps) => {
  const { title, description, icon, scope, href } = CARD_CONTENTS[resourceType];

  if (completed) {
    return (
      <Card
        className="h-full bg-purple-surface"
        title={title}
        description={description}
        descriptionClassName="text-content-primary"
        color={ColorPaletteEnum.Purple}
        icon={icon}
        iconSize={IconSize.Medium}
        clickable={false}
        footerNode={
          <Badge
            icon={IconEnum.Checkmark}
            iconClassName="text-green-content"
            theme={BadgeTheme.Tertiary}
            size={BadgeSize.Small}
            className="text-sm-medium text-content-primary"
          >
            Completed
          </Badge>
        }
      />
    );
  }

  return (
    <GatedButton
      analyticsTrackingId={`get-started-${resourceType}`}
      theme={ButtonTheme.Naked}
      href={href}
      requiredScope={scope}
    >
      <Card
        className="h-full"
        icon={icon}
        iconSize={IconSize.Medium}
        title={title}
        description={description}
        color={ColorPaletteEnum.Red}
        clickable
        footerNode={<ChevronFooter />}
      />
    </GatedButton>
  );
};
