import {
  Incident,
  IncidentsListSortByEnum,
  InvestigationPlanTypeEnum,
} from "@incident-io/api";
import {
  EvaluationBacktest,
  EvaluationBacktestEntry,
  useAiStaffServiceAiStaffRegradeEvaluationBacktestEntries,
  UseAiStaffServiceAiStaffShowEvaluationBacktestKeyFn,
} from "@incident-io/query-api";
import { useGetIncidents } from "@incident-shared/incidents";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  StackedList,
  StackedListItem,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { sortBy } from "lodash";
import { useMemo, useState } from "react";

import {
  EvaluationFilterBar,
  EvaluationFilterContextProvider,
  ScorecardMetricIdentifier,
  useEvaluationFilterContext,
  useFilterEntries as useFilterBacktestEntries,
} from "../common/EvaluationFilterContext";
import { InvestigationAggregateScorecardController } from "../investigations/InvestigationAggregateScorecard";
import { InvestigationGradeMetric } from "../investigations/InvestigationScorecardGrades";
import { BacktestCreateModal } from "./BacktestCreateModal";

export const BacktestEntriesList = ({
  backtest,
  entries,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
}) => {
  // Grab all the incidents that are referenced by these entries
  const incidentIDs = entries.map((e) => e.incident_id).filter(Boolean);

  const { incidents } = useGetIncidents({
    filters: [],
    fixedFilters: {
      pageSize: 100,
      sortBy: IncidentsListSortByEnum.NewestFirst,
      id: {
        one_of: incidentIDs,
      },
    },
    eagerLoad: true,
  });

  if (!incidents) {
    return <Loader />;
  }

  const tags = entries.map((e) => e.tags).flat();
  const uniqueTags = Array.from(new Set(tags)).sort();

  return (
    <EvaluationFilterContextProvider
      enableSelectingMetrics={true}
      availableTags={uniqueTags}
    >
      <BacktestEntriesListContent
        backtest={backtest}
        incidents={incidents}
        entries={entries}
      />
    </EvaluationFilterContextProvider>
  );
};

const BacktestEntriesListContent = ({
  backtest,
  entries,
  incidents,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  incidents: Incident[];
}) => {
  const incidentLookup: { [key: string]: Incident } = {};
  incidents.forEach((i) => {
    incidentLookup[i.id] = i;
  });

  const tags = entries.map((e) => e.tags).flat();
  const uniqueTags = Array.from(new Set(tags)).sort();

  const { selectedMetric } = useEvaluationFilterContext();
  const filterEntries = useFilterBacktestEntries();

  const filteredEntries = filterEntries(entries);

  const queryClient = useQueryClient();

  const scorecardIDs = filteredEntries
    .map((e) => e.investigation_scorecard?.id)
    .filter(Boolean)
    .sort();
  const scorecardIDsString = JSON.stringify(scorecardIDs);

  // Silly hack to stop the scorecard repeatedly re-rendering.
  const memoizedScorecardIDs = useMemo(
    () => scorecardIDs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scorecardIDsString],
  );

  const sortedEntries = sortBy(filteredEntries, (e) => {
    if (!selectedMetric) {
      return "ID" + e.id;
    }

    const { metric } = getGradeForMetric(e, selectedMetric);
    if (metric) {
      return "A" + metric.score;
    }

    return "ID" + e.id;
  });

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const toggleEntry = (entryID: string) => {
    if (selectedEntries?.includes(entryID)) {
      setSelectedEntries(selectedEntries.filter((e) => e !== entryID));
    } else {
      setSelectedEntries([...selectedEntries, entryID]);
    }
  };
  const selectedSegments = {};
  uniqueTags.forEach((tag) => {
    selectedSegments[tag] = sortedEntries
      .filter((e) => e.tags.includes(tag))
      .map((e) => e.id);
  });

  const toast = useToast();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { mutate: regradeEntries, isPending: regrading } =
    useAiStaffServiceAiStaffRegradeEvaluationBacktestEntries({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffShowEvaluationBacktestKeyFn({
            id: backtest.id,
          }),
        });

        toast({
          title: "Regraded entries",
          theme: ToastTheme.Success,
        });
      },
      onError: (error) => {
        toast({
          title: "Failed to regrade investigations",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    });

  const onRegrade = () => {
    regradeEntries({
      id: backtest.id,
      requestBody: {
        entry_ids: selectedEntries,
      },
    });
  };

  return (
    <>
      <EvaluationFilterBar />
      <InvestigationAggregateScorecardController
        investigationScorecardIds={memoizedScorecardIDs as string[]}
        allEntries={entries}
        showFilterBar={false}
      />
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <Button
              icon={
                selectedEntries.length === sortedEntries.length
                  ? IconEnum.FakeCheckboxChecked
                  : IconEnum.FakeCheckbox
              }
              className={"hover:bg-surface-tertiary"}
              analyticsTrackingId={null}
              theme={ButtonTheme.Ghost}
              onClick={() =>
                setSelectedEntries(
                  selectedEntries.length === sortedEntries.length
                    ? []
                    : sortedEntries.map((e) => e.id),
                )
              }
            >
              Select all
            </Button>
            {selectedMetric && (
              <div className="text-xs text-content-secondary">
                Showing entries sorted by {selectedMetric.gradeName} -{" "}
                {selectedMetric.metricName}
              </div>
            )}
          </div>
          <DropdownMenu
            align="end"
            triggerButton={
              <Button
                theme={ButtonTheme.Secondary}
                analyticsTrackingId="backtest-bulk-action"
                loading={regrading}
              >
                Apply bulk actions
              </Button>
            }
            disabled={selectedEntries.length === 0}
          >
            <DropdownMenuItem
              label="Re-grade entries"
              icon={IconEnum.ArrowSpin}
              onSelect={onRegrade}
              analyticsTrackingId={null}
            />
            <DropdownMenuItem
              label="Create new backtest"
              icon={IconEnum.Test}
              onSelect={() => setShowCreateModal(true)}
              analyticsTrackingId={null}
            />
          </DropdownMenu>
        </div>
        <StackedList>
          {sortedEntries.map((entry) => (
            <BacktestEntryListRow
              key={entry.id}
              entry={entry}
              incident={incidentLookup[entry.incident_id]}
              selectedMetric={selectedMetric}
              isSelected={selectedEntries.includes(entry.id)}
              onToggleSelect={toggleEntry}
            />
          ))}
        </StackedList>
      </div>
      {showCreateModal && (
        <BacktestCreateModal
          defaultPlanType={
            backtest.investigation_plan_type as unknown as InvestigationPlanTypeEnum
          }
          onClose={() => setShowCreateModal(false)}
          incidentIDs={sortedEntries
            .filter((e) => selectedEntries.includes(e.id))
            .map((e) => e.incident_id)}
          segments={selectedSegments}
        />
      )}
    </>
  );
};

const BacktestEntryListRow = ({
  entry,
  incident,
  selectedMetric,
  isSelected,
  onToggleSelect,
}: {
  entry: EvaluationBacktestEntry;
  incident: Incident;
  selectedMetric?: ScorecardMetricIdentifier;
  isSelected: boolean;
  onToggleSelect: (entryId: string) => void;
}) => {
  const reference = incident ? "INC-" + incident.external_id : "INC-?";

  return (
    <StackedListItem
      key={entry.id}
      className="pl-1"
      iconNode={
        <Button
          icon={
            isSelected ? IconEnum.FakeCheckboxChecked : IconEnum.FakeCheckbox
          }
          className="px-2 rounded hover:bg-surface-tertiary py-5 z-50"
          title=""
          analyticsTrackingId={null}
          theme={ButtonTheme.Unstyled}
          onClick={() => onToggleSelect(entry.id)}
        />
      }
      title={
        <div className="flex items-center gap-2">
          <span className="font-semibold">{reference}</span>
          <span className="font-normal max-w-md truncate">
            {incident?.name}
          </span>
        </div>
      }
      badgeNode={
        <div className="flex items-center gap-2">
          {entry.tags.map((tag) => (
            <Badge
              theme={BadgeTheme.Info}
              key={tag}
              size={BadgeSize.ExtraSmall}
            >
              {tag}
            </Badge>
          ))}
        </div>
      }
      accessory={
        <BacktestEntryScore entry={entry} selectedMetric={selectedMetric} />
      }
      description={
        <div className="flex items-center gap-2 mt-2">
          <BacktestEntryStatusBadge entry={entry} />
          {entry.incident_id && (
            <Button
              theme={ButtonTheme.Link}
              href={`/incidents/${entry.incident_id}`}
              icon={IconEnum.ExternalLink}
              analyticsTrackingId={null}
              openInNewTab
              size={BadgeSize.ExtraSmall}
            >
              {reference}
            </Button>
          )}
          {entry.investigation_id && (
            <Button
              theme={ButtonTheme.Link}
              href={`/workbench/investigations/${entry.investigation_id}`}
              icon={IconEnum.ExternalLink}
              analyticsTrackingId={null}
              openInNewTab
              size={BadgeSize.ExtraSmall}
            >
              Investigation
            </Button>
          )}
          {entry.investigation_cost_cents && (
            <div className="flex items-center justify-end gap-0.5">
              <Icon id={IconEnum.PiggyBank} size={IconSize.Small} />$
              {(entry.investigation_cost_cents / 100.0).toFixed(2)}
            </div>
          )}
          {entry.investigation_failing_checks &&
            entry.investigation_failing_checks.length > 0 && (
              <div className="flex items-center justify-end gap-0.5">
                <Tooltip
                  content={entry.investigation_failing_checks.join(", ")}
                >
                  <Badge theme={BadgeTheme.Warning} icon={IconEnum.Warning}>
                    {entry.investigation_failing_checks.length} check
                    {entry.investigation_failing_checks.length === 1
                      ? " failed"
                      : "s failed"}
                  </Badge>
                </Tooltip>
              </div>
            )}
        </div>
      }
    />
  );
};

const BacktestEntryScore = ({
  entry,
  selectedMetric,
}: {
  entry: EvaluationBacktestEntry;
  selectedMetric?: ScorecardMetricIdentifier;
}) => {
  if (!selectedMetric) {
    return null;
  }

  const { grade, metric } = getGradeForMetric(entry, selectedMetric);
  if (grade && metric) {
    return (
      <InvestigationGradeMetric
        grade={grade}
        metric={metric}
        className="p-0"
        showDescription={false}
      />
    );
  }

  return "—";
};

const getGradeForMetric = (
  entry: EvaluationBacktestEntry,
  selectedMetric: ScorecardMetricIdentifier,
) => {
  const grade = entry.investigation_scorecard?.grades?.find(
    (g) => g.name === selectedMetric.gradeName,
  );
  const metric = grade?.metrics?.find(
    (m) => m.name === selectedMetric.metricName,
  );

  return { grade, metric };
};

const BacktestEntryStatusBadge = ({
  entry,
}: {
  entry: EvaluationBacktestEntry;
}) => {
  let theme = BadgeTheme.Info;
  let icon = IconEnum.QuestionMark;
  let text = "Unknown";

  if (entry.investigation_scorecard) {
    theme = BadgeTheme.Success;
    text = "Scored";
    icon = IconEnum.Tick;
  } else if (entry.investigation_id) {
    theme = BadgeTheme.Secondary;
    text = "Running";
    icon = IconEnum.Loader;
    if (entry.investigation_state === "error") {
      theme = BadgeTheme.Error;
      text = "Errored";
      icon = IconEnum.Warning;
    }
  } else {
    theme = BadgeTheme.Tertiary;
    text = "Pending";
    icon = IconEnum.DottedCircle;
  }

  return (
    <Badge theme={theme} icon={icon} size={BadgeSize.ExtraSmall}>
      {text}
    </Badge>
  );
};
