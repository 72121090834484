/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IssueTemplateSlim
 */
export interface IssueTemplateSlim {
    /**
     * The context of the issue template
     * @type {string}
     * @memberof IssueTemplateSlim
     */
    context: IssueTemplateSlimContextEnum;
    /**
     * The unique ID for the issue template
     * @type {string}
     * @memberof IssueTemplateSlim
     */
    id: string;
    /**
     * The issue template has been marked as broken for a reason.
     * @type {string}
     * @memberof IssueTemplateSlim
     */
    invalid_reason: IssueTemplateSlimInvalidReasonEnum;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof IssueTemplateSlim
     */
    name: string;
    /**
     * The issue tracker provider to create the template for
     * @type {string}
     * @memberof IssueTemplateSlim
     */
    provider: IssueTemplateSlimProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum IssueTemplateSlimContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}/**
* @export
* @enum {string}
*/
export enum IssueTemplateSlimInvalidReasonEnum {
    Empty = '',
    ValidationError = 'validation_error'
}/**
* @export
* @enum {string}
*/
export enum IssueTemplateSlimProviderEnum {
    Asana = 'asana',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function IssueTemplateSlimFromJSON(json: any): IssueTemplateSlim {
    return IssueTemplateSlimFromJSONTyped(json, false);
}

export function IssueTemplateSlimFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTemplateSlim {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'id': json['id'],
        'invalid_reason': json['invalid_reason'],
        'name': json['name'],
        'provider': json['provider'],
    };
}

export function IssueTemplateSlimToJSON(value?: IssueTemplateSlim | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'id': value.id,
        'invalid_reason': value.invalid_reason,
        'name': value.name,
        'provider': value.provider,
    };
}

