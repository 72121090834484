import {
  AIStaffCreateEvaluationNoteRequestBodyResourceTypeEnum,
  AIStaffListEvaluationNotesResourceTypeEnum,
  AIStaffUpdateEvaluationNoteRequestBodyResourceTypeEnum,
  EvaluationNote,
  EvaluationNoteResourceTypeEnum,
  TextDocumentPayload,
} from "@incident-io/api";
import { Form } from "@incident-shared/forms";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  Icon,
  IconBadge,
  IconEnum,
  IconSize,
  LocalDateTime,
  Tooltip,
} from "@incident-ui";
import { startCase } from "lodash";
import { useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useStaffOverlay } from "src/utils/StaffOverlayProvider";
import { useAPI, useAPIMutation, useAPIRefetch } from "src/utils/swr";
import { useEventListener } from "use-hooks";

export const EvaluationNotesWidget = ({
  resourceId,
  resourceType,
  parentIncidentId,
}: {
  resourceId: string;
  resourceType: AIStaffListEvaluationNotesResourceTypeEnum;
  parentIncidentId?: string;
}) => {
  // Only show the widget if the user has said they want to see it.
  const { showEvaluationNotes } = useStaffOverlay();
  if (!showEvaluationNotes) {
    return null;
  }

  return (
    <EvaluationNotesWidgetInner
      resourceId={resourceId}
      resourceType={resourceType}
      parentIncidentId={parentIncidentId}
    />
  );
};

type EditState = {
  state: "adding" | "editing" | "none";
  editingId?: string;
};

const EvaluationNotesWidgetInner = ({
  resourceId,
  resourceType,
  parentIncidentId,
}: {
  resourceId: string;
  resourceType: AIStaffListEvaluationNotesResourceTypeEnum;
  parentIncidentId?: string;
}) => {
  const [showNotes, setShowNotes] = useState(false);
  const [editState, setEditState] = useState<EditState>({ state: "none" });
  const formMethods = useForm<FormData>();

  const { data: resourceNotesData } = useAPI("aIStaffListEvaluationNotes", {
    resourceId: resourceId,
    resourceType: resourceType,
  });

  const { data: incidentNotesData } = useAPI(
    parentIncidentId ? "aIStaffListEvaluationNotes" : null,
    {
      resourceId: parentIncidentId || "",
      resourceType: AIStaffListEvaluationNotesResourceTypeEnum.Incident,
    },
  );

  const notes = [
    ...(resourceNotesData?.evaluation_notes || []),
    ...(incidentNotesData?.evaluation_notes || []),
  ];
  const count = notes.length;

  const onOpenCreateForm = () => {
    formMethods.reset({
      attach_to: resourceType,
    });
    setEditState({ state: "adding" });
  };

  useEventListener("keydown", (e: KeyboardEvent) => {
    // Handle Escape key
    if (e.key === "Esc" || (e.key === "Escape" && showNotes)) {
      setShowNotes(false);
    }

    // Cmd + E => open notes
    if (e.key === "e" && e.metaKey && !showNotes) {
      e.preventDefault(); // Prevent default browser behavior
      setShowNotes(true);
    }

    // C => add note
    if (showNotes && e.key === "c" && editState.state === "none") {
      e.preventDefault(); // Prevent default browser behavior
      onOpenCreateForm();
    }
  });

  return (
    <>
      <div className="absolute bottom-4 left-4 z-50">
        <Button
          theme={ButtonTheme.Unstyled}
          className="relative group"
          analyticsTrackingId={null}
          onClick={() => setShowNotes(!showNotes)}
        >
          <div className="flex size-14 items-center justify-center rounded-lg bg-purple-800 group-hover:bg-purple-700">
            <Icon
              id={IconEnum.Message}
              size={IconSize.XXL}
              className="text-white"
            />
          </div>

          <div className="absolute -right-1 -top-1 flex size-5 items-center justify-center rounded-full bg-purple-400 text-xs-bold font-medium text-white">
            {count}
          </div>
        </Button>
      </div>

      {/* Notes overlay with backdrop */}
      {showNotes && (
        <>
          <div
            className="fixed inset-0 z-40"
            onClick={() => setShowNotes(false)}
          />

          {/* Notes panel */}
          <div className="absolute bottom-24 left-4 z-50 max-h-[70vh] w-96 overflow-y-auto text-xs">
            <ContentBox className="bg-purple-800">
              <div className="space-y-4 p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-white">
                    Evaluation notes
                  </h3>
                  <Button
                    size={BadgeSize.ExtraSmall}
                    theme={ButtonTheme.Secondary}
                    icon={IconEnum.Close}
                    onClick={() => setShowNotes(false)}
                    analyticsTrackingId={null}
                    title=""
                  />
                </div>

                <div className="flex flex-col gap-3">
                  {notes
                    .sort(
                      (a, b) =>
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime(),
                    )
                    .map((note) => {
                      const isEditing =
                        editState.state === "editing" &&
                        editState.editingId === note.id;

                      if (isEditing) {
                        return (
                          <CreateOrEditForm
                            key={note.id}
                            editingId={note.id}
                            formMethods={formMethods}
                            resourceId={resourceId}
                            resourceType={resourceType}
                            parentIncidentId={parentIncidentId}
                            onClose={() => {
                              setEditState({ state: "none" });
                              formMethods.reset();
                            }}
                          />
                        );
                      }
                      return (
                        <EvaluationNoteCard
                          key={note.id}
                          note={note}
                          onEditClick={() => {
                            setEditState({
                              state: "editing",
                              editingId: note.id,
                            });
                            formMethods.setValue(
                              "content.text_node",
                              note.content.text_node,
                            );
                            formMethods.setValue(
                              "attach_to",
                              parentIncidentId &&
                                note.resource_type === "incident"
                                ? "incident"
                                : resourceType,
                            );
                          }}
                        />
                      );
                    })}
                  {editState.state === "adding" && (
                    <CreateOrEditForm
                      formMethods={formMethods}
                      resourceId={resourceId}
                      resourceType={resourceType}
                      parentIncidentId={parentIncidentId}
                      onClose={() => setEditState({ state: "none" })}
                    />
                  )}

                  {notes.length === 0 && editState.state === "none" && (
                    <EmptyState content="No evaluation notes yet" />
                  )}
                  {editState.state === "none" && (
                    <Button
                      size={BadgeSize.Small}
                      theme={ButtonTheme.Secondary}
                      icon={IconEnum.Add}
                      onClick={onOpenCreateForm}
                      analyticsTrackingId={null}
                      title=""
                      className="w-fit"
                    >
                      <span>Add note</span>
                      <span className="text-content-tertiary">c</span>
                    </Button>
                  )}
                </div>
              </div>
            </ContentBox>
          </div>
        </>
      )}
    </>
  );
};

const EvaluationNoteCard = ({
  note,
  onEditClick,
}: {
  note: EvaluationNote;
  onEditClick: () => void;
}) => {
  const { trigger: deleteNote, isMutating: isDeleting } = useAPIMutation(
    "aIStaffListEvaluationNotes",
    {
      resourceId: note.resource_id,
      resourceType:
        note.resource_type as unknown as AIStaffListEvaluationNotesResourceTypeEnum,
    },
    async (apiClient, data: { id: string }) => {
      await apiClient.aIStaffDestroyEvaluationNote({
        id: data.id,
      });
    },
    {
      showErrorToast: "Failed to delete note",
    },
  );

  const { icon, color } = themeForNoteType(note.resource_type);

  return (
    <ContentBox className="bg-surface-primary p-2 flex flex-col gap-2">
      <div className="flex gap-2 items-start">
        <TemplatedTextDisplay
          value={note.content.text_node}
          style={TemplatedTextDisplayStyle.Naked}
          className="grow"
        />
        <div className="flex items-center gap-2">
          <Button
            size={BadgeSize.ExtraSmall}
            theme={ButtonTheme.Tertiary}
            icon={IconEnum.Edit}
            iconProps={{ size: IconSize.XS }}
            onClick={onEditClick}
            analyticsTrackingId={null}
            title=""
          />
          <Button
            size={BadgeSize.ExtraSmall}
            theme={ButtonTheme.Tertiary}
            icon={IconEnum.Delete}
            loading={isDeleting}
            iconProps={{ size: IconSize.XS }}
            onClick={() => deleteNote({ id: note.id })}
            analyticsTrackingId={null}
            title=""
          />
        </div>
      </div>
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Tooltip content={note.resource_type}>
            <IconBadge
              color={color}
              icon={icon}
              size={IconSize.XS}
              className="size-5"
            />
          </Tooltip>
          <div className="flex items-center gap-1">
            <Avatar
              url={note.user.avatar_url}
              name={note.user.name}
              size={IconSize.XS}
            />
            <div className="font-medium">{note.user?.name || "Unknown"}</div>
          </div>
        </div>
        <LocalDateTime timestamp={note.created_at} className="text-xs" />
      </div>
    </ContentBox>
  );
};

type FormData = {
  content: TextDocumentPayload;
  attach_to: string;
};

export const CreateOrEditForm = ({
  editingId,
  resourceId,
  resourceType,
  parentIncidentId,
  onClose,
  formMethods,
}: {
  editingId?: string;
  resourceId: string;
  parentIncidentId?: string;
  resourceType: AIStaffListEvaluationNotesResourceTypeEnum;
  onClose: () => void;
  formMethods: UseFormReturn<FormData>;
}) => {
  // We want to revalidate the parent incident notes as well as the resource notes.
  const refetchParentIncident = useAPIRefetch(
    parentIncidentId ? "aIStaffListEvaluationNotes" : null,
    {
      resourceId: parentIncidentId || "",
      resourceType: AIStaffListEvaluationNotesResourceTypeEnum.Incident,
    },
  );

  const { trigger: createNote, isMutating } = useAPIMutation(
    "aIStaffListEvaluationNotes",
    { resourceId: resourceId, resourceType: resourceType },
    async (apiClient, data: FormData) => {
      const useParent = parentIncidentId && data.attach_to === "incident";
      const targetResourceType = useParent
        ? EvaluationNoteResourceTypeEnum.Incident
        : resourceType;
      const targetResourceId = useParent ? parentIncidentId : resourceId;

      if (editingId) {
        await apiClient.aIStaffUpdateEvaluationNote({
          id: editingId,
          updateEvaluationNoteRequestBody: {
            content: data.content,
            resource_id: targetResourceId,
            resource_type:
              targetResourceType as unknown as AIStaffUpdateEvaluationNoteRequestBodyResourceTypeEnum,
          },
        });
      } else {
        await apiClient.aIStaffCreateEvaluationNote({
          createEvaluationNoteRequestBody: {
            content: data.content,
            resource_id: targetResourceId,
            resource_type:
              targetResourceType as unknown as AIStaffCreateEvaluationNoteRequestBodyResourceTypeEnum,
          },
        });
      }
    },
    {
      onSuccess: () => {
        refetchParentIncident();
        onClose();
        formMethods.reset();
      },
      showErrorToast: "Failed to add note",
    },
  );

  useEventListener("keydown", (e: KeyboardEvent) => {
    // Handle Escape key
    if (e.key === "Esc" || e.key === "Escape") {
      onClose();
    }

    // Cmd + Enter => submit the form
    if (e.key === "Enter" && e.metaKey) {
      e.preventDefault(); // Prevent default browser behavior
      createNote(formMethods.getValues());
    }

    // Cmd + Backspace => cancel the form
    if (e.key === "Backspace" && e.metaKey) {
      e.preventDefault(); // Prevent default browser behavior
      onClose();
    }
  });

  return (
    <Form.Root formMethods={formMethods} onSubmit={createNote}>
      <TemplatedTextInputV2
        autoFocus
        formMethods={formMethods}
        name="content.text_node"
        placeholder="Add your note..."
        required="Please enter some content"
        format="rich"
        includeExpressions={false}
        includeVariables={false}
      />
      {parentIncidentId && (
        <RadioButtonGroupV2
          formMethods={formMethods}
          srLabel="Attach to"
          horizontal
          boxed
          groupClassName="gap-1"
          radioClassName="p-2 [&_div]:text-xs"
          name="attach_to"
          options={[
            { value: resourceType, label: startCase(resourceType) },
            { value: "incident", label: "Incident" },
          ]}
        />
      )}
      <div className="flex justify-end gap-2">
        <Button
          size={BadgeSize.ExtraSmall}
          theme={ButtonTheme.Secondary}
          onClick={() => onClose()}
          analyticsTrackingId={null}
          disabled={isMutating}
        >
          <span>Cancel</span>
          <span className="text-content-tertiary">⌘+←</span>
        </Button>
        <Button
          size={BadgeSize.ExtraSmall}
          theme={ButtonTheme.Secondary}
          type="submit"
          analyticsTrackingId={null}
          loading={isMutating}
        >
          <span>{editingId ? "Save" : "Add note"}</span>
          <span className="text-content-tertiary">⌘+↵</span>
        </Button>
      </div>
    </Form.Root>
  );
};

const themeForNoteType = (
  type: EvaluationNoteResourceTypeEnum,
): { icon: IconEnum; color: ColorPaletteEnum } => {
  switch (type) {
    case EvaluationNoteResourceTypeEnum.Incident:
      return { icon: IconEnum.Incident, color: ColorPaletteEnum.Red };
    case EvaluationNoteResourceTypeEnum.CopilotThread:
      return { icon: IconEnum.Robot, color: ColorPaletteEnum.Purple };
    case EvaluationNoteResourceTypeEnum.Investigation:
      return { icon: IconEnum.Bulb, color: ColorPaletteEnum.Green };
    case EvaluationNoteResourceTypeEnum.Suggestion:
      return { icon: IconEnum.Nudge, color: ColorPaletteEnum.Yellow };
    case EvaluationNoteResourceTypeEnum.Alert:
      return { icon: IconEnum.Alert, color: ColorPaletteEnum.Blue };
    default:
      return { icon: IconEnum.QuestionMark, color: ColorPaletteEnum.Slate };
  }
};
