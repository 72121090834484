import {
  InvestigationScorecard,
  useAiStaffServiceAiStaffListAiSpans,
} from "@incident-io/query-api";
import { Loader } from "@incident-ui";

import { AISpanTrace } from "../ai-requests/AISpanTrace";

export const InvestigationScorecardTrace = ({
  scorecard,
}: {
  scorecard: InvestigationScorecard;
}) => {
  const { data: scorecardSpanData, isLoading: scorecardSpanDataLoading } =
    useAiStaffServiceAiStaffListAiSpans({
      resourceId: scorecard.id,
      type: "scorecard",
      includeChildSpans: true,
    });

  if (scorecardSpanDataLoading) {
    return <Loader />;
  }

  if (scorecardSpanData?.spans) {
    return <AISpanTrace spans={scorecardSpanData?.spans} />;
  }
  return null;
};
