/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessorBackfillResourceOptions
 */
export interface ProcessorBackfillResourceOptions {
    /**
     * Limit the number of resources to process for each organisation
     * @type {number}
     * @memberof ProcessorBackfillResourceOptions
     */
    limit_per_org?: number;
    /**
     * Only process assets that have not been processed since this timestamp
     * @type {Date}
     * @memberof ProcessorBackfillResourceOptions
     */
    not_processed_since?: Date;
    /**
     * When resource ID is not specified, only target resources younger than this
     * @type {number}
     * @memberof ProcessorBackfillResourceOptions
     */
    resource_cutoff_days?: number;
    /**
     * Resource ID to target, for the given processor
     * @type {string}
     * @memberof ProcessorBackfillResourceOptions
     */
    resource_id?: string;
    /**
     * Flag if this backfill should run for all organisations
     * @type {boolean}
     * @memberof ProcessorBackfillResourceOptions
     */
    run_for_all_organisations: boolean;
}

export function ProcessorBackfillResourceOptionsFromJSON(json: any): ProcessorBackfillResourceOptions {
    return ProcessorBackfillResourceOptionsFromJSONTyped(json, false);
}

export function ProcessorBackfillResourceOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBackfillResourceOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit_per_org': !exists(json, 'limit_per_org') ? undefined : json['limit_per_org'],
        'not_processed_since': !exists(json, 'not_processed_since') ? undefined : (new Date(json['not_processed_since'])),
        'resource_cutoff_days': !exists(json, 'resource_cutoff_days') ? undefined : json['resource_cutoff_days'],
        'resource_id': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'run_for_all_organisations': json['run_for_all_organisations'],
    };
}

export function ProcessorBackfillResourceOptionsToJSON(value?: ProcessorBackfillResourceOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit_per_org': value.limit_per_org,
        'not_processed_since': value.not_processed_since === undefined ? undefined : (value.not_processed_since.toISOString()),
        'resource_cutoff_days': value.resource_cutoff_days,
        'resource_id': value.resource_id,
        'run_for_all_organisations': value.run_for_all_organisations,
    };
}

