/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FieldValue,
    FieldValueFromJSON,
    FieldValueFromJSONTyped,
    FieldValueToJSON,
} from './FieldValue';

/**
 * 
 * @export
 * @interface FollowUpsExportRequestBody
 */
export interface FollowUpsExportRequestBody {
    /**
     * The values to use for each field
     * @type {Array<FieldValue>}
     * @memberof FollowUpsExportRequestBody
     */
    field_values: Array<FieldValue>;
    /**
     * The issue tracker provider
     * @type {string}
     * @memberof FollowUpsExportRequestBody
     */
    provider: FollowUpsExportRequestBodyProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum FollowUpsExportRequestBodyProviderEnum {
    Asana = 'asana',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function FollowUpsExportRequestBodyFromJSON(json: any): FollowUpsExportRequestBody {
    return FollowUpsExportRequestBodyFromJSONTyped(json, false);
}

export function FollowUpsExportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsExportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field_values': ((json['field_values'] as Array<any>).map(FieldValueFromJSON)),
        'provider': json['provider'],
    };
}

export function FollowUpsExportRequestBodyToJSON(value?: FollowUpsExportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field_values': ((value.field_values as Array<any>).map(FieldValueToJSON)),
        'provider': value.provider,
    };
}

