/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFieldFilterByOptions
 */
export interface CustomFieldFilterByOptions {
    /**
     * This must be an attribute of the catalog type of this custom field. It must be an attribute that points to another catalog type (so not a plain string, number, or boolean attribute).
     * @type {string}
     * @memberof CustomFieldFilterByOptions
     */
    catalog_attribute_id: string;
    /**
     * This must be the ID of a custom field, which must have values of the same type as the attribute you are filtering by.
     * 
     * When this filtering field is set on an incident, the options for this custom field will be filtered to only those with the attribute value that matches the value of the filtering field.
     * @type {string}
     * @memberof CustomFieldFilterByOptions
     */
    custom_field_id: string;
}

export function CustomFieldFilterByOptionsFromJSON(json: any): CustomFieldFilterByOptions {
    return CustomFieldFilterByOptionsFromJSONTyped(json, false);
}

export function CustomFieldFilterByOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldFilterByOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'catalog_attribute_id': json['catalog_attribute_id'],
        'custom_field_id': json['custom_field_id'],
    };
}

export function CustomFieldFilterByOptionsToJSON(value?: CustomFieldFilterByOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'catalog_attribute_id': value.catalog_attribute_id,
        'custom_field_id': value.custom_field_id,
    };
}

