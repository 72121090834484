import {
  OverviewDeclineRateDatapoint,
  OverviewWakeUpDatapoint,
  OverviewWorkloadDatapoint,
} from "@incident-io/api";
import { ExtendedFormFieldValue } from "@incident-shared/filters";
import { GenericErrorMessage } from "@incident-ui";
import { InsightsTable } from "@incident-ui/InsightsTable/InsightsTable";
import { motion } from "framer-motion";
import { formFieldValueToInsightsFilter } from "src/components/insights/v3/dashboards/common/marshall";
import { useAPI } from "src/utils/swr";

import { ALERT_INSIGHTS_TIME_PERIOD_LABEL } from "../alert-details/useAlertInsightsDates";
import { formatValueWithUnit } from "../common/formatValueWithUnit";
import { GetNavigateHref } from "./AlertsOverview";

enum AlertActivityDatapointUnit {
  Alert = "alert",
  Incident = "incident",
  Hour = "hour",
  Percent = "%",
  Pages = "pages",
}

export const AlertInsightTables = ({
  timezone,
  startDate,
  endDate,
  filters,
  getNavigateHref,
}: {
  timezone: string;
  startDate: Date;
  endDate: Date;
  filters: ExtendedFormFieldValue[];
  getNavigateHref: GetNavigateHref;
}): React.ReactNode => {
  const { data, isLoading, error } = useAPI("alertsGetInsightsOverviewTables", {
    getInsightsOverviewTablesRequestBody: {
      timezone: timezone,
      start_date: startDate,
      end_date: endDate,
      filters: filters.map(formFieldValueToInsightsFilter),
    },
  });

  // Leave some empty space while loading
  if (!data || isLoading) {
    return <div className={"h-[300px]"} />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <motion.div
      className="space-y-10"
      variants={{
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: 0.2,
            delayChildren: 0.3,
          },
        },
      }}
      initial="hidden"
      animate="visible"
    >
      {/* Wake-ups */}
      <TableSection
        title="Most wake-ups"
        timeRange={ALERT_INSIGHTS_TIME_PERIOD_LABEL}
      >
        <motion.div variants={tableVariants}>
          <InsightsTable
            isAIThemed
            title={"Top themes"}
            columns={[
              { key: "theme", label: "Theme", align: "left" },
              { key: "count", label: "Overnight pages", align: "right" },
            ]}
            entries={data.theme_wake_up_table.datapoints.map(
              (datapoint: OverviewWakeUpDatapoint) => {
                if (!datapoint.theme) {
                  throw new Error(
                    "Unreachable: expected datapoint to have a theme",
                  );
                }

                return {
                  id: datapoint.theme,
                  theme: datapoint.theme,
                  count: formatValueWithUnit(
                    datapoint.number_of_wake_ups,
                    AlertActivityDatapointUnit.Pages,
                  ),
                  href: getNavigateHref({ theme: datapoint.theme }),
                };
              },
            )}
          />
        </motion.div>
        <motion.div variants={tableVariants}>
          <InsightsTable
            title={"Top alerts"}
            columns={[
              { key: "theme", label: "Theme", align: "left" },
              { key: "count", label: "Overnight pages", align: "right" },
            ]}
            entries={data.title_wake_up_table.datapoints.map(
              (datapoint: OverviewWakeUpDatapoint) => {
                if (!datapoint.title) {
                  throw new Error(
                    "Unreachable: expected datapoint to have a title",
                  );
                }

                return {
                  id: datapoint.title,
                  theme: datapoint.title,
                  count: formatValueWithUnit(
                    datapoint.number_of_wake_ups,
                    AlertActivityDatapointUnit.Pages,
                  ),
                  href: getNavigateHref({ title: datapoint.title }),
                };
              },
            )}
          />
        </motion.div>
      </TableSection>
      {/* Time spent */}
      <TableSection
        title="Most time spent"
        timeRange={ALERT_INSIGHTS_TIME_PERIOD_LABEL}
      >
        <motion.div variants={tableVariants}>
          <InsightsTable
            isAIThemed
            title={"Top themes"}
            columns={[
              { key: "theme", label: "Theme", align: "left" },
              { key: "hours", label: "Hours", align: "left" },
              {
                key: "contribution",
                label: "Contribution",
                align: "left",
                render: (value) => (
                  <span>
                    {formatValueWithUnit(
                      value as number,
                      AlertActivityDatapointUnit.Percent,
                    )}
                  </span>
                ),
              },
            ]}
            entries={data.theme_workload_table.datapoints.map(
              (datapoint: OverviewWorkloadDatapoint) => {
                if (!datapoint.theme) {
                  throw new Error(
                    "Unreachable: expected datapoint to have a theme",
                  );
                }

                return {
                  id: datapoint.theme,
                  theme: datapoint.theme,
                  hours: formatValueWithUnit(
                    datapoint.hours,
                    AlertActivityDatapointUnit.Hour,
                  ),
                  contribution: datapoint.contribution_pct,
                  percentageChange: datapoint.change,
                  href: getNavigateHref({ theme: datapoint.theme }),
                };
              },
            )}
          />
        </motion.div>
        <motion.div variants={tableVariants}>
          <InsightsTable
            title={"Top alerts"}
            columns={[
              { key: "theme", label: "Theme", align: "left" },
              { key: "hours", label: "Hours", align: "left" },
              {
                key: "contribution",
                label: "Contribution",
                align: "left",
                render: (value) => (
                  <span>
                    {formatValueWithUnit(
                      value as number,
                      AlertActivityDatapointUnit.Percent,
                    )}
                  </span>
                ),
              },
            ]}
            entries={data.title_workload_table.datapoints.map(
              (datapoint: OverviewWorkloadDatapoint) => {
                if (!datapoint.title) {
                  throw new Error(
                    "Unreachable: expected datapoint to have a title",
                  );
                }

                return {
                  id: datapoint.title,
                  theme: datapoint.title,
                  hours: formatValueWithUnit(
                    datapoint.hours,
                    AlertActivityDatapointUnit.Hour,
                  ),
                  contribution: datapoint.contribution_pct,
                  percentageChange: datapoint.change,
                  href: getNavigateHref({ title: datapoint.title }),
                };
              },
            )}
          />
        </motion.div>
      </TableSection>

      <motion.div
        variants={sectionVariants}
        className="w-full border-b border-stroke-secondary"
      />

      {/* Decline rate  */}
      <TableSection
        title="Highest decline rates"
        timeRange={ALERT_INSIGHTS_TIME_PERIOD_LABEL}
      >
        <motion.div variants={tableVariants}>
          <InsightsTable
            isAIThemed
            title={"Top themes"}
            columns={[
              { key: "theme", label: "Theme", align: "left" },
              { key: "percentage", label: "Percentage", align: "right" },
            ]}
            entries={data.theme_decline_rate_table.datapoints.map(
              (datapoint: OverviewDeclineRateDatapoint) => {
                if (!datapoint.theme) {
                  throw new Error(
                    "Unreachable: expected datapoint to have a theme",
                  );
                }

                return {
                  id: datapoint.theme,
                  theme: datapoint.theme,
                  percentage: formatValueWithUnit(
                    datapoint.percentage,
                    AlertActivityDatapointUnit.Percent,
                  ),
                  href: `/alerts/recent?themes%5Bone_of%5D=${encodeURIComponent(
                    datapoint.theme,
                  )}`,
                };
              },
            )}
          />
        </motion.div>
        <motion.div variants={tableVariants}>
          <InsightsTable
            title={"Top alerts"}
            columns={[
              { key: "theme", label: "Theme", align: "left" },
              { key: "percentage", label: "Percentage", align: "right" },
            ]}
            entries={data.title_decline_rate_table.datapoints.map(
              (datapoint: OverviewDeclineRateDatapoint) => {
                if (!datapoint.title) {
                  throw new Error(
                    "Unreachable: expected datapoint to have a title",
                  );
                }

                return {
                  id: datapoint.title,
                  theme: datapoint.title,
                  percentage: formatValueWithUnit(
                    datapoint.percentage,
                    AlertActivityDatapointUnit.Percent,
                  ),
                  href: `/alerts/recent?full_text_search%5Bis%5D=${encodeURIComponent(
                    datapoint.title,
                  )}`,
                };
              },
            )}
          />
        </motion.div>
      </TableSection>
    </motion.div>
  );
};

// Animation variants for each section
const sectionVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

// Animation variants for tables
const tableVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: "easeOut",
    },
  },
};

const TableSection = ({
  title,
  timeRange,
  children,
}: {
  title: string;
  timeRange: string;
  children: React.ReactNode;
}) => {
  return (
    <motion.div className="space-y-3" variants={sectionVariants}>
      <div className="flex items-end gap-2">
        <div className="text-base-bold">
          {title}
          <span className="ml-2 text-xs-med text-content-secondary">
            {timeRange}
          </span>
        </div>
      </div>
      <div className="grid w-full grid-cols-1 xl:grid-cols-2 gap-4">
        {children}
      </div>
    </motion.div>
  );
};
