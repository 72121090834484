import {
  useAiStaffServiceAiStaffCurateEvaluationDataset,
  useAiStaffServiceAiStaffListEvaluationDatasets,
  UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn,
} from "@incident-io/query-api";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Loader, Modal, ModalContent, ModalFooter } from "@incident-ui";
import { ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useAnalytics } from "src/contexts/AnalyticsContext";
import { useRevalidate } from "src/utils/use-revalidate";

type CurationMode = "add" | "remove";

type BulkDatasetCurateFormData = {
  dataset_id: string;
};

type BulkDatasetCurateProps = {
  overrideDatasetID?: string;
  incidentIDs: string[];
  mode: CurationMode;
  onClose: () => void;
  onSubmit: () => void;
};

export function BulkDatasetWith<T extends Partial<BulkDatasetCurateProps>>(
  partialProps: T,
): (props: Omit<BulkDatasetCurateProps, keyof T>) => React.ReactElement {
  const WithBulkDataset = (
    remainingProps: Omit<BulkDatasetCurateProps, keyof T>,
  ) => {
    // Merge the props, ensuring the type is correct
    const mergedProps: BulkDatasetCurateProps = {
      ...remainingProps,
      ...partialProps,
    } as BulkDatasetCurateProps;

    return <BulkDatasetCurate {...mergedProps} />;
  };

  WithBulkDataset.displayName = `BulkDatasetWith(${Object.keys(
    partialProps,
  ).join(",")})`;

  return WithBulkDataset;
}

export const BulkDatasetAdd = BulkDatasetWith({ mode: "add" });
export const BulkDatasetRemove = BulkDatasetWith({ mode: "remove" });

function BulkDatasetCurate({
  overrideDatasetID,
  incidentIDs,
  mode,
  onClose,
  onSubmit,
}: BulkDatasetCurateProps): React.ReactElement {
  const formMethods = useForm<BulkDatasetCurateFormData>({
    defaultValues: {
      dataset_id: overrideDatasetID,
    },
  });
  const queryClient = useQueryClient();
  const { handleSubmit, control } = formMethods;
  const navigate = useOrgAwareNavigate();
  const analytics = useAnalytics();
  const toast = useToast();
  const refetchIncidents = useRevalidate(["incidentsList"]);

  const { data, isLoading } = useAiStaffServiceAiStaffListEvaluationDatasets();
  const datasets = data?.datasets || [];

  const selectedDatasetId = useWatch({
    control,
    name: "dataset_id",
  });

  const selectedDataset = datasets.find(
    (dataset) => dataset.id === selectedDatasetId,
  );

  const { mutate: curateDataset, isPending } =
    useAiStaffServiceAiStaffCurateEvaluationDataset({
      onSuccess: (_, variables) => {
        analytics?.track("bulkActionApplied", {
          action: `dataset_${mode}`,
          numIncidents: incidentIDs.length,
          datasetId: variables.id,
        });

        // Expire any cached incident lists, as we sometimes filter by dataset.
        refetchIncidents();

        // Expire the dataset itself, as this will now have changed.
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn({
            id: variables.id,
          }),
        });

        navigate(`/workbench/datasets/${variables.id}`);
        onSubmit();
      },
      onError: () => {
        toast({
          title: `Failed to ${mode} incidents ${
            mode === "add" ? "to" : "from"
          } dataset`,
          theme: ToastTheme.Error,
        });
      },
    });

  const onSubmitForm = (data: BulkDatasetCurateFormData) => {
    curateDataset({
      id: data.dataset_id,
      requestBody: {
        to_add: mode === "add" ? incidentIDs : [],
        to_remove: mode === "remove" ? incidentIDs : [],
      },
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const modeConfig = {
    add: {
      title: "Add to dataset",
      buttonText: "Add",
      helpText: "Select the dataset to add these incidents to.",
      actionText: "add",
      preposition: "to",
    },
    remove: {
      title: "Remove from dataset",
      buttonText: "Remove",
      helpText: "Select the dataset to remove these incidents from.",
      actionText: "remove",
      preposition: "from",
    },
  }[mode];

  return (
    <Modal
      as="form"
      onSubmit={handleSubmit(onSubmitForm)}
      title={modeConfig.title}
      isOpen
      analyticsTrackingId={`bulk-${mode}-dataset`}
      onClose={onClose}
    >
      <ModalContent>
        <FormProvider {...formMethods}>
          <div className="space-y-4">
            <StaticSingleSelectV2
              formMethods={formMethods}
              name="dataset_id"
              label="Dataset"
              helptext={modeConfig.helpText}
              required
              options={
                data?.datasets.map((dataset) => ({
                  value: dataset.id,
                  label: dataset.name,
                })) || []
              }
              disabled={!!overrideDatasetID}
            />
            {selectedDataset && (
              <p className="text-sm text-content-secondary">
                This will {modeConfig.actionText} {incidentIDs.length} incident
                {incidentIDs.length !== 1 ? "s" : ""} {modeConfig.preposition}{" "}
                <span className="font-medium text-content-primary">
                  {selectedDataset.name}
                </span>
              </p>
            )}
          </div>
        </FormProvider>
      </ModalContent>
      <ModalFooter
        hideBorder
        confirmButtonText={modeConfig.buttonText}
        saving={isPending}
        onClose={onClose}
        confirmButtonType="submit"
      />
    </Modal>
  );
}
