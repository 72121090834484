/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationDatasetSegment,
    EvaluationDatasetSegmentFromJSON,
    EvaluationDatasetSegmentFromJSONTyped,
    EvaluationDatasetSegmentToJSON,
} from './EvaluationDatasetSegment';

/**
 * 
 * @export
 * @interface AIStaffUpdateEvaluationDatasetRequestBody
 */
export interface AIStaffUpdateEvaluationDatasetRequestBody {
    /**
     * Description of the dataset's purpose
     * @type {string}
     * @memberof AIStaffUpdateEvaluationDatasetRequestBody
     */
    description?: string;
    /**
     * Human readable name of the dataset
     * @type {string}
     * @memberof AIStaffUpdateEvaluationDatasetRequestBody
     */
    name: string;
    /**
     * List of named filter groups used to categorize incidents
     * @type {Array<EvaluationDatasetSegment>}
     * @memberof AIStaffUpdateEvaluationDatasetRequestBody
     */
    segments?: Array<EvaluationDatasetSegment>;
}

export function AIStaffUpdateEvaluationDatasetRequestBodyFromJSON(json: any): AIStaffUpdateEvaluationDatasetRequestBody {
    return AIStaffUpdateEvaluationDatasetRequestBodyFromJSONTyped(json, false);
}

export function AIStaffUpdateEvaluationDatasetRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffUpdateEvaluationDatasetRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'segments': !exists(json, 'segments') ? undefined : ((json['segments'] as Array<any>).map(EvaluationDatasetSegmentFromJSON)),
    };
}

export function AIStaffUpdateEvaluationDatasetRequestBodyToJSON(value?: AIStaffUpdateEvaluationDatasetRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'segments': value.segments === undefined ? undefined : ((value.segments as Array<any>).map(EvaluationDatasetSegmentToJSON)),
    };
}

