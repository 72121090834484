/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationScorecardGrade,
    InvestigationScorecardGradeFromJSON,
    InvestigationScorecardGradeFromJSONTyped,
    InvestigationScorecardGradeToJSON,
} from './InvestigationScorecardGrade';

/**
 * Aggregate scorecard measuring performance across multiple investigations
 * @export
 * @interface InvestigationAggregateScorecard
 */
export interface InvestigationAggregateScorecard {
    /**
     * Collection of aggregated grades across all investigations
     * @type {Array<InvestigationScorecardGrade>}
     * @memberof InvestigationAggregateScorecard
     */
    grades: Array<InvestigationScorecardGrade>;
    /**
     * IDs of the investigation scorecards that were aggregated
     * @type {Array<string>}
     * @memberof InvestigationAggregateScorecard
     */
    investigation_scorecard_ids: Array<string>;
    /**
     * Warnings generated during the aggregation process
     * @type {Array<string>}
     * @memberof InvestigationAggregateScorecard
     */
    warnings: Array<string>;
}

export function InvestigationAggregateScorecardFromJSON(json: any): InvestigationAggregateScorecard {
    return InvestigationAggregateScorecardFromJSONTyped(json, false);
}

export function InvestigationAggregateScorecardFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationAggregateScorecard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grades': ((json['grades'] as Array<any>).map(InvestigationScorecardGradeFromJSON)),
        'investigation_scorecard_ids': json['investigation_scorecard_ids'],
        'warnings': json['warnings'],
    };
}

export function InvestigationAggregateScorecardToJSON(value?: InvestigationAggregateScorecard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grades': ((value.grades as Array<any>).map(InvestigationScorecardGradeToJSON)),
        'investigation_scorecard_ids': value.investigation_scorecard_ids,
        'warnings': value.warnings,
    };
}

