import { captureException } from "@sentry/react";
import { useCallback, useState } from "react";
import {
  ErrorOption,
  FieldValues,
  Path,
  UseFormSetError,
} from "react-hook-form";
import { ErrorResponse } from "src/contexts/ClientContext";

/* README for these hooks is in fetchData.md */

export const GENERIC_ERROR_MESSAGE =
  "Sorry, something went wrong. Please try again, or contact us if this keeps happening";

type FieldError<DataType> = {
  name: Path<DataType>;
  error: ErrorOption;
  code?: string;
};

export type UseMutationOptions<DataType extends FieldValues, ResponseType> = {
  setError?: UseFormSetError<DataType>;
  onError?: (
    error: Error | ErrorResponse | Response,
    fieldErrors?: FieldError<DataType>[],
  ) => Promise<void> | void;
  onSuccess?: (data: ResponseType, reqData: DataType) => Promise<void> | void;
};

export type UseMutationReturn<DataType, ResponseType> = [
  (data: DataType) => Promise<ResponseType | undefined>,
  {
    response: ResponseType | null;
    saving: boolean;
    fieldErrors: FieldError<DataType>[];
    genericError: string | null;
  },
];

/**
 * useMutation is a helper to use when posting data to our API.
 *
 * It'll provide a `saving` bool which you can use to render an 'in progress' state, and try to
* make handling errors a bit less boilerplate-y. The easiest way to use it is
* to pass in `setError` from your react-hook-form into the `options`, but if you need to intercept
* the errors the fieldErrors are also provided.

 * @param submitFn - the callback to execute in order to fetch the data. This can be an inline async function.
 * @param options - define what to do onSuccess or onError if you want. this is also where you pass in `setError`.
 * @returns an array containing the fetcher, any returned data, then an object containing loading and error states.
 */
export function useMutation<DataType extends FieldValues, ResponseType>(
  submitFn: (data: DataType) => Promise<ResponseType>,
  options?: UseMutationOptions<DataType, ResponseType>,
): UseMutationReturn<DataType, ResponseType> {
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState<ResponseType | null>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldError<DataType>[]>([]);
  const [genericError, setGenericError] = useState<string | null>(null);

  const { onSuccess, onError, setError } = options || {};

  const onSubmit = useCallback(
    async (data: DataType) => {
      // If we're already saving, don't try to submit the request again.
      if (saving) {
        return undefined;
      }
      setSaving(true);
      try {
        const resp = await submitFn(data);
        setResponse(resp);
        if (onSuccess) {
          await onSuccess(resp, data);
        }
        return resp;
      } catch (e) {
        const localFieldErrors: FieldError<DataType>[] = [];
        if (e instanceof Response) {
          const jsonErr: ErrorResponse = await e.json();
          if (jsonErr.type === "validation_error") {
            jsonErr.errors.forEach((e) => {
              const fieldError = {
                name: e?.source?.pointer as unknown as Path<DataType>,
                error: {
                  type: "manual",
                  message: e.message,
                },
                code: e?.code,
              };
              localFieldErrors.push(fieldError);
              if (setError) {
                // I can't work out how to dynamically check whether the
                // pointer is valid for the form type, it would be really cool
                // if we could.
                setError(fieldError.name, fieldError.error);
              }
            });
            setFieldErrors(localFieldErrors);
          } else {
            console.error(e);
            captureException(e);
            setGenericError(GENERIC_ERROR_MESSAGE);
          }
          if (onError) {
            await onError(e, localFieldErrors);
          }
        } else {
          console.error(e);
          captureException(e);
          setGenericError(GENERIC_ERROR_MESSAGE);
          if (onError) {
            await onError(e as Response | Error | ErrorResponse);
          }
        }
      } finally {
        setSaving(false);
      }
      return undefined;
    },
    [submitFn, setSaving, setResponse, setError, onSuccess, onError, saving],
  );

  return [onSubmit, { response, saving, fieldErrors, genericError }];
}
