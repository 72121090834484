import { useAiStaffServiceAiStaffListAiSpans } from "@incident-io/query-api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import {
  EmptyState,
  IconEnum,
  LoadingWrapper,
  LocalDateTime,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { AISpanTrace } from "../ai-requests/AISpanTrace";

export const ProcessorShowPage = () => {
  const { spanId } = useParams<{ traceId: string; spanId: string }>();
  const navigate = useOrgAwareNavigate();
  if (!spanId) {
    navigate("/workbench");
    return null;
  }

  return (
    <WorkbenchSubPageWrapper backHref="/workbench/processors">
      <InteractionsList spanId={spanId} />
    </WorkbenchSubPageWrapper>
  );
};

const InteractionsList = ({ spanId }: { spanId: string }) => {
  const { data: spanData, isLoading: spanDataLoading } =
    useAiStaffServiceAiStaffListAiSpans({
      id: spanId,
      includeChildSpans: true,
    });

  const spans = spanData?.spans ?? [];

  const topSpan = spans.find((span) => span.id === spanId);
  if (!topSpan) {
    return (
      <EmptyState
        icon={IconEnum.Filter}
        content="Didn't find top span: something weird has happened"
      />
    );
  }

  return (
    <LoadingWrapper loading={spanDataLoading} className="pb-32">
      {spans.length === 0 ? (
        <EmptyState icon={IconEnum.Filter} content="No spans found" />
      ) : (
        <div className="flex flex-col gap-4">
          <div className="text-2xl-bold">{topSpan.name}</div>
          {topSpan.resource_id && (
            <SingleLineCodeBlock
              title="Resource ID"
              code={topSpan.resource_id}
              className="w-fit"
            />
          )}
          <LocalDateTime timestamp={topSpan.created_at} />
          <AISpanTrace spans={spans} />
        </div>
      )}
    </LoadingWrapper>
  );
};
