import { SelectOption } from "@incident-ui/Select/types";
import { tcx } from "src/utils/tailwind-classes";
import { v4 as uuidv4 } from "uuid";

import { Checkbox, CheckboxHelptextDisplayEnum } from "./Checkbox";

export type CheckboxSelectOption = SelectOption<React.ReactNode> & {
  disabled?: boolean;
  disabledTooltipContent?: React.ReactNode;
  helptext?: string;
};

export type CheckboxGroupProps = {
  options: CheckboxSelectOption[];
  onChange: (val: string[]) => void;
  value: string[];
  helptextDisplay?: CheckboxHelptextDisplayEnum;
  className?: string;
  showSelectAll?: boolean;
  onValueChange?: (val: string[]) => void;
};

export const CheckboxGroup = ({
  options,
  onChange,
  value = [],
  className,
  showSelectAll = false,
  helptextDisplay = CheckboxHelptextDisplayEnum.Inline,
}: CheckboxGroupProps): React.ReactElement => {
  const allItemsSelected = options.every((opt) => value.includes(opt.value));

  const onDeSelectAll = () => onChange([]);
  const onSelectAll = () => onChange(options.map((x) => x.value));

  return (
    <div className={tcx("flex flex-col", className)}>
      {showSelectAll && options.length > 1 && (
        <Checkbox
          className=""
          // This needs to be unique (e.g. if you have multiple checkboxes on a page, it's sad)
          id={`select-all-${uuidv4()}`}
          onChange={allItemsSelected ? onDeSelectAll : onSelectAll}
          checked={allItemsSelected}
          label="Select All"
          disabled={options.every((opt) => opt.disabled)}
          disabledTooltipContent={
            options.every((opt) => opt.disabled)
              ? "All options are disabled"
              : null
          }
        />
      )}
      {options.map((option) => {
        return (
          <div className={tcx({ "ml-4": showSelectAll })} key={option.value}>
            <Checkbox
              id={option.value}
              label={option.label}
              helptext={option.helptext}
              helptextDisplay={helptextDisplay}
              checked={value.includes(option.value)}
              disabled={option.disabled}
              disabledTooltipContent={option.disabledTooltipContent}
              onChange={(boolVal) => {
                if (boolVal.target.checked) {
                  return onChange(sortValue([...value, option.value], options));
                }

                return onChange(value.filter((item) => item !== option.value));
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const sortValue = (
  value: string[],
  options: SelectOption<React.ReactNode>[],
) => {
  return value.sort((a, b) => {
    const optionA = options.find((item) => item.value === a);
    const optionB = options.find((item) => item.value === b);
    return (optionA?.sort_key || "") < (optionB?.sort_key || "") ? -1 : 1;
  });
};
