import { Route, Routes } from "react-router";

import { ProcessorShowPage } from "./ProcessorShowPage";
import { ProcessorsListPage } from "./ProcessorsListPage";

export const ProcessorsRoute = () => {
  return (
    <Routes>
      <Route path="/:spanId" element={<ProcessorShowPage />} />
      <Route path="*" element={<ProcessorsListPage />} />
    </Routes>
  );
};
