/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorDebug,
    ErrorDebugFromJSON,
    ErrorDebugFromJSONTyped,
    ErrorDebugToJSON,
} from './ErrorDebug';
import {
    ErrorRateLimit,
    ErrorRateLimitFromJSON,
    ErrorRateLimitFromJSONTyped,
    ErrorRateLimitToJSON,
} from './ErrorRateLimit';
import {
    ErrorSingle,
    ErrorSingleFromJSON,
    ErrorSingleFromJSONTyped,
    ErrorSingleToJSON,
} from './ErrorSingle';

/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {ErrorDebug}
     * @memberof ErrorResponse
     */
    debug?: ErrorDebug;
    /**
     * List of errors that caused this request to fail
     * @type {Array<ErrorSingle>}
     * @memberof ErrorResponse
     */
    errors: Array<ErrorSingle>;
    /**
     * 
     * @type {ErrorRateLimit}
     * @memberof ErrorResponse
     */
    rate_limit?: ErrorRateLimit;
    /**
     * Unique identifier of the request
     * @type {string}
     * @memberof ErrorResponse
     */
    request_id: string;
    /**
     * HTTP status of the response
     * @type {number}
     * @memberof ErrorResponse
     */
    status: number;
    /**
     * Machine-readable identifier for the general category of error
     * @type {string}
     * @memberof ErrorResponse
     */
    type: ErrorResponseTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ErrorResponseTypeEnum {
    InvalidRequestError = 'invalid_request_error',
    AuthenticationError = 'authentication_error',
    ResourceForbidden = 'resource_forbidden',
    NotFound = 'not_found',
    NotAcceptable = 'not_acceptable',
    RequestTimeout = 'request_timeout',
    Conflict = 'conflict',
    PreconditionFailed = 'precondition_failed',
    PayloadTooLarge = 'payload_too_large',
    ValidationError = 'validation_error',
    TooManyRequests = 'too_many_requests',
    ApiError = 'api_error',
    RateLimitReached = 'rate_limit_reached'
}

export function ErrorResponseFromJSON(json: any): ErrorResponse {
    return ErrorResponseFromJSONTyped(json, false);
}

export function ErrorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debug': !exists(json, 'debug') ? undefined : ErrorDebugFromJSON(json['debug']),
        'errors': ((json['errors'] as Array<any>).map(ErrorSingleFromJSON)),
        'rate_limit': !exists(json, 'rate_limit') ? undefined : ErrorRateLimitFromJSON(json['rate_limit']),
        'request_id': json['request_id'],
        'status': json['status'],
        'type': json['type'],
    };
}

export function ErrorResponseToJSON(value?: ErrorResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debug': ErrorDebugToJSON(value.debug),
        'errors': ((value.errors as Array<any>).map(ErrorSingleToJSON)),
        'rate_limit': ErrorRateLimitToJSON(value.rate_limit),
        'request_id': value.request_id,
        'status': value.status,
        'type': value.type,
    };
}

