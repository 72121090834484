import { useAiStaffServiceAiStaffShowAiRequest } from "@incident-io/query-api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Drawer, DrawerContentsLoading } from "@incident-ui/Drawer/Drawer";
import { useParams } from "react-router";

import { AISpanDrawer } from "./AISpanDrawer";

export const AIRequestShowDrawer = () => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/workbench/requests");
  const { id: requestID } = useParams<{ id: string }>();

  const { data, isLoading } = useAiStaffServiceAiStaffShowAiRequest({
    id: requestID as string,
  });

  if (isLoading || !data) {
    return (
      <Drawer width="medium" onClose={onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  return <AISpanDrawer onClose={onClose} span={data.span} />;
};
