import { Incident } from "@incident-io/api";
import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { CreatePostMortemModal } from "@incident-shared/postmortems/CreatePostMortemModal";
import { ExportPostmortemDrawer } from "@incident-shared/postmortems/ExportPostmortemDrawer";
import { SharePostMortemModal } from "@incident-shared/postmortems/SharePostMortemModal";
import { LoadingModal } from "@incident-ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FunctionComponent, useState } from "react";
import { Route, Routes, useOutlet } from "react-router";
import { CatalogEntryViewDrawer } from "src/components/catalog/entry-view/CatalogEntryViewDrawer";
import { EscalationDrawer } from "src/components/escalations/EscalateDrawer";
import { CallNotesDrawer } from "src/components/incident-calls/CallNotesDrawer";
import { TimelineEditor } from "src/components/incident-timeline/TimelineEditor";
import { useDisableNPS } from "src/contexts/SurvicateContext";
import {
  IncidentDrawer,
  IncidentHeaderModal,
  StreamModal,
} from "src/routes/legacy/IncidentRoute";

import { SummaryContext } from "./body/SummaryContext";
import { ScheduleDebriefModal } from "./debriefs/ScheduleDebriefModal";
import { CancelIncidentModal } from "./header/CancelIncidentModal";
import { ChangePrivacyModal } from "./header/ChangePrivacyModal";
import { DemoMagicCallModal } from "./header/DemoMagicCallModal";
import { EditDurationTimestampsModal } from "./header/EditDurationTimestampsModal";
import {
  EditRoleAssignmentsModal,
  EditStreamRoleAssignmentsModal,
} from "./header/EditRoleAssignmentsModal";
import { EditTimestampsModal } from "./header/EditTimestampsModal";
import { GiveShoutoutModal } from "./header/GiveShoutoutModal";
import { UpdateIsTestConfirmationModal } from "./header/IncidentHeaderModal";
import {
  IncidentManageAccessModal,
  StreamManageAccessModal,
} from "./header/ManageAccessModal";
import { OptOutOfPostIncidentFlowModal } from "./header/OptOutOfPostIncidentFlowModal";
import { RenameIncidentModal } from "./header/RenameIncidentModal";
import {
  RequestStreamUpdateModal,
  RequestUpdateContextProvider,
  RequestUpdateModal,
} from "./header/RequestUpdateModal";
import { ResolveIncidentModal } from "./header/ResolveIncidentModal";
import { RunInvestigationModal } from "./header/RunInvestigationModal";
import { RunWorkflowModal } from "./header/RunWorkflowModal";
import {
  UpdateCallModal,
  UpdateStreamCallModal,
} from "./header/UpdateCallModal";
import { UpdateIncidentModal } from "./header/UpdateIncidentModal";
import { UpdateIncidentTypeModal } from "./header/UpdateIncidentTypeModal";
import { UpdateSeverityModal } from "./header/UpdateSeverityModal";
import { UpdateStatusModal } from "./header/UpdateStatusModal";
import { useIncident } from "./hooks";
import { IncidentComponent } from "./IncidentComponent";
import { PostIncidentModalProvider } from "./postincidentflow/modals/PostIncidentActionModals";
import { AddRelatedIncidentModal } from "./sidebar/AddRelatedIncidentModal";
import { EditCustomFieldEntriesModal } from "./sidebar/EditCustomFieldEntriesModal";
import { DebriefsDrawer } from "./sidebar/links/Debriefs";
import { IncidentAlertsDrawer } from "./sidebar/links/IncidentAlerts";
import { IncidentAttachmentsDrawer } from "./sidebar/links/IncidentAttachments";
import { EscalationsDrawer } from "./sidebar/links/IncidentEscalations";
import { RelatedIncidentsDrawer } from "./sidebar/links/RelatedIncidents";
import { StreamsCreateDrawer } from "./streams/StreamsCreateDrawer";
import { StreamsViewDrawer } from "./streams/StreamsViewDrawer";

export const IncidentDetailsPage = ({
  incidentId,
  externalId,
}: {
  incidentId: string | null;
  externalId: number;
}) => {
  const { incident } = useIncident(incidentId);
  const {
    featureLiveCallTranscription,
    postmortemsInHouse: featurePostmortemsInHouse,
  } = useFlags();

  // This page is a core part of incident response - we don't want to get in
  // the way here
  useDisableNPS();

  const navigate = useOrgAwareNavigate();
  const onCloseModal = (isModalInDrawer = false) => {
    if (isModalInDrawer) {
      navigate(
        {
          pathname: "..",
          search: location.search,
        },
        {
          relative: "path",
          replace: true,
        },
      );
    } else {
      navigate(
        {
          pathname: `/incidents/${externalId}`,
          search: location.search,
        },
        { replace: true },
      );
    }
  };

  const Modals: {
    [key in IncidentHeaderModal]: FunctionComponent<{
      incident: Incident;
      onClose: () => void;
    }>;
  } = {
    [IncidentHeaderModal.AddRelatedIncident]: AddRelatedIncidentModal,
    [IncidentHeaderModal.Cancel]: CancelIncidentModal,
    [IncidentHeaderModal.ChangePrivacy]: ChangePrivacyModal,
    // When we rip this out, we should also move the drawer into it's own route below like the other drawers.
    // This is to avoid an intermediate loading modal.
    [IncidentHeaderModal.CreatePostmortem]: featurePostmortemsInHouse
      ? ExportPostmortemDrawer
      : CreatePostMortemModal,
    [IncidentHeaderModal.DemoMagicCall]: DemoMagicCallModal,
    [IncidentHeaderModal.EditCustomFields]: EditCustomFieldEntriesModal,
    [IncidentHeaderModal.EditRoleAssignments]: EditRoleAssignmentsModal,
    [IncidentHeaderModal.EditTimestamps]: EditTimestampsModal,
    [IncidentHeaderModal.GiveShoutout]: GiveShoutoutModal,
    [IncidentHeaderModal.ManageAccess]: IncidentManageAccessModal,
    [IncidentHeaderModal.OptOutOfPostIncident]: OptOutOfPostIncidentFlowModal,
    [IncidentHeaderModal.RenameIncident]: RenameIncidentModal,
    [IncidentHeaderModal.RequestUpdate]: RequestUpdateModal,
    [IncidentHeaderModal.Resolve]: ResolveIncidentModal,
    [IncidentHeaderModal.RunWorkflow]: RunWorkflowModal,
    [IncidentHeaderModal.RunInvestigation]: RunInvestigationModal,
    [IncidentHeaderModal.ScheduleDebrief]: ScheduleDebriefModal,
    [IncidentHeaderModal.UpdateCall]: UpdateCallModal,
    [IncidentHeaderModal.SharePostmortem]: SharePostMortemModal,
    [IncidentHeaderModal.UpdateIncidentType]: UpdateIncidentTypeModal,
    [IncidentHeaderModal.UpdateIncident]: UpdateIncidentModal,
    [IncidentHeaderModal.UpdateIsTest]: UpdateIsTestConfirmationModal,
    [IncidentHeaderModal.UpdateSeverity]: UpdateSeverityModal,
    [IncidentHeaderModal.UpdateStatus]: UpdateStatusModal,
  };

  const streamModals: {
    [key in StreamModal]: FunctionComponent<{
      onClose: () => void;
    }>;
  } = {
    [IncidentHeaderModal.EditRoleAssignments]: EditStreamRoleAssignmentsModal,
    [IncidentHeaderModal.ManageAccess]: StreamManageAccessModal,
    [IncidentHeaderModal.RequestUpdate]: RequestStreamUpdateModal,
    [IncidentHeaderModal.UpdateCall]: UpdateStreamCallModal,
  };
  const [isEditingSummary, setIsEditingSummary] = useState(false);

  return (
    <RequestUpdateContextProvider>
      <PostIncidentModalProvider>
        <SummaryContext.Provider
          value={{ setIsEditingSummary, isEditingSummary }}
        >
          <Routes>
            <Route
              path="/"
              element={<IncidentWithOutlet incidentId={incidentId} />}
            >
              <Route
                path={`${IncidentDrawer.Streams}-create`}
                element={
                  incidentId && (
                    <StreamsCreateDrawer
                      onClose={() => onCloseModal(true)}
                      incidentId={incidentId}
                    />
                  )
                }
              />
              <Route
                path={`${IncidentDrawer.Streams}/:streamId`}
                element={
                  <StreamsViewDrawer onClose={() => onCloseModal(false)} />
                }
              >
                {Object.entries(streamModals).map(([route, Modal]) => (
                  <Route
                    key={route}
                    path={`${route}`}
                    element={<Modal onClose={() => onCloseModal(true)} />}
                  />
                ))}
              </Route>

              {/* A route so we can link to the escalate drawer within teams */}
              <Route
                path={IncidentDrawer.Escalate}
                element={
                  <EscalationDrawer
                    onClose={() => onCloseModal(false)}
                    incidentId={incident?.id}
                    showDeclareIncident={false}
                    shouldWarnWhenDirty={false}
                  />
                }
              />

              {/* A route so we can show catalog entries in a drawer */}
              <Route
                path={`${IncidentDrawer.CatalogEntry}/:id/:entry_id`}
                element={
                  <CatalogEntryViewDrawer
                    refetchEntries={() => null}
                    onClose={() => onCloseModal(false)}
                  />
                }
              />

              {featureLiveCallTranscription && (
                <>
                  <Route
                    path={`${IncidentDrawer.CallNotes}/:callSessionId?`}
                    element={
                      incident && (
                        <CallNotesDrawer
                          onClose={() => onCloseModal(false)}
                          incidentId={incident.id}
                        />
                      )
                    }
                  />
                  <Route
                    path={`${IncidentDrawer.Streams}/:streamId/${IncidentDrawer.CallNotes}/:callSessionId?`}
                    element={
                      incident && (
                        <CallNotesDrawer
                          onClose={() => onCloseModal(false)}
                          incidentId={incident.id}
                        />
                      )
                    }
                  />
                </>
              )}
              <Route
                path={IncidentDrawer.RelatedIncidents}
                element={
                  <RelatedIncidentsDrawer
                    onClose={() => onCloseModal(false)}
                    incident={incident}
                  />
                }
              />

              <Route
                path={`duration/:duration_id`}
                element={
                  incident ? (
                    <EditDurationTimestampsModal
                      onClose={() => onCloseModal(false)}
                      incident={incident}
                    />
                  ) : (
                    <LoadingModal
                      title={"Loading"}
                      onClose={() => onCloseModal(false)}
                    />
                  )
                }
              />
              <Route
                path={`edit-timeline`}
                element={
                  incident && (
                    <TimelineEditor
                      incident={incident}
                      onClose={() => onCloseModal(true)}
                    />
                  )
                }
              />
              {/* As these are drawers, the route needs to be separate otherwise we see an intermediate loading modal. */}
              <Route
                path={IncidentDrawer.Attachments}
                element={
                  incident && (
                    <IncidentAttachmentsDrawer
                      incident={incident}
                      onClose={() => onCloseModal(true)}
                    />
                  )
                }
              />
              <Route
                path={IncidentDrawer.Debriefs}
                element={
                  incident && (
                    <DebriefsDrawer
                      incident={incident}
                      onClose={() => onCloseModal(true)}
                    />
                  )
                }
              />
              <Route
                path={IncidentDrawer.Alerts}
                element={
                  incident && (
                    <IncidentAlertsDrawer
                      incident={incident}
                      onClose={() => onCloseModal(true)}
                    />
                  )
                }
              />
              <Route
                path={IncidentDrawer.Escalations}
                element={
                  incident && (
                    <EscalationsDrawer
                      incident={incident}
                      onClose={() => onCloseModal(true)}
                    />
                  )
                }
              />
              <Route
                // This drawer existed for a few days, and there's some links in Slack channels that'll still
                // point to it.
                path="post-incident"
                element={
                  <OrgAwareNavigate
                    to={`/incidents/${externalId}?tab=post-incident`}
                  />
                }
              />
              {Object.entries(Modals).map(([route, Modal]) => (
                <Route
                  key={route}
                  path={`${route}`}
                  element={
                    incident ? (
                      <Modal onClose={onCloseModal} incident={incident} />
                    ) : (
                      <LoadingModal onClose={onCloseModal} />
                    )
                  }
                />
              ))}
            </Route>
          </Routes>
        </SummaryContext.Provider>
      </PostIncidentModalProvider>
    </RequestUpdateContextProvider>
  );
};

const IncidentWithOutlet = ({ incidentId }: { incidentId: string | null }) => {
  // The outlet renders any modal or drawer that is open. We render it like this
  // so that it can be _directly_ wrapped in AnimatePresence, to give us exit
  // animations.
  const outlet = useOutlet();

  return (
    <>
      {outlet}
      <IncidentComponent incidentId={incidentId} />
    </>
  );
};
