import {
  EvaluationDatasetEntry,
  IncidentFilterFieldsListContextEnum,
  IncidentsListSortByEnum,
} from "@incident-io/api";
import {
  EvaluationDataset,
  useAiStaffServiceAiStaffCalculateDatasetDimensions,
  useAiStaffServiceAiStaffGroupDatasetEntriesBySegment,
} from "@incident-io/query-api";
import {
  AddFilterButton,
  AppliedFiltersBanner,
  ExtendedFormFieldValue,
  FiltersContextProvider,
  useInitialiseFilters,
} from "@incident-shared/filters";
import { IncidentsFilterControlSearchBar } from "@incident-shared/filters/IncidentsFilterControlSearchBar";
import {
  IncidentsListContextProvider,
  useGetIncidents,
} from "@incident-shared/incidents";
import {
  EmptyState,
  ErrorMessage,
  IconEnum,
  LoadingModal,
  LoadingWrapper,
} from "@incident-ui";
import { useState } from "react";
import { useDebounce } from "use-debounce";

import { BulkDatasetWith } from "../../legacy/incidents-list/bulk-actions/BulkDatasetCurate";
import { useIncidentsSelections } from "../../legacy/incidents-list/IncidentListPage";
import { IncidentsList } from "../../legacy/incidents-list/IncidentsList";
import { BacktestCreateModal } from "../backtests/BacktestCreateModal";
import { DatasetDimensions } from "./DatasetDimensions";
import { DatasetSegments } from "./DatasetSegments";

// Show a list of incidents in a similar style to our incident list page.
export const DatasetShowPageContent = ({
  dataset,
  entries,
}: {
  dataset: EvaluationDataset;
  entries: EvaluationDatasetEntry[];
}) => {
  const [filters, setFilters] = useState<ExtendedFormFieldValue[]>([]);
  const [debouncedFilters] = useDebounce(filters, 300);

  const {
    loading,
    availableIncidentFilterFields: availableFilterFields,
    error,
  } = useInitialiseFilters(``, IncidentFilterFieldsListContextEnum.Incidents);

  // Remove the dataset filter from the list of available filters, as we want this list to
  // always be filtered by our dataset and for users to be unable to remove that filter.
  const filtersWithoutDataset = availableFilterFields.filter(
    (filter) => filter.filter_id !== "dataset",
  );

  if (error) {
    return <ErrorMessage message={JSON.stringify(error)} />;
  }

  return (
    <FiltersContextProvider
      filters={filters}
      setFilters={setFilters}
      availableFilterFields={filtersWithoutDataset}
      kind="incident"
      filtersLoading={loading}
    >
      <IncidentsListContextProvider>
        <IncidentsListContent
          dataset={dataset}
          entries={entries}
          filters={filters}
          debouncedFilters={debouncedFilters}
        />
      </IncidentsListContextProvider>
    </FiltersContextProvider>
  );
};

const IncidentsListContent = ({
  dataset,
  entries,
  filters,
  debouncedFilters,
}: {
  dataset: EvaluationDataset;
  entries: EvaluationDatasetEntry[];
  filters: ExtendedFormFieldValue[];
  debouncedFilters: ExtendedFormFieldValue[];
}) => {
  const { data: groupedEntries, isLoading: groupedEntriesLoading } =
    useAiStaffServiceAiStaffGroupDatasetEntriesBySegment({
      id: dataset.id,
    });

  const {
    incidents,
    someIncidentsLoaded,
    allIncidentsLoaded,
    totalNumberOfIncidents,
    refetchIncidents,
    loadMore,
    isLoading,
  } = useGetIncidents({
    filters: debouncedFilters,
    fixedFilters: {
      pageSize: 100,
      sortBy: IncidentsListSortByEnum.NewestFirst,
      dataset: {
        one_of: [dataset.id],
      },
    },
    eagerLoad: true,
  });

  const {
    selectAllIncidents,
    setSelectAllIncidents,
    selectedIncidentIDs,
    setSelectedIncidentIDs,
  } = useIncidentsSelections(
    incidents.map((inc) => inc.id),
    allIncidentsLoaded,
  );

  return (
    <div className="flex flex-col h-full w-full gap-6">
      <DatasetSegments
        dataset={dataset}
        filters={filters}
        entriesBySegment={
          groupedEntries ? groupedEntries.entries_by_segment : {}
        }
      />
      <div className="flex justify-between gap-3 w-full">
        <IncidentsFilterControlSearchBar
          incidents={incidents}
          selectedIncidentIDs={selectedIncidentIDs}
          selectAllIncidents={selectAllIncidents}
          setSelectAllIncidents={setSelectAllIncidents}
          refetchIncidents={refetchIncidents}
          includesTriageIncidents={false}
          isAllTriageIncidents={false}
          totalNumberOfIncidents={totalNumberOfIncidents ?? 0}
          canCustomiseDisplayedInfo={true}
          bulkActionsOverride={[
            {
              label: "Remove from dataset",
              icon: IconEnum.InsightsDashboard,
              name: "dataset_remove",
              form: BulkDatasetWith({
                mode: "remove",
                overrideDatasetID: dataset.id,
              }),
            },
            {
              label: "Run a backtest",
              icon: IconEnum.Test,
              name: "run_backtest",
              form: ({ onClose, incidentIDs }) =>
                groupedEntriesLoading ? (
                  <LoadingModal onClose={onClose} />
                ) : (
                  <BacktestCreateModal
                    incidentIDs={incidentIDs}
                    onClose={onClose}
                    segments={
                      groupedEntries ? groupedEntries.entries_by_segment : {}
                    }
                  />
                ),
            },
          ]}
        />
        <AddFilterButton />
      </div>

      <AppliedFiltersBanner
        totalNumberOfItems={incidents.length}
        itemsLabel={"incident"}
        style={"partOfPage"}
      />
      {entries.length === 0 ? (
        <EmptyState
          content="There are no incidents in this dataset"
          icon={IconEnum.Incident}
        />
      ) : (
        <div className="flex flex-col gap-6 xl:flex-row">
          <div className="w-1/2 overflow-x-auto">
            <IncidentsList
              className={"-mx-8"}
              incidents={incidents}
              incidentsIsLoading={isLoading}
              incidentsLoadMore={loadMore}
              anyIncidentsLoaded={someIncidentsLoaded}
              allIncidentsLoaded={allIncidentsLoaded}
              filters={filters}
              selectedIncidentIDs={selectedIncidentIDs}
              setSelectedIncidentIDs={setSelectedIncidentIDs}
              enableSelection={true}
              incidentTypesEnabled={true}
            />
          </div>
          <div className="w-1/2 flex flex-col gap-6">
            {incidents.length > 0 && (
              <DatasetDimensionsWrapper
                incidentIDs={incidents.map((inc) => inc.id)}
                datasetId={dataset.id}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const DatasetDimensionsWrapper = ({
  incidentIDs,
  datasetId,
}: {
  incidentIDs: string[];
  datasetId: string;
}) => {
  const { data, isLoading } =
    useAiStaffServiceAiStaffCalculateDatasetDimensions({
      id: datasetId,
      entryIds: incidentIDs,
    });

  return (
    <LoadingWrapper loading={isLoading}>
      <DatasetDimensions dimensions={data?.dimensions ?? []} />
    </LoadingWrapper>
  );
};
