import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  Tooltip,
} from "@incident-ui";
import React, { useState } from "react";
import { EditPostIncidentTaskAssigneeModal } from "src/components/legacy/incident/postincidentflow/modals/PostIncidentTaskAssignee";
import {
  PostIncidentTask,
  PostIncidentTaskSlim,
  User,
} from "src/contexts/ClientContext";
import { formatDurationInSecondsShort } from "src/utils/datetime";

export const PostIncidentAssigneeSection = ({
  task,
  refetchIncidents,
  BadgeSize,
}: {
  task: PostIncidentTaskSlim | PostIncidentTask;
  refetchIncidents: () => Promise<void>;
  BadgeSize: BadgeSize;
}): React.ReactElement => {
  const [isAssigning, setIsAssigning] = useState(false);

  if (task.completed_at || task.rejected_at) {
    return <CompleterOrRejectorSection task={task} />;
  }

  return (
    <>
      <Button
        analyticsTrackingId={"post-incident-list-assign"}
        onClick={() => setIsAssigning(true)}
        theme={ButtonTheme.Tertiary}
        size={BadgeSize}
        icon={task?.assignee ? undefined : IconEnum.UserAdd}
      >
        {task?.assignee && (
          <Avatar
            size={IconSize.Medium}
            url={task?.assignee?.avatar_url}
            name={task?.assignee?.name}
          />
        )}
        <span className="text-right truncate">
          {task?.assignee ? task?.assignee?.name : "Assign"}
        </span>
      </Button>
      {isAssigning && (
        <EditPostIncidentTaskAssigneeModal
          incidentId={task.incident_id}
          task={task}
          onClose={() => {
            setIsAssigning(false);
            refetchIncidents();
          }}
        />
      )}
    </>
  );
};

const CompleterOrRejectorSection = ({
  task,
}: {
  task: PostIncidentTaskSlim | PostIncidentTask;
}): React.ReactElement => {
  let user: User | undefined;
  let tooltipContent = "";

  if (task.completed_at && task.completer) {
    user = task.completer.user;
    const completedAgo = formatDurationInSecondsShort(
      (new Date().getTime() - task.completed_at.getTime()) / 1000,
      { significantFigures: 1 },
    );
    tooltipContent = `Completed by ${task.completer.user?.name} ${completedAgo} ago`;
  } else if (task.rejected_at && task.rejecter) {
    user = task.rejecter.user;
    const rejectedAgo = formatDurationInSecondsShort(
      (new Date().getTime() - task.rejected_at.getTime()) / 1000,
      { significantFigures: 1 },
    );
    tooltipContent = `Skipped by ${task.rejecter.user?.name} ${rejectedAgo} ago`;
  }

  // If we haven't recorded a completer or rejector for some reason,
  // we'll just show nothing as anything else looks weird
  if (!user) {
    return <></>;
  }

  return (
    <>
      <Tooltip
        content={<span className="whitespace-normal">{tooltipContent}</span>}
      >
        <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.Small}>
          <Avatar
            size={IconSize.Small}
            url={user?.avatar_url}
            name={user?.name}
          />
          <span className="text-right truncate">
            <span>{user?.name}</span>
          </span>
        </Badge>
      </Tooltip>
    </>
  );
};
