import {
  OnCallNotificationMethodMethodTypeEnum,
  OnCallNotificationMethodV2MethodTypeEnum,
  OnCallNotificationsTestContactMethodRequestBody,
  OnCallNotificationsTestContactMethodRequestBodyPhoneContactMethodEnum,
} from "@incident-io/api";
import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { ModalFooter, ToastTheme, Txt } from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";

import { Identity } from "../../../contexts/ClientContext";
import { useIdentity } from "../../../contexts/IdentityContext";
import { useAPIMutation } from "../../../utils/swr";
import { assertUnreachable } from "../../../utils/utils";
import { MethodDisplayItem } from "./ContactMethods";

const formatContactMethodReachoutText = (
  identity: Identity | null,
  method: MethodDisplayItem,
) => {
  if (!identity) {
    return "";
  }
  switch (method.type) {
    case OnCallNotificationMethodV2MethodTypeEnum.Phone:
      return `We'll send an example notification to ${method.value}.`;
    case OnCallNotificationMethodV2MethodTypeEnum.Email:
      return `We'll send an example email notification to ${identity.user_email}.`;
    case OnCallNotificationMethodV2MethodTypeEnum.Slack:
      return `We'll send an example Slack notification to ${identity.user_name}.`;
    case OnCallNotificationMethodV2MethodTypeEnum.MicrosoftTeams:
      return `We'll send an example Microsoft Teams notification to ${identity.user_name}.`;
    case OnCallNotificationMethodV2MethodTypeEnum.App:
      return `We'll send an example push notification to your mobile device.`;
    default:
      assertUnreachable(method.type);
      return "";
  }
};

type TestNotificationMethodFormData =
  OnCallNotificationsTestContactMethodRequestBody;
export const TestContactMethodModal = ({
  onClose,
  selectedMethod,
}: {
  onClose: () => void;
  selectedMethod: MethodDisplayItem;
}) => {
  const showToast = useToast();
  const { identity } = useIdentity();
  const methodID = selectedMethod.methodId;
  const formMethods = useForm<TestNotificationMethodFormData>({
    defaultValues: {
      on_call_notification_method_id: methodID,
      phone_contact_method:
        selectedMethod.type === "phone"
          ? OnCallNotificationsTestContactMethodRequestBodyPhoneContactMethodEnum.Sms
          : undefined,
    },
  });

  const { trigger: onSubmit } = useAPIMutation(
    "onCallNotificationsListMethods",
    undefined,
    async (
      apiClient,
      data: OnCallNotificationsTestContactMethodRequestBody,
    ) => {
      await apiClient.onCallNotificationsTestContactMethod({
        onCallNotificationType: selectedMethod.type,
        testContactMethodRequestBody: {
          on_call_notification_method_id: methodID,
          phone_contact_method: data.phone_contact_method,
          mobile_key_id: selectedMethod.mobileKeyID,
        },
      });
      return;
    },
    {
      onSuccess: () => {
        showToast({
          title: "Test notification sent",
          description: `We sent a test notification to ${
            TEST_NOTIFICATION_LABEL[selectedMethod.type]
          }.`,
          toastSide: ToastSideEnum.TopRight,
          theme: ToastTheme.Success,
        });
        onClose();
      },
      showErrorToast: `We failed to sent a test notification to ${
        TEST_NOTIFICATION_LABEL[selectedMethod.type]
      }`,
      setError: formMethods.setError,
    },
  );

  return (
    <Form.Modal
      title="Send a test notification"
      analyticsTrackingId="test-on-call-notification-method-modal"
      onClose={onClose}
      formMethods={formMethods}
      onSubmit={onSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Send test notification"
          confirmButtonType="submit"
          disabledTooltipContent="Please select a contact method"
          onClose={onClose}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <Txt>{formatContactMethodReachoutText(identity, selectedMethod)}</Txt>
        {selectedMethod.type === "phone" && (
          <RadioButtonGroupV2
            name="phone_contact_method"
            formMethods={formMethods}
            label={<Txt>Which phone contact method should we use?</Txt>}
            srLabel="phone_contact_method"
            boxed
            horizontal
            options={[
              {
                label: "SMS",
                value: "sms",
              },
              {
                label: "Phone call",
                value: "call",
              },
            ]}
          />
        )}
      </div>
    </Form.Modal>
  );
};

export const TEST_NOTIFICATION_LABEL: {
  [key in OnCallNotificationMethodMethodTypeEnum];
} = {
  [OnCallNotificationMethodMethodTypeEnum.Slack]: "Slack",
  [OnCallNotificationMethodMethodTypeEnum.MicrosoftTeams]: "Microsoft Teams",
  [OnCallNotificationMethodMethodTypeEnum.App]: "your app",
  [OnCallNotificationMethodMethodTypeEnum.Phone]: "your phone",
  [OnCallNotificationMethodMethodTypeEnum.Email]: "your email",
};
