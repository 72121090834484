import { AIConfig, ScopeNameEnum } from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { IconEnum } from "@incident-ui";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
  AIProductMarketingCard,
  AIProductMarketingCards,
} from "../../ai/AIFeatureConfigurationDrawer";
import {
  AIPrivacyButton,
  AISuggestionFeatureDrawerProps,
  ToggleSuggestionButton,
} from "./SuggestionAICards";

export const SimilarIncidentsDrawer = ({
  feature,
  onClose,
}: AISuggestionFeatureDrawerProps): React.ReactElement => {
  const { data: aiConfigResp } = useAPI("aIShowConfig", undefined);

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        onClose();
      },
      showErrorToast: "Toggling similar incidents",
    },
  );

  const enabled = aiConfigResp?.config.incident_similarity_enabled;

  return (
    <AIFeatureConfigurationDrawer
      loading={!aiConfigResp}
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleSuggestionButton
                enabled={!!enabled}
                disableModalTitle="Disable related incidents"
                disableModalContent="This means we'll stop suggesting related incidents."
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }

                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp.config,
                      incident_similarity_enabled: true,
                    },
                  });
                }}
                onDisable={() => {
                  if (!aiConfigResp?.config) {
                    return;
                  }

                  toggleAIFeature({
                    newConfig: {
                      ...aiConfigResp.config,
                      incident_similarity_enabled: false,
                    },
                  });
                }}
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <>
          <AIProductMarketingCards>
            <AIProductMarketingCard
              title="Get the right people in the room"
              description="Find out who's handled this kind of issue before, and what they did last time to resolve it."
              icon={IconEnum.Users}
            />
            <AIProductMarketingCard
              title="Understand patterns"
              description="We'll show you related incidents in the dashboard so you can review connections and learn from past incidents."
              icon={IconEnum.Bulb}
            />
          </AIProductMarketingCards>
        </>
      }
      width="medium"
    />
  );
};
