/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIToggleFeatureRequestBody
 */
export interface AIToggleFeatureRequestBody {
    /**
     * Whether or not to enable the AI assistant
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    assistant_enabled: boolean;
    /**
     * Whether or not to enable the AI copilot
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    copilot_enabled: boolean;
    /**
     * Whether or not to enable AI-generated incident similarity
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    incident_similarity_enabled: boolean;
    /**
     * Whether or not to enable AI-generated summaries for incidents
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    incident_summaries_enabled: boolean;
    /**
     * Whether or not to enable call transcription
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    scribe_enabled: boolean;
    /**
     * Whether or not to enable AI-generated followups
     * @type {boolean}
     * @memberof AIToggleFeatureRequestBody
     */
    suggest_followups_enabled: boolean;
}

export function AIToggleFeatureRequestBodyFromJSON(json: any): AIToggleFeatureRequestBody {
    return AIToggleFeatureRequestBodyFromJSONTyped(json, false);
}

export function AIToggleFeatureRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIToggleFeatureRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assistant_enabled': json['assistant_enabled'],
        'copilot_enabled': json['copilot_enabled'],
        'incident_similarity_enabled': json['incident_similarity_enabled'],
        'incident_summaries_enabled': json['incident_summaries_enabled'],
        'scribe_enabled': json['scribe_enabled'],
        'suggest_followups_enabled': json['suggest_followups_enabled'],
    };
}

export function AIToggleFeatureRequestBodyToJSON(value?: AIToggleFeatureRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assistant_enabled': value.assistant_enabled,
        'copilot_enabled': value.copilot_enabled,
        'incident_similarity_enabled': value.incident_similarity_enabled,
        'incident_summaries_enabled': value.incident_summaries_enabled,
        'scribe_enabled': value.scribe_enabled,
        'suggest_followups_enabled': value.suggest_followups_enabled,
    };
}

