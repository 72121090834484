/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalIssueReference
 */
export interface ExternalIssueReference {
    /**
     * ID of the issue in the issue tracker
     * @type {string}
     * @memberof ExternalIssueReference
     */
    issue_id: string;
    /**
     * Human readable ID for the issue
     * @type {string}
     * @memberof ExternalIssueReference
     */
    issue_name: string;
    /**
     * URL linking directly to the action in the issue tracker
     * @type {string}
     * @memberof ExternalIssueReference
     */
    issue_permalink: string;
    /**
     * ID of the template used to create the issue in the issue tracker
     * @type {string}
     * @memberof ExternalIssueReference
     */
    issue_template_id?: string;
    /**
     * ID of the issue tracker provider
     * @type {string}
     * @memberof ExternalIssueReference
     */
    provider: ExternalIssueReferenceProviderEnum;
    /**
     * The ID of the issue tracker instance, for example the Jira Site ID or Linear Organization ID
     * @type {string}
     * @memberof ExternalIssueReference
     */
    provider_instance_id: string;
}

/**
* @export
* @enum {string}
*/
export enum ExternalIssueReferenceProviderEnum {
    Asana = 'asana',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function ExternalIssueReferenceFromJSON(json: any): ExternalIssueReference {
    return ExternalIssueReferenceFromJSONTyped(json, false);
}

export function ExternalIssueReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalIssueReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issue_id': json['issue_id'],
        'issue_name': json['issue_name'],
        'issue_permalink': json['issue_permalink'],
        'issue_template_id': !exists(json, 'issue_template_id') ? undefined : json['issue_template_id'],
        'provider': json['provider'],
        'provider_instance_id': json['provider_instance_id'],
    };
}

export function ExternalIssueReferenceToJSON(value?: ExternalIssueReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issue_id': value.issue_id,
        'issue_name': value.issue_name,
        'issue_permalink': value.issue_permalink,
        'issue_template_id': value.issue_template_id,
        'provider': value.provider,
        'provider_instance_id': value.provider_instance_id,
    };
}

