import { AIRequest } from "@incident-io/query-api";

interface EvalOverrideResult {
  result: string;
  id?: string;
  hasOverride: boolean;
}

// Types for the eval override storage system
export interface EvalResultOverride {
  requestId: string;
  result: string;
  id?: string;
  updatedAt: string;
}

interface EvalOverrideStorage {
  overrides: Record<string, EvalResultOverride>;
}

const STORAGE_KEY = "evalResultOverrides";

/**
 * Initialize or get the override storage
 */
const getOverrideStorage = (): EvalOverrideStorage => {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (!stored) {
    return {
      overrides: {},
    };
  }

  try {
    const parsed = JSON.parse(stored) as EvalOverrideStorage;
    return parsed;
  } catch (error) {
    console.error("Failed to parse eval overrides from localStorage:", error);
    return {
      overrides: {},
    };
  }
};

/**
 * Save the override storage back to localStorage
 */
const saveOverrideStorage = (storage: EvalOverrideStorage): void => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storage));
  } catch (error) {
    console.error("Failed to save eval overrides to localStorage:", error);
  }
};

/**
 * Upsert an eval result override for a given request ID
 */
export const upsertEvalOverride = ({
  requestId,
  result,
  id,
}: {
  requestId: string;
  result: string;
  id?: string;
}): void => {
  const storage = getOverrideStorage();

  storage.overrides[requestId] = {
    requestId,
    result,
    id,
    updatedAt: new Date().toISOString(),
  };

  saveOverrideStorage(storage);
};

/**
 * Get an eval result for a given AI request
 * Returns the result and whether it was overridden
 */
export const getEvalOverride = (request: AIRequest): EvalOverrideResult => {
  const storage = getOverrideStorage();
  const override = storage.overrides[request.id];

  if (!override) {
    return {
      result: request.response,
      hasOverride: false,
    };
  }

  // Check if the override is more than 24h old
  const overrideTime = new Date(override.updatedAt);
  const now = new Date();
  const hoursDiff = (now.getTime() - overrideTime.getTime()) / (1000 * 60 * 60);

  if (hoursDiff > 24) {
    return {
      result: request.response,
      hasOverride: false,
    };
  }

  return {
    hasOverride: true,
    result: override.result,
    id: override.id,
  };
};

/**
 * Remove an eval result override for a given request ID
 */
export const removeEvalOverride = (requestId: string): void => {
  const storage = getOverrideStorage();

  if (storage.overrides[requestId]) {
    delete storage.overrides[requestId];
    saveOverrideStorage(storage);
  }
};

/**
 * Clear all eval result overrides
 */
export const clearAllEvalOverrides = (): void => {
  saveOverrideStorage({
    overrides: {},
  });
};

/**
 * Get all stored eval result overrides
 */
export const getAllEvalOverrides = (): Record<string, EvalResultOverride> => {
  const storage = getOverrideStorage();
  return storage.overrides;
};

// Define the structure of a batch item
export interface AIRequestBatchItem {
  requestId: string;
  caseName: string;
}

// Batch storage types
interface BatchStorage {
  batches: Record<string, AIRequestBatchItem[]>;
}

const BATCH_STORAGE_KEY = "evalRequestBatches";

/**
 * Initialize or get the batch storage
 */
const getBatchStorage = (): BatchStorage => {
  const stored = localStorage.getItem(BATCH_STORAGE_KEY);
  if (!stored) {
    return {
      batches: {},
    };
  }

  try {
    const parsed = JSON.parse(stored) as BatchStorage;
    return parsed;
  } catch (error) {
    console.error("Failed to parse eval batches from localStorage:", error);
    return {
      batches: {},
    };
  }
};

/**
 * Save the batch storage back to localStorage
 */
const saveBatchStorage = (storage: BatchStorage): void => {
  try {
    localStorage.setItem(BATCH_STORAGE_KEY, JSON.stringify(storage));
  } catch (error) {
    console.error("Failed to save eval batches to localStorage:", error);
  }
};

/**
 * Get a unique case name by appending a number if needed
 * @param existingItems - Current items in the batch
 * @param baseCaseName - The original case name to make unique
 * @returns A unique case name
 */
const getUniqueCaseName = (
  existingItems: AIRequestBatchItem[],
  baseCaseName: string,
): string => {
  // If no items have this case name, return it as is
  if (!existingItems.some((item) => item.caseName === baseCaseName)) {
    return baseCaseName;
  }

  // Find all items that start with this case name and have a number in parentheses
  const regex = new RegExp(`^${baseCaseName}\\s*\\((\\d+)\\)$`);
  const existingNumbers = existingItems
    .map((item) => {
      const match = item.caseName.match(regex);
      return match ? parseInt(match[1], 10) : 0;
    })
    .filter((num) => num > 0);

  // Start with 1 and find the first number that's not taken
  let counter = 1;
  while (existingNumbers.includes(counter)) {
    counter++;
  }

  return `${baseCaseName} (${counter})`;
};

/**
 * Add an AI request to a batch for a given prompt name
 * @param promptName - The name of the prompt to batch requests for
 * @param requestId - The request ID to add to the batch
 * @param caseName - The case name associated with the request
 */
export const addAIRequestToBatch = (
  promptName: string,
  requestId: string,
  caseName: string,
): void => {
  const storage = getBatchStorage();

  // Initialize the batch array if it doesn't exist
  if (!storage.batches[promptName]) {
    storage.batches[promptName] = [];
  }

  // Only add the item if a request with this ID doesn't already exist
  if (
    !storage.batches[promptName].some((item) => item.requestId === requestId)
  ) {
    const uniqueCaseName = getUniqueCaseName(
      storage.batches[promptName],
      caseName,
    );
    storage.batches[promptName].push({ requestId, caseName: uniqueCaseName });
    saveBatchStorage(storage);
  }
};

/**
 * Remove a specific AI request from a batch for a given prompt name
 * @param promptName - The name of the prompt to remove the request from
 * @param requestId - The request ID to remove from the batch
 */
export const removeRequestFromBatch = (
  promptName: string,
  requestId: string,
): void => {
  const storage = getBatchStorage();

  if (storage.batches[promptName]) {
    storage.batches[promptName] = storage.batches[promptName].filter(
      (item) => item.requestId !== requestId,
    );
    saveBatchStorage(storage);
  }
};

/**
 * Clear all AI requests from a batch for a given prompt name
 * @param promptName - The name of the prompt to clear the batch for
 */
export const clearAIRequestBatch = (promptName: string): void => {
  const storage = getBatchStorage();

  if (storage.batches[promptName]) {
    delete storage.batches[promptName];
    saveBatchStorage(storage);
  }
};

/**
 * Get all AI requests from a batch for a given prompt name
 * @param promptName - The name of the prompt to get the batch for
 * @returns An array of BatchItems, or an empty array if no batch exists
 */
export const getAIRequestBatch = (promptName: string): AIRequestBatchItem[] => {
  const storage = getBatchStorage();
  return storage.batches[promptName] || [];
};

/**
 * Get all stored batches
 * @returns A record of prompt names to arrays of BatchItems
 */
export const getAllAIRequestBatches = (): Record<
  string,
  AIRequestBatchItem[]
> => {
  const storage = getBatchStorage();
  return storage.batches;
};

/**
 * Clear all stored batches
 */
export const clearAllAIRequestBatches = (): void => {
  saveBatchStorage({
    batches: {},
  });
};
