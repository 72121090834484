/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OverviewWakeUpDatapoint,
    OverviewWakeUpDatapointFromJSON,
    OverviewWakeUpDatapointFromJSONTyped,
    OverviewWakeUpDatapointToJSON,
} from './OverviewWakeUpDatapoint';

/**
 * 
 * @export
 * @interface OverviewWakeUpTable
 */
export interface OverviewWakeUpTable {
    /**
     * 
     * @type {Array<OverviewWakeUpDatapoint>}
     * @memberof OverviewWakeUpTable
     */
    datapoints: Array<OverviewWakeUpDatapoint>;
}

export function OverviewWakeUpTableFromJSON(json: any): OverviewWakeUpTable {
    return OverviewWakeUpTableFromJSONTyped(json, false);
}

export function OverviewWakeUpTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewWakeUpTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datapoints': ((json['datapoints'] as Array<any>).map(OverviewWakeUpDatapointFromJSON)),
    };
}

export function OverviewWakeUpTableToJSON(value?: OverviewWakeUpTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datapoints': ((value.datapoints as Array<any>).map(OverviewWakeUpDatapointToJSON)),
    };
}

