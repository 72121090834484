import {
  IncidentPostmortemStatusEnum,
  PostIncidentTaskOptionTaskTypeEnum,
} from "@incident-io/api";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import { usePostmortemName } from "src/utils/postmortem-name";

import { usePostIncidentModalContext } from "../modals/PostIncidentActionModals";
import { usePostmortem } from "../usePostmortem";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps, PostIncidentActionModal } from "./types";

export const SharePostMortemItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { postmortemName, postmortemNameFormatted } = usePostmortemName(
    props.incident,
  );
  const { setShowingModal } = usePostIncidentModalContext();
  const { availableDocument: externalDocument } = usePostmortem(props.incident);

  // Check if postmortem exists (either external or in-house)
  // if external, we'll have a document, otherwise we check that there's a postmortem_template_id
  const hasPostmortem =
    externalDocument || props.incident.postmortem_template_id;
  const isComplete =
    props.incident.postmortem_status === IncidentPostmortemStatusEnum.Complete;

  // Determine disabled state and tooltip message
  const disabled = !hasPostmortem || (!externalDocument && !isComplete);
  const disabledExplanation = !hasPostmortem
    ? `${postmortemName} has not been created yet`
    : `${postmortemName} has not been completed yet`;

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.SharePostMortem,
          props,
        })
      }
      analyticsTrackingId="post-incident-task-share-postmortem"
      disabled={disabled}
      disabledExplanation={disabledExplanation}
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.SharePostmortem)}
      label={`Share ${postmortemNameFormatted}`}
    />
  );
};
