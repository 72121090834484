import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useLocation } from "react-router";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";

import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps } from "./types";

export const ReviewTimelineItem = ({
  incidentTask,
  mode,
}: ItemProps): React.ReactElement | null => {
  const { pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() => {
          // If we're on the incident details page, just scroll
          if (pathname.includes("/incidents/")) {
            const timeline = document.getElementById("timeline");
            timeline && timeline.scrollIntoView({ behavior: "smooth" });
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(
              `/incidents/${incidentTask.incident_id}?tab=post-incident#timeline`,
            );
          }
        }}
        analyticsTrackingId="post-incident-task-review-timeline"
        icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.ReviewTimeline)}
        label="View timeline"
      />
    </>
  );
};
