/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FollowUpsConnectExternalIssueRequestBody
 */
export interface FollowUpsConnectExternalIssueRequestBody {
    /**
     * The issue tracker provider
     * @type {string}
     * @memberof FollowUpsConnectExternalIssueRequestBody
     */
    provider: FollowUpsConnectExternalIssueRequestBodyProviderEnum;
    /**
     * URL of the issue in the external provider
     * @type {string}
     * @memberof FollowUpsConnectExternalIssueRequestBody
     */
    url: string;
}

/**
* @export
* @enum {string}
*/
export enum FollowUpsConnectExternalIssueRequestBodyProviderEnum {
    Asana = 'asana',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function FollowUpsConnectExternalIssueRequestBodyFromJSON(json: any): FollowUpsConnectExternalIssueRequestBody {
    return FollowUpsConnectExternalIssueRequestBodyFromJSONTyped(json, false);
}

export function FollowUpsConnectExternalIssueRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpsConnectExternalIssueRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': json['provider'],
        'url': json['url'],
    };
}

export function FollowUpsConnectExternalIssueRequestBodyToJSON(value?: FollowUpsConnectExternalIssueRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'url': value.url,
    };
}

