/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EscalatedTimelineUserNotificationUpdate,
    EscalatedTimelineUserNotificationUpdateFromJSON,
    EscalatedTimelineUserNotificationUpdateFromJSONTyped,
    EscalatedTimelineUserNotificationUpdateToJSON,
} from './EscalatedTimelineUserNotificationUpdate';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface EscalatedTimelineUserNotification
 */
export interface EscalatedTimelineUserNotification {
    /**
     * Whether the escalation was made during a live call
     * @type {boolean}
     * @memberof EscalatedTimelineUserNotification
     */
    is_live_call: boolean;
    /**
     * 
     * @type {Array<EscalatedTimelineUserNotificationUpdate>}
     * @memberof EscalatedTimelineUserNotification
     */
    method_updates: Array<EscalatedTimelineUserNotificationUpdate>;
    /**
     * 
     * @type {User}
     * @memberof EscalatedTimelineUserNotification
     */
    user: User;
}

export function EscalatedTimelineUserNotificationFromJSON(json: any): EscalatedTimelineUserNotification {
    return EscalatedTimelineUserNotificationFromJSONTyped(json, false);
}

export function EscalatedTimelineUserNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalatedTimelineUserNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'is_live_call': json['is_live_call'],
        'method_updates': ((json['method_updates'] as Array<any>).map(EscalatedTimelineUserNotificationUpdateFromJSON)),
        'user': UserFromJSON(json['user']),
    };
}

export function EscalatedTimelineUserNotificationToJSON(value?: EscalatedTimelineUserNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_live_call': value.is_live_call,
        'method_updates': ((value.method_updates as Array<any>).map(EscalatedTimelineUserNotificationUpdateToJSON)),
        'user': UserToJSON(value.user),
    };
}

