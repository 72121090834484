import { Input } from "@incident-ui";
import { InputProps, InputType } from "@incident-ui/Input/Input";
import { ChangeEvent } from "react";
import { FieldValues, Path, useController } from "react-hook-form";

import { InputElementProps, parseProps } from "../formsv2";
import { FormInputWrapper } from "../helpers";

export const NumberInputV2 = <TFormType extends FieldValues>(
  props: InputElementProps<TFormType, Omit<InputProps, "type">> & {
    onBlurCallback?: () => void;
    onValueChange?: (val: number) => void;
    highlightErrors?: boolean;
  },
): React.ReactElement => {
  const { highlightErrors, onBlurCallback, onValueChange, ...rest } = props;
  const { name, rules, inputProps, wrapperProps } = parseProps(rest);
  const { field, fieldState } = useController({
    name,
    rules,
  });

  return (
    <FormInputWrapper<TFormType>
      {...wrapperProps}
      name={name as unknown as Path<TFormType>}
    >
      <Input
        id={name}
        {...field}
        {...inputProps}
        type={InputType.Number}
        invalid={highlightErrors && fieldState.error !== undefined}
        onBlur={() => {
          onBlurCallback && onBlurCallback();

          return field.onBlur();
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          let floatVal = parseFloat(e.target.value);
          if (isNaN(floatVal)) {
            floatVal = 0;
          }

          field.onChange(floatVal);

          onValueChange && onValueChange(floatVal);
        }}
        value={field.value?.toString() ?? ""}
      />
    </FormInputWrapper>
  );
};
