import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import { usePostmortemName } from "src/utils/postmortem-name";

import { usePostmortem } from "../usePostmortem";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps } from "./types";

export const DraftPostMortemItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(props.incident);

  const { availableDocument: document } = usePostmortem(props.incident);

  const navigate = useOrgAwareNavigate();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={async () => {
        // Redirect to viewing the postmortem.
        if (document) {
          window.open(document.permalink, "_blank");
        } else {
          navigate(`/incidents/${incidentTask.incident_id}?tab=post-incident`);
        }
      }}
      analyticsTrackingId="post-incident-task-in-review-postmortem"
      icon={TaskTypeToIcon(
        PostIncidentTaskOptionTaskTypeEnum.InReviewPostmortem,
      )}
      label={`View ${postmortemNameFormatted}`}
    />
  );
};
