import { Icon, IconEnum, IconSize } from "@incident-ui";

export const BillingSettingValue = ({
  value,
  gateType,
}: {
  value: boolean | number | undefined;
  gateType: "bool" | "number";
}) => {
  const presentedValue =
    gateType === "bool"
      ? value
        ? "Enabled"
        : "Disabled"
      : value ?? "Unlimited";

  return <span>{presentedValue}</span>;
};

export const BillingSettingValueComparison = ({
  value,
  planValue,
  gateType,
}: {
  value: boolean | number | undefined;
  planValue: boolean | number | undefined;
  gateType: "bool" | "number";
}) => {
  if (value === planValue) {
    return (
      <div className="flex items-center gap-1 text-content-tertiary">
        <Icon size={IconSize.Medium} id={IconEnum.Tick} />
        <span>Matches plan</span>
      </div>
    );
  }

  return <BillingSettingValue value={planValue} gateType={gateType} />;
};
