import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  Icon,
  IconEnum,
  Loader,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import { Popover } from "@incident-ui/Popover/Popover";
import { SelectOption } from "@incident-ui/Select/types";
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
} from "@incident-ui/Table/Table";
import { useState } from "react";
import {
  FieldValues,
  FormProvider,
  Path,
  useController,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  FeatureGates,
  StaffSetFeatureGatesRequestBody,
  StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum,
} from "src/contexts/ClientContext";
import {
  StaffPermissionEnum,
  useCheckStaffPermissions,
} from "src/hooks/useCheckStaffPermissions";
import { useAPI, useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { StaffRoomOperationProps } from "../Operation";
import { BillingSettingValueComparison } from "./UpdateFeatureGatesBillingSettingValue";

export const UpdateFeatureGatesForm = (props: StaffRoomOperationProps) => {
  return (
    <FeatureGatesForm
      organisationSlug={props.organisation.organisation_slug}
      availableProducts={props.organisation.organisation_available_products}
      gates={props.organisation.feature_gates}
      onClose={() => props.onClose()}
    />
  );
};

const toRequest = (formData: FormType): StaffSetFeatureGatesRequestBody => {
  const res: StaffSetFeatureGatesRequestBody = { ...formData };

  for (const key in res) {
    if (key === "search") {
      delete res[key];
      continue;
    }

    const gateType = FeatureGateConfig[key as FeatureGateEnum].type;

    switch (gateType) {
      case "bool":
        res[key] = !!res[key];
        break;

      case "number":
        res[key] = res[key] === "" ? undefined : parseInt(res[key]);
        break;

      default:
        assertUnreachable(gateType);
    }
  }

  return res;
};

type FormType = FeatureGates;

const FeatureGatesForm = ({
  organisationSlug,
  availableProducts,
  gates,
  onClose,
}: {
  organisationSlug: string;
  availableProducts: StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum[];
  gates: FeatureGates;
  onClose: () => void;
}) => {
  const formMethods = useForm<FormType>({
    defaultValues: { ...gates },
  });

  const disabledProps = useCheckStaffPermissions(
    StaffPermissionEnum.ManageFeatureAccess,
    !formMethods.formState.isDirty,
  );

  const { trigger, isMutating, genericError } = useAPIMutation(
    "staffShowOrganisation",
    { organisationSlug },
    async (apiClient, data: FormType) => {
      await apiClient.staffSetFeatureGates({
        organisationSlug,
        setFeatureGatesRequestBody: toRequest(data),
      });
    },
    {
      onSuccess: onClose,
      showErrorToast: "Could not update gates",
    },
  );

  const { data, isLoading } = useAPI("staffGetFeatureGates", {
    organisationSlug,
  });

  if (!data || isLoading) {
    return <Loader />;
  }

  // Sort the feature gates by their name in FeatureGateConfig
  const featureGates = Object.entries(data.feature_gates).sort(
    ([keyA], [keyB]) =>
      FeatureGateConfig[keyA].name.localeCompare(FeatureGateConfig[keyB].name),
  );

  return (
    <Form.Root
      formMethods={formMethods}
      onSubmit={trigger}
      saving={isMutating}
      genericError={genericError}
    >
      <div className="flex flex-col gap-y-4">
        {data.gates_match_plan ? (
          <Callout theme={CalloutTheme.Success}>
            This organisation&apos;s feature gates match their{" "}
            <b className="font-semibold">{data.plan_name}</b> plan.
          </Callout>
        ) : (
          <Callout theme={CalloutTheme.Warning}>
            This organisation&apos;s feature gates{" "}
            <b className="font-semibold">have been changed</b>, and don&apos;t
            match their <b className="font-semibold">{data.plan_name}</b> plan.
          </Callout>
        )}

        <Table
          gridTemplateColumns="repeat(3, 1fr)"
          header={
            <>
              <TableHeaderCell title="Name" />
              <TableHeaderCell title="Value" />
              <TableHeaderCell title="Plan value" />
            </>
          }
          data={featureGates}
          renderRow={(entry, index) => {
            const featureName = entry[0] as FeatureGateEnum;
            const billingSettingValue = entry[1];
            const planValue = data.plan_feature_gates[featureName];
            const config = FeatureGateConfig[featureName];

            return (
              <TableRow
                key={featureName}
                isLastRow={index === featureGates.length}
              >
                <TableCell key={`${featureName}-name`}>{config.name}</TableCell>
                <TableCell key={`${featureName}-value`}>
                  <FeatureGateInput
                    key={featureName}
                    formMethods={formMethods}
                    gate={featureName}
                    availableProducts={availableProducts}
                    name={featureName}
                    isActive={true}
                  />
                </TableCell>
                <TableCell key={`${index}-value`}>
                  <BillingSettingValueComparison
                    value={billingSettingValue}
                    planValue={planValue}
                    gateType={config.type}
                  />
                </TableCell>
              </TableRow>
            );
          }}
        />
        <GatedButton
          type="submit"
          analyticsTrackingId={null}
          {...disabledProps}
          theme={ButtonTheme.Primary}
        >
          Save
        </GatedButton>
      </div>
    </Form.Root>
  );
};

export const FeatureGateInput = <TFormType extends FieldValues>({
  formMethods,
  name,
  availableProducts,
  gate,
  isActive,
}: {
  formMethods: UseFormReturn<TFormType>;
  name: Path<TFormType>;
  availableProducts: StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum[];
  gate: FeatureGateEnum;
  isActive: boolean;
}) => {
  const config = FeatureGateConfig[gate];
  const inputType = config.type;

  const value: boolean | number | undefined = formMethods.watch(name);
  const presentedValue =
    inputType === "bool"
      ? value
        ? "Enabled"
        : "Disabled"
      : value ?? "Unlimited";
  const [state, setState] = useState<"open" | "custom" | "closed">("closed");

  const { field } = useController({ name });
  const parseValue = buildParseValue(inputType);

  const handleSelect = (newValue: string) => {
    field.onChange(parseValue(newValue));
    setState("closed");
  };

  let disabled = false;
  if (config.requires_on_call_product) {
    const hasOnCall = availableProducts.includes(
      StaffShowOrganisationResponseBodyOrganisationAvailableProductsEnum.OnCall,
    );
    disabled = !hasOnCall;
  }
  if (!isActive) return null;

  return (
    <Form.InputWrapper name={name} labelWrapperClassName="mb-0">
      <Popover
        onInteractOutside={() => setState("closed")}
        trigger={
          <GatedButton
            size={BadgeSize.Medium}
            analyticsTrackingId={null}
            onClick={() => setState("open")}
            className={
              "border !border-stroke !bg-surface-secondary !text-content-primary"
            }
            disabled={disabled}
            disabledTooltipContent={
              "Use 'Enable On-call' to enable this feature"
            }
          >
            <div className="flex items-center gap-1">
              <div>{presentedValue}</div>
              <Icon id={IconEnum.Expand} className="text-slate-400" />
            </div>
          </GatedButton>
        }
        className="max-h-[400px] min-h-[50px] !p-0 w-[350px]"
        sideOffset={-38}
        align="start"
        onOpenChange={(open) => {
          if (!open) {
            setState("closed");
          }
        }}
        open={state !== "closed"}
      >
        <menu>
          {state === "custom" ? (
            <CustomInput
              initialValue={value?.toString() ?? ""}
              handleSelect={handleSelect}
            />
          ) : (
            <DropdownOptions
              formMethods={formMethods}
              handleSelect={handleSelect}
              onCustom={() => setState("custom")}
              name={name}
              gate={gate}
            />
          )}
        </menu>
      </Popover>
    </Form.InputWrapper>
  );
};

const DropdownOptions = <TFormType extends FieldValues>({
  handleSelect,
  onCustom,
  name,
  gate,
  formMethods,
}: {
  handleSelect: (value: string) => void;
  onCustom: () => void;
  name: Path<TFormType>;
  gate: FeatureGateEnum;
  formMethods: UseFormReturn<TFormType>;
}) => {
  const value = formMethods.watch(name);
  const config = FeatureGateConfig[gate];
  const parseValue = buildParseValue(config.type);
  const options =
    config.type === "bool"
      ? boolOptions
      : numberOptions(config.defaults, value);

  return (
    <ul className="py-2">
      {options.map((option) => (
        <li
          key={option.value}
          onClick={() => handleSelect(option.value)}
          className={tcx(
            "flex-center-y text-sm text-content-primary font-normal rounded border",
            parseValue(option.value) === parseValue(value)
              ? "border-stroke bg-slate-50 border-dashed hover:border-solid"
              : "border-transparent",
            "hover:border-stroke hover:bg-surface-secondary hover:cursor-pointer p-1 mx-2",
          )}
        >
          {option.label}
        </li>
      ))}

      {config.type === "number" && (
        <li
          onClick={onCustom}
          className="flex-center-y text-sm text-content-primary font-normal rounded border border-transparent hover:border-stroke hover:bg-surface-secondary hover:cursor-pointer p-1 mx-2"
        >
          Custom value ...
        </li>
      )}
    </ul>
  );
};

const CustomInput = ({
  initialValue,
  handleSelect,
}: {
  initialValue: string;
  handleSelect: (value: string) => void;
}) => {
  const formMethods = useForm<{ value: string }>({
    defaultValues: { value: initialValue },
  });

  return (
    <form
      onSubmit={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        const { value } = formMethods.getValues();
        handleSelect(value);
      }}
      className="p-2"
    >
      <FormProvider<{ value: string }> {...formMethods}>
        <InputV2
          formMethods={formMethods}
          type={InputType.Number}
          name="value"
          inputClassName="!py-0.5"
          insetSuffixNode={
            <Button
              type="submit"
              analyticsTrackingId={null}
              title="Confirm"
              icon={IconEnum.Checkmark}
              theme={ButtonTheme.Naked}
            />
          }
        />
      </FormProvider>
    </form>
  );
};
// buildParseValue normalises all the garbage we put into the form state into the correct API type.
// Inputs in HTML only have string values, so our form state _starts_ off as API
// types (bools, numbers), and then gets full of strings over time.
//
// This handles any kind of input and returns the right API type for the gate type.
const buildParseValue =
  (inputType: "bool" | "number") =>
  (value: string | number | boolean | null | undefined) => {
    if (inputType === "bool") {
      return value === "true" || value === true;
    } else {
      if (typeof value === "boolean") {
        throw new Error(`Number gate had value ${value} - a bool!`);
      }

      return value === "" || value === undefined || value == null
        ? (null as unknown as undefined) // this is a hack to force react-hook-form to do what we say
        : typeof value === "number"
        ? value
        : parseInt(value);
    }
  };
const boolOptions: SelectOption[] = [
  { label: "Enabled", value: "true" },
  { label: "Disabled", value: "false" },
];

const numberOptions = (
  defaults: SelectOption[] | undefined,
  currentValueForm: number | boolean | undefined,
) => {
  const currentValue = currentValueForm?.toString() ?? "";
  const res: SelectOption[] = defaults ?? [
    { label: "None", value: "0" },
    { label: "1", value: "1" },
    { label: "Unlimited", value: "" },
  ];

  if (!res.some((opt) => opt.value === (currentValue?.toString() ?? ""))) {
    res.push({
      value: currentValue?.toString() ?? "",
      label: currentValue?.toString() ?? "Unlimited",
    });
  }

  return res;
};

type FeatureGateEnum = keyof Required<FeatureGates>;
type GateType<T extends FeatureGateEnum> =
  Required<FeatureGates>[T] extends boolean ? "bool" : "number";

export const FeatureGateConfig: {
  [key in FeatureGateEnum]: {
    category: string;
    name: string;
    type: GateType<key>;
    search?: string;
    defaults?: GateType<key> extends "number" ? SelectOption[] : undefined;
    requires_on_call_product?: boolean;
  };
} = {
  advanced_insights: {
    category: "Insights",
    name: "Advanced Insights",
    type: "bool",
  },
  ai_assistant: { category: "AI", name: "AI: Assistant", type: "bool" },
  ai_features: { category: "AI", name: "AI: Features", type: "bool" },
  ai_alert_config: { category: "AI", name: "AI: Alert config", type: "bool" },
  suggested_follow_ups: {
    category: "AI",
    name: "AI: Suggested follow-ups",
    type: "bool",
  },
  suggested_summaries: {
    category: "AI",
    name: "AI: Suggested summaries",
    type: "bool",
  },
  related_incidents: {
    category: "AI",
    name: "AI: Related incidents",
    type: "bool",
  },
  api_access: { category: "API", name: "API", type: "bool" },
  atlassian_statuspage_pages_count: {
    category: "Status pages",
    name: "Atlassian Statuspage pages",
    type: "number",
  },
  audit_logs: {
    category: "Security & controls",
    name: "Audit logs",
    type: "bool",
  },
  custom_catalog_types_count: {
    category: "Catalog",
    name: "Catalog: custom types",
    type: "number",
    defaults: [
      { label: "None", value: "0" },
      { label: "2", value: "2" },
      { label: "Unlimited", value: "" },
    ],
  },
  custom_dashboards_count: {
    category: "Insights",
    name: "Insights custom dashboards",
    type: "number",
    defaults: [
      { label: "None", value: "0" },
      { label: "5", value: "5" },
      { label: "Unlimited", value: "" },
    ],
  },
  custom_field_count: {
    category: "Core",
    name: "Custom fields",
    type: "number",
  },
  custom_field_conditions: {
    category: "Core",
    name: "Custom field conditions",
    type: "bool",
  },
  custom_nudges: { category: "Core", name: "Custom nudges", type: "bool" },
  debrief_placeholders: {
    category: "Post-incident",
    name: "Debrief placeholders",
    type: "bool",
    search: "calendar meeting schedule",
  },
  enabled_atlassian_sites_count: {
    category: "Jira",
    name: "Multiple Jira sites",
    type: "number",
    search: "instances",
  },
  follow_up_issue_templates_count: {
    category: "Follow-ups",
    name: "Follow-up auto-export templates",
    type: "number",
  },
  follow_up_priorities: {
    category: "Follow-ups",
    name: "Follow-up priorities",
    type: "bool",
  },
  follow_up_priorities_customisable: {
    category: "Follow-ups",
    name: "Follow-up priorities: customisable",
    type: "bool",
  },
  incident_lifeycle_count: {
    category: "Core",
    name: "Incident lifecycles",
    type: "number",
    search: "statuses timestamps types",
  },
  incident_ticket_issue_templates_count: {
    category: "Jira",
    name: "Incident ticket: templates",
    type: "number",
  },
  incident_types: {
    category: "Core",
    name: "Incident Types",
    type: "bool",
  },
  integrations_count: {
    category: "Core",
    name: "Integrations",
    type: "number",
  },
  on_call_calculator: {
    category: "On-call",
    name: "On-call calculator",
    type: "bool",
    search: "pay",
  },
  on_call_responders_count: {
    category: "On-call",
    name: "On-call responders",
    type: "number",
    defaults: [
      { label: "None", value: "0" },
      { label: "5", value: "5" },
      { label: "Unlimited", value: "" },
    ],
    requires_on_call_product: true,
  },
  on_call_schedules_count: {
    category: "On-call",
    name: "On-call schedules",
    type: "number",
    search: "rota shift",
    requires_on_call_product: true,
  },
  call_routes_count: {
    category: "On-call",
    name: "Call routes",
    type: "number",
    search: "phone live routing",
    requires_on_call_product: true,
    defaults: [
      { label: "None", value: "0" },
      { label: "1", value: "1" },
      { label: "5", value: "5" },
    ],
  },
  policies_count: {
    category: "Post-incident",
    name: "Policies",
    type: "number",
    search: "policy",
  },
  post_incident_flow_custom_tasks: {
    category: "Post-incident",
    name: "Post-incident tasks: custom tasks",
    type: "bool",
    search: "flow checklist",
  },
  post_incident_task_auto_assign: {
    category: "Post-incident",
    name: "Post-incident tasks: auto-assign",
    type: "bool",
    search: "flow checklist",
  },
  post_incident_task_auto_remind: {
    category: "Post-incident",
    name: "Post-incident tasks: auto-remind",
    type: "bool",
    search: "flow checklist",
  },
  postmortem_templates_count: {
    category: "Post-incident",
    name: "Postmortem templates",
    type: "number",
    search: "debrief retrospective",
  },
  private_incidents: {
    category: "Core",
    name: "Private incidents",
    type: "bool",
  },
  rbac_base_roles_customisable: {
    category: "Security & controls",
    name: "RBAC: base roles customisable",
    type: "bool",
  },
  rbac_custom_roles: {
    category: "Security & controls",
    name: "RBAC: custom roles",
    type: "bool",
  },
  saml: {
    category: "Security & controls",
    name: "SAML",
    type: "bool",
    search: "sso single sign on okta onelogin",
  },
  saved_views_per_context_count: {
    category: "Core",
    name: "Saved views",
    type: "number",
  },
  scim: {
    category: "Security & controls",
    name: "SCIM",
    type: "bool",
    search: "okta onelogin",
  },
  slack_enterprise_grid_install: {
    category: "Security & controls",
    name: "Slack Enterprise Grid install",
    type: "bool",
  },
  status_pages_count: {
    category: "Status pages",
    name: "Status pages",
    type: "number",
  },
  status_pages_customer_pages: {
    category: "Status pages",
    name: "Status pages: customer pages",
    type: "bool",
  },
  status_pages_sub_pages: {
    category: "Status pages",
    name: "Status pages: sub-pages",
    type: "bool",
  },
  internal_status_pages_count: {
    category: "Status pages",
    name: "Internal status pages",
    type: "number",
  },
  streams_per_incident_count: {
    category: "Core",
    name: "Streams per incident",
    type: "number",
    search: "swimlane parent child",
  },
  suggestions: {
    category: "AI",
    name: "AI: suggestions (in Beta)",
    type: "bool",
  },
  call_transcription_hours_per_month: {
    category: "Core",
    name: "Call transcription hours per month",
    type: "number",
    search: "transcribe transcript call",
  },
  triage_incidents: {
    category: "Core",
    name: "Triage incidents",
    type: "bool",
    search: "status",
  },
  use_telecom: {
    category: "Automation",
    name: "SMS, Phone and Email workflows",
    type: "bool",
  },
  webhooks: { category: "API", name: "Webhooks", type: "bool" },
  workflows_count: {
    category: "Automation",
    name: "Workflows",
    type: "number",
  },
};
