import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  CheckboxGroup,
  EmptyState,
  IconEnum,
  LoadingWrapper,
  Modal,
  ModalContent,
  ModalFooter,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { useEffect, useMemo, useState } from "react";
import { useAPI } from "src/utils/swr";

import { CodeViewer } from "./CodeViewer";
import {
  AIRequestBatchItem,
  clearAIRequestBatch,
  getAIRequestBatch,
  getAllEvalOverrides,
  removeRequestFromBatch,
} from "./evals";
import { snakeCasePreserveULID } from "./utils";

export const ShowAIRequestBatchModal = ({
  onClose,
  promptName,
  inputKeys,
  defaultInputKeys,
}: {
  onClose: () => void;
  promptName: string;
  defaultInputKeys: string[];
  inputKeys: {
    label: string;
    value: string;
    len: number;
  }[];
}) => {
  const [requests, setRequests] = useState<AIRequestBatchItem[]>([]);
  const [fixtureKeys, setFixtureKeys] = useState<string[]>(defaultInputKeys);

  useEffect(() => {
    const batchRequests = getAIRequestBatch(promptName);
    setRequests(batchRequests);
  }, [promptName]);

  const handleRemoveRequest = (requestId: string) => {
    removeRequestFromBatch(promptName, requestId);
    setRequests((prev) => prev.filter((item) => item.requestId !== requestId));
  };

  const allOverrides = getAllEvalOverrides();
  const overrides = Object.keys(allOverrides);

  const { data, isLoading } = useAPI("aIStaffGetTokenForAIRequests", {
    ids: requests.map((item) => item.requestId),
  });

  const urls = useMemo(() => {
    return requests.map((batchItem) => {
      const url = new URL(
        `/api/ai_staff/ai_requests/${batchItem.requestId}/eval`,
        window.location.origin,
      );
      const override = allOverrides[batchItem.requestId];
      if (override && override.id) {
        url.searchParams.append("result_override_id", override.id);
      }

      url.searchParams.append("case_name", batchItem.caseName);

      const caseNameForFilepath = snakeCasePreserveULID(batchItem.caseName);

      url.searchParams.append("fixture_path", `${caseNameForFilepath}.yaml`);

      fixtureKeys.forEach((key) => {
        url.searchParams.append("fixture_keys", key);
      });

      return url;
    });
  }, [requests, fixtureKeys, allOverrides]);

  const command = useMemo(() => {
    let cmd = `go run cmd/copilot/main.go download  --token '${data?.jwt}'`;
    urls.forEach((url) => {
      cmd = cmd + ` --url '${url}'`;
    });
    return cmd;
  }, [urls, data?.jwt]);

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={`Batch: ${promptName}`}
      analyticsTrackingId={null}
    >
      <ModalContent>
        <div className="flex flex-col gap-4 text-sm">
          {requests.length === 0 ? (
            <EmptyState content="No requests in this batch" />
          ) : (
            <StackedList>
              {requests.map((item) => {
                const hasOverride = overrides.includes(item.requestId);
                return (
                  <StackedListItem
                    key={item.requestId}
                    title={item.caseName}
                    badgeNode={
                      hasOverride && (
                        <Badge
                          size={BadgeSize.ExtraSmall}
                          theme={BadgeTheme.Info}
                        >
                          Edited
                        </Badge>
                      )
                    }
                    accessory={
                      <Button
                        size={BadgeSize.ExtraSmall}
                        onClick={() => handleRemoveRequest(item.requestId)}
                        icon={IconEnum.Close}
                        analyticsTrackingId={null}
                        title="Remove from batch"
                      />
                    }
                  />
                );
              })}
            </StackedList>
          )}
          <div className="flex flex-col gap-2">
            <div className="text-sm-bold">
              Which params should be extracted to a fixture?
            </div>
            <CheckboxGroup
              showSelectAll
              options={inputKeys}
              value={fixtureKeys}
              onChange={(vals) => setFixtureKeys(vals)}
            />
          </div>

          <LoadingWrapper loading={isLoading}>
            <CodeViewer
              mode="text"
              title="Download eval locally"
              content={command}
            />
          </LoadingWrapper>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className="flex w-full items-center justify-between">
          <Button
            analyticsTrackingId={null}
            theme={ButtonTheme.DestroySecondary}
            onClick={() => {
              clearAIRequestBatch(promptName);
              onClose();
            }}
          >
            Clear batch
          </Button>
          <Button onClick={onClose} analyticsTrackingId={null}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
