import { ScopeNameEnum, UserOption } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useUniqueColorGenerator } from "@incident-shared/utils/uniqueColorContext";
import {
  Avatar,
  Callout,
  CalloutTheme,
  IconSize,
  LoadingModal,
  Modal,
  ModalContent,
  ModalFooter,
  Txt,
} from "@incident-ui";
import { chain } from "lodash";
import pluralize from "pluralize";
import { FieldValues } from "react-hook-form";
import { EscalationPathUserTargetFormData } from "src/components/escalation-paths/common/types";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  isOnCallSeatCount,
  useCanPromoteToOnCall,
} from "src/hooks/useCanPromoteToOnCall";
import { tcx } from "src/utils/tailwind-classes";

import { useAPI } from "../../../../utils/swr";

type PromotionStateOpen<FormType extends FieldValues> = {
  formData: FormType;
  usersToPromote: EscalationPathUserTargetFormData[];
  requiresSeatCountIncrease?: boolean;
};

export type PromotionState<FormType extends FieldValues> =
  PromotionStateOpen<FormType> | null;

export const OnCallPromotionConfirmationModal = <FormType extends FieldValues>({
  state,
  onClose,
  onSubmit,
  noun,
}: {
  state: PromotionState<FormType>;
  onClose: () => void;
  onSubmit: (formData: FormType, userIdsToPromote: string[]) => void;
  noun: string;
}) => {
  if (state == null) return null;

  return (
    <OnCallPromotionConfirmationInner {...{ state, onClose, onSubmit, noun }} />
  );
};

const OnCallPromotionConfirmationInner = <FormType extends FieldValues>({
  state,
  onClose,
  onSubmit,
  noun,
}: {
  state: PromotionStateOpen<FormType>;
  onClose: () => void;
  onSubmit: (formData: FormType, userIdsToPromote: string[]) => void;
  noun: string;
}) => {
  const orgAwareNavigate = useOrgAwareNavigate();
  const { identity, hasScope } = useIdentity();
  const { formData, usersToPromote: usersToPromoteRaw } = state;
  const usersToPromote = chain(usersToPromoteRaw)
    .uniqBy((user) => user.value)
    .sortBy("label")
    .value();

  const { upgradeRequired, upgradeRequiredProps, isLoading } =
    useCanPromoteToOnCall(usersToPromote.length);

  if (!identity || isLoading) {
    return <LoadingModal onClose={onClose} />;
  }

  const onConfirm = () => {
    onClose();
    onSubmit(
      formData,
      usersToPromote.map((user) => user.value),
    );
  };

  const onUpgrade = () => {
    orgAwareNavigate("/settings/billing");
  };

  const canUpgrade = hasScope(ScopeNameEnum.BillingSettingsEdit);

  return (
    <Modal
      analyticsTrackingId={"confirm-oncall-promotions"}
      isOpen
      onClose={onClose}
      title={"Add On-call users"}
    >
      <ModalContent className="text-sm space-y-4">
        <UpgradeUsersSection noun={noun} users={usersToPromote} />
        <SeatCountIncreaseSection
          seatCountIncreaseRequired={state.requiresSeatCountIncrease}
        />
        {!canUpgrade ? (
          <Callout theme={CalloutTheme.Info}>
            These changes may affect your billing. Only users with the
            &quot;Manage billing settings&quot; permission can do this.
          </Callout>
        ) : null}
      </ModalContent>
      {state.requiresSeatCountIncrease ? (
        <ModalFooter
          onClose={onClose}
          confirmButtonType="button"
          onConfirm={onUpgrade}
          confirmButtonText="Upgrade plan"
          requiredScope={ScopeNameEnum.BillingSettingsEdit}
        />
      ) : (
        <ModalFooter
          onClose={onClose}
          confirmButtonType="button"
          onConfirm={onConfirm}
          confirmButtonText="Confirm"
          requiredScope={ScopeNameEnum.BillingSettingsEdit}
          upgradeRequired={upgradeRequired}
          upgradeRequiredProps={upgradeRequiredProps}
        />
      )}
    </Modal>
  );
};

const UsersToPromote = ({
  users,
}: {
  users: EscalationPathUserTargetFormData[];
}) => (
  <div className="flex flex-wrap gap-2">
    {users.map((user) => (
      <UserToPromote key={user.value} user={user} />
    ))}
  </div>
);

const displayNumber = (num: number, noun: string, pluralNoun?: string) => {
  if (num === 1) {
    return `One ${noun}`;
  }
  return `${num} ${pluralNoun ?? pluralize(noun)}`;
};

const UpgradeUsersSection = ({
  noun,
  users,
}: {
  noun: string;
  users: EscalationPathUserTargetFormData[];
}) => {
  const total = users.length;
  const single = total === 1;

  return (
    <>
      <p>
        <Txt inline bold>
          {displayNumber(total, "person", "people")}
        </Txt>{" "}
        on this {noun} {single ? "doesn't" : "don't"} have On-call enabled. We
        will enable this for them as part of adding them to this {noun}.
      </p>
      <UsersToPromote users={users} />
    </>
  );
};

const UserToPromote = ({ user }: { user: Omit<UserOption, "sort_key"> }) => {
  const name = user.label.replace(" (me)", "");
  const colorGenerator = useUniqueColorGenerator();
  const { border, background, preview } = colorGenerator(user.value);

  return (
    <div
      className={tcx(
        "flex items-center h-[42px] rounded-[6px] p-2 relative",
        border,
        background,
      )}
    >
      <div className={tcx(preview, "h-full w-1 mr-2 rounded-full")} />
      <Avatar
        size={IconSize.Large}
        className={"w-7 h-7 mr-1.5"}
        url={user.image_url}
        name={name}
      />
      {name}
    </div>
  );
};

const SeatCountIncreaseSection = ({
  seatCountIncreaseRequired,
}: {
  seatCountIncreaseRequired: boolean | undefined;
}) => {
  // Billing information
  const {
    data: { seat_counts: seatCounts },
  } = useAPI("billingListSeatCounts", undefined, {
    fallbackData: { seat_counts: [] },
  });

  const onCallSeatCountLimit = seatCounts?.find(isOnCallSeatCount)?.limit ?? 0;

  if (!seatCountIncreaseRequired) {
    return null;
  }

  return (
    <Callout theme={CalloutTheme.Warning}>
      <Txt>
        <span>
          You have reached your maximum count of {onCallSeatCountLimit} On-call
          users. You will need to upgrade your plan to add more.
        </span>
      </Txt>
    </Callout>
  );
};
