/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CatalogTypeSchema,
    CatalogTypeSchemaFromJSON,
    CatalogTypeSchemaFromJSONTyped,
    CatalogTypeSchemaToJSON,
} from './CatalogTypeSchema';

/**
 * 
 * @export
 * @interface CatalogType
 */
export interface CatalogType {
    /**
     * Annotations that can track metadata about this type
     * @type {{ [key: string]: string; }}
     * @memberof CatalogType
     */
    annotations: { [key: string]: string; };
    /**
     * What categories is this type considered part of
     * @type {Array<string>}
     * @memberof CatalogType
     */
    categories: Array<CatalogTypeCategoriesEnum>;
    /**
     * Sets the display color of this type in the dashboard
     * @type {string}
     * @memberof CatalogType
     */
    color: CatalogTypeColorEnum;
    /**
     * When this type was created
     * @type {Date}
     * @memberof CatalogType
     */
    created_at: Date;
    /**
     * Human readble description of this type
     * @type {string}
     * @memberof CatalogType
     */
    description: string;
    /**
     * If this is a dynamic catalog type, this will be the unique parameter for identitfying this resource externally.
     * @type {string}
     * @memberof CatalogType
     */
    dynamic_resource_parameter?: string;
    /**
     * The way this resource type is referenced in the engine
     * @type {string}
     * @memberof CatalogType
     */
    engine_resource_type: string;
    /**
     * If populated, gives an estimated count of entries for this type
     * @type {number}
     * @memberof CatalogType
     */
    estimated_count?: number;
    /**
     * Sets the display icon of this type in the dashboard
     * @type {string}
     * @memberof CatalogType
     */
    icon: CatalogTypeIconEnum;
    /**
     * ID of this catalog type
     * @type {string}
     * @memberof CatalogType
     */
    id: string;
    /**
     * Catalog types that are synced with external resources can't be edited
     * @type {boolean}
     * @memberof CatalogType
     */
    is_editable: boolean;
    /**
     * When this type was last synced (if it's ever been sync'd)
     * @type {Date}
     * @memberof CatalogType
     */
    last_synced_at?: Date;
    /**
     * What mode is this catalog type?
     * @type {string}
     * @memberof CatalogType
     */
    mode: CatalogTypeModeEnum;
    /**
     * Name is the human readable name of this type
     * @type {string}
     * @memberof CatalogType
     */
    name: string;
    /**
     * If present, the path to view this resource natively in the web UI
     * @type {string}
     * @memberof CatalogType
     */
    native_resource_path?: string;
    /**
     * If this type should be ranked
     * @type {boolean}
     * @memberof CatalogType
     */
    ranked: boolean;
    /**
     * The registry resource this type is synced from, if any
     * @type {string}
     * @memberof CatalogType
     */
    registry_type?: string;
    /**
     * If populated, the integrations required for this type
     * @type {Array<string>}
     * @memberof CatalogType
     */
    required_integrations?: Array<CatalogTypeRequiredIntegrationsEnum>;
    /**
     * 
     * @type {CatalogTypeSchema}
     * @memberof CatalogType
     */
    schema: CatalogTypeSchema;
    /**
     * The url of the external repository where this type is managed
     * @type {string}
     * @memberof CatalogType
     */
    source_repo_url?: string;
    /**
     * How often this type is synced (if it is synced from an external type)
     * @type {string}
     * @memberof CatalogType
     */
    sync_frequency?: string;
    /**
     * The type name of this catalog type, to be used when defining attributes. This is immutable once a CatalogType has been created. For non-externally sync types, it must follow the pattern Custom["SomeName "]
     * @type {string}
     * @memberof CatalogType
     */
    type_name: string;
    /**
     * When this type was last updated
     * @type {Date}
     * @memberof CatalogType
     */
    updated_at: Date;
    /**
     * If populated, the name of the entry will be added to the list of identifiers for this type
     * @type {boolean}
     * @memberof CatalogType
     */
    use_name_as_identifier: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CatalogTypeCategoriesEnum {
    Customer = 'customer',
    IssueTracker = 'issue-tracker',
    ProductFeature = 'product-feature',
    Service = 'service',
    OnCall = 'on-call',
    Team = 'team',
    User = 'user'
}/**
* @export
* @enum {string}
*/
export enum CatalogTypeColorEnum {
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Violet = 'violet',
    Pink = 'pink',
    Cyan = 'cyan',
    Orange = 'orange'
}/**
* @export
* @enum {string}
*/
export enum CatalogTypeIconEnum {
    Alert = 'alert',
    Bolt = 'bolt',
    Box = 'box',
    Briefcase = 'briefcase',
    Browser = 'browser',
    Bulb = 'bulb',
    Calendar = 'calendar',
    Clock = 'clock',
    Cog = 'cog',
    Components = 'components',
    Database = 'database',
    Doc = 'doc',
    Email = 'email',
    EscalationPath = 'escalation-path',
    Files = 'files',
    Flag = 'flag',
    Folder = 'folder',
    Globe = 'globe',
    Money = 'money',
    Server = 'server',
    Severity = 'severity',
    StatusPage = 'status-page',
    Store = 'store',
    Star = 'star',
    Tag = 'tag',
    User = 'user',
    Users = 'users'
}/**
* @export
* @enum {string}
*/
export enum CatalogTypeModeEnum {
    Manual = 'manual',
    External = 'external',
    Internal = 'internal'
}/**
* @export
* @enum {string}
*/
export enum CatalogTypeRequiredIntegrationsEnum {
    Asana = 'asana',
    Statuspage = 'statuspage',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Confluence = 'confluence',
    Cortex = 'cortex',
    Datadog = 'datadog',
    Github = 'github',
    Gitlab = 'gitlab',
    GoogleDocs = 'google_docs',
    GoogleMeet = 'google_meet',
    GoogleCalendar = 'google_calendar',
    GoogleWorkspace = 'google_workspace',
    Grafana = 'grafana',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Linear = 'linear',
    MicrosoftTeams = 'microsoft_teams',
    MicrosoftTeamsOnlineMeetings = 'microsoft_teams_online_meetings',
    Notion = 'notion',
    OutlookCalendar = 'outlook_calendar',
    Opsgenie = 'opsgenie',
    Opslevel = 'opslevel',
    Pagerduty = 'pagerduty',
    Salesforce = 'salesforce',
    Sentry = 'sentry',
    ServiceNow = 'service_now',
    Shortcut = 'shortcut',
    Scim = 'scim',
    Slack = 'slack',
    SplunkOnCall = 'splunk_on_call',
    IncidentIoStatusPages = 'incident_io_status_pages',
    IncidentIoOnCall = 'incident_io_on_call',
    Vanta = 'vanta',
    Webhooks = 'webhooks',
    Zendesk = 'zendesk',
    Zoom = 'zoom'
}

export function CatalogTypeFromJSON(json: any): CatalogType {
    return CatalogTypeFromJSONTyped(json, false);
}

export function CatalogTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'annotations': json['annotations'],
        'categories': json['categories'],
        'color': json['color'],
        'created_at': (new Date(json['created_at'])),
        'description': json['description'],
        'dynamic_resource_parameter': !exists(json, 'dynamic_resource_parameter') ? undefined : json['dynamic_resource_parameter'],
        'engine_resource_type': json['engine_resource_type'],
        'estimated_count': !exists(json, 'estimated_count') ? undefined : json['estimated_count'],
        'icon': json['icon'],
        'id': json['id'],
        'is_editable': json['is_editable'],
        'last_synced_at': !exists(json, 'last_synced_at') ? undefined : (new Date(json['last_synced_at'])),
        'mode': json['mode'],
        'name': json['name'],
        'native_resource_path': !exists(json, 'native_resource_path') ? undefined : json['native_resource_path'],
        'ranked': json['ranked'],
        'registry_type': !exists(json, 'registry_type') ? undefined : json['registry_type'],
        'required_integrations': !exists(json, 'required_integrations') ? undefined : json['required_integrations'],
        'schema': CatalogTypeSchemaFromJSON(json['schema']),
        'source_repo_url': !exists(json, 'source_repo_url') ? undefined : json['source_repo_url'],
        'sync_frequency': !exists(json, 'sync_frequency') ? undefined : json['sync_frequency'],
        'type_name': json['type_name'],
        'updated_at': (new Date(json['updated_at'])),
        'use_name_as_identifier': json['use_name_as_identifier'],
    };
}

export function CatalogTypeToJSON(value?: CatalogType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'annotations': value.annotations,
        'categories': value.categories,
        'color': value.color,
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'dynamic_resource_parameter': value.dynamic_resource_parameter,
        'engine_resource_type': value.engine_resource_type,
        'estimated_count': value.estimated_count,
        'icon': value.icon,
        'id': value.id,
        'is_editable': value.is_editable,
        'last_synced_at': value.last_synced_at === undefined ? undefined : (value.last_synced_at.toISOString()),
        'mode': value.mode,
        'name': value.name,
        'native_resource_path': value.native_resource_path,
        'ranked': value.ranked,
        'registry_type': value.registry_type,
        'required_integrations': value.required_integrations,
        'schema': CatalogTypeSchemaToJSON(value.schema),
        'source_repo_url': value.source_repo_url,
        'sync_frequency': value.sync_frequency,
        'type_name': value.type_name,
        'updated_at': (value.updated_at.toISOString()),
        'use_name_as_identifier': value.use_name_as_identifier,
    };
}

