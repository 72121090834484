/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruthCodeChange,
    IncidentGroundTruthCodeChangeFromJSON,
    IncidentGroundTruthCodeChangeFromJSONTyped,
    IncidentGroundTruthCodeChangeToJSON,
} from './IncidentGroundTruthCodeChange';

/**
 * 
 * @export
 * @interface AIStaffUpdateIncidentGroundTruthRequestBody
 */
export interface AIStaffUpdateIncidentGroundTruthRequestBody {
    /**
     * List of code changes that caused this incident
     * @type {Array<IncidentGroundTruthCodeChange>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    causing_code_changes?: Array<IncidentGroundTruthCodeChange>;
    /**
     * When code changes were human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    causing_code_changes_verified_at?: string;
    /**
     * Whether the investigation findings were useful to responders ('not_relevant', 'relevant', 'critical')
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance?: string;
    /**
     * Explanation of why the investigation was or wasn't useful
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance_reasoning?: string;
    /**
     * Tags describing how the investigation was useful (e.g. 'code-change', 'telemetry')
     * @type {Array<string>}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance_tags?: Array<string>;
    /**
     * When investigation relevance was human-verified (blank string to unset)
     * @type {string}
     * @memberof AIStaffUpdateIncidentGroundTruthRequestBody
     */
    investigation_relevance_verified_at?: string;
}

export function AIStaffUpdateIncidentGroundTruthRequestBodyFromJSON(json: any): AIStaffUpdateIncidentGroundTruthRequestBody {
    return AIStaffUpdateIncidentGroundTruthRequestBodyFromJSONTyped(json, false);
}

export function AIStaffUpdateIncidentGroundTruthRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffUpdateIncidentGroundTruthRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causing_code_changes': !exists(json, 'causing_code_changes') ? undefined : ((json['causing_code_changes'] as Array<any>).map(IncidentGroundTruthCodeChangeFromJSON)),
        'causing_code_changes_verified_at': !exists(json, 'causing_code_changes_verified_at') ? undefined : json['causing_code_changes_verified_at'],
        'investigation_relevance': !exists(json, 'investigation_relevance') ? undefined : json['investigation_relevance'],
        'investigation_relevance_reasoning': !exists(json, 'investigation_relevance_reasoning') ? undefined : json['investigation_relevance_reasoning'],
        'investigation_relevance_tags': !exists(json, 'investigation_relevance_tags') ? undefined : json['investigation_relevance_tags'],
        'investigation_relevance_verified_at': !exists(json, 'investigation_relevance_verified_at') ? undefined : json['investigation_relevance_verified_at'],
    };
}

export function AIStaffUpdateIncidentGroundTruthRequestBodyToJSON(value?: AIStaffUpdateIncidentGroundTruthRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causing_code_changes': value.causing_code_changes === undefined ? undefined : ((value.causing_code_changes as Array<any>).map(IncidentGroundTruthCodeChangeToJSON)),
        'causing_code_changes_verified_at': value.causing_code_changes_verified_at,
        'investigation_relevance': value.investigation_relevance,
        'investigation_relevance_reasoning': value.investigation_relevance_reasoning,
        'investigation_relevance_tags': value.investigation_relevance_tags,
        'investigation_relevance_verified_at': value.investigation_relevance_verified_at,
    };
}

