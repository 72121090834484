/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessorBackfillResourceOptions,
    ProcessorBackfillResourceOptionsFromJSON,
    ProcessorBackfillResourceOptionsFromJSONTyped,
    ProcessorBackfillResourceOptionsToJSON,
} from './ProcessorBackfillResourceOptions';

/**
 * 
 * @export
 * @interface AIStaffPreviewProcessorBackfillRequestBody
 */
export interface AIStaffPreviewProcessorBackfillRequestBody {
    /**
     * Name of the processor to backfill
     * @type {string}
     * @memberof AIStaffPreviewProcessorBackfillRequestBody
     */
    processor: string;
    /**
     * 
     * @type {ProcessorBackfillResourceOptions}
     * @memberof AIStaffPreviewProcessorBackfillRequestBody
     */
    resource_options: ProcessorBackfillResourceOptions;
}

export function AIStaffPreviewProcessorBackfillRequestBodyFromJSON(json: any): AIStaffPreviewProcessorBackfillRequestBody {
    return AIStaffPreviewProcessorBackfillRequestBodyFromJSONTyped(json, false);
}

export function AIStaffPreviewProcessorBackfillRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffPreviewProcessorBackfillRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processor': json['processor'],
        'resource_options': ProcessorBackfillResourceOptionsFromJSON(json['resource_options']),
    };
}

export function AIStaffPreviewProcessorBackfillRequestBodyToJSON(value?: AIStaffPreviewProcessorBackfillRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processor': value.processor,
        'resource_options': ProcessorBackfillResourceOptionsToJSON(value.resource_options),
    };
}

