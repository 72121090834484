import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useLocation } from "react-router";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";

import { BodyTabs } from "../../body/IncidentBody";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps } from "./types";

export const ReviewFollowUpsItem = ({
  incidentTask,
  mode,
}: ItemProps): React.ReactElement | null => {
  const { search, pathname } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() => {
          // If we're on the incident details page, just switch tabs.
          if (pathname.includes("/incidents/")) {
            const newSearch = new URLSearchParams(search);
            newSearch.set("tab", BodyTabs.FollowUps);
            navigate(
              {
                pathname,
                search: newSearch.toString(),
              },
              { replace: true },
            );
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?tab=follow-ups`);
          }
        }}
        analyticsTrackingId="post-incident-task-review-follow-ups"
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.ReviewFollowups,
        )}
        label="View follow-ups"
      />
    </>
  );
};
