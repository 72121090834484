import {
  InvestigationScorecardEvent,
  InvestigationScorecardGrade,
  InvestigationScorecardMetric,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  Tooltip,
} from "@incident-ui";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import _ from "lodash";
import { tcx } from "src/utils/tailwind-classes";

import {
  ScorecardMetricIdentifier,
  useEvaluationFilterContext,
} from "../common/EvaluationFilterContext";
import { InvestigationGradeEvents } from "./InvestigationGradeEvents";

type InvestigationScorecardGradesProps = {
  grades: InvestigationScorecardGrade[];
  warnings?: string[];
  className?: string;
  showFilterBar: boolean;
  allEvents: InvestigationScorecardEvent[];
};

// Show grades of either a single investigation scorecard or an aggregate.
export const InvestigationScorecardGrades = ({
  grades,
  warnings,
  className,
  allEvents,
  showFilterBar,
}: InvestigationScorecardGradesProps) => {
  return (
    <div className={tcx("relative", className)}>
      {warnings && warnings.length > 0 && (
        <div className="absolute right-4 top-4">
          <Tooltip content={<WarningsContent warnings={warnings} />}>
            <div className="flex items-center gap-1 text-amber-500">
              <Icon id={IconEnum.AlertPriority} />
              <span className="text-sm font-medium">{warnings.length}</span>
            </div>
          </Tooltip>
        </div>
      )}

      {grades.map((grade, i) => (
        <div key={i} className="p-4 flex flex-col gap-2 w-full">
          <div className="flex items-center gap-2 border-b border-stroke pb-2">
            <h3 className="text-base-bold font-medium">{grade.name}</h3>
            {grade.metrics.some((m) => m.sample) && (
              <Badge theme={BadgeTheme.Secondary} className="text-xs">
                Aggregate of {grade.metrics[0]?.sample?.count || 0} results
              </Badge>
            )}
          </div>

          <div className="w-full flex gap-2 items-start">
            <div className="grid xl:grid-cols-2 gap-4 h-fit w-3/4">
              {grade.metrics.map((metric, j) => (
                <InvestigationGradeMetric
                  key={j}
                  metric={metric}
                  grade={grade}
                  showDescription
                />
              ))}
            </div>
            <div className="border-l border-stroke pl-4 w-1/4">
              <InvestigationGradeEvents
                grade={grade}
                showFilterBar={showFilterBar}
                allEvents={allEvents}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const WarningsContent = ({
  warnings,
  className,
}: {
  warnings: string[];
  className?: string;
}) => {
  if (warnings.length === 0) return null;

  return (
    <div
      className={tcx(
        "p-4 max-w-md bg-slate-800 rounded-lg shadow-lg",
        className,
      )}
    >
      <div className="text-base text-white font-medium mb-3">Warnings</div>
      <ul className="space-y-2">
        {warnings.map((warning, index) => (
          <li key={index} className="text-sm text-slate-400">
            {warning}
          </li>
        ))}
      </ul>
    </div>
  );
};

type GradeMetricProps = {
  metric: InvestigationScorecardMetric;
  grade: InvestigationScorecardGrade;
  className?: string;
  showDescription: boolean;
};

export const InvestigationGradeMetric = ({
  showDescription,
  metric,
  grade,
  className,
}: GradeMetricProps) => {
  const { name, score, numerator, denominator, description } = metric;
  const metadata = {
    ...(metric.metadata instanceof Object && metric.metadata),
    ...(metric.sample && {
      sample: {
        total: metric.sample.count,
        null_values: metric.sample.null_count,
      },
    }),
  };

  const { setSelectedMetric, selectedMetric, enableSelectingMetrics } =
    useEvaluationFilterContext();
  const onToggleSelectedMetric = (identifier: ScorecardMetricIdentifier) => {
    setSelectedMetric(
      selectedMetric?.gradeName === grade.name &&
        selectedMetric?.metricName === name
        ? undefined
        : identifier,
    );
  };

  const indicator = getGradeIndicator(score);
  const hasRatio = numerator !== undefined && denominator !== undefined;
  const scoreDisplay = _.isUndefined(score)
    ? "N/A"
    : `${(score * 100).toFixed(1)}%`;

  return (
    <div className={tcx("flex items-center gap-2", className)}>
      <Icon id={indicator.icon} className={indicator.color} />
      <div className="flex-1">
        <div className="flex items-center gap-2 flex-wrap">
          {enableSelectingMetrics ? (
            <Button
              onClick={() =>
                onToggleSelectedMetric({
                  gradeName: grade.name,
                  metricName: metric.name,
                })
              }
              analyticsTrackingId={null}
              theme={
                selectedMetric?.gradeName === grade.name &&
                selectedMetric?.metricName === name
                  ? ButtonTheme.Primary
                  : ButtonTheme.Secondary
              }
              size={BadgeSize.ExtraSmall}
            >
              {name}
            </Button>
          ) : (
            <span className="font-medium">{name}</span>
          )}

          {metadata ? (
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  size={BadgeSize.ExtraSmall}
                  theme={ButtonTheme.UnstyledPill}
                  analyticsTrackingId={null}
                  className={tcx(
                    indicator.color,
                    indicator.bgColor,
                    "hover:opacity-90",
                  )}
                >
                  {scoreDisplay}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className={tcx(
                  "min-w-72 max-w-120 p-4",
                  "bg-white dark:bg-gray-950", // Ensure solid background
                  "border border-border", // Consistent border
                  "shadow-lg", // Stronger shadow
                  "rounded-md", // Consistent rounding
                  "z-50",
                )}
                sideOffset={5}
                collisionPadding={20}
              >
                <h4 className="font-medium text-sm mb-3">Metadata</h4>
                <MetadataContent metadata={metadata} />
              </PopoverContent>
            </Popover>
          ) : (
            <span className={indicator.color}>{scoreDisplay}</span>
          )}

          {hasRatio && (
            <Badge theme={BadgeTheme.Secondary} className="text-xs">
              {numerator} / {denominator}
            </Badge>
          )}
        </div>
        {showDescription && (
          <div className="text-xs text-gray-500 mt-0.5">{description}</div>
        )}
      </div>
    </div>
  );
};

type GradeIndicator = {
  icon: IconEnum;
  color: string;
  bgColor: string;
};

const getGradeIndicator = (score: number | undefined): GradeIndicator => {
  if (score === undefined) {
    return {
      icon: IconEnum.Circle,
      color: "text-gray-400",
      bgColor: "bg-gray-100",
    };
  }

  if (score >= 0.7) {
    return {
      icon: IconEnum.TickCircle,
      color: "text-green-500",
      bgColor: "bg-green-50",
    };
  }

  if (score >= 0.4) {
    return {
      icon: IconEnum.Circle,
      color: "text-amber-500",
      bgColor: "bg-amber-50",
    };
  }

  return {
    icon: IconEnum.Exclamation,
    color: "text-red-500",
    bgColor: "bg-red-50",
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MetadataContent = ({ metadata }: { metadata: Record<string, any> }) => {
  return (
    <div className="grid gap-2">
      {Object.entries(metadata).map(([key, value]) => (
        <div key={key} className="grid grid-cols-2 gap-2">
          <span className="text-xs font-medium text-muted-foreground capitalize">
            {key.replace(/_/g, " ")}:
          </span>
          <span className="text-xs">
            {typeof value === "object"
              ? JSON.stringify(value)
              : value.toString()}
          </span>
        </div>
      ))}
    </div>
  );
};
