/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroup,
    ConditionGroupFromJSON,
    ConditionGroupFromJSONTyped,
    ConditionGroupToJSON,
} from './ConditionGroup';
import {
    CustomFieldFilterByOptions,
    CustomFieldFilterByOptionsFromJSON,
    CustomFieldFilterByOptionsFromJSONTyped,
    CustomFieldFilterByOptionsToJSON,
} from './CustomFieldFilterByOptions';
import {
    CustomFieldOption,
    CustomFieldOptionFromJSON,
    CustomFieldOptionFromJSONTyped,
    CustomFieldOptionToJSON,
} from './CustomFieldOption';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';

/**
 * 
 * @export
 * @interface CustomField
 */
export interface CustomField {
    /**
     * If yes, a custom field cannot be unset once it has been set.
     * @type {boolean}
     * @memberof CustomField
     */
    cannot_be_unset: boolean;
    /**
     * For catalog fields, the ID of the associated catalog type
     * @type {string}
     * @memberof CustomField
     */
    catalog_type_id?: string;
    /**
     * What conditions must be true for this field to be available?
     * @type {Array<ConditionGroup>}
     * @memberof CustomField
     */
    condition_groups: Array<ConditionGroup>;
    /**
     * When the action was created
     * @type {Date}
     * @memberof CustomField
     */
    created_at: Date;
    /**
     * Description of the custom field
     * @type {string}
     * @memberof CustomField
     */
    description: string;
    /**
     * For option fields, enables creating of new options during an incident, such as from `/incident fields` or the Incident Homepage.
     * @type {boolean}
     * @memberof CustomField
     */
    dynamic_options: boolean;
    /**
     * 
     * @type {Expression}
     * @memberof CustomField
     */
    engine_expression?: Expression;
    /**
     * How the value of this field is set
     * @type {string}
     * @memberof CustomField
     */
    field_mode: CustomFieldFieldModeEnum;
    /**
     * Type of custom field
     * @type {string}
     * @memberof CustomField
     */
    field_type: CustomFieldFieldTypeEnum;
    /**
     * 
     * @type {CustomFieldFilterByOptions}
     * @memberof CustomField
     */
    filter_by?: CustomFieldFilterByOptions;
    /**
     * For catalog fields, the ID of the attribute used to group catalog entries (if applicable)
     * @type {string}
     * @memberof CustomField
     */
    group_by_catalog_attribute_id?: string;
    /**
     * Which catalog attribute provides helptext for the options
     * @type {string}
     * @memberof CustomField
     */
    helptext_catalog_attribute_id?: string;
    /**
     * Unique identifier for the custom field
     * @type {string}
     * @memberof CustomField
     */
    id: string;
    /**
     * Whether a custom field can be used in an incident: this will be false if it's a static select with no options
     * @type {boolean}
     * @memberof CustomField
     */
    is_usable: boolean;
    /**
     * Human readable name for the custom field
     * @type {string}
     * @memberof CustomField
     */
    name: string;
    /**
     * What options are available for this custom field, if this field has options
     * @type {Array<CustomFieldOption>}
     * @memberof CustomField
     */
    options: Array<CustomFieldOption>;
    /**
     * Unique identifier of the organisation ID
     * @type {string}
     * @memberof CustomField
     */
    organisation_id: string;
    /**
     * Rank to help sort custom fields
     * @type {number}
     * @memberof CustomField
     */
    rank: number;
    /**
     * When this custom field must be set during the incident lifecycle. [DEPRECATED: please use required_v2 instead].
     * @type {string}
     * @memberof CustomField
     */
    required?: CustomFieldRequiredEnum;
    /**
     * When this custom field must be set during the incident lifecycle.
     * @type {string}
     * @memberof CustomField
     */
    required_v2?: CustomFieldRequiredV2Enum;
    /**
     * Whether a custom field should be shown in the incident resolve modal. If this custom field is required before resolution, but no value has been set for it, the field will be shown in the resolve modal whatever the value of this setting.
     * @type {boolean}
     * @memberof CustomField
     */
    show_before_closure?: boolean;
    /**
     * Whether a custom field should be shown in the incident creation modal. This must be true if the field is always required.
     * @type {boolean}
     * @memberof CustomField
     */
    show_before_creation?: boolean;
    /**
     * Whether a custom field should be shown in the incident update modal.
     * @type {boolean}
     * @memberof CustomField
     */
    show_before_update?: boolean;
    /**
     * Whether a custom field should be shown in the list of fields as part of the announcement post when set.
     * @type {boolean}
     * @memberof CustomField
     */
    show_in_announcement_post?: boolean;
    /**
     * When the action was last updated
     * @type {Date}
     * @memberof CustomField
     */
    updated_at: Date;
}

/**
* @export
* @enum {string}
*/
export enum CustomFieldFieldModeEnum {
    Manual = 'manual',
    SensibleDefault = 'sensible_default',
    FullyDerived = 'fully_derived'
}/**
* @export
* @enum {string}
*/
export enum CustomFieldFieldTypeEnum {
    SingleSelect = 'single_select',
    MultiSelect = 'multi_select',
    Text = 'text',
    Link = 'link',
    Numeric = 'numeric'
}/**
* @export
* @enum {string}
*/
export enum CustomFieldRequiredEnum {
    Never = 'never',
    BeforeClosure = 'before_closure',
    Always = 'always'
}/**
* @export
* @enum {string}
*/
export enum CustomFieldRequiredV2Enum {
    Never = 'never',
    BeforeResolution = 'before_resolution',
    Always = 'always'
}

export function CustomFieldFromJSON(json: any): CustomField {
    return CustomFieldFromJSONTyped(json, false);
}

export function CustomFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannot_be_unset': json['cannot_be_unset'],
        'catalog_type_id': !exists(json, 'catalog_type_id') ? undefined : json['catalog_type_id'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupFromJSON)),
        'created_at': (new Date(json['created_at'])),
        'description': json['description'],
        'dynamic_options': json['dynamic_options'],
        'engine_expression': !exists(json, 'engine_expression') ? undefined : ExpressionFromJSON(json['engine_expression']),
        'field_mode': json['field_mode'],
        'field_type': json['field_type'],
        'filter_by': !exists(json, 'filter_by') ? undefined : CustomFieldFilterByOptionsFromJSON(json['filter_by']),
        'group_by_catalog_attribute_id': !exists(json, 'group_by_catalog_attribute_id') ? undefined : json['group_by_catalog_attribute_id'],
        'helptext_catalog_attribute_id': !exists(json, 'helptext_catalog_attribute_id') ? undefined : json['helptext_catalog_attribute_id'],
        'id': json['id'],
        'is_usable': json['is_usable'],
        'name': json['name'],
        'options': ((json['options'] as Array<any>).map(CustomFieldOptionFromJSON)),
        'organisation_id': json['organisation_id'],
        'rank': json['rank'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'required_v2': !exists(json, 'required_v2') ? undefined : json['required_v2'],
        'show_before_closure': !exists(json, 'show_before_closure') ? undefined : json['show_before_closure'],
        'show_before_creation': !exists(json, 'show_before_creation') ? undefined : json['show_before_creation'],
        'show_before_update': !exists(json, 'show_before_update') ? undefined : json['show_before_update'],
        'show_in_announcement_post': !exists(json, 'show_in_announcement_post') ? undefined : json['show_in_announcement_post'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function CustomFieldToJSON(value?: CustomField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannot_be_unset': value.cannot_be_unset,
        'catalog_type_id': value.catalog_type_id,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupToJSON)),
        'created_at': (value.created_at.toISOString()),
        'description': value.description,
        'dynamic_options': value.dynamic_options,
        'engine_expression': ExpressionToJSON(value.engine_expression),
        'field_mode': value.field_mode,
        'field_type': value.field_type,
        'filter_by': CustomFieldFilterByOptionsToJSON(value.filter_by),
        'group_by_catalog_attribute_id': value.group_by_catalog_attribute_id,
        'helptext_catalog_attribute_id': value.helptext_catalog_attribute_id,
        'id': value.id,
        'is_usable': value.is_usable,
        'name': value.name,
        'options': ((value.options as Array<any>).map(CustomFieldOptionToJSON)),
        'organisation_id': value.organisation_id,
        'rank': value.rank,
        'required': value.required,
        'required_v2': value.required_v2,
        'show_before_closure': value.show_before_closure,
        'show_before_creation': value.show_before_creation,
        'show_before_update': value.show_before_update,
        'show_in_announcement_post': value.show_in_announcement_post,
        'updated_at': (value.updated_at.toISOString()),
    };
}

