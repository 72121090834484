import {
  PostIncidentTask,
  PostIncidentTaskSlim,
  ScopeNameEnum,
} from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  BadgeSize,
  ButtonTheme,
  GatedDropdownMenuItem,
  IconEnum,
} from "@incident-ui";

import { ActionMode } from "./types";

export const ButtonOrDropdownItem = ({
  mode,
  label,
  icon,
  analyticsTrackingId,
  incidentTask,
  disabled,
  disabledExplanation,
  requiredScope,
  onClick,
}: {
  mode: ActionMode;
  label: string;
  icon: IconEnum;
  analyticsTrackingId: string;
  incidentTask: PostIncidentTaskSlim | PostIncidentTask;
  disabled?: boolean;
  onClick: () => void;
  disabledExplanation?: string;
  requiredScope?: ScopeNameEnum;
}) => {
  if (mode === "dropdown") {
    return (
      <GatedDropdownMenuItem
        onSelect={onClick}
        analyticsTrackingId={analyticsTrackingId}
        analyticsTrackingMetadata={{
          incident_task_id: incidentTask.id,
        }}
        icon={icon}
        label={label}
        disabled={disabled}
        requiredScope={requiredScope}
        explanationText={disabledExplanation}
      />
    );
  }

  return (
    <GatedButton
      onClick={onClick}
      theme={ButtonTheme.Primary}
      requiredScope={requiredScope}
      size={BadgeSize.Small}
      icon={icon}
      analyticsTrackingId={analyticsTrackingId}
      analyticsTrackingMetadata={{
        incident_task_id: incidentTask.id,
      }}
      disabled={disabled}
      disabledTooltipContent={disabledExplanation}
    >
      {label}
    </GatedButton>
  );
};
