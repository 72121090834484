/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSourceEmailOptions
 */
export interface AlertSourceEmailOptions {
    /**
     * Email address this alert source receives alerts to
     * @type {string}
     * @memberof AlertSourceEmailOptions
     */
    email_address: string;
}

export function AlertSourceEmailOptionsFromJSON(json: any): AlertSourceEmailOptions {
    return AlertSourceEmailOptionsFromJSONTyped(json, false);
}

export function AlertSourceEmailOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceEmailOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email_address': json['email_address'],
    };
}

export function AlertSourceEmailOptionsToJSON(value?: AlertSourceEmailOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_address': value.email_address,
    };
}

