/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OverviewDeclineRateTable,
    OverviewDeclineRateTableFromJSON,
    OverviewDeclineRateTableFromJSONTyped,
    OverviewDeclineRateTableToJSON,
} from './OverviewDeclineRateTable';
import {
    OverviewWakeUpTable,
    OverviewWakeUpTableFromJSON,
    OverviewWakeUpTableFromJSONTyped,
    OverviewWakeUpTableToJSON,
} from './OverviewWakeUpTable';
import {
    OverviewWorkloadTable,
    OverviewWorkloadTableFromJSON,
    OverviewWorkloadTableFromJSONTyped,
    OverviewWorkloadTableToJSON,
} from './OverviewWorkloadTable';

/**
 * 
 * @export
 * @interface AlertsGetInsightsOverviewTablesResponseBody
 */
export interface AlertsGetInsightsOverviewTablesResponseBody {
    /**
     * The timestamp that this data is valid from - note that we cache insights queries so this may be earlier than GetLastSync reports
     * @type {Date}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    last_sync_at: Date;
    /**
     * 
     * @type {OverviewDeclineRateTable}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    theme_decline_rate_table: OverviewDeclineRateTable;
    /**
     * 
     * @type {OverviewWakeUpTable}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    theme_wake_up_table: OverviewWakeUpTable;
    /**
     * 
     * @type {OverviewWorkloadTable}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    theme_workload_table: OverviewWorkloadTable;
    /**
     * 
     * @type {OverviewDeclineRateTable}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    title_decline_rate_table: OverviewDeclineRateTable;
    /**
     * 
     * @type {OverviewWakeUpTable}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    title_wake_up_table: OverviewWakeUpTable;
    /**
     * 
     * @type {OverviewWorkloadTable}
     * @memberof AlertsGetInsightsOverviewTablesResponseBody
     */
    title_workload_table: OverviewWorkloadTable;
}

export function AlertsGetInsightsOverviewTablesResponseBodyFromJSON(json: any): AlertsGetInsightsOverviewTablesResponseBody {
    return AlertsGetInsightsOverviewTablesResponseBodyFromJSONTyped(json, false);
}

export function AlertsGetInsightsOverviewTablesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertsGetInsightsOverviewTablesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'last_sync_at': (new Date(json['last_sync_at'])),
        'theme_decline_rate_table': OverviewDeclineRateTableFromJSON(json['theme_decline_rate_table']),
        'theme_wake_up_table': OverviewWakeUpTableFromJSON(json['theme_wake_up_table']),
        'theme_workload_table': OverviewWorkloadTableFromJSON(json['theme_workload_table']),
        'title_decline_rate_table': OverviewDeclineRateTableFromJSON(json['title_decline_rate_table']),
        'title_wake_up_table': OverviewWakeUpTableFromJSON(json['title_wake_up_table']),
        'title_workload_table': OverviewWorkloadTableFromJSON(json['title_workload_table']),
    };
}

export function AlertsGetInsightsOverviewTablesResponseBodyToJSON(value?: AlertsGetInsightsOverviewTablesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_sync_at': (value.last_sync_at.toISOString()),
        'theme_decline_rate_table': OverviewDeclineRateTableToJSON(value.theme_decline_rate_table),
        'theme_wake_up_table': OverviewWakeUpTableToJSON(value.theme_wake_up_table),
        'theme_workload_table': OverviewWorkloadTableToJSON(value.theme_workload_table),
        'title_decline_rate_table': OverviewDeclineRateTableToJSON(value.title_decline_rate_table),
        'title_wake_up_table': OverviewWakeUpTableToJSON(value.title_wake_up_table),
        'title_workload_table': OverviewWorkloadTableToJSON(value.title_workload_table),
    };
}

