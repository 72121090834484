/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertTemplateAttributePayload,
    AlertTemplateAttributePayloadFromJSON,
    AlertTemplateAttributePayloadFromJSONTyped,
    AlertTemplateAttributePayloadToJSON,
} from './AlertTemplateAttributePayload';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface AlertTemplatePayload
 */
export interface AlertTemplatePayload {
    /**
     * Attributes to set on alerts coming from this source, with a binding describing how to set them.
     * @type {Array<AlertTemplateAttributePayload>}
     * @memberof AlertTemplatePayload
     */
    attributes: Array<AlertTemplateAttributePayload>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplatePayload
     */
    description: EngineParamBindingPayload;
    /**
     * Expressions available for use in bindings within this template
     * @type {Array<ExpressionPayload>}
     * @memberof AlertTemplatePayload
     */
    expressions: Array<ExpressionPayload>;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplatePayload
     */
    priority?: EngineParamBindingPayload;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplatePayload
     */
    title: EngineParamBindingPayload;
}

export function AlertTemplatePayloadFromJSON(json: any): AlertTemplatePayload {
    return AlertTemplatePayloadFromJSONTyped(json, false);
}

export function AlertTemplatePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertTemplatePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AlertTemplateAttributePayloadFromJSON)),
        'description': EngineParamBindingPayloadFromJSON(json['description']),
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionPayloadFromJSON)),
        'priority': !exists(json, 'priority') ? undefined : EngineParamBindingPayloadFromJSON(json['priority']),
        'title': EngineParamBindingPayloadFromJSON(json['title']),
    };
}

export function AlertTemplatePayloadToJSON(value?: AlertTemplatePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(AlertTemplateAttributePayloadToJSON)),
        'description': EngineParamBindingPayloadToJSON(value.description),
        'expressions': ((value.expressions as Array<any>).map(ExpressionPayloadToJSON)),
        'priority': EngineParamBindingPayloadToJSON(value.priority),
        'title': EngineParamBindingPayloadToJSON(value.title),
    };
}

