import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";

import { usePostIncidentModalContext } from "../modals/PostIncidentActionModals";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps, PostIncidentActionModal } from "./types";

export const SetCustomFieldsItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const applicableIDs = incidentTask.config.custom_field_ids ?? [];
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.EditCustomFieldEntries,
          props: { ...props, filterToCustomFieldIDs: applicableIDs },
        })
      }
      analyticsTrackingId="post-incident-task-assign-role"
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.SetCustomFields)}
      label="Update custom fields"
    />
  );
};
