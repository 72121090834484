import {
  BackfillProcessorRequestBody,
  ProcessorBackfillPreview,
  useAiStaffServiceAiStaffBackfillProcessor,
  useAiStaffServiceAiStaffListProcessors,
  useAiStaffServiceAiStaffPreviewProcessorBackfill,
} from "@incident-io/query-api";
import { DateTimeInputV2 } from "@incident-shared/forms/v2/inputs/DateTimeInputV2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { NumberInputV2 } from "@incident-shared/forms/v2/inputs/NumberInputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { ToggleV2 } from "@incident-shared/forms/v2/inputs/ToggleV2";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";

import { Environment, getEnvironment } from "../../../utils/environment";
import { displayCost } from "../common/utils";

type FormData = BackfillProcessorRequestBody;

export const ProcessorBackfillDrawer = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { identity } = useIdentity();
  const formMethods = useForm<FormData>({
    defaultValues: {
      resource_options: {
        limit_per_org: 10,
        resource_cutoff_days: 30,
        run_for_all_organisations: false,
      },
      run_options: {
        batch_size: 10,
        run_inline: getEnvironment() === Environment.Development,
      },
    },
  });

  const showToast = useToast();
  const { data: processorsData } = useAiStaffServiceAiStaffListProcessors();

  const { mutate, isPending } = useAiStaffServiceAiStaffBackfillProcessor({
    onSuccess: () => {
      showToast({
        title: "Backfill has started.",
        theme: ToastTheme.Success,
      });
      onClose();
    },
    onError: () => {
      showToast({
        title: "Failed to start backfill",
        theme: ToastTheme.Error,
      });
    },
  });

  const [runForAllOrgs] = formMethods.watch([
    "resource_options.run_for_all_organisations",
  ]);

  return (
    <Drawer onClose={onClose} width="medium">
      <DrawerContents>
        <DrawerTitle
          title="Backfill via a processor"
          icon={IconEnum.Cog}
          onClose={onClose}
          color={ColorPaletteEnum.Purple}
        />
        <DrawerBody className="flex grow">
          <Form.Root
            id="processor-backfill"
            onSubmit={(data) => {
              mutate({
                requestBody: data,
              });
            }}
            formMethods={formMethods}
            saving={isPending}
          >
            <StaticSingleSelectV2
              formMethods={formMethods}
              name="processor"
              placeholder="(e.g. ProcessIncidentFact)"
              options={
                processorsData?.processors.map((processor) => ({
                  label: processor,
                  value: processor,
                })) || []
              }
              label={"Processor"}
              required
            />
            <hr />
            <InputV2
              formMethods={formMethods}
              name="resource_options.resource_id"
              label="Resource ID (optional)"
              helptext="Backfill a specific resource ID (e.g. incident ID)"
            />
            <NumberInputV2
              formMethods={formMethods}
              name="resource_options.limit_per_org"
              label="Limit (per org)"
              helptext="Limit the total number of resources to backfill"
            />
            <NumberInputV2
              formMethods={formMethods}
              name="run_options.batch_size"
              label="Batch size"
              helptext="How many resources should be backfilled in a single event"
            />
            <NumberInputV2
              formMethods={formMethods}
              name="resource_options.resource_cutoff_days"
              label="Resource cutoff days (optional)"
              helptext="Backfill resources created within the last [X] days"
            />
            <DateTimeInputV2
              formMethods={formMethods}
              name="resource_options.not_processed_since"
              label="Not processed since (optional)"
              helptext="Backfill resources that haven't been processed since this date"
            />
            <hr />
            <ToggleV2
              formMethods={formMethods}
              name="resource_options.run_for_all_organisations"
              label="Run for all organisations"
            />
            {runForAllOrgs ? (
              <Callout
                theme={CalloutTheme.Danger}
                title="Think about the monies"
                subtitle="This will run the backfill for all organisations, which could be very expensive. Are you sure?"
              />
            ) : (
              <Callout
                theme={CalloutTheme.Info}
                title={`This will run on the ${identity.organisation_name} org`}
                subtitle="If you want to run this on another organisation, you can impersonate them and then navigate to Workbench."
              />
            )}
            <Preview />
          </Form.Root>
        </DrawerBody>
        <DrawerFooter className="flex justify-end">
          <Button
            type="submit"
            form="processor-backfill"
            analyticsTrackingId={"enqueue-processor-backfill"}
            theme={ButtonTheme.Primary}
            loading={isPending}
          >
            Submit
          </Button>
        </DrawerFooter>
      </DrawerContents>
    </Drawer>
  );
};

const Preview = () => {
  const formMethods = useFormContext<FormData>();
  const [latestPreview, setLatestPreview] = useState<
    ProcessorBackfillPreview | undefined
  >();
  const showToast = useToast();

  const { mutate: generatePreview, isPending: isGenerating } =
    useAiStaffServiceAiStaffPreviewProcessorBackfill({
      onSuccess: (data) => {
        setLatestPreview(data.preview);
      },
      onError: () => {
        showToast({
          title: "Failed to generate preview",
          theme: ToastTheme.Error,
        });
      },
    });
  const [processor] = formMethods.watch(["processor"]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between gap-2 items-center">
        <div className="text-base-bold">Preview</div>
        <Button
          theme={ButtonTheme.Secondary}
          icon={IconEnum.Refresh}
          analyticsTrackingId={null}
          loading={isGenerating}
          disabled={!processor}
          onClick={() =>
            generatePreview({ requestBody: formMethods.getValues() })
          }
        >
          Generate
        </Button>
      </div>
      {latestPreview ? (
        <PreviewExplanation preview={latestPreview} />
      ) : (
        <EmptyState content="Generate a preview to get an estimate of how much this might cost" />
      )}
    </div>
  );
};

const PreviewExplanation = ({
  preview,
}: {
  preview: ProcessorBackfillPreview;
}) => {
  const {
    total_resource_count: totalCount,
    cached_resource_count_estimate: cachedCount,
    cost_per_resource_estimate: costEstimate,
  } = preview;
  const countToProcess = totalCount - (cachedCount || 0);
  const overallCostEstimate = countToProcess * costEstimate;

  return (
    <ContentBox className="flex flex-col gap-2 p-4">
      <div>
        This backfill will process <B>{totalCount}</B> resources.
      </div>
      {cachedCount !== undefined ? (
        <>
          <div>
            Assuming all recent processor runs generated a new asset, we
            estimate that <B>{cachedCount}</B> resources have been processed
            since the provided cutoff, so won&apos;t be reprocessed. That leaves{" "}
            <B>{countToProcess}</B> that will be processed afresh.
          </div>
          <div>
            WARNING: this makes a load of assumptions about the previous
            processor runs, and knows nothing about your processor structure, so
            may be wildly inaccurate.
          </div>
        </>
      ) : (
        <>{`We weren't able to estimate how many resources might be cached. `}</>
      )}
      {costEstimate ? (
        <>
          <div>
            By looking at recent runs, we think it&apos;ll cost{" "}
            <B>{displayCost(costEstimate)}</B> to process each resource.
          </div>
          <div>
            That means we estimate this backfill will cost{" "}
            <B>{displayCost(overallCostEstimate)}</B> in total.
          </div>
        </>
      ) : (
        <div>
          We weren&apos;t able to estimate the cost based on previous runs
        </div>
      )}
    </ContentBox>
  );
};

const B = ({ children }: { children: React.ReactNode }) => {
  return <span className="font-semibold">{children}</span>;
};
