import { Form } from "@incident-shared/forms";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { BadgeSize, IconEnum, ModalContent, ModalFooter } from "@incident-ui";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Modal } from "@incident-ui/Modal/Modal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useClient } from "src/contexts/ClientContext";

import { CodeViewer } from "./CodeViewer";

type FormData = {
  instruction: string;
};

type ImproveEvalResultModalProps = {
  requestId: string;
  actualResult: string;
  onSetResult: (result: string) => void;
  onClose: () => void;
};

export const ImproveEvalResultModal = ({
  requestId,
  actualResult,
  onSetResult,
  onClose,
}: ImproveEvalResultModalProps) => {
  const [improvedResult, setImprovedResult] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const formMethods = useForm<FormData>();
  const apiClient = useClient();

  const onSuggest = async (data: FormData) => {
    setIsLoading(true);
    try {
      const res = await apiClient.aIStaffSuggestFixedEvalResult({
        id: requestId,
        suggestFixedEvalResultRequestBody: {
          prompt: data.instruction,
        },
      });
      setImprovedResult(res.eval_result);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Improve Eval Result"
      analyticsTrackingId="improve-eval-result"
    >
      <ModalContent className="flex flex-col gap-4">
        <Form.Root formMethods={formMethods} onSubmit={onSuggest}>
          <div className="flex flex-col gap-4">
            <TextareaV2
              formMethods={formMethods}
              name="instruction"
              label="Instruction"
              placeholder="Enter your instruction for improving the eval result..."
              required="Please enter an instruction"
            />

            <div className="flex justify-end gap-2">
              <Button
                theme={ButtonTheme.Primary}
                type="submit"
                loading={isLoading}
                analyticsTrackingId={null}
                size={BadgeSize.Small}
                icon={IconEnum.MagicWand}
              >
                {improvedResult ? "Try again" : "Improve Result"}
              </Button>
            </div>
          </div>
        </Form.Root>
        <CodeViewer title="Actual Result" mode="yaml" content={actualResult} />
        {improvedResult && (
          <CodeViewer
            mode="yaml"
            title="Suggestion"
            content={improvedResult}
            isEditing={false}
          />
        )}
      </ModalContent>
      <ModalFooter>
        <div className="flex flex-end items-center gap-2">
          <Button
            onClick={onClose}
            theme={ButtonTheme.Secondary}
            analyticsTrackingId={null}
          >
            Cancel
          </Button>
          <Button
            analyticsTrackingId={null}
            onClick={() => {
              onSetResult(improvedResult || "");
              onClose();
            }}
            theme={ButtonTheme.Primary}
            disabled={!improvedResult}
          >
            Set Result
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
