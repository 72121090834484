/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffSuggestFixedEvalResultResponseBody
 */
export interface AIStaffSuggestFixedEvalResultResponseBody {
    /**
     * The suggested eval result
     * @type {string}
     * @memberof AIStaffSuggestFixedEvalResultResponseBody
     */
    eval_result: string;
}

export function AIStaffSuggestFixedEvalResultResponseBodyFromJSON(json: any): AIStaffSuggestFixedEvalResultResponseBody {
    return AIStaffSuggestFixedEvalResultResponseBodyFromJSONTyped(json, false);
}

export function AIStaffSuggestFixedEvalResultResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffSuggestFixedEvalResultResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eval_result': json['eval_result'],
    };
}

export function AIStaffSuggestFixedEvalResultResponseBodyToJSON(value?: AIStaffSuggestFixedEvalResultResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eval_result': value.eval_result,
    };
}

