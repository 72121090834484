/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FollowUpsBuildScopeResponseBody,
    FollowUpsBuildScopeResponseBodyFromJSON,
    FollowUpsBuildScopeResponseBodyToJSON,
    FollowUpsConnectExternalIssueRequestBody,
    FollowUpsConnectExternalIssueRequestBodyFromJSON,
    FollowUpsConnectExternalIssueRequestBodyToJSON,
    FollowUpsConnectExternalIssueResponseBody,
    FollowUpsConnectExternalIssueResponseBodyFromJSON,
    FollowUpsConnectExternalIssueResponseBodyToJSON,
    FollowUpsCreatePriorityOptionRequestBody,
    FollowUpsCreatePriorityOptionRequestBodyFromJSON,
    FollowUpsCreatePriorityOptionRequestBodyToJSON,
    FollowUpsCreatePriorityOptionResponseBody,
    FollowUpsCreatePriorityOptionResponseBodyFromJSON,
    FollowUpsCreatePriorityOptionResponseBodyToJSON,
    FollowUpsCreateRequestBody,
    FollowUpsCreateRequestBodyFromJSON,
    FollowUpsCreateRequestBodyToJSON,
    FollowUpsCreateResponseBody,
    FollowUpsCreateResponseBodyFromJSON,
    FollowUpsCreateResponseBodyToJSON,
    FollowUpsDisconnectExternalIssueResponseBody,
    FollowUpsDisconnectExternalIssueResponseBodyFromJSON,
    FollowUpsDisconnectExternalIssueResponseBodyToJSON,
    FollowUpsExportRequestBody,
    FollowUpsExportRequestBodyFromJSON,
    FollowUpsExportRequestBodyToJSON,
    FollowUpsExportResponseBody,
    FollowUpsExportResponseBodyFromJSON,
    FollowUpsExportResponseBodyToJSON,
    FollowUpsExternalIssueSetPriorityRequestBody,
    FollowUpsExternalIssueSetPriorityRequestBodyFromJSON,
    FollowUpsExternalIssueSetPriorityRequestBodyToJSON,
    FollowUpsExternalIssueSetPriorityResponseBody,
    FollowUpsExternalIssueSetPriorityResponseBodyFromJSON,
    FollowUpsExternalIssueSetPriorityResponseBodyToJSON,
    FollowUpsListPriorityOptionsResponseBody,
    FollowUpsListPriorityOptionsResponseBodyFromJSON,
    FollowUpsListPriorityOptionsResponseBodyToJSON,
    FollowUpsListResponseBody,
    FollowUpsListResponseBodyFromJSON,
    FollowUpsListResponseBodyToJSON,
    FollowUpsListSuggestedFollowUpsResponseBody,
    FollowUpsListSuggestedFollowUpsResponseBodyFromJSON,
    FollowUpsListSuggestedFollowUpsResponseBodyToJSON,
    FollowUpsMarkAsCompleteResponseBody,
    FollowUpsMarkAsCompleteResponseBodyFromJSON,
    FollowUpsMarkAsCompleteResponseBodyToJSON,
    FollowUpsShowPriorityOptionResponseBody,
    FollowUpsShowPriorityOptionResponseBodyFromJSON,
    FollowUpsShowPriorityOptionResponseBodyToJSON,
    FollowUpsShowResponseBody,
    FollowUpsShowResponseBodyFromJSON,
    FollowUpsShowResponseBodyToJSON,
    FollowUpsSkipSuggestedFollowUpRequestBody,
    FollowUpsSkipSuggestedFollowUpRequestBodyFromJSON,
    FollowUpsSkipSuggestedFollowUpRequestBodyToJSON,
    FollowUpsStatisticsResponseBody,
    FollowUpsStatisticsResponseBodyFromJSON,
    FollowUpsStatisticsResponseBodyToJSON,
    FollowUpsUpdatePriorityOptionRanksRequestBody,
    FollowUpsUpdatePriorityOptionRanksRequestBodyFromJSON,
    FollowUpsUpdatePriorityOptionRanksRequestBodyToJSON,
    FollowUpsUpdatePriorityOptionRanksResponseBody,
    FollowUpsUpdatePriorityOptionRanksResponseBodyFromJSON,
    FollowUpsUpdatePriorityOptionRanksResponseBodyToJSON,
    FollowUpsUpdatePriorityOptionRequestBody,
    FollowUpsUpdatePriorityOptionRequestBodyFromJSON,
    FollowUpsUpdatePriorityOptionRequestBodyToJSON,
    FollowUpsUpdatePriorityOptionResponseBody,
    FollowUpsUpdatePriorityOptionResponseBodyFromJSON,
    FollowUpsUpdatePriorityOptionResponseBodyToJSON,
    FollowUpsUpdateRequestBody,
    FollowUpsUpdateRequestBodyFromJSON,
    FollowUpsUpdateRequestBodyToJSON,
    FollowUpsUpdateResponseBody,
    FollowUpsUpdateResponseBodyFromJSON,
    FollowUpsUpdateResponseBodyToJSON,
} from '../models';

export interface FollowUpsConnectExternalIssueRequest {
    id: string;
    connectExternalIssueRequestBody: FollowUpsConnectExternalIssueRequestBody;
}

export interface FollowUpsCreateRequest {
    createRequestBody: FollowUpsCreateRequestBody;
}

export interface FollowUpsCreatePriorityOptionRequest {
    createPriorityOptionRequestBody: FollowUpsCreatePriorityOptionRequestBody;
}

export interface FollowUpsDestroyRequest {
    id: string;
}

export interface FollowUpsDestroyPriorityOptionRequest {
    id: string;
}

export interface FollowUpsDisconnectExternalIssueRequest {
    id: string;
}

export interface FollowUpsExportRequest {
    id: string;
    exportRequestBody: FollowUpsExportRequestBody;
}

export interface FollowUpsExternalIssueSetPriorityRequest {
    id: string;
    externalIssueSetPriorityRequestBody: FollowUpsExternalIssueSetPriorityRequestBody;
}

export interface FollowUpsListRequest {
    incidentId?: string;
    incidentMode?: FollowUpsListIncidentModeEnum;
    assigneeId?: string;
}

export interface FollowUpsListSuggestedFollowUpsRequest {
    incidentId: string;
}

export interface FollowUpsMarkAsCompleteRequest {
    id: string;
}

export interface FollowUpsShowRequest {
    id: string;
}

export interface FollowUpsShowPriorityOptionRequest {
    id: string;
}

export interface FollowUpsSkipSuggestedFollowUpRequest {
    skipSuggestedFollowUpRequestBody: FollowUpsSkipSuggestedFollowUpRequestBody;
}

export interface FollowUpsStatisticsRequest {
    splitType: FollowUpsStatisticsSplitTypeEnum;
    splitValue?: string;
    id?: any;
    reference?: any;
    fullTextSearch?: any;
    status?: any;
    source?: any;
    slackTeamId?: any;
    severity?: any;
    incidentType?: any;
    participants?: any;
    statusCategory?: any;
    includeDeclinedCanceled?: any;
    includeMerged?: any;
    hasOutstandingFollowUps?: any;
    hasPostmortem?: any;
    hasDebrief?: any;
    hasStatusPage?: any;
    postmortemStatus?: any;
    includePrivate?: any;
    mode?: any;
    createdAt?: any;
    updatedAt?: any;
    incidentRole?: any;
    incidentTimestamp?: any;
    customField?: any;
    optedOutOfPostIncidentFlow?: any;
    dataset?: any;
    groundTruthCausingCodeChangesAny?: any;
    groundTruthCausingCodeChangesVerified?: any;
    groundTruthInvestigationRelevanceAny?: any;
    groundTruthInvestigationRelevance?: any;
    groundTruthInvestigationRelevanceVerified?: any;
    followUpStatus?: any;
    followUpIsExported?: any;
    followUpPriority?: any;
    followUpOwner?: any;
    followUpCreator?: any;
    followUpCreatedAt?: any;
    followUpCompletedAt?: any;
    followUpWithPolicyViolation?: any;
}

export interface FollowUpsUpdateRequest {
    id: string;
    updateRequestBody: FollowUpsUpdateRequestBody;
}

export interface FollowUpsUpdatePriorityOptionRequest {
    id: string;
    updatePriorityOptionRequestBody: FollowUpsUpdatePriorityOptionRequestBody;
}

export interface FollowUpsUpdatePriorityOptionRanksRequest {
    updatePriorityOptionRanksRequestBody: FollowUpsUpdatePriorityOptionRanksRequestBody;
}

/**
 * 
 */
export class FollowUpsApi extends runtime.BaseAPI {

    /**
     * Build a followup scope, expanding root-level references into their child references
     * BuildScope FollowUps
     */
    async followUpsBuildScopeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsBuildScopeResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/follow_up_scope`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsBuildScopeResponseBodyFromJSON(jsonValue));
    }

    /**
     * Build a followup scope, expanding root-level references into their child references
     * BuildScope FollowUps
     */
    async followUpsBuildScope(initOverrides?: RequestInit): Promise<FollowUpsBuildScopeResponseBody> {
        const response = await this.followUpsBuildScopeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Connect a follow-up to an existing issue in an issue tracker
     * ConnectExternalIssue FollowUps
     */
    async followUpsConnectExternalIssueRaw(requestParameters: FollowUpsConnectExternalIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsConnectExternalIssueResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsConnectExternalIssue.');
        }

        if (requestParameters.connectExternalIssueRequestBody === null || requestParameters.connectExternalIssueRequestBody === undefined) {
            throw new runtime.RequiredError('connectExternalIssueRequestBody','Required parameter requestParameters.connectExternalIssueRequestBody was null or undefined when calling followUpsConnectExternalIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/{id}/actions/connect_external_issue`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsConnectExternalIssueRequestBodyToJSON(requestParameters.connectExternalIssueRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsConnectExternalIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Connect a follow-up to an existing issue in an issue tracker
     * ConnectExternalIssue FollowUps
     */
    async followUpsConnectExternalIssue(requestParameters: FollowUpsConnectExternalIssueRequest, initOverrides?: RequestInit): Promise<FollowUpsConnectExternalIssueResponseBody> {
        const response = await this.followUpsConnectExternalIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates an incident follow_up
     * Create FollowUps
     */
    async followUpsCreateRaw(requestParameters: FollowUpsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsCreateResponseBody>> {
        if (requestParameters.createRequestBody === null || requestParameters.createRequestBody === undefined) {
            throw new runtime.RequiredError('createRequestBody','Required parameter requestParameters.createRequestBody was null or undefined when calling followUpsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsCreateRequestBodyToJSON(requestParameters.createRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsCreateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Creates an incident follow_up
     * Create FollowUps
     */
    async followUpsCreate(requestParameters: FollowUpsCreateRequest, initOverrides?: RequestInit): Promise<FollowUpsCreateResponseBody> {
        const response = await this.followUpsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a follow up priority option
     * CreatePriorityOption FollowUps
     */
    async followUpsCreatePriorityOptionRaw(requestParameters: FollowUpsCreatePriorityOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsCreatePriorityOptionResponseBody>> {
        if (requestParameters.createPriorityOptionRequestBody === null || requestParameters.createPriorityOptionRequestBody === undefined) {
            throw new runtime.RequiredError('createPriorityOptionRequestBody','Required parameter requestParameters.createPriorityOptionRequestBody was null or undefined when calling followUpsCreatePriorityOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/priorities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsCreatePriorityOptionRequestBodyToJSON(requestParameters.createPriorityOptionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsCreatePriorityOptionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Create a follow up priority option
     * CreatePriorityOption FollowUps
     */
    async followUpsCreatePriorityOption(requestParameters: FollowUpsCreatePriorityOptionRequest, initOverrides?: RequestInit): Promise<FollowUpsCreatePriorityOptionResponseBody> {
        const response = await this.followUpsCreatePriorityOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Removes an incident follow-up
     * Destroy FollowUps
     */
    async followUpsDestroyRaw(requestParameters: FollowUpsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes an incident follow-up
     * Destroy FollowUps
     */
    async followUpsDestroy(requestParameters: FollowUpsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.followUpsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a priority option
     * DestroyPriorityOption FollowUps
     */
    async followUpsDestroyPriorityOptionRaw(requestParameters: FollowUpsDestroyPriorityOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsDestroyPriorityOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/priorities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a priority option
     * DestroyPriorityOption FollowUps
     */
    async followUpsDestroyPriorityOption(requestParameters: FollowUpsDestroyPriorityOptionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.followUpsDestroyPriorityOptionRaw(requestParameters, initOverrides);
    }

    /**
     * Disconnect an external issue from a follow-up
     * DisconnectExternalIssue FollowUps
     */
    async followUpsDisconnectExternalIssueRaw(requestParameters: FollowUpsDisconnectExternalIssueRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsDisconnectExternalIssueResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsDisconnectExternalIssue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/{id}/actions/disconnect_external_issue`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsDisconnectExternalIssueResponseBodyFromJSON(jsonValue));
    }

    /**
     * Disconnect an external issue from a follow-up
     * DisconnectExternalIssue FollowUps
     */
    async followUpsDisconnectExternalIssue(requestParameters: FollowUpsDisconnectExternalIssueRequest, initOverrides?: RequestInit): Promise<FollowUpsDisconnectExternalIssueResponseBody> {
        const response = await this.followUpsDisconnectExternalIssueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Export a follow-up to an issue tracker
     * Export FollowUps
     */
    async followUpsExportRaw(requestParameters: FollowUpsExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsExportResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsExport.');
        }

        if (requestParameters.exportRequestBody === null || requestParameters.exportRequestBody === undefined) {
            throw new runtime.RequiredError('exportRequestBody','Required parameter requestParameters.exportRequestBody was null or undefined when calling followUpsExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/{id}/actions/export`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsExportRequestBodyToJSON(requestParameters.exportRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsExportResponseBodyFromJSON(jsonValue));
    }

    /**
     * Export a follow-up to an issue tracker
     * Export FollowUps
     */
    async followUpsExport(requestParameters: FollowUpsExportRequest, initOverrides?: RequestInit): Promise<FollowUpsExportResponseBody> {
        const response = await this.followUpsExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the priority of a follow-up with an external issue
     * ExternalIssueSetPriority FollowUps
     */
    async followUpsExternalIssueSetPriorityRaw(requestParameters: FollowUpsExternalIssueSetPriorityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsExternalIssueSetPriorityResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsExternalIssueSetPriority.');
        }

        if (requestParameters.externalIssueSetPriorityRequestBody === null || requestParameters.externalIssueSetPriorityRequestBody === undefined) {
            throw new runtime.RequiredError('externalIssueSetPriorityRequestBody','Required parameter requestParameters.externalIssueSetPriorityRequestBody was null or undefined when calling followUpsExternalIssueSetPriority.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/{id}/actions/external_issue_set_priority`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsExternalIssueSetPriorityRequestBodyToJSON(requestParameters.externalIssueSetPriorityRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsExternalIssueSetPriorityResponseBodyFromJSON(jsonValue));
    }

    /**
     * Set the priority of a follow-up with an external issue
     * ExternalIssueSetPriority FollowUps
     */
    async followUpsExternalIssueSetPriority(requestParameters: FollowUpsExternalIssueSetPriorityRequest, initOverrides?: RequestInit): Promise<FollowUpsExternalIssueSetPriorityResponseBody> {
        const response = await this.followUpsExternalIssueSetPriorityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List incident follow-ups
     * List FollowUps
     */
    async followUpsListRaw(requestParameters: FollowUpsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsListResponseBody>> {
        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        if (requestParameters.incidentMode !== undefined) {
            queryParameters['incident_mode'] = requestParameters.incidentMode;
        }

        if (requestParameters.assigneeId !== undefined) {
            queryParameters['assignee_id'] = requestParameters.assigneeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsListResponseBodyFromJSON(jsonValue));
    }

    /**
     * List incident follow-ups
     * List FollowUps
     */
    async followUpsList(requestParameters: FollowUpsListRequest, initOverrides?: RequestInit): Promise<FollowUpsListResponseBody> {
        const response = await this.followUpsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all follow up priority options for an organisation
     * ListPriorityOptions FollowUps
     */
    async followUpsListPriorityOptionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsListPriorityOptionsResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/priorities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsListPriorityOptionsResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all follow up priority options for an organisation
     * ListPriorityOptions FollowUps
     */
    async followUpsListPriorityOptions(initOverrides?: RequestInit): Promise<FollowUpsListPriorityOptionsResponseBody> {
        const response = await this.followUpsListPriorityOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show any suggested follow-ups for an incident
     * ListSuggestedFollowUps FollowUps
     */
    async followUpsListSuggestedFollowUpsRaw(requestParameters: FollowUpsListSuggestedFollowUpsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsListSuggestedFollowUpsResponseBody>> {
        if (requestParameters.incidentId === null || requestParameters.incidentId === undefined) {
            throw new runtime.RequiredError('incidentId','Required parameter requestParameters.incidentId was null or undefined when calling followUpsListSuggestedFollowUps.');
        }

        const queryParameters: any = {};

        if (requestParameters.incidentId !== undefined) {
            queryParameters['incident_id'] = requestParameters.incidentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/suggested_followups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsListSuggestedFollowUpsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show any suggested follow-ups for an incident
     * ListSuggestedFollowUps FollowUps
     */
    async followUpsListSuggestedFollowUps(requestParameters: FollowUpsListSuggestedFollowUpsRequest, initOverrides?: RequestInit): Promise<FollowUpsListSuggestedFollowUpsResponseBody> {
        const response = await this.followUpsListSuggestedFollowUpsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark a follow-up as complete
     * MarkAsComplete FollowUps
     */
    async followUpsMarkAsCompleteRaw(requestParameters: FollowUpsMarkAsCompleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsMarkAsCompleteResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsMarkAsComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/actions/mark_as_complete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsMarkAsCompleteResponseBodyFromJSON(jsonValue));
    }

    /**
     * Mark a follow-up as complete
     * MarkAsComplete FollowUps
     */
    async followUpsMarkAsComplete(requestParameters: FollowUpsMarkAsCompleteRequest, initOverrides?: RequestInit): Promise<FollowUpsMarkAsCompleteResponseBody> {
        const response = await this.followUpsMarkAsCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show an follow_up
     * Show FollowUps
     */
    async followUpsShowRaw(requestParameters: FollowUpsShowRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsShowResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsShow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsShowResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show an follow_up
     * Show FollowUps
     */
    async followUpsShow(requestParameters: FollowUpsShowRequest, initOverrides?: RequestInit): Promise<FollowUpsShowResponseBody> {
        const response = await this.followUpsShowRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a specific follow up priority option
     * ShowPriorityOption FollowUps
     */
    async followUpsShowPriorityOptionRaw(requestParameters: FollowUpsShowPriorityOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsShowPriorityOptionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsShowPriorityOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/priorities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsShowPriorityOptionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Show a specific follow up priority option
     * ShowPriorityOption FollowUps
     */
    async followUpsShowPriorityOption(requestParameters: FollowUpsShowPriorityOptionRequest, initOverrides?: RequestInit): Promise<FollowUpsShowPriorityOptionResponseBody> {
        const response = await this.followUpsShowPriorityOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark a suggested followup as skipped
     * SkipSuggestedFollowUp FollowUps
     */
    async followUpsSkipSuggestedFollowUpRaw(requestParameters: FollowUpsSkipSuggestedFollowUpRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.skipSuggestedFollowUpRequestBody === null || requestParameters.skipSuggestedFollowUpRequestBody === undefined) {
            throw new runtime.RequiredError('skipSuggestedFollowUpRequestBody','Required parameter requestParameters.skipSuggestedFollowUpRequestBody was null or undefined when calling followUpsSkipSuggestedFollowUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/suggested_followups/actions/skip`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsSkipSuggestedFollowUpRequestBodyToJSON(requestParameters.skipSuggestedFollowUpRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark a suggested followup as skipped
     * SkipSuggestedFollowUp FollowUps
     */
    async followUpsSkipSuggestedFollowUp(requestParameters: FollowUpsSkipSuggestedFollowUpRequest, initOverrides?: RequestInit): Promise<void> {
        await this.followUpsSkipSuggestedFollowUpRaw(requestParameters, initOverrides);
    }

    /**
     * Get summary statistics about follow-ups.
     * Statistics FollowUps
     */
    async followUpsStatisticsRaw(requestParameters: FollowUpsStatisticsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsStatisticsResponseBody>> {
        if (requestParameters.splitType === null || requestParameters.splitType === undefined) {
            throw new runtime.RequiredError('splitType','Required parameter requestParameters.splitType was null or undefined when calling followUpsStatistics.');
        }

        const queryParameters: any = {};

        if (requestParameters.splitType !== undefined) {
            queryParameters['split_type'] = requestParameters.splitType;
        }

        if (requestParameters.splitValue !== undefined) {
            queryParameters['split_value'] = requestParameters.splitValue;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.reference !== undefined) {
            queryParameters['reference'] = requestParameters.reference;
        }

        if (requestParameters.fullTextSearch !== undefined) {
            queryParameters['full_text_search'] = requestParameters.fullTextSearch;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.slackTeamId !== undefined) {
            queryParameters['slack_team_id'] = requestParameters.slackTeamId;
        }

        if (requestParameters.severity !== undefined) {
            queryParameters['severity'] = requestParameters.severity;
        }

        if (requestParameters.incidentType !== undefined) {
            queryParameters['incident_type'] = requestParameters.incidentType;
        }

        if (requestParameters.participants !== undefined) {
            queryParameters['participants'] = requestParameters.participants;
        }

        if (requestParameters.statusCategory !== undefined) {
            queryParameters['status_category'] = requestParameters.statusCategory;
        }

        if (requestParameters.includeDeclinedCanceled !== undefined) {
            queryParameters['include_declined_canceled'] = requestParameters.includeDeclinedCanceled;
        }

        if (requestParameters.includeMerged !== undefined) {
            queryParameters['include_merged'] = requestParameters.includeMerged;
        }

        if (requestParameters.hasOutstandingFollowUps !== undefined) {
            queryParameters['has_outstanding_follow_ups'] = requestParameters.hasOutstandingFollowUps;
        }

        if (requestParameters.hasPostmortem !== undefined) {
            queryParameters['has_postmortem'] = requestParameters.hasPostmortem;
        }

        if (requestParameters.hasDebrief !== undefined) {
            queryParameters['has_debrief'] = requestParameters.hasDebrief;
        }

        if (requestParameters.hasStatusPage !== undefined) {
            queryParameters['has_status_page'] = requestParameters.hasStatusPage;
        }

        if (requestParameters.postmortemStatus !== undefined) {
            queryParameters['postmortem_status'] = requestParameters.postmortemStatus;
        }

        if (requestParameters.includePrivate !== undefined) {
            queryParameters['include_private'] = requestParameters.includePrivate;
        }

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.createdAt !== undefined) {
            queryParameters['created_at'] = requestParameters.createdAt;
        }

        if (requestParameters.updatedAt !== undefined) {
            queryParameters['updated_at'] = requestParameters.updatedAt;
        }

        if (requestParameters.incidentRole !== undefined) {
            queryParameters['incident_role'] = requestParameters.incidentRole;
        }

        if (requestParameters.incidentTimestamp !== undefined) {
            queryParameters['incident_timestamp'] = requestParameters.incidentTimestamp;
        }

        if (requestParameters.customField !== undefined) {
            queryParameters['custom_field'] = requestParameters.customField;
        }

        if (requestParameters.optedOutOfPostIncidentFlow !== undefined) {
            queryParameters['opted_out_of_post_incident_flow'] = requestParameters.optedOutOfPostIncidentFlow;
        }

        if (requestParameters.dataset !== undefined) {
            queryParameters['dataset'] = requestParameters.dataset;
        }

        if (requestParameters.groundTruthCausingCodeChangesAny !== undefined) {
            queryParameters['ground_truth_causing_code_changes_any'] = requestParameters.groundTruthCausingCodeChangesAny;
        }

        if (requestParameters.groundTruthCausingCodeChangesVerified !== undefined) {
            queryParameters['ground_truth_causing_code_changes_verified'] = requestParameters.groundTruthCausingCodeChangesVerified;
        }

        if (requestParameters.groundTruthInvestigationRelevanceAny !== undefined) {
            queryParameters['ground_truth_investigation_relevance_any'] = requestParameters.groundTruthInvestigationRelevanceAny;
        }

        if (requestParameters.groundTruthInvestigationRelevance !== undefined) {
            queryParameters['ground_truth_investigation_relevance'] = requestParameters.groundTruthInvestigationRelevance;
        }

        if (requestParameters.groundTruthInvestigationRelevanceVerified !== undefined) {
            queryParameters['ground_truth_investigation_relevance_verified'] = requestParameters.groundTruthInvestigationRelevanceVerified;
        }

        if (requestParameters.followUpStatus !== undefined) {
            queryParameters['follow_up_status'] = requestParameters.followUpStatus;
        }

        if (requestParameters.followUpIsExported !== undefined) {
            queryParameters['follow_up_is_exported'] = requestParameters.followUpIsExported;
        }

        if (requestParameters.followUpPriority !== undefined) {
            queryParameters['follow_up_priority'] = requestParameters.followUpPriority;
        }

        if (requestParameters.followUpOwner !== undefined) {
            queryParameters['follow_up_owner'] = requestParameters.followUpOwner;
        }

        if (requestParameters.followUpCreator !== undefined) {
            queryParameters['follow_up_creator'] = requestParameters.followUpCreator;
        }

        if (requestParameters.followUpCreatedAt !== undefined) {
            queryParameters['follow_up_created_at'] = requestParameters.followUpCreatedAt;
        }

        if (requestParameters.followUpCompletedAt !== undefined) {
            queryParameters['follow_up_completed_at'] = requestParameters.followUpCompletedAt;
        }

        if (requestParameters.followUpWithPolicyViolation !== undefined) {
            queryParameters['follow_up_with_policy_violation'] = requestParameters.followUpWithPolicyViolation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/follow_ups/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsStatisticsResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get summary statistics about follow-ups.
     * Statistics FollowUps
     */
    async followUpsStatistics(requestParameters: FollowUpsStatisticsRequest, initOverrides?: RequestInit): Promise<FollowUpsStatisticsResponseBody> {
        const response = await this.followUpsStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a follow-up
     * Update FollowUps
     */
    async followUpsUpdateRaw(requestParameters: FollowUpsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsUpdateResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsUpdate.');
        }

        if (requestParameters.updateRequestBody === null || requestParameters.updateRequestBody === undefined) {
            throw new runtime.RequiredError('updateRequestBody','Required parameter requestParameters.updateRequestBody was null or undefined when calling followUpsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsUpdateRequestBodyToJSON(requestParameters.updateRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsUpdateResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a follow-up
     * Update FollowUps
     */
    async followUpsUpdate(requestParameters: FollowUpsUpdateRequest, initOverrides?: RequestInit): Promise<FollowUpsUpdateResponseBody> {
        const response = await this.followUpsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a follow up priority option
     * UpdatePriorityOption FollowUps
     */
    async followUpsUpdatePriorityOptionRaw(requestParameters: FollowUpsUpdatePriorityOptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsUpdatePriorityOptionResponseBody>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling followUpsUpdatePriorityOption.');
        }

        if (requestParameters.updatePriorityOptionRequestBody === null || requestParameters.updatePriorityOptionRequestBody === undefined) {
            throw new runtime.RequiredError('updatePriorityOptionRequestBody','Required parameter requestParameters.updatePriorityOptionRequestBody was null or undefined when calling followUpsUpdatePriorityOption.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/priorities/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsUpdatePriorityOptionRequestBodyToJSON(requestParameters.updatePriorityOptionRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsUpdatePriorityOptionResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update a follow up priority option
     * UpdatePriorityOption FollowUps
     */
    async followUpsUpdatePriorityOption(requestParameters: FollowUpsUpdatePriorityOptionRequest, initOverrides?: RequestInit): Promise<FollowUpsUpdatePriorityOptionResponseBody> {
        const response = await this.followUpsUpdatePriorityOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of existing priority options
     * UpdatePriorityOptionRanks FollowUps
     */
    async followUpsUpdatePriorityOptionRanksRaw(requestParameters: FollowUpsUpdatePriorityOptionRanksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FollowUpsUpdatePriorityOptionRanksResponseBody>> {
        if (requestParameters.updatePriorityOptionRanksRequestBody === null || requestParameters.updatePriorityOptionRanksRequestBody === undefined) {
            throw new runtime.RequiredError('updatePriorityOptionRanksRequestBody','Required parameter requestParameters.updatePriorityOptionRanksRequestBody was null or undefined when calling followUpsUpdatePriorityOptionRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/follow_ups/priorities/update_ranks`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FollowUpsUpdatePriorityOptionRanksRequestBodyToJSON(requestParameters.updatePriorityOptionRanksRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FollowUpsUpdatePriorityOptionRanksResponseBodyFromJSON(jsonValue));
    }

    /**
     * Update ranks of existing priority options
     * UpdatePriorityOptionRanks FollowUps
     */
    async followUpsUpdatePriorityOptionRanks(requestParameters: FollowUpsUpdatePriorityOptionRanksRequest, initOverrides?: RequestInit): Promise<FollowUpsUpdatePriorityOptionRanksResponseBody> {
        const response = await this.followUpsUpdatePriorityOptionRanksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum FollowUpsListIncidentModeEnum {
    Standard = 'standard',
    Retrospective = 'retrospective',
    Test = 'test',
    Tutorial = 'tutorial'
}
/**
    * @export
    * @enum {string}
    */
export enum FollowUpsStatisticsSplitTypeEnum {
    CustomField = 'custom_field',
    FollowUpPriority = 'follow_up_priority',
    IncidentType = 'incident_type',
    Severity = 'severity',
    Status = 'status',
    AssigneeCatalog = 'assignee_catalog'
}
