/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessorBackfillPreview,
    ProcessorBackfillPreviewFromJSON,
    ProcessorBackfillPreviewFromJSONTyped,
    ProcessorBackfillPreviewToJSON,
} from './ProcessorBackfillPreview';

/**
 * 
 * @export
 * @interface AIStaffPreviewProcessorBackfillResponseBody
 */
export interface AIStaffPreviewProcessorBackfillResponseBody {
    /**
     * 
     * @type {ProcessorBackfillPreview}
     * @memberof AIStaffPreviewProcessorBackfillResponseBody
     */
    preview?: ProcessorBackfillPreview;
}

export function AIStaffPreviewProcessorBackfillResponseBodyFromJSON(json: any): AIStaffPreviewProcessorBackfillResponseBody {
    return AIStaffPreviewProcessorBackfillResponseBodyFromJSONTyped(json, false);
}

export function AIStaffPreviewProcessorBackfillResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffPreviewProcessorBackfillResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preview': !exists(json, 'preview') ? undefined : ProcessorBackfillPreviewFromJSON(json['preview']),
    };
}

export function AIStaffPreviewProcessorBackfillResponseBodyToJSON(value?: AIStaffPreviewProcessorBackfillResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'preview': ProcessorBackfillPreviewToJSON(value.preview),
    };
}

