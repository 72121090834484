import { ErrorModal } from "@incident-ui/ErrorModal/ErrorModal";
import { useFormContext } from "react-hook-form";

import { WorkflowsDeploySettingsModal } from "../../advanced-settings/WorkflowsDeploySettingsModal";
import { WorkflowFormData } from "../../common/types";
import { WorkflowsDeletionConfirmationModal } from "../../list/WorkflowsDeletionConfirmationModal";
import { WorkflowsConfigureTestModal } from "../../test/WorkflowsConfigureTestModal";
import { WorkflowsTriggerWarningModal } from "../../triggers/WorkflowsTriggerWarningModal";
import { useWorkflowsMutations } from "../hooks/useMutationController";
import { useWorkflowsTrigger } from "../hooks/useTriggerController";
import {
  useWorkflowsViewState,
  WorkflowModalState,
} from "../hooks/useViewController";
import { useWorkflowsDeps } from "../WorkflowsFormContext";

export const WorkflowsCreateEditModals = (): React.ReactElement | null => {
  const { onEditTrigger } = useWorkflowsTrigger();

  const { resources, workflow, isDraft, trigger } = useWorkflowsDeps();
  const { onCloseModal, modalState } = useWorkflowsViewState();
  const {
    publishWorkflow,
    deleteWorkflow,
    disableWorkflow,
    state: { isPublishing, isDeleting, isDisabling },
  } = useWorkflowsMutations();
  const formMethods = useFormContext<WorkflowFormData>();

  const onDeleteWorkflow = async () => {
    // This intercept to reset the form before deleting the workflow
    // prevents the unsaved changes modal from showing up
    formMethods.reset();
    await deleteWorkflow();
  };

  switch (modalState) {
    case WorkflowModalState.None:
      return null;
    case WorkflowModalState.ConfigureTest:
      if (!workflow) {
        return (
          // Should never happen, but lets show a human-readable error if it does
          <ErrorModal
            onClose={onCloseModal}
            description="Cannot test a workflow that hasn't been saved"
          />
        );
      }
      return (
        <WorkflowsConfigureTestModal
          resources={resources}
          onClose={onCloseModal}
          workflow={workflow}
        />
      );
    case WorkflowModalState.DeploySettings:
      return (
        <WorkflowsDeploySettingsModal
          formMethods={formMethods}
          onSubmit={publishWorkflow}
          onClose={onCloseModal}
          // Ask for pending run strategies if previous version of the workflow had a delay
          showPendingRunStrategies={!isDraft && !!workflow?.delay}
          triggerLabel={trigger?.label}
          triggerName={trigger?.name}
          saving={isPublishing}
          showRunsOnIncidents={isDraft && !!workflow?.id}
        />
      );

    case WorkflowModalState.DeleteConfirmation:
      if (!workflow?.id) {
        // Should never happen, but lets show a human-readable error if it does
        return (
          <ErrorModal
            onClose={onCloseModal}
            description="Cannot delete a workflow that hasn't been saved"
          />
        );
      }

      return (
        <WorkflowsDeletionConfirmationModal
          onClose={onCloseModal}
          onConfirm={onDeleteWorkflow}
          onDisable={disableWorkflow}
          workflowId={workflow.id}
          workflowName={workflow.name}
          isDeleting={isDeleting}
          isDisabled={!!workflow.disabled_at}
          isDisabling={isDisabling}
          isDraft={isDraft}
        />
      );
    case WorkflowModalState.ChangeTriggerWarning:
      return (
        <WorkflowsTriggerWarningModal
          onClose={onCloseModal}
          onConfirm={() => {
            onEditTrigger();
            onCloseModal();
          }}
        />
      );
  }

  return null;
};
