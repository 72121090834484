import {
  CatalogType,
  EscalationPathNodeTypeEnum,
  EscalationPathTargetTypeEnum,
  ManagementMeta,
  PartialEntryPayload,
  Schedule,
} from "@incident-io/api";
import { CreateEditFormProps, Mode } from "@incident-shared/forms/v2/formsv2";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  defaultManagementMeta,
  isTerraform,
} from "@incident-shared/management-meta/utils";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerProps,
  DrawerTitle,
  DrawerTitleTheme,
  Mode as DrawerMode,
} from "@incident-ui/Drawer/Drawer";
import { ToastSideEnum, ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { AnimatePresence } from "framer-motion";
import _ from "lodash";
import { createContext, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI, useAPIMutation, ValidationErrors } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { useRevalidate } from "src/utils/use-revalidate";

import { ClientType, useClient } from "../../../contexts/ClientContext";
import { useMutation } from "../../../utils/fetchData";
import { useQueryParams } from "../../../utils/query-params";
import { PrioritiesCreateEditDrawer } from "../../alerts/priorities/PrioritiesCreateEditDrawer";
import { OnCallPromotionConfirmationModal } from "../../legacy/on-call/common/OnCallPromotionConfirmationModal";
import { useOnCallPromotionState } from "../../legacy/on-call/common/useOnCallPromotionState";
import { useHydratedUserCache } from "../../legacy/on-call/schedules/common/useHydratedUserCache";
import { generateDefaultFormData } from "../common/default";
import {
  escalationPathCatalogEntriesToFormData,
  escalationPathFormDataToCreatePayload,
  escalationPathFormDataToUpdatePayload,
  escalationPathResponseToFormData,
} from "../common/marshall";
import { useHydratedTargets } from "../common/options";
import {
  EscalationPathCatalogBindingData,
  EscalationPathFormData,
  PathNode,
} from "../common/types";
import { EscalationPathNodeEditor } from "../node-editor/EscalationPathNodeEditor";
import { ZoomProvider } from "../node-editor/ZoomContext";
import { EscalationPathCatalogSetupWidget } from "./EscalationPathCatalogSetupWidget";
import { EscalationPathCopyToTerraformDrawer } from "./EscalationPathCopyToTerraform";
import { EscalationPathCreateEditSplitButton } from "./EscalationPathCreateEditSplitButton";
import { EscalationPathExternalCatalogCalloutModal } from "./EscalationPathExternalCatalogModal";
import {
  workingHoursAreUsed,
  WorkingHoursInput,
} from "./EscalationPathWorkingHoursInput";
import {
  createEngineParamBindingPayload,
  isExternallyManagedManualCatalogType,
} from "./helpers";
import { CatalogSetup, useCatalogSetup } from "./useCatalogSetup";

export const EscalationPathCreateEditDrawer = ({
  onClose,
  escalationPathId,
}: {
  onClose: () => void;
  escalationPathId?: string;
}) => {
  // Get the clone ID from the URL, if present.
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cloneId = queryParams.get("clone");

  // Fetch existing escalation path
  const {
    data: response,
    isLoading: escalationPathLoading,
    error: escalationPathError,
  } = useAPI(escalationPathId || cloneId ? "escalationPathsShow" : null, {
    id: (escalationPathId || cloneId) ?? "",
  });

  // Hydrate the targets in the escalation path
  const { targets, targetsLoading, targetsError } = useHydratedTargets(
    response?.escalation_path?.path,
  );

  // Load up data for us to link escalation paths to Catalog.
  const catalogSetup = useCatalogSetup({
    escalationPathId: escalationPathId,
  });

  const sharedDrawerProps: Omit<DrawerProps, "children"> = {
    onClose,
    width: "full",
  };

  // Handle error being returned from API.
  const error = escalationPathError || targetsError;
  if (error)
    return (
      <Drawer {...sharedDrawerProps}>
        <GenericErrorMessage error={error} />
      </Drawer>
    );

  if (!catalogSetup) {
    return (
      <Drawer {...sharedDrawerProps}>
        <Loader />
      </Drawer>
    );
  }

  // Marshall the escalation path data into the form data
  const escalationPath = escalationPathResponseToFormData(
    response?.escalation_path,
    targets,
  );

  // Add a suffix to the name if we're cloning.
  if (cloneId && escalationPath) {
    escalationPath.name = `${escalationPath.name} - Copy`;
  }

  const initialData: CreateEditFormProps<EscalationPathFormData> =
    escalationPath
      ? { mode: Mode.Edit, initialData: escalationPath }
      : { mode: Mode.Create };

  // If we have a clone ID, then we're in create mode, not edit.
  if (cloneId) {
    initialData.mode = Mode.Create;
  }

  // Determine the mode of the drawer, using an enum that includes duplicate.
  let mode = DrawerMode.Create;
  if (cloneId) {
    mode = DrawerMode.Duplicate;
  } else if (escalationPathId) {
    mode = DrawerMode.Edit;
  }

  return (
    <EscalationPathDrawer
      mode={mode}
      onClose={onClose}
      initialData={initialData}
      managementMeta={response?.management_meta ?? defaultManagementMeta()}
      catalogSetup={catalogSetup}
      loading={escalationPathLoading || targetsLoading}
    />
  );
};

const EscalationPathDrawer = ({
  mode,
  onClose,
  initialData,
  managementMeta,
  catalogSetup,
  loading,
}: {
  mode: DrawerMode;
  onClose: () => void;
  initialData: CreateEditFormProps<EscalationPathFormData>;
  managementMeta: ManagementMeta;
  catalogSetup: CatalogSetup;
  loading: boolean;
}) => {
  const sharedDrawerProps: Omit<DrawerProps, "children"> = {
    onClose,
    width: "full",
  };

  const [showPrioritiesDrawer, setShowPrioritiesDrawer] = useState(false);

  const catalogTypeToTeamEntries = escalationPathCatalogEntriesToFormData(
    catalogSetup?.catalogRelations,
  );

  const {
    data: priorities,
    isLoading: prioritiesLoading,
    error: errorPriorities,
  } = useAPI("alertsListPriorities", {});

  const scheduleId = useQueryParams().get("schedule_id");
  const {
    data: scheduleData,
    isLoading: scheduleLoading,
    error: scheduleError,
  } = useAPI(scheduleId ? "schedulesShow" : null, { id: scheduleId ?? "" });

  const showToast = useToast();
  const [showTerraformDrawer, setShowTerraformDrawer] =
    useState<boolean>(false);

  const closeTerraformDrawer = () => {
    setShowTerraformDrawer(false);
    onClose();
    showToast({
      title: "",
      description: "Copy your escalation path configuration to Terraform",
      theme: ToastTheme.Info,
      toastSide: ToastSideEnum.Bottom,
    });
  };

  if (errorPriorities || scheduleError) {
    return <GenericErrorMessage error={errorPriorities || scheduleError} />;
  }

  if (prioritiesLoading || scheduleLoading) {
    return <Loader />;
  }

  // The 'highest' rank priority is actually rank 0, go figure!
  const highestPriority = _.minBy(priorities?.priorities, "rank");
  if (!highestPriority) {
    throw new Error("no alert priorities found");
  }

  return (
    <>
      <Drawer
        {...sharedDrawerProps}
        className="!overflow-hidden"
        warnWhenDirty
        isInBackground={showPrioritiesDrawer}
      >
        {loading ? (
          <Loader />
        ) : (
          <DrawersContext.Provider
            value={{
              openPrioritiesDrawer: () => setShowPrioritiesDrawer(true),
              openTerraformDrawer: () => setShowTerraformDrawer(true),
              closeTerraformDrawer,
              terraformDrawerOpen: showTerraformDrawer,
            }}
          >
            <EscalationPathFormInner
              mode={mode}
              onClose={onClose}
              initialData={initialData}
              initialCatalogData={catalogTypeToTeamEntries}
              catalogSetup={catalogSetup}
              highestPriorityId={highestPriority.id}
              createForSchedule={scheduleData?.schedule}
              managementMeta={managementMeta}
            />
          </DrawersContext.Provider>
        )}
      </Drawer>
      <AnimatePresence>
        {showPrioritiesDrawer && (
          <PrioritiesCreateEditDrawer
            onClose={() => setShowPrioritiesDrawer(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const DrawersContext = createContext<{
  openPrioritiesDrawer: () => void;
  openTerraformDrawer: () => void;
  closeTerraformDrawer: () => void;
  terraformDrawerOpen: boolean;
}>({
  openPrioritiesDrawer: () => null,
  openTerraformDrawer: () => null,
  closeTerraformDrawer: () => null,
  terraformDrawerOpen: false,
});

export const useEscalationPathDrawers = () => useContext(DrawersContext);

const EscalationPathFormInner = ({
  mode,
  onClose,
  initialData,
  initialCatalogData,
  catalogSetup,
  highestPriorityId,
  createForSchedule,
  managementMeta,
}: {
  mode: DrawerMode;
  onClose: () => void;
  initialData: CreateEditFormProps<EscalationPathFormData>;
  initialCatalogData: EscalationPathCatalogBindingData;
  catalogSetup: CatalogSetup;
  highestPriorityId: string;
  createForSchedule?: Schedule;
  managementMeta: ManagementMeta;
}) => {
  const showToast = useToast();
  const { identity } = useIdentity();
  const apiClient = useClient();
  const {
    openPrioritiesDrawer,
    openTerraformDrawer,
    closeTerraformDrawer,
    terraformDrawerOpen,
  } = useEscalationPathDrawers();

  // Form state.
  const formMethods = useForm<EscalationPathFormData>({
    defaultValues: {
      ...(mode === DrawerMode.Create
        ? generateDefaultFormData(
            identity,
            highestPriorityId,
            createForSchedule,
          )
        : initialData.initialData),
    },
  });

  const catalogFormMethods = useForm<EscalationPathCatalogBindingData>({
    defaultValues: {
      ...initialCatalogData,
    },
  });

  const [id, name, nodes] = formMethods.watch(["id", "name", "nodes"]);
  const escalationPathCatalogBindings = catalogFormMethods.watch();
  const usersInPath = Object.values(nodes)
    .flatMap((node) => node.data.level?.targets || [])
    .filter((target) => target.type === "user" && target.value !== "NOBODY")
    .map((target) => target.value);
  const userCache = useHydratedUserCache(usersInPath);

  // Hold state for the user promotion modal.
  const { promotionState, setPromotionState, handlePromotion } =
    useOnCallPromotionState<EscalationPathFormData>({
      users: usersInPath,
      userCache,
    });

  // Hold state for attaching this escalation path to an externally managed catalog type
  const [showExternalCatalogCallout, setShowExternalCatalogCallout] = useState<{
    escalationPathId: string;
    open: boolean;
  }>({ escalationPathId: formMethods.watch("id") || "", open: false });

  // If you change any fields, stop showing the existing errors
  formMethods.watch(
    useCallback(() => {
      if (Object.keys(formMethods.formState.errors).length > 0) {
        formMethods.clearErrors();
      }
    }, [formMethods]),
  );

  const [linkToCatalogEntries] = useMutation(
    async ({
      formData,
      escalationPathId,
    }: {
      formData: EscalationPathCatalogBindingData;
      escalationPathId: string;
    }) => {
      // This function will also not do anything for non-manual catalog types but let's be double careful.
      await updateCatalogEntriesForEscalationPath(
        apiClient,
        escalationPathId,
        formData,
        catalogSetup.escalationCatalogTypes?.filter(
          (type) => type.source_repo_url === undefined,
        ) || [],
      );
    },
    {
      onError: () => {
        showToast({
          title: "Failed to link this escalation path to the catalog",
          theme: ToastTheme.Error,
          toastSide: ToastSideEnum.Bottom,
        });
      },
    },
  );

  // API function to create an escalation path.
  const { trigger: createEscalationPath, fieldErrors: createFieldErrors } =
    useAPIMutation(
      "escalationPathsList",
      undefined,
      async (
        apiClient,
        data: EscalationPathFormData & { userIdsToPromote: string[] },
      ) => {
        await apiClient.escalationPathsCreate(
          escalationPathFormDataToCreatePayload(
            data,
            identity,
            data.userIdsToPromote,
          ),
        );
      },
      {
        onSuccess: () => {
          showToast({
            title: "Escalation path created",
            theme: ToastTheme.Success,
            toastSide: ToastSideEnum.Bottom,
          });
        },
        onError: () => {
          showToast({
            title: "Failed to create the escalation path",
            theme: ToastTheme.Error,
            toastSide: ToastSideEnum.Bottom,
          });
        },
        showErrorToast: "Failed to create the escalation path",
      },
    );

  const refetchPaths = useRevalidate([
    "escalationPathsList",
    "escalationPathsListExternal",
  ]);

  // API function to update an escalation path.
  const { trigger: updateEscalationPath, fieldErrors: updateFieldErrors } =
    useAPIMutation(
      "escalationPathsShow",
      { id },
      async (
        apiClient,
        data: EscalationPathFormData & { userIdsToPromote: string[] },
      ) => {
        await apiClient.escalationPathsUpdate(
          escalationPathFormDataToUpdatePayload(
            id || "",
            data,
            identity,
            data.userIdsToPromote,
          ),
        );
      },
      {
        onSuccess: () => {
          showToast({
            title: "Escalation path updated",
            theme: ToastTheme.Success,
            toastSide: ToastSideEnum.Bottom,
          });
        },
        setError: formMethods.setError,
        showErrorToast: "Failed to update the escalation path",
        onError: () => {
          showToast({
            title: "Failed to update the escalation path",
            theme: ToastTheme.Error,
            toastSide: ToastSideEnum.Bottom,
          });
        },
      },
    );

  const schedules = getScheduleTargets(nodes);

  const {
    data: { schedules: allSchedules },
  } = useAPI("schedulesList", undefined, {
    fallbackData: { schedules: [], users: [] },
  });
  const hydratedSchedules = allSchedules.filter((schedule) =>
    schedules.includes(schedule.id),
  );

  // We'll show a callout about a mix of schedule and escalation path working hours being used when:
  // - You use working hours in your escalation path
  // - You use working intervals in all of your schedule rotations
  // If one of your rotas is 24/7, that's fine as you can't target a rota directly.
  const showWorkingHoursCallout =
    workingHoursAreUsed(nodes) &&
    hydratedSchedules.some(
      (schedule) =>
        schedule.config?.rotations.every(
          (rota) => rota.working_intervals.length > 0,
        ),
    );

  // Try to save, or fallback to the on-call promotion modal.
  const savePathOrPromoteUsers = async (formData: EscalationPathFormData) => {
    const valid = await formMethods.trigger();
    if (!valid) {
      return;
    }

    const { shouldPromote, userIdsToPromote } = await handlePromotion(formData);
    if (shouldPromote) {
      onSubmit(formData, userIdsToPromote);
    }
  };

  const onSubmit = async (
    formData: EscalationPathFormData,
    userIdsToPromote: string[] = [],
  ) => {
    let escalationPathId: string;
    if (initialData.mode === Mode.Edit) {
      escalationPathId = await updateEscalationPath({
        ...formData,
        userIdsToPromote,
      }).then((r) => r.escalation_path.id);
    } else {
      const escalationPaths = await createEscalationPath({
        ...formData,
        userIdsToPromote,
      }).then((r) => r.escalation_paths);
      const newlyCreatedPath = escalationPaths.find(
        (path) => path.name === name,
      );

      if (!newlyCreatedPath) {
        throw new Error(
          "Unreachable: failed to find newly created escalation path.",
        );
      }
      escalationPathId = newlyCreatedPath.id;
    }

    if (
      escalationPathId &&
      Object.values(catalogFormMethods.formState.dirtyFields).length > 0
    ) {
      // If you've attached this escalation path to some catalog entries, we need to update them.
      // linkToCatalogEntries will only do this for manual catalog types that are not managed externally.
      linkToCatalogEntries({
        formData: escalationPathCatalogBindings,
        escalationPathId,
      });

      // Then, we check if you've tried to attach this escalation path to some catalog types that are
      // managed externally, in which case we'll show you a helpful modal.
      // 1. Grab IDs of the catalog types that are managed externally.
      const externalEscalationCatalogTypeIds = new Set(
        catalogSetup.escalationCatalogTypes
          ?.filter((type) => isExternallyManagedManualCatalogType(type))
          .map((type) => type.id),
      );
      // 2. Grab any bindings that have been set for these catalog types
      const hasExternalCatalogBindings = Object.entries(
        escalationPathCatalogBindings,
      )?.some(
        ([key, bindings]) =>
          bindings?.length > 0 && externalEscalationCatalogTypeIds.has(key),
      );
      if (hasExternalCatalogBindings) {
        setShowExternalCatalogCallout({ escalationPathId, open: true });
      } else {
        refetchPaths();
        onClose();
      }
    } else {
      refetchPaths();
      onClose();
    }
  };

  return (
    <>
      <div className="flex flex-col h-full !overflow-hidden">
        <DrawerTitle
          icon={IconEnum.EscalationPath}
          color={ColorPaletteEnum.Slate}
          title={title[initialData.mode]}
          theme={DrawerTitleTheme.Bordered}
          onClose={onClose}
          closeIcon={IconEnum.Close}
          sticky
          compact
          className={"p-0"}
          secondaryAccessory={
            initialData.initialData?.id ? (
              <Tooltip content="View in catalog">
                <Button
                  analyticsTrackingId={null}
                  size={BadgeSize.Medium}
                  icon={IconEnum.Book}
                  title=""
                  href={`/catalog/EscalationPath/${initialData.initialData.id}`}
                />
              </Tooltip>
            ) : undefined
          }
        />
        <DrawerBody className={"p-0 gap-0 overflow-y-hidden"}>
          <ReactFlowProvider>
            <EscalationPathErrorContext.Provider
              value={updateFieldErrors ?? createFieldErrors ?? null}
            >
              <OnCallPromotionConfirmationModal
                noun="escalation path"
                state={promotionState}
                onClose={() => setPromotionState(null)}
                onSubmit={onSubmit}
              />
              <Form.Root
                id="escalation-path-create-edit-form"
                onSubmit={savePathOrPromoteUsers}
                formMethods={formMethods}
                outerClassName="h-full"
                innerClassName="flex h-full space-y-0"
                loadingWrapperClassName="h-full"
                warnWhenDirty
              >
                <div
                  className={tcx(
                    "h-full flex flex-col space-y-6 p-6 min-w-[400px] w-[30%] bg-white border-r-[1px] border-stroke",
                    "overflow-y-auto justify-between",
                  )}
                >
                  <div className="flex flex-col gap-10">
                    <div className={"flex flex-col gap-6"}>
                      <InputV2
                        formMethods={formMethods}
                        name="name"
                        label="Escalation path name"
                        placeholder="Security team"
                        required
                      />
                      <EscalationPathCatalogSetupWidget
                        mode={mode}
                        initialData={initialCatalogData}
                        formMethods={catalogFormMethods}
                        catalogSetup={catalogSetup}
                      />
                    </div>
                    <div className={"flex flex-col gap-6"}>
                      <WorkingHoursInput />
                      {showWorkingHoursCallout && (
                        <Callout
                          theme={CalloutTheme.Warning}
                          title={
                            "You're already using working hours in your schedules"
                          }
                          subtitle={
                            "If we attempt to reach a schedule outside its working hours as part of this path, your escalation could expire earlier than you'd expect. We recommend only using working hours in one or the other."
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="text-xs text-content-tertiary">
                      Useful links
                    </div>
                    <ul className="flex flex-col gap-1">
                      <li>
                        <Button
                          theme={ButtonTheme.Naked}
                          className="text-sm-med shadow-button-inner"
                          analyticsTrackingId="escalation-path-docs"
                          href="https://help.incident.io/articles/7922672863-smart-escalation-paths"
                          openInNewTab
                        >
                          Read the help centre article
                        </Button>
                      </li>
                      <li>
                        <Button
                          theme={ButtonTheme.Naked}
                          className="text-sm-med shadow-button-inner"
                          analyticsTrackingId="escalation-path-notification-preferences"
                          href="/user-preferences/on-call-notifications"
                          openInNewTab
                        >
                          Notification preferences
                        </Button>
                      </li>
                      <li>
                        <Button
                          theme={ButtonTheme.Naked}
                          className="text-sm-med shadow-button-inner"
                          analyticsTrackingId="escalation-path-priorities-settings"
                          onClick={openPrioritiesDrawer}
                        >
                          Edit alert priorities
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>

                <ZoomProvider>
                  <EscalationPathNodeEditor />
                </ZoomProvider>
                {showExternalCatalogCallout.open && (
                  <EscalationPathExternalCatalogCalloutModal
                    mode={mode}
                    onClose={() => {
                      setShowExternalCatalogCallout({
                        escalationPathId: "",
                        open: false,
                      });
                      refetchPaths();
                      onClose();
                    }}
                    escalationPathId={
                      showExternalCatalogCallout.escalationPathId
                    }
                    escalationCatalogTypes={
                      catalogSetup.escalationCatalogTypes || []
                    }
                    escalationPathCatalogBindings={
                      escalationPathCatalogBindings
                    }
                  />
                )}
              </Form.Root>
            </EscalationPathErrorContext.Provider>
          </ReactFlowProvider>
        </DrawerBody>
        <DrawerFooter className="flex justify-end">
          {initialData.mode !== Mode.Create && isTerraform(managementMeta) ? (
            <div className={"flex flex-center gap-0.5 mr-6"}>
              {/* Export to Terraform button */}
              <Button
                analyticsTrackingId="ep-sync-with-terraform"
                icon={IconEnum.Terraform}
                iconProps={{
                  className: "!text-terraform-purple-500",
                }}
                onClick={() =>
                  formMethods
                    .trigger()
                    .then((valid) => valid && openTerraformDrawer())
                }
              >
                Export
              </Button>
            </div>
          ) : (
            <EscalationPathCreateEditSplitButton
              mode={initialData.mode}
              formMethods={formMethods}
            />
          )}
        </DrawerFooter>
      </div>
      {terraformDrawerOpen && (
        <EscalationPathCopyToTerraformDrawer
          formMethods={formMethods}
          managementMeta={managementMeta}
          onClose={closeTerraformDrawer}
          isOpen={terraformDrawerOpen}
          resourceID={initialData.initialData?.id}
        />
      )}
    </>
  );
};

// Conditional copy for drawer title and button text.
const title = {
  create: "Create a new escalation path",
  edit: "Edit escalation path",
  duplicate: "Duplicate escalation path",
};

const updateCatalogEntriesForEscalationPath = async (
  apiClient: ClientType,
  escalationPathId: string,
  catalogBindings: EscalationPathCatalogBindingData,
  escalationTypes: CatalogType[],
) => {
  for (const [catalogTypeID, bindings] of Object.entries(catalogBindings)) {
    const catalogType = escalationTypes.find(
      (type) => type.id === catalogTypeID,
    );
    if (!catalogType) continue;

    // If this catalog type is not manual or managed externally, we shouldn't do any bulk editing here.
    if (
      !(
        catalogType.mode === "manual" &&
        catalogType.source_repo_url === undefined
      )
    )
      continue;

    const escalationPathAttribute = catalogType.schema.attributes.find(
      (attribute) => attribute.type === "EscalationPath",
    );
    if (!escalationPathAttribute) continue;

    if (!bindings) continue;

    const payloads: PartialEntryPayload[] = [];
    bindings.forEach((value) => {
      if (!value) {
        return;
      }
      payloads.push({
        entry_id: value,
        attribute_values: {
          [escalationPathAttribute.id]: createEngineParamBindingPayload(
            escalationPathId,
            escalationPathAttribute.array,
          ),
        },
      });
    });

    if (!payloads) continue;

    await apiClient.catalogBulkUpdateAttributeValues({
      bulkUpdateAttributeValuesRequestBody: {
        catalog_type_id: catalogTypeID,
        partial_entry_payloads: payloads,
      },
    });
  }
};

export const EscalationPathErrorContext = createContext<ValidationErrors<
  EscalationPathFormData & { userIdsToPromote: string[] }
> | null>(null);

const getScheduleTargets = (nodes: Record<string, PathNode>): string[] => {
  return (
    Object.values(nodes)
      // Filter nodes to get only those of type Level
      .filter((node) => node.data.type === EscalationPathNodeTypeEnum.Level)
      // Extract targets from each level node
      .flatMap((node) => node.data.level?.targets || [])
      // Filter targets to get only those of type Schedule
      .filter((target) => target.type === EscalationPathTargetTypeEnum.Schedule)
      // Get all the schedule IDs
      .map((target) => target.value)
  );
};
