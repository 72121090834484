/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffGroupDatasetEntriesBySegmentResponseBody
 */
export interface AIStaffGroupDatasetEntriesBySegmentResponseBody {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AIStaffGroupDatasetEntriesBySegmentResponseBody
     */
    entries_by_segment: { [key: string]: Array<string>; };
}

export function AIStaffGroupDatasetEntriesBySegmentResponseBodyFromJSON(json: any): AIStaffGroupDatasetEntriesBySegmentResponseBody {
    return AIStaffGroupDatasetEntriesBySegmentResponseBodyFromJSONTyped(json, false);
}

export function AIStaffGroupDatasetEntriesBySegmentResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffGroupDatasetEntriesBySegmentResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries_by_segment': json['entries_by_segment'],
    };
}

export function AIStaffGroupDatasetEntriesBySegmentResponseBodyToJSON(value?: AIStaffGroupDatasetEntriesBySegmentResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries_by_segment': value.entries_by_segment,
    };
}

