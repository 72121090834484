import {
  DateRangeRelativeStateIntervalEnum as Interval,
  InsightsDateRangeAggregationEnum as DateAggregation,
} from "@incident-io/api";
import {
  DateRangePicker,
  DateRangePickerMode,
  DateRangePickerState,
  DisplayDateRange,
  QUICK_SELECT_INTERVAL_CONFIG,
  QuickSelectInterval,
} from "@incident-shared/forms/v1/DateRangePicker";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Toggle,
} from "@incident-ui";
import { Popover } from "@incident-ui/Popover/Popover";

import {
  InsightsDateRangeState,
  useInsightsContext,
} from "./useInsightsContext";

export const InsightsDatePicker = () => {
  const { dateRange, setDateRange } = useInsightsContext();

  const setDateRangeAndInterval = (range: DateRangePickerState) => {
    const partial: Partial<InsightsDateRangeState> = { range };
    // If quick-selecting, also set a default aggregation interval
    if (range.mode === DateRangePickerMode.QuickSelect) {
      partial.aggregation = calculateDefaultAggregation(range.quick_select);
    }

    setDateRange(partial);
  };

  return (
    <Popover
      align="end"
      trigger={
        <Button
          analyticsTrackingId={null}
          className="text-sm-med text-content-secondary pl-1.5"
        >
          <InsightsDateBadge dateRange={dateRange} size={BadgeSize.Medium} />
          <Icon id={IconEnum.ChevronDown} size={IconSize.Medium} />
        </Button>
      }
    >
      <div className="p-3 flex flex-col gap-4">
        <div className="font-normal flex flex-col gap-1">
          <span className="text-sm-bold">Time period</span>
          <DateRangePicker
            state={dateRange.range}
            setState={setDateRangeAndInterval}
            showRangeCaption={false}
          />
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm-bold">Time interval</span>
          <DateAggregationPicker />
        </div>
        <ComparePreviousPeriodToggle />
      </div>
    </Popover>
  );
};

export const InsightsDateBadge = ({
  dateRange,
  size,
}: {
  dateRange: InsightsDateRangeState;
  size: BadgeSize;
}) => {
  return (
    <div className="flex items-center gap-2">
      <Badge size={size} theme={BadgeTheme.Tertiary} icon={IconEnum.Calendar}>
        <DisplayDateRange
          state={dateRange.range}
          hideParentheses={true}
          className="text-content-secondary"
        />
      </Badge>
      <Badge size={size} theme={BadgeTheme.Tertiary} icon={IconEnum.BarChart}>
        {aggregationToAdjective[dateRange.aggregation]}
      </Badge>
    </div>
  );
};

const ComparePreviousPeriodToggle = () => {
  const { dateRange, setDateRange } = useInsightsContext();

  return (
    <div className="flex gap-2">
      <Icon id={IconEnum.Compare} size={IconSize.Medium} />
      <span id="compare-label" className="text-sm-med grow">
        Compare with previous period
      </span>
      <Toggle
        id="toggle-compare-previous-period"
        analyticsTrackingId="toggle-compare-previous-period"
        labelledById="compare-label"
        on={dateRange.is_comparison}
        onToggle={() =>
          setDateRange({ is_comparison: !dateRange.is_comparison })
        }
      />
    </div>
  );
};

const intervalToAggregation: {
  [key in Interval]: DateAggregation;
} = {
  [Interval.Days]: DateAggregation.Days,
  [Interval.Weeks]: DateAggregation.Weeks,
  [Interval.Months]: DateAggregation.Months,
};

const downsizedIntervals: {
  [key in Interval]: Interval;
} = {
  [Interval.Days]: Interval.Days,
  [Interval.Weeks]: Interval.Days,
  [Interval.Months]: Interval.Weeks,
};

const aggregationToAdjective: {
  [key in DateAggregation]: string;
} = {
  [DateAggregation.Days]: "Daily",
  [DateAggregation.Weeks]: "Weekly",
  [DateAggregation.Months]: "Monthly",
};

const calculateDefaultAggregation = (s: QuickSelectInterval) => {
  const config = QUICK_SELECT_INTERVAL_CONFIG[s];

  // If e.g. 7 weeks, set the aggregation interval to weeks.
  // If only 1 week, set the aggregation interval to the smaller unit (days).
  const newInterval =
    config.state.numberOfIntervals > 1
      ? config.state.interval
      : downsizedIntervals[config.state.interval];

  return intervalToAggregation[newInterval];
};

const DateAggregationPicker = () => {
  const { dateRange, setDateRange } = useInsightsContext();

  return (
    <DropdownMenu
      menuClassName="mt-2"
      menuWidthMatchesTrigger
      align="end"
      triggerButton={
        <Button
          analyticsTrackingId="selected-date-aggregation"
          className="!font-normal !pr-2 !py-1.5 flex justify-between"
        >
          {/* The label of the selected quick interval */}
          <span className="shrink-0 mr-0.5 flex">
            {aggregationToAdjective[dateRange.aggregation]}
          </span>
          <Icon id={IconEnum.ChevronDown} />
        </Button>
      }
    >
      {Object.entries(DateAggregation).map(([_, option]) => (
        <DropdownMenuItem
          key={option}
          analyticsTrackingId={`select-${option}-date-aggregation`}
          onSelect={() => setDateRange({ aggregation: option })}
          label={aggregationToAdjective[option]}
        />
      ))}
    </DropdownMenu>
  );
};
