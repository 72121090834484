/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProcessorBackfillResourceOptions,
    ProcessorBackfillResourceOptionsFromJSON,
    ProcessorBackfillResourceOptionsFromJSONTyped,
    ProcessorBackfillResourceOptionsToJSON,
} from './ProcessorBackfillResourceOptions';
import {
    ProcessorBackfillRunOptions,
    ProcessorBackfillRunOptionsFromJSON,
    ProcessorBackfillRunOptionsFromJSONTyped,
    ProcessorBackfillRunOptionsToJSON,
} from './ProcessorBackfillRunOptions';

/**
 * 
 * @export
 * @interface AIStaffBackfillProcessorRequestBody
 */
export interface AIStaffBackfillProcessorRequestBody {
    /**
     * Name of the processor to backfill
     * @type {string}
     * @memberof AIStaffBackfillProcessorRequestBody
     */
    processor: string;
    /**
     * 
     * @type {ProcessorBackfillResourceOptions}
     * @memberof AIStaffBackfillProcessorRequestBody
     */
    resource_options: ProcessorBackfillResourceOptions;
    /**
     * 
     * @type {ProcessorBackfillRunOptions}
     * @memberof AIStaffBackfillProcessorRequestBody
     */
    run_options: ProcessorBackfillRunOptions;
}

export function AIStaffBackfillProcessorRequestBodyFromJSON(json: any): AIStaffBackfillProcessorRequestBody {
    return AIStaffBackfillProcessorRequestBodyFromJSONTyped(json, false);
}

export function AIStaffBackfillProcessorRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffBackfillProcessorRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processor': json['processor'],
        'resource_options': ProcessorBackfillResourceOptionsFromJSON(json['resource_options']),
        'run_options': ProcessorBackfillRunOptionsFromJSON(json['run_options']),
    };
}

export function AIStaffBackfillProcessorRequestBodyToJSON(value?: AIStaffBackfillProcessorRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'processor': value.processor,
        'resource_options': ProcessorBackfillResourceOptionsToJSON(value.resource_options),
        'run_options': ProcessorBackfillRunOptionsToJSON(value.run_options),
    };
}

