import {
  CatalogEntry,
  ErrorResponse,
  FormFieldIconEnum,
} from "@incident-io/api";
import { useIsInInsightsContext } from "src/components/insights/useIsInInsightsContext";
import { useAPI } from "src/utils/swr";

import { useIdentity } from "../../../contexts/IdentityContext";

export type Backlink = {
  id: string;
  catalog_type_icon: FormFieldIconEnum;
  catalog_type_id: string;
  catalog_type_name: string;
  backlink_attribute: string;
  backlink_name: string;
  user_attribute_id: string;
  array: boolean;
};

export const useUserBacklinks = (options?: {
  isInsightsOverride?: boolean;
}): {
  data: Backlink[] | undefined;
  isLoading: boolean;
  error: ErrorResponse | undefined;
} => {
  const forInsights = useIsInInsightsContext();
  const { data, isLoading, error } = useAPI(
    forInsights || options?.isInsightsOverride
      ? "insightsListCatalogTypes"
      : "catalogListTypes",
    {},
  );

  const userCatalogType = data?.catalog_types.find(
    (catalogType) => catalogType.type_name === "User",
  );

  // we are cheating here and using flatMap as filterMap, where empty arrays are removed
  const backlinks = userCatalogType?.schema.attributes.flatMap((backlink) => {
    if (backlink.mode !== "backlink" || !backlink.backlink_attribute) {
      return [];
    }

    const catalogType = data?.catalog_types.find(
      (ct) => ct.type_name === backlink.type,
    );
    if (!catalogType) {
      return [];
    }

    const backlinkTarget = catalogType?.schema.attributes.find(
      (ct) => ct.id === backlink.backlink_attribute,
    );

    // We're filtering out backlinks where the target isn't an array.
    // It doesn't make sense to include these as they're not giving you any extra
    // power. For example, you might have a backlink
    // from User to SlackUser, but there's no point in filtering by
    // SlackUser as it's 1-1 with a User.
    if (!backlinkTarget || !backlinkTarget.array) {
      return [];
    }

    return [
      {
        id: backlink.id,
        catalog_type_icon: catalogType.icon as unknown as FormFieldIconEnum,
        catalog_type_id: catalogType.id,
        catalog_type_name: catalogType.name,
        backlink_attribute: backlink.backlink_attribute,
        backlink_name: backlink.name,
        user_attribute_id: backlink.id,
        array: backlink.array,
      },
    ];
  });

  return {
    data: backlinks,
    isLoading,
    error,
  };
};

export const useCurrentUserCatalogEntry = (): {
  userCatalogEntry: CatalogEntry | undefined;
  isLoading: boolean;
  error: ErrorResponse | undefined;
} => {
  const {
    data: catalogTypeData,
    isLoading: isLoadingCatalogTypes,
    error: catalogTypesError,
  } = useAPI("catalogListTypes", {});

  const userCatalogType = catalogTypeData?.catalog_types.find(
    (catalogType) => catalogType.type_name === "User",
  );

  const { identity } = useIdentity();

  const {
    data: userCatalogEntries,
    isLoading: isLoadingUserCatalogEntries,
    error: catalogEntriesError,
  } = useAPI(userCatalogType ? "catalogFindEntries" : null, {
    findEntriesRequestBody: {
      lookups: [
        {
          lookup_terms: [identity?.user_id ?? ""],
          catalog_type_id: userCatalogType?.id ?? "",
        },
      ],
    },
  });

  // We _should_ only get one as we've only asked
  // for one catalog type, and one lookup term
  const userCatalogEntry: CatalogEntry | undefined =
    userCatalogEntries?.results[
      Object.keys(userCatalogEntries.results)[0]
    ]?.[0];

  return {
    userCatalogEntry,
    isLoading: isLoadingCatalogTypes || isLoadingUserCatalogEntries,
    error: catalogTypesError || catalogEntriesError,
  };
};
