import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  IconSize,
  Link,
  Txt,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import { NumberedList } from "@incident-ui/NumberedList/NumberedList";
import { NakedSteps } from "@incident-ui/Steps/NakedSteps";
import { useState } from "react";
import { useParams } from "react-router";
import { useAPI } from "src/utils/swr";

import { CatalogFixedFooter } from "../common/CatalogFixedFooter";
import {
  ManageInGithubStepConfig,
  ManageInGitHubStepEnum,
} from "./ManageInGithubRoute";

export const UploadRepoStep = () => {
  const { repoId } = useParams<{ repoId: string }>();
  const navigate = useOrgAwareNavigate();

  const [downloadClicked, setDownloadClicked] = useState<boolean>(false);
  const [useNewRepo, setUseNewRepo] = useState<boolean>(true);

  const { data, error } = useAPI(
    repoId ? "catalogShowGeneratedRepository" : null,
    { id: repoId ?? "" },
  );
  if (error?.status === 404) {
    return <OrgAwareNavigate to="/catalog/manage-in-github" />;
  }

  if (error) {
    return <GenericErrorMessage />;
  }

  const downloadURL = data?.signed_url;
  if (data && !downloadURL) {
    return (
      <OrgAwareNavigate
        to={`/catalog/manage-in-github/${repoId}/download`}
        replace
      />
    );
  }

  // There's some awful stuff here to get the positioning just right, sorry.
  return (
    <>
      <div className="w-full p-4">
        <NakedSteps
          steps={ManageInGithubStepConfig}
          currentStepID={ManageInGitHubStepEnum.UploadRepo}
          color={ColorPaletteEnum.Slate}
        />
        <div className="flex flex-col pt-6 mx-auto pb-[71px]">
          <div className="text-[24px] font-semibold leading-10">
            Upload to repository
          </div>

          <Txt className="text-slate-600 mb-6">
            To upload your custom types to{" "}
            {useNewRepo ? "a new" : "an existing"} GitHub repository, follow the
            steps below.
          </Txt>

          {useNewRepo ? (
            <NumberedList className="text-slate-700 [&_strong]:!text-content-primary [&_strong]:font-semibold">
              <div className="mb-4 w-full text-sm">
                <strong>Download</strong> this zip file:
                <div className="flex items-center bg-surface-secondary rounded-2 p-4 gap-3 w-full text-sm mt-4">
                  <div className="bg-white rounded-2 p-2 border border-stroke text-content-primary">
                    <Icon id={IconEnum.Folder} size={IconSize.Large} />
                  </div>
                  <strong className="grow mr-8">custom-types.zip</strong>
                  <Button
                    href={downloadURL}
                    theme={ButtonTheme.Primary}
                    analyticsTrackingId="download-zip"
                    icon={IconEnum.Download}
                    loading={!downloadURL}
                    onClick={() => setDownloadClicked(true)}
                  >
                    Download code
                  </Button>
                </div>
              </div>
              <Txt grey className="mb-4">
                Unpack the zip file on your computer
              </Txt>
              <div className="flex justify-between w-full mb-4">
                <Txt grey>
                  Create a{" "}
                  <Link
                    analyticsTrackingId={null}
                    href="https://github.com/new?visibility=private&name=incident-io-catalog-types"
                    openInNewTab
                  >
                    new repository
                  </Link>{" "}
                  in GitHub
                </Txt>
                <Button
                  analyticsTrackingId={null}
                  theme={ButtonTheme.Naked}
                  className="underline text-sm"
                  onClick={() => setUseNewRepo(false)}
                >
                  Or use an existing repository
                </Button>
              </div>
              <Txt grey className="mb-4">
                Go in to the folder where you unpacked the zip file and
                initialise a git repository
                <CodeBlock
                  className="mt-4"
                  code={`git init\ngit add .\ngit commit -m "Initial commit [skip actions]"`}
                  hideHeader
                />
              </Txt>
              <Txt grey className="mb-4 space-y-4">
                <div>
                  Run the{" "}
                  <strong>
                    …or push an existing repository from the command line
                  </strong>{" "}
                  instructions that GitHub shows. Make sure to select the
                  correct HTTPS or SSH option before copying the instructions
                </div>
                <div className="border border-stroke rounded p-1 shadow-lg mx-4">
                  <img src="/manage-in-github/initialise-repository.png" />
                </div>
              </Txt>
            </NumberedList>
          ) : (
            <NumberedList className="text-slate-700 [&_strong]:!text-content-primary [&_strong]:font-semibold">
              <div className="mb-4 w-full text-sm">
                <strong>Download</strong> this zip file:
                <div className="flex items-center bg-surface-secondary rounded-2 p-4 gap-3 w-full text-sm mt-4">
                  <div className="bg-white rounded-2 p-2 border border-stroke text-content-primary">
                    <Icon id={IconEnum.Folder} size={IconSize.Large} />
                  </div>
                  <strong className="grow mr-8">custom-types.zip</strong>
                  <Button
                    href={downloadURL}
                    theme={ButtonTheme.Primary}
                    analyticsTrackingId="download-zip"
                    icon={IconEnum.Download}
                    loading={!downloadURL}
                    onClick={() => setDownloadClicked(true)}
                  >
                    Download code
                  </Button>
                </div>
              </div>
              <Txt grey className="mb-4">
                Unpack the zip file on your computer
              </Txt>
              <div className="flex justify-between w-full mb-4">
                <Txt grey>
                  Move the unzipped files to your existing repository
                </Txt>
                <Button
                  analyticsTrackingId={null}
                  theme={ButtonTheme.Naked}
                  className="underline text-sm"
                  onClick={() => setUseNewRepo(true)}
                >
                  Or use a new repository
                </Button>
              </div>
              <Txt grey className="mb-4">
                Commit the new files and push the change to GitHub
                <CodeBlock
                  className="mt-4"
                  code={`git add .\ngit commit -m "Initial commit [skip actions]"\ngit push`}
                  hideHeader
                />
              </Txt>
            </NumberedList>
          )}
        </div>
      </div>
      <CatalogFixedFooter
        onGoBack={() => {
          navigate(`/catalog/manage-in-github`);
        }}
      >
        {!downloadClicked && <Txt grey>Download the repository to proceed</Txt>}
        <Button
          analyticsTrackingId={null}
          onClick={() => {
            navigate(`/catalog/manage-in-github/${repoId}/api-key`);
          }}
          theme={ButtonTheme.Primary}
          disabled={!downloadClicked}
        >
          Continue
        </Button>
      </CatalogFixedFooter>
    </>
  );
};
