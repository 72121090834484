import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Button,
  ButtonTheme,
  ContentBox,
  Loader,
  SharedToasts,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import React, { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  ScopeNameEnum,
  Settings,
  SettingsUpdateStoreIncidentChannelMessagesRequestBodyStoreIncidentChannelMessagesEnum as RequestEnum,
} from "src/contexts/ClientContext";
import { useSettings } from "src/hooks/useSettings";
import {
  getAnchorId,
  scrollToAnchor,
  slackChannelStorageSetting,
} from "src/utils/anchor";
import { useAPIMutation } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { SettingsSubHeading } from "../SettingsSubHeading";
import styles from "./StoreIncidentChannelMessagesForm.module.scss";

export const StoreIncidentChannelMessagesForm =
  (): React.ReactElement | null => {
    const { settings } = useSettings();

    if (!settings) {
      return <Loader />;
    }

    return <StoreIncidentChannelMessagesFormInnerV2 settings={settings} />;
  };

// This is the new version of the form.
type FormDataV2 = {
  store_slack_channel_messages: RequestEnum;
};

const storeSlackChannelOptions: {
  [key in RequestEnum]: { name: string; description: ReactNode };
} = {
  [RequestEnum.Never]: {
    name: "None",
    description: (
      <p>We won&lsquo;t store any messages from incident channels.</p>
    ),
  },
  [RequestEnum.TimelineOnly]: {
    name: "Messages pinned to the incident timeline",
    description: (
      <p>
        This means we can continue to show them in the timeline even if the
        channel is archived.
      </p>
    ),
  },
  [RequestEnum.All]: {
    name: "All",
    description: (
      <>
        <p>
          This means that our AI suggestions will be more accurate because they
          take your incident channel conversations into account.
        </p>
        <p>
          Note that for private incidents we will only store messages that are
          on the timeline.
        </p>
      </>
    ),
  },
};

const toFormV2 = (settings: Settings): FormDataV2 => {
  return {
    store_slack_channel_messages: settings.misc
      .store_incident_channel_messages as unknown as RequestEnum,
  };
};

const StoreIncidentChannelMessagesFormInnerV2 = ({
  settings,
}: {
  settings: Settings;
}): React.ReactElement => {
  const showToast = useToast();
  const formMethods = useForm<FormDataV2>({
    defaultValues: toFormV2(settings),
  });

  const { reset, setError } = formMethods;

  const [hasScrolledTo, setHasScrolledTo] = useState("");
  useEffect(() => {
    scrollToAnchor(
      false,
      0,
      hasScrolledTo,
      setHasScrolledTo,
      undefined,
      "smooth",
    );
  }, [location.href, hasScrolledTo, setHasScrolledTo]); // eslint-disable-line react-hooks/exhaustive-deps

  const mutation = useAPIMutation(
    "settingsShow",
    undefined,
    async (apiClient, data: FormDataV2) =>
      await apiClient.settingsUpdateStoreIncidentChannelMessages({
        updateStoreIncidentChannelMessagesRequestBody: {
          store_incident_channel_messages: data.store_slack_channel_messages,
        },
      }),
    {
      onSuccess: ({ settings }) => {
        showToast(SharedToasts.SETTINGS_SAVED);
        reset(toFormV2(settings));
      },

      setError,
    },
  );

  const { trigger: onSubmit, isMutating: saving, genericError } = mutation;

  const radioButtonOptions = Object.entries(storeSlackChannelOptions).map(
    ([id, config]) => ({
      value: id,
      label: config.name,
      description: config.description,
    }),
  );

  return (
    <div>
      <Form.Root
        onSubmit={onSubmit}
        formMethods={formMethods}
        genericError={genericError}
      >
        <ContentBox
          className={tcx(
            "p-6 space-y-4",
            getAnchorId() === slackChannelStorageSetting && styles.anchored,
          )}
          id={slackChannelStorageSetting}
        >
          <SettingsSubHeading
            title="Store incident channel messages"
            titleHeadingLevel={2}
            className="!mb-2"
            explanation={
              <>
                Choose which Slack messages we store.{" "}
                <Button
                  theme={ButtonTheme.Link}
                  href="https://incident.io/security"
                  analyticsTrackingId="settings-store-channel-messages-help"
                >
                  Learn more
                </Button>
              </>
            }
          />
          <RadioButtonGroupV2
            formMethods={formMethods}
            name={"store_slack_channel_messages"}
            srLabel="Store incident channel messages"
            options={radioButtonOptions}
            boxed
          />
          <GatedButton
            className="mt-2"
            analyticsTrackingId="store-channel-messages"
            theme={ButtonTheme.Primary}
            loading={saving}
            type="submit"
            disabled={!formMethods.formState.isDirty}
            requiredScope={ScopeNameEnum.SecuritySettingsUpdate}
          >
            Save
          </GatedButton>
        </ContentBox>
      </Form.Root>
    </div>
  );
};
