import {
  CustomFieldRequiredV2Enum,
  PostIncidentTask,
  PostIncidentTaskConfigSlimTaskTypeEnum as ConfigTaskTypeEnum,
  PostIncidentTaskSlim,
} from "@incident-io/api";
import { ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { sortBy } from "lodash";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { useIncident } from "../../hooks";
import { AssignRoleItem } from "./AssignRoleItem";
import { CompletePostMortemItem } from "./CompletePostmortemItem";
import { CreatePostMortemItem } from "./CreatePostmortemItem";
import { DraftPostMortemItem } from "./DraftPostMortemItem";
import { GiveShoutoutItem } from "./GiveShoutoutItem";
import { ReviewFollowUpsItem } from "./ReviewFollowUpsItem";
import { ReviewTimelineItem } from "./ReviewTimelineItem";
import { ScheduleDebriefItem } from "./ScheduleDebriefItem";
import { SetCustomFieldsItem } from "./SetCustomFieldsItem";
import { SharePostMortemItem } from "./SharePostMortemItem";
import { ActionMode, ItemProps } from "./types";
import { UpdateIncidentSummaryItem } from "./UpdateIncidentSummaryItem";
import { UpdateTimestampsItem } from "./UpdateTimestampsItem";

// PostIncidentTaskActionItem returns a Item for doing the given task (e.g. Create postmortem).
// If the task can only be completed by marking it as complete, it will return null.
export const PostIncidentTaskActionItem = ({
  incidentTask,
  onTaskComplete,
  mode,
}: {
  incidentTask: PostIncidentTaskSlim | PostIncidentTask;
  onTaskComplete: () => void;
  mode: ActionMode;
}): React.ReactElement | null => {
  const { identity } = useIdentity();
  const { incident } = useIncident(incidentTask.incident_id);
  const showToast = useToast();

  const {
    data: { custom_fields },
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });
  const customFields = sortBy(custom_fields, (field) => field.rank);

  const applicableIDs = incidentTask.config.custom_field_ids ?? [];

  // We only want to show the fields that they've chosen for this post-incident step.
  // We also want to fake that they're required, regardless of their regular
  // custom field setting.
  const requiredCustomFields = (
    customFields?.filter((x) => applicableIDs.includes(x.id)) || []
  ).map((c) => ({
    ...c,
    required_v2: CustomFieldRequiredV2Enum.Always,
  }));

  if (!incident || !incidentTask) {
    return null;
  }

  const onError = (error: string) =>
    showToast({
      theme: ToastTheme.Error,
      title: error,
    });

  const props: ItemProps = {
    incident,
    incidentTask,
    onTaskComplete,
    onError,
    mode,
    allCustomFields: requiredCustomFields,
  };

  switch (incidentTask.config?.task_type) {
    case ConfigTaskTypeEnum.CreatePostmortem:
      return <CreatePostMortemItem {...props} />;
    case ConfigTaskTypeEnum.SharePostmortem:
      if (!identity.can_share_postmortems) {
        return null;
      }
      return <SharePostMortemItem {...props} />;
    case ConfigTaskTypeEnum.CompletePostmortem:
      return <CompletePostMortemItem {...props} />;
    case ConfigTaskTypeEnum.InReviewPostmortem:
      return <DraftPostMortemItem {...props} />;
    case ConfigTaskTypeEnum.ReviewFollowups:
      return <ReviewFollowUpsItem {...props} />;
    case ConfigTaskTypeEnum.UpdateTimestamps:
      return <UpdateTimestampsItem {...props} />;
    case ConfigTaskTypeEnum.GiveShoutout:
      return <GiveShoutoutItem {...props} />;
    case ConfigTaskTypeEnum.ReviewTimeline:
      return <ReviewTimelineItem {...props} />;
    case ConfigTaskTypeEnum.UpdateIncidentSummary:
      return <UpdateIncidentSummaryItem {...props} />;
    case ConfigTaskTypeEnum.AssignRole:
      return <AssignRoleItem {...props} />;
    case ConfigTaskTypeEnum.SetCustomFields:
      return <SetCustomFieldsItem {...props} />;
    case ConfigTaskTypeEnum.SetTimestamps:
      return <UpdateTimestampsItem {...props} />;
    case ConfigTaskTypeEnum.ScheduleDebrief:
      return <ScheduleDebriefItem {...props} />;
    default:
      return null;
  }
};
