import {
  PopoverSingleSelect,
  PopoverSingleSelectProps,
} from "@incident-ui/PopoverSelect";
import { StaticSingleSelect } from "@incident-ui/Select/StaticSingleSelect";
import {
  SelectOption,
  SharedStaticSelectProps,
} from "@incident-ui/Select/types";
import { getTimeZones, TimeZone } from "@vvo/tzdb";
import { Searcher } from "fast-fuzzy";
import _ from "lodash";
import { useState } from "react";

export type TimeZoneSelectProps = {
  value?: string;
  onChange: (val: string | null | undefined) => void;
  className?: string;
  onValueChange?: (val: string | null | undefined) => void;
} & Omit<SharedStaticSelectProps, "options" | "allowAdding" | "onCreateOption">;

export const TimeZoneSelect = ({
  value,
  onChange,
  className,
  placeholder,
  ...rest
}: TimeZoneSelectProps) => {
  const allOptions = getTimeZones({ includeUtc: true }).map((tz) => {
    return {
      ...tz,
      value: tz.name,
      label: `${tz.name} (${tz.abbreviation})`,
    };
  });
  const sortedOptions = _.sortBy(allOptions, (x) => x.value);

  const [options, setOptions] = useState(sortedOptions);

  const timezoneSearcher = new Searcher(sortedOptions, {
    keySelector: (timezone: TimeZone) => {
      return [
        timezone.name,
        timezone.alternativeName,
        timezone.countryCode,
        timezone.countryName,
        timezone.abbreviation,
        timezone.continentName,
        timezone.continentCode,
      ].filter((x) => x);
    },
  });

  return (
    <StaticSingleSelect
      placeholder={placeholder || "Type a city, country, or timezone"}
      options={options}
      value={value ?? ""}
      onChange={onChange}
      onInputChange={(val) => {
        if (!val) {
          setOptions(options);
        } else {
          setOptions(timezoneSearcher.search(val));
        }
      }}
      filterOption={() => true}
      className={className}
      {...rest}
    />
  );
};

export type TimeZonePopoverSelectProps = {
  value?: string;
  onChange: (val: string | null | undefined) => void;
  onValueChange?: (val: string | null | undefined) => void;
} & Omit<PopoverSingleSelectProps<true, false, SelectOption>, "options">;

type TimeZoneOption = SelectOption & Omit<TimeZone, "group">;

export const TimeZonePopoverSelect = ({
  value,
  onChange,
  placeholder,
  ...rest
}: TimeZonePopoverSelectProps) => {
  const allOptions = getTimeZones({ includeUtc: true }).map((tz) => {
    return {
      ...tz,
      value: tz.name,
      label: `${tz.name} (${tz.abbreviation})`,
      group: undefined,
    };
  });
  const sortedOptions: TimeZoneOption[] = _.sortBy(allOptions, (x) => x.value);

  return (
    <PopoverSingleSelect<true, false, TimeZoneOption>
      placeholder={placeholder || "Type a city, country, or timezone"}
      options={sortedOptions}
      value={value ?? ""}
      onChange={onChange}
      popoverClassName="w-[250px] max-w-[250px]"
      keySelector={(timezone: TimeZoneOption) => {
        return [
          timezone.name,
          timezone.alternativeName,
          timezone.countryCode,
          timezone.countryName,
          timezone.abbreviation,
          timezone.continentName,
          timezone.continentCode,
        ].filter((x) => x);
      }}
      {...rest}
    />
  );
};
