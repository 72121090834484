/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AIStaffResumeEvaluationBacktestRequestBody
 */
export interface AIStaffResumeEvaluationBacktestRequestBody {
    /**
     * Optional credit to set. If unset, resumes with current credit.
     * @type {number}
     * @memberof AIStaffResumeEvaluationBacktestRequestBody
     */
    credit?: number;
}

export function AIStaffResumeEvaluationBacktestRequestBodyFromJSON(json: any): AIStaffResumeEvaluationBacktestRequestBody {
    return AIStaffResumeEvaluationBacktestRequestBodyFromJSONTyped(json, false);
}

export function AIStaffResumeEvaluationBacktestRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffResumeEvaluationBacktestRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credit': !exists(json, 'credit') ? undefined : json['credit'],
    };
}

export function AIStaffResumeEvaluationBacktestRequestBodyToJSON(value?: AIStaffResumeEvaluationBacktestRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credit': value.credit,
    };
}

