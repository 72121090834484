/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EngineParamBindingPayload,
    EngineParamBindingPayloadFromJSON,
    EngineParamBindingPayloadFromJSONTyped,
    EngineParamBindingPayloadToJSON,
} from './EngineParamBindingPayload';

/**
 * 
 * @export
 * @interface AlertTemplateAttributePayload
 */
export interface AlertTemplateAttributePayload {
    /**
     * ID of the alert attribute to set with this binding
     * @type {string}
     * @memberof AlertTemplateAttributePayload
     */
    alert_attribute_id: string;
    /**
     * 
     * @type {EngineParamBindingPayload}
     * @memberof AlertTemplateAttributePayload
     */
    binding: EngineParamBindingPayload;
}

export function AlertTemplateAttributePayloadFromJSON(json: any): AlertTemplateAttributePayload {
    return AlertTemplateAttributePayloadFromJSONTyped(json, false);
}

export function AlertTemplateAttributePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertTemplateAttributePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alert_attribute_id': json['alert_attribute_id'],
        'binding': EngineParamBindingPayloadFromJSON(json['binding']),
    };
}

export function AlertTemplateAttributePayloadToJSON(value?: AlertTemplateAttributePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alert_attribute_id': value.alert_attribute_id,
        'binding': EngineParamBindingPayloadToJSON(value.binding),
    };
}

