/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvaluationDatasetEntry
 */
export interface EvaluationDatasetEntry {
    /**
     * When this entry was created
     * @type {Date}
     * @memberof EvaluationDatasetEntry
     */
    created_at: Date;
    /**
     * ID of the dataset this entry belongs to
     * @type {string}
     * @memberof EvaluationDatasetEntry
     */
    dataset_id: string;
    /**
     * Unique identifier
     * @type {string}
     * @memberof EvaluationDatasetEntry
     */
    id: string;
    /**
     * ID of the incident this entry represents
     * @type {string}
     * @memberof EvaluationDatasetEntry
     */
    incident_id: string;
    /**
     * ID of the organisation this entry belongs to
     * @type {string}
     * @memberof EvaluationDatasetEntry
     */
    organisation_id: string;
    /**
     * When this entry was last updated
     * @type {Date}
     * @memberof EvaluationDatasetEntry
     */
    updated_at: Date;
}

export function EvaluationDatasetEntryFromJSON(json: any): EvaluationDatasetEntry {
    return EvaluationDatasetEntryFromJSONTyped(json, false);
}

export function EvaluationDatasetEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvaluationDatasetEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created_at': (new Date(json['created_at'])),
        'dataset_id': json['dataset_id'],
        'id': json['id'],
        'incident_id': json['incident_id'],
        'organisation_id': json['organisation_id'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function EvaluationDatasetEntryToJSON(value?: EvaluationDatasetEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created_at': (value.created_at.toISOString()),
        'dataset_id': value.dataset_id,
        'id': value.id,
        'incident_id': value.incident_id,
        'organisation_id': value.organisation_id,
        'updated_at': (value.updated_at.toISOString()),
    };
}

