import { ScopeNameEnum, SlimDashboard } from "@incident-io/api";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { OrgAwareLink } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Button,
  ButtonTheme,
  Card,
  GenericErrorMessage,
  Heading,
  IconEnum,
  IconSize,
  Loader,
} from "@incident-ui";
import { FullPageLoader } from "@incident-ui/Loader/Loader";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { LevelUpIncidentResponseBanner } from "src/components/settings/LevelUpIncidentResponseBanner";
import { PlanBadge } from "src/components/settings/PlanBadge";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AIConfigEnabledFeaturesEnum,
  useAIFeatureForOrg,
} from "src/hooks/useAI";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  AssistantAvatar,
  AssistantAvatarBackground,
} from "../../assistant/AssistantAvatar";
import { useAssistantOverlay } from "../../assistant/AssistantOverlayContext";
import { useAssistantThreadContext } from "../../assistant/AssistantThreadContext";
import InsightsGatedVisual from "../../insights-empty-state.png";
import { CreateCustomDashboardModal } from "../dashboards/create-edit/CreateCustomDashboardModal";
import { InsightsV3HomepageTrendsTiles } from "../dashboards/trends/TrendsTiles";
import { CustomDashboardsSection } from "./CustomDashboardsSection";
import { InsightsListEmptyState } from "./InsightsListEmptyState";
import { IntroducingInsightsV3Modal } from "./InsightsV3Modal";

const INSIGHTS_CTA = "insights-scheduled-reports";

export const InsightsV3ListPage = (): React.ReactElement => {
  const { identity } = useIdentity();
  const insightsFeatureGate = identity?.feature_gates.advanced_insights;
  const customDashboardsGate = identity?.feature_gates.custom_dashboards_count;
  const { hasResponse } = useProductAccess();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const {
    data: { dashboards: customDashboards },
    error,
  } = useAPI("insightsListCustomDashboards", undefined, {
    fallbackData: { dashboards: [] },
  });

  const { hasDismissedCTA } = useIdentity();

  const [showModal, setShowModal] = useState(
    !hasDismissedCTA(INSIGHTS_CTA) && insightsFeatureGate,
  );

  const { trigger: dismissBanner, isMutating: dismissing } = useAPIMutation(
    "identitySelf",
    undefined,
    async (apiClient, _) => {
      await apiClient.identityDismissCta({
        dismissCtaRequestBody: {
          cta: INSIGHTS_CTA,
          for_whole_organisation: false,
        },
      });
    },
  );
  const closeAndDismissModal = () => {
    setShowModal(false);
    dismissBanner({});
  };

  // Currently, our insights charts rely on the presence of custom fields for filtering
  // TODO: RESP-4731 we should be able to remove this check if we improve the way we
  // deal with unexpected setups in insights
  const {
    data: customFieldsData,
    error: customFieldError,
    isLoading,
  } = useAPI("customFieldsList", undefined);
  if (isLoading || !customFieldsData) {
    return <FullPageLoader />;
  }

  if (error || customFieldError) {
    return <GenericErrorMessage error={error} />;
  }

  const showEmptyState = customFieldsData.custom_fields.length === 0;
  const fullWidth = showEmptyState;

  const hasExceededCustomDashboardGate =
    customDashboardsGate !== undefined &&
    customDashboards.length >= customDashboardsGate;

  return (
    <PageWrapper
      width={fullWidth ? PageWidth.Full : PageWidth.Medium}
      title="Insights"
      icon={IconEnum.Chart}
      accessory={
        <>
          <AssistantButton />
          <GatedButton
            icon={IconEnum.InsightsDashboard}
            theme={ButtonTheme.Primary}
            analyticsTrackingId="insights-create-dashboard"
            requiredScope={ScopeNameEnum.InsightsCustomDashboardsCreate}
            upgradeRequired={hasExceededCustomDashboardGate}
            onClick={() => setShowCreateModal(true)}
            upgradeRequiredProps={{
              gate: {
                type: "numeric",
                value: customDashboardsGate,
                featureNameSingular: "custom dashboard",
              },
              featureName: "custom dashboards",
            }}
          >
            Create dashboard
          </GatedButton>
        </>
      }
    >
      {!insightsFeatureGate ? (
        <InsightsUpsell />
      ) : showEmptyState ? (
        <div className="flex flex-col items-center grow ">
          <InsightsListEmptyState />
        </div>
      ) : (
        <div className="flex flex-col h-full gap-10">
          <InsightsV3HomepageTrendsTiles />
          <CoreDashboardsSection />
          <CustomDashboardsSection
            hasExceededCustomDashboardGate={hasExceededCustomDashboardGate}
            showCreateModal={() => setShowCreateModal(true)}
          />
          {!hasResponse ? <LevelUpIncidentResponseBanner /> : null}
        </div>
      )}
      {showCreateModal && (
        <CreateCustomDashboardModal onClose={() => setShowCreateModal(false)} />
      )}
      <AnimatePresence>
        {showModal && (
          <IntroducingInsightsV3Modal
            onClose={closeAndDismissModal}
            loading={dismissing}
          />
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

const InsightsUpsell = (): React.ReactElement => (
  <div className="flex flex-grow flex-col h-full justify-center items-center">
    <img className="w-[646px] mb-10" src={InsightsGatedVisual} />
    <div className="w-96 flex flex-col gap-2 justify-center items-center mb-6">
      <PlanBadge planName="Pro" />
      <p className="font-semibold text-base">Dig deeper into your incidents</p>
      <p className="font-normal text-sm text-center">
        Identify incident trends and recurring pain points with Insights. See
        what types of incidents are occurring across your organization and
        improve how you respond to them.
      </p>
    </div>
    <div className="flex gap-2">
      <Button
        analyticsTrackingId="insights-upgrade"
        theme={ButtonTheme.Primary}
        href={"/settings/billing"}
      >
        Upgrade
      </Button>
      <Button
        analyticsTrackingId="insights-learn-more"
        theme={ButtonTheme.Secondary}
        href="https://incident.io/changelog/insights-updates"
      >
        Learn more
      </Button>
    </div>
  </div>
);

const CoreDashboardsSection = (): React.ReactElement => {
  const { data, isLoading, error } = useAPI(
    "insightsListCoreDashboards",
    undefined,
  );

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  return (
    <div>
      <Heading level={2} size="medium" className="mb-6">
        Core dashboards
      </Heading>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4">
        {data?.dashboards.map((dashboard) => (
          <CoreDashboardCard key={dashboard.id} dashboard={dashboard} />
        ))}
      </div>
    </div>
  );
};

const CoreDashboardCard = ({ dashboard }: { dashboard: SlimDashboard }) => {
  return (
    <OrgAwareLink to={`/insights/dashboards/core/${dashboard.id}`}>
      <Card
        icon={dashboard.icon as unknown as IconEnum}
        color={dashboard.color as unknown as ColorPaletteEnum}
        title={dashboard.name}
        description={dashboard.description}
        compact
      />
    </OrgAwareLink>
  );
};

const AssistantButton = () => {
  const canUseAI = useAIFeatureForOrg();
  const { toggleOverlay } = useAssistantOverlay();
  const assistantThreadContext = useAssistantThreadContext();

  if (!assistantThreadContext) {
    return null;
  }

  if (!canUseAI(AIConfigEnabledFeaturesEnum.Assistant)) {
    return null;
  }
  return (
    <Button
      theme={ButtonTheme.Secondary}
      analyticsTrackingId="open-assistant"
      onClick={() => toggleOverlay()}
      className="pl-2"
    >
      <AssistantAvatar
        size={IconSize.Medium}
        className={"!p-0 !rounded w-7 h-7 flex items-center justify-center"}
        iconClassName="fill-white"
        background={AssistantAvatarBackground.None}
      />
      Ask Assistant
    </Button>
  );
};
