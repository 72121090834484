/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationNote,
    EvaluationNoteFromJSON,
    EvaluationNoteFromJSONTyped,
    EvaluationNoteToJSON,
} from './EvaluationNote';

/**
 * 
 * @export
 * @interface AIStaffCreateEvaluationNoteResponseBody
 */
export interface AIStaffCreateEvaluationNoteResponseBody {
    /**
     * 
     * @type {EvaluationNote}
     * @memberof AIStaffCreateEvaluationNoteResponseBody
     */
    evaluation_note: EvaluationNote;
}

export function AIStaffCreateEvaluationNoteResponseBodyFromJSON(json: any): AIStaffCreateEvaluationNoteResponseBody {
    return AIStaffCreateEvaluationNoteResponseBodyFromJSONTyped(json, false);
}

export function AIStaffCreateEvaluationNoteResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffCreateEvaluationNoteResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evaluation_note': EvaluationNoteFromJSON(json['evaluation_note']),
    };
}

export function AIStaffCreateEvaluationNoteResponseBodyToJSON(value?: AIStaffCreateEvaluationNoteResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evaluation_note': EvaluationNoteToJSON(value.evaluation_note),
    };
}

