/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRangeAbsoluteState,
    DateRangeAbsoluteStateFromJSON,
    DateRangeAbsoluteStateFromJSONTyped,
    DateRangeAbsoluteStateToJSON,
} from './DateRangeAbsoluteState';
import {
    DateRangeRelativeState,
    DateRangeRelativeStateFromJSON,
    DateRangeRelativeStateFromJSONTyped,
    DateRangeRelativeStateToJSON,
} from './DateRangeRelativeState';

/**
 * 
 * @export
 * @interface InsightsDateRange
 */
export interface InsightsDateRange {
    /**
     * 
     * @type {DateRangeAbsoluteState}
     * @memberof InsightsDateRange
     */
    absolute?: DateRangeAbsoluteState;
    /**
     * Date aggregation
     * @type {string}
     * @memberof InsightsDateRange
     */
    aggregation: InsightsDateRangeAggregationEnum;
    /**
     * Compare to the previous period
     * @type {boolean}
     * @memberof InsightsDateRange
     */
    compare_previous_period: boolean;
    /**
     * Mode of the date range
     * @type {string}
     * @memberof InsightsDateRange
     */
    mode: InsightsDateRangeModeEnum;
    /**
     * 
     * @type {DateRangeRelativeState}
     * @memberof InsightsDateRange
     */
    relative?: DateRangeRelativeState;
}

/**
* @export
* @enum {string}
*/
export enum InsightsDateRangeAggregationEnum {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months'
}/**
* @export
* @enum {string}
*/
export enum InsightsDateRangeModeEnum {
    Relative = 'relative',
    Absolute = 'absolute'
}

export function InsightsDateRangeFromJSON(json: any): InsightsDateRange {
    return InsightsDateRangeFromJSONTyped(json, false);
}

export function InsightsDateRangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightsDateRange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'absolute': !exists(json, 'absolute') ? undefined : DateRangeAbsoluteStateFromJSON(json['absolute']),
        'aggregation': json['aggregation'],
        'compare_previous_period': json['compare_previous_period'],
        'mode': json['mode'],
        'relative': !exists(json, 'relative') ? undefined : DateRangeRelativeStateFromJSON(json['relative']),
    };
}

export function InsightsDateRangeToJSON(value?: InsightsDateRange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'absolute': DateRangeAbsoluteStateToJSON(value.absolute),
        'aggregation': value.aggregation,
        'compare_previous_period': value.compare_previous_period,
        'mode': value.mode,
        'relative': DateRangeRelativeStateToJSON(value.relative),
    };
}

