import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";

import { usePostIncidentModalContext } from "../modals/PostIncidentActionModals";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps, PostIncidentActionModal } from "./types";

export const GiveShoutoutItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement | null => {
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      onClick={() =>
        setShowingModal({
          modal: PostIncidentActionModal.GiveShoutout,
          props,
        })
      }
      analyticsTrackingId="post-incident-task-give-shoutout"
      icon={TaskTypeToIcon(PostIncidentTaskOptionTaskTypeEnum.GiveShoutout)}
      label="Give shoutout"
    />
  );
};
