/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertSourceJiraOptions
 */
export interface AlertSourceJiraOptions {
    /**
     * Which projects in Jira should this alert source watch for new issues? IDs can either be IDs of the projects in Jira, or ID of catalog entries in the 'Jira Project' catalog type.
     * @type {Array<string>}
     * @memberof AlertSourceJiraOptions
     */
    project_ids: Array<string>;
}

export function AlertSourceJiraOptionsFromJSON(json: any): AlertSourceJiraOptions {
    return AlertSourceJiraOptionsFromJSONTyped(json, false);
}

export function AlertSourceJiraOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertSourceJiraOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'project_ids': json['project_ids'],
    };
}

export function AlertSourceJiraOptionsToJSON(value?: AlertSourceJiraOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project_ids': value.project_ids,
    };
}

