import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { useLocation } from "react-router";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";

import { useSummaryContext } from "../../body/SummaryContext";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps } from "./types";

export const UpdateIncidentSummaryItem = ({
  incidentTask,
  mode,
}: ItemProps): React.ReactElement | null => {
  const { setIsEditingSummary } = useSummaryContext();
  const { pathname, search } = useLocation();
  const navigate = useOrgAwareNavigate();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() => {
          // If we're on the incident details page, we can just set the state.
          if (pathname.includes("/incidents/")) {
            setIsEditingSummary(true);
            if (search.includes("tab=post-incident")) {
              // Switch to the overview tab, which is where the summary is.
              navigate(`/incidents/${incidentTask.incident_id}?tab=overview`);
            }
          } else {
            // Otherwise, we're on the post-incident flow page, so we need to
            // navigate to the incident details page.
            navigate(`/incidents/${incidentTask.incident_id}?`);
          }
        }}
        analyticsTrackingId="post-incident-task-update-incident-summary"
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.UpdateIncidentSummary,
        )}
        label="Update the summary"
      />
    </>
  );
};
