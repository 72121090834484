import { UserWithRoles } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { navigateToOverrideForEntry } from "@incident-shared/schedules/ScheduleOverview/common/navigateToOverrideForEntry";
import {
  isHolidayEntry,
  isHolidayPublicEntry,
  isScheduleEntry,
  isScheduleOverride,
  TimelineEntry,
} from "@incident-shared/schedules/ScheduleOverview/common/types";
import {
  ColorPalette,
  ColorPaletteEnum,
  getColorPalette,
} from "@incident-shared/utils/ColorPalettes";
import { useUniqueColorGenerator } from "@incident-shared/utils/uniqueColorContext";
import { Avatar, BadgeSize, IconEnum, IconSize } from "@incident-ui";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { Tooltip } from "@incident-ui/Tooltip/Tooltip";
import { uniq } from "lodash";
import { DateTime } from "luxon";
import { useContext } from "react";
import { formatTimestampLocale } from "src/utils/datetime";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";

import { CopyDebugID } from "../../../../utils/StaffOverlayProvider";
import { CLICK_NOT_DRAG, DragContext } from "./DragToOverrideIndicator";
import stripeBackground from "./hatched.png";
import styles from "./ScheduleEntryComponent.module.scss";
import { zoneName } from "./TimelineSectionV2/zoneName";

type TooltipProps =
  | {
      enableTooltip: boolean;
      enableCreateOverride?: boolean;
      entry: TimelineEntry;
      timezone: string;
      otherTimezones: string[];
    }
  | {
      enableTooltip?: never | false;
      entry?: never;
      timezone?: never;
      otherTimezones?: never;
      enableCreateOverride?: boolean; // you can only create overrides if you have a tooltip
    };

export const ScheduleEntryComponent = ({
  userId: userIdProp,
  user: userProp,
  width,
  className,
  roundedLeft = true,
  roundedRight = true,
  enableTooltip,
  isDraft,
  clipContent,
  entry,
  timezone,
  otherTimezones,
  enableCreateOverride,
  height = "28px",
  appearanceMode = "timeline",
}: {
  height?: string;
  className?: string;
  width?: string; // capturing the width separately and using it in styles as tailwind is not accepting it as a className always
  roundedLeft?: boolean;
  roundedRight?: boolean;
  enableTooltip?: boolean;
  clipContent?: boolean;
  isDraft?: boolean;
  // If user is provided, we'll use it, or we'll look-up the user, or we'll show no one on call
  user?: UserWithRoles;
  userId?: string;
  appearanceMode?: "timeline" | "calendar";
} & TooltipProps) => {
  const colorGenerator = useUniqueColorGenerator();

  const { data: userResp, isLoading } = useAPI(
    isHolidayPublicEntry(entry) || userIdProp === "NOBODY" || userProp
      ? null
      : "usersShow",
    {
      id: userIdProp ?? "",
    },
  );

  const navigate = useOrgAwareNavigate();

  const Wrapper = enableCreateOverride ? Button : "div";

  // We might be displaying 'nobody' or a public holiday, so remember this user might never get set.
  const maybeUser = userProp ?? userResp?.user;

  let displayName: string;
  let colorPalette: ColorPalette;
  if (isHolidayEntry(entry)) {
    displayName = entry.name;
    if (isHolidayPublicEntry(entry)) {
      colorPalette = getColorPalette(ColorPaletteEnum.Slate);
    } else {
      colorPalette = colorGenerator(entry.user_id);
    }
  } else {
    if (userIdProp === "NOBODY") {
      colorPalette = getColorPalette(ColorPaletteEnum.Slate);
      displayName = "No one";
    } else {
      const userIsDeactivated = (maybeUser?.state as string) === "deactivated";

      displayName = userIsDeactivated
        ? maybeUser?.name + " (deactivated)"
        : maybeUser?.name ?? "";
      colorPalette = colorGenerator(maybeUser?.id ?? "");
    }
  }

  const { background, text, hover, hoverBg } = colorPalette;

  let overrideId: string | undefined;
  if (isScheduleEntry(entry)) {
    overrideId = entry.override_id;
  } else if (isScheduleOverride(entry)) {
    // 'user_id' is a discrimnator to work out if we've got a ScheduleEntry or a ScheduleOverride
    // if it's an override, then we'll have 'user_id'.
    overrideId = entry.id;
  } else {
    // Otherwise, it must be a holiday, there's no override to edit.
    overrideId = undefined;
  }

  let timeLabel: string | undefined;
  if (appearanceMode === "calendar" && entry) {
    const startAt = DateTime.fromJSDate(entry.start_at).setZone(timezone);
    const endAt = DateTime.fromJSDate(entry.end_at).setZone(timezone);
    timeLabel = isRoughlyAllDay(startAt, endAt)
      ? "All day"
      : formatTimestampLocale({
          timeStyle: "short",
          timeZone: timezone,
          timestamp: entry.start_at,
        });
  }
  const canDrag = appearanceMode === "timeline";
  const { dragState } = useContext(DragContext);
  const isDragging = dragState != null;

  return (
    <Tooltip
      key={`tooltip-${userIdProp}-${entry?.start_at.toISOString()}`}
      content={
        // Disable all tooltips while dragging: they get in the way.
        enableTooltip && entry && !isDragging ? (
          <ScheduledShiftTooltip
            user={maybeUser}
            entry={entry}
            userId={userIdProp ?? maybeUser?.id}
            timezone={timezone || ""}
            otherTimezones={otherTimezones}
            enableCreateOverride={enableCreateOverride}
            overrideId={overrideId}
            canDrag={canDrag}
          />
        ) : null
      }
      bubbleProps={{
        className: "!p-0",
      }}
      delayDuration={180}
      light
      followMousePosition
      side="bottom"
      noMaxWidth
    >
      {/* Apply the width separately to the border, so that we don't include the border in width */}
      <Wrapper
        style={{ width, height }}
        className={tcx(
          "box-border relative !no-underline !border-0",
          // Don't allow selecting the names on these: it looks bad
          "select-none",
          {
            "hover:cursor-crosshairs": !enableCreateOverride,
          },
        )}
        theme={ButtonTheme.Unstyled}
        analyticsTrackingId={enableCreateOverride ? "schedule-entry" : null}
        onClick={
          enableCreateOverride && entry && !canDrag
            ? () => navigateToOverrideForEntry(navigate, entry)
            : undefined
        }
      >
        <div
          style={{
            minHeight: height,
          }}
          className={tcx(
            background,
            `flex flex-row justify-between items-center group min-w-full`,
            {
              "border-r-0": !roundedRight,
              "border-l-0": !roundedLeft,
              "rounded-r-md": roundedRight,
              "rounded-l-md": roundedLeft,
              [hover]: enableTooltip,
              [hoverBg]: enableTooltip,
              "animate-pulse": isDraft,
              "absolute left-0 right-0 top-0 bottom-0": clipContent,
            },
            className,
          )}
        >
          {overrideId && (
            <div
              style={{
                opacity: 0.6,
                backgroundImage: `url(${stripeBackground})`,
                backgroundSize: "32px 32px",
                mixBlendMode: "color-burn",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}

          {/* User name */}
          <div
            className={tcx(
              `text-xs text-clip whitespace-nowrap text-xs-bold overflow-hidden grow text-start`,
              text,
              {
                "mx-2": appearanceMode === "timeline",
                "mx-1": appearanceMode === "calendar",
              },
            )}
          >
            {isLoading ? null : displayName}
          </div>

          {timeLabel && (
            <div
              className={tcx(
                `text-xs text-clip whitespace-nowrap text-xs-medium shrink-0`,
                text,
                {
                  "mx-2": appearanceMode === "timeline",
                  "mx-1": appearanceMode === "calendar",
                },
              )}
            >
              {timeLabel}
            </div>
          )}
        </div>
      </Wrapper>
    </Tooltip>
  );
};

type ScheduleEntryTooltipProps = {
  entry: TimelineEntry;
  timezone: string;
  otherTimezones: string[];
  enableCreateOverride?: boolean;
  user: UserWithRoles | undefined;
  userId: string | undefined;
  overrideId: string | undefined;
  canDrag: boolean;
};

const ScheduledShiftTooltip = ({
  entry,
  timezone,
  otherTimezones,
  enableCreateOverride,
  canDrag,
  user,
  userId,
  overrideId,
}: ScheduleEntryTooltipProps) => {
  const navigate = useOrgAwareNavigate();

  const isHoliday = isHolidayEntry(entry);

  const { format, rows } = formatForShiftInTimeZones(
    entry,
    uniq([timezone, ...otherTimezones]),
  );

  return (
    <div
      className={tcx("flex flex-col p-3 gap-3 rounded-lg min-w-[280px]", {
        [styles.overrideCard]: overrideId,
      })}
    >
      {/* Top row: what is this? (user? nobody? holiday?) */}
      <div className={"flex flex-row gap-2 items-center text-base"}>
        {user && (
          <Avatar
            url={user.avatar_url}
            name={user.name}
            size={IconSize.Medium}
          />
        )}
        <div
          className={"text-content-primary font-medium max-w-[300px] truncate"}
        >
          {isHolidayEntry(entry)
            ? entry.name
            : userId === "NOBODY"
            ? "Nobody on call"
            : user?.name}
        </div>
      </div>
      <CopyDebugID id={userId ?? user?.id} />

      {/* Middle row: when is this? */}
      <div className="flex flex-col justify-start items-end gap-1">
        {rows.map(({ timeZone, ...rest }) => (
          <ShiftInTimeZoneRow
            key={timeZone}
            format={format}
            timeZone={timeZone}
            {...rest}
          />
        ))}
      </div>

      {/* Bottom row: what can you do? */}
      {isHoliday ? (
        <Button
          analyticsTrackingId={"create-override-tooltip"}
          className={"w-full flex justify-center"}
          onClick={() => navigateToOverrideForEntry(navigate, entry)}
        >
          Create override
        </Button>
      ) : overrideId ? (
        <div className={"inline-flex gap-2"}>
          <Button
            analyticsTrackingId={"edit-override-tooltip"}
            className={tcx("grow", CLICK_NOT_DRAG)}
            size={BadgeSize.Medium}
            onClick={() => navigateToOverrideForEntry(navigate, entry)}
          >
            <span className={"text-sm"}>Edit override</span>
          </Button>
          <Button
            analyticsTrackingId={"delete-override-tooltip"}
            theme={ButtonTheme.DestroySecondary}
            icon={IconEnum.Delete}
            iconProps={{
              size: IconSize.Medium,
            }}
            className={tcx(CLICK_NOT_DRAG)}
            size={BadgeSize.Medium}
            onClick={() => {
              navigate(
                `/on-call/schedules/${entry.schedule_id}/overrides/${overrideId}/delete`,
              );
            }}
            title={""}
          />
        </div>
      ) : enableCreateOverride ? (
        <div className="text-content-tertiary">
          Click {canDrag && "or drag "}to create an override
        </div>
      ) : null}
    </div>
  );
};

const ShiftInTimeZoneRow = ({
  startAt,
  endAt,
  timeZone,
  format,
}: {
  startAt: DateTime;
  endAt: DateTime;
  timeZone: string;
  format: ShiftDescriptionFormats;
}) => {
  // Fri, Jan 10th, 09:30 to Fri, Jan 17th, 09:30
  let text = (
    <>
      {formatTimestampLocale({
        timestamp: startAt,
        dateStyle: "short",
        addWeekday: true,
        timeStyle: "short",
      })}{" "}
      to{" "}
      {formatTimestampLocale({
        timestamp: endAt,
        dateStyle: "short",
        addWeekday: true,
        timeStyle: "short",
      })}
    </>
  );

  if (format === "same_day") {
    // Fri, Jan 17th, 09:30 to 17:30
    text = (
      <>
        {formatTimestampLocale({
          timestamp: startAt,
          dateStyle: "short",
          addWeekday: true,
        })}
        ,{" "}
        {formatTimestampLocale({
          timestamp: startAt,
          timeStyle: "short",
        })}{" "}
        to{" "}
        {formatTimestampLocale({
          timestamp: endAt,
          timeStyle: "short",
        })}
      </>
    );
  }

  if (format === "all_day") {
    // All day on Fri, Jan 17th
    text = (
      <>
        All day on{" "}
        {formatTimestampLocale({ timestamp: startAt, dateStyle: "short" })}
      </>
    );
  }

  return (
    <div className="flex w-full justify-between text-sm-normal">
      <div className="text-content-primary mr-3">{text}</div>
      <div className="text-content-tertiary font-mono">
        {zoneName(timeZone, "short")}
      </div>
    </div>
  );
};

type ShiftDescriptionFormats = "all_day" | "same_day" | "full";

const formatForShift = (
  entry: { start_at: Date; end_at: Date },
  timeZone: string,
) => {
  const startAt = DateTime.fromJSDate(entry.start_at).setZone(timeZone);
  const endAt = DateTime.fromJSDate(entry.end_at).setZone(timeZone);

  if (isRoughlyAllDay(startAt, endAt)) {
    return { format: "all_day", startAt, endAt };
  }
  if (startAt.hasSame(endAt, "day")) {
    return { format: "same_day", startAt, endAt };
  }
  return { format: "full", startAt, endAt };
};

const formatForShiftInTimeZones = (
  entry: { start_at: Date; end_at: Date },
  timeZones: string[],
) => {
  const bestFormats = timeZones.map((tz) => ({
    ...formatForShift(entry, tz),
    timeZone: tz,
  }));
  let shortestPossibleFormat: ShiftDescriptionFormats = "all_day";
  bestFormats.forEach(({ format }) => {
    if (format === "full") {
      // If any requires 'full', we have to degrade to that.
      shortestPossibleFormat = "full";
    } else if (format === "same_day" && shortestPossibleFormat !== "full") {
      // If any requires 'same_day', we can't go shorter than that (unless someone already needs `full`).
      shortestPossibleFormat = "same_day";
    }
  });

  return {
    format: shortestPossibleFormat,
    rows: bestFormats.map(({ timeZone, startAt, endAt }) => ({
      timeZone,
      startAt,
      endAt,
    })),
  };
};

const isRoughlyAllDay = (start: DateTime, end: DateTime) => {
  // If it's near enough to the start or end of the day, we'll call it all day.
  return (
    Math.abs(start.startOf("day").diff(start, "seconds").seconds) <= 90 &&
    Math.abs(end.endOf("day").diff(end, "seconds").seconds) <= 90
  );
};
