import {
  OrgAwareNavigate,
  useOrgAwareNavigate,
} from "@incident-shared/org-aware";
import React from "react";
import { Route, Routes } from "react-router";

import { useSuggestionFeatures } from "./ai/SuggestionAICards";
import { NudgesCreateDrawer } from "./create/NudgesCreateDrawer";
import { NudgesEditDrawer } from "./edit/NudgesEditDrawer";
import { SuggestionsListPage } from "./list/SuggestionsListPage";

export const SuggestionsRoute = (): React.ReactElement => {
  const navigate = useOrgAwareNavigate();
  const onClose = () => navigate("/settings/suggestions");
  const { features } = useSuggestionFeatures();

  const onEditFeatureClose = (feature: string) => () => {
    navigate(`/settings/suggestions/${feature}`);
  };

  return (
    <Routes>
      <Route path="" element={<SuggestionsListPage />}>
        <Route
          path="create"
          element={
            <NudgesCreateDrawer renameToChannelSuggestion onClose={onClose} />
          }
        />
        <Route
          path=":id/edit"
          element={
            <NudgesEditDrawer renameToChannelSuggestion onClose={onClose} />
          }
        />
        {features
          .filter((f) => f.Drawer != null)
          .map((feature) => (
            <>
              <Route
                key={feature.slug}
                path={feature.slug}
                element={<feature.Drawer feature={feature} onClose={onClose} />}
              >
                <Route
                  path={`create`}
                  element={
                    <NudgesCreateDrawer
                      renameToChannelSuggestion
                      onClose={onEditFeatureClose(feature.slug)}
                    />
                  }
                />
                <Route
                  path={`:id/edit`}
                  element={
                    <NudgesEditDrawer
                      renameToChannelSuggestion
                      onClose={onEditFeatureClose(feature.slug)}
                    />
                  }
                />
              </Route>
            </>
          ))}
      </Route>
      <Route path="*" element={<OrgAwareNavigate to="/404" replace />} />
    </Routes>
  );
};
