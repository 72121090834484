import { PostIncidentTaskOptionTaskTypeEnum } from "@incident-io/api";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import { usePostmortemName } from "src/utils/postmortem-name";

import { usePostIncidentModalContext } from "../modals/PostIncidentActionModals";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps, PostIncidentActionModal } from "./types";

export const CreatePostMortemItem = ({
  incidentTask,
  mode,
  ...props
}: ItemProps): React.ReactElement => {
  const { postmortemNameFormatted } = usePostmortemName(props.incident);

  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();
  const { setShowingModal } = usePostIncidentModalContext();

  return (
    <>
      <ButtonOrDropdownItem
        mode={mode}
        incidentTask={incidentTask}
        onClick={() =>
          setShowingModal({
            modal: featurePostmortemsInHouse
              ? PostIncidentActionModal.ExportPostMortem
              : PostIncidentActionModal.CreatePostMortem,
            props,
          })
        }
        analyticsTrackingId="post-incident-task-create-postmortem"
        icon={TaskTypeToIcon(
          PostIncidentTaskOptionTaskTypeEnum.CreatePostmortem,
        )}
        label={
          featurePostmortemsInHouse
            ? `Export ${postmortemNameFormatted}`
            : `Create ${postmortemNameFormatted}`
        }
      />
    </>
  );
};
