import {
  AISpan,
  useAiStaffServiceAiStaffListAiSpans,
  useAiStaffServiceAiStaffListProcessors,
} from "@incident-io/query-api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
} from "@incident-ui";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { ProcessorBackfillDrawer } from "./ProcessorBackfillDrawer";

type ProcessorFilterValues = {
  processor: string;
  resourceId: string;
  traceId: string;
};

export const ProcessorsListPage = () => {
  const formMethods = useForm<ProcessorFilterValues>({
    defaultValues: {},
  });

  const [showFilters, setShowFilters] = useState(false);

  const filters = formMethods.watch();

  const { data, isLoading } = useAiStaffServiceAiStaffListAiSpans({
    name: filters.processor ?? undefined,
    resourceId: filters.resourceId ?? undefined,
    traceId: filters.traceId ?? undefined,
    type: "processor",
  });

  const { data: processorsData } = useAiStaffServiceAiStaffListProcessors();
  const [showBackfillDrawer, setShowBackfillDrawer] = useState(false);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          theme={ButtonTheme.Primary}
          analyticsTrackingId={null}
          onClick={() => setShowBackfillDrawer(true)}
        >
          Run backfill
        </Button>
      }
    >
      {showBackfillDrawer && (
        <ProcessorBackfillDrawer onClose={() => setShowBackfillDrawer(false)} />
      )}
      <Form.Root formMethods={formMethods} onSubmit={() => null}>
        <div className="flex flex-row items-center gap-4">
          <div className="flex-center-y w-full justify-between">
            <div className="flex-center-y gap-4">
              <h2 className="text-lg font-semibold">Recent processor runs</h2>
            </div>
            <Button
              theme={ButtonTheme.Naked}
              onClick={() => setShowFilters(!showFilters)}
              analyticsTrackingId={null}
              icon={IconEnum.Filter}
            >
              <>Filters</>
              <Icon
                id={showFilters ? IconEnum.ChevronUp : IconEnum.ChevronDown}
                size={IconSize.Small}
              />
            </Button>
          </div>
        </div>
        {showFilters && (
          <Callout theme={CalloutTheme.Plain} showIcon={false}>
            <div className="w-full space-y-2">
              <StaticSingleSelectV2
                formMethods={formMethods}
                name="processor"
                placeholder="(e.g. ProcessIncidentFact)"
                options={
                  processorsData?.processors.map((processor) => ({
                    label: processor,
                    value: processor,
                  })) || []
                }
                label={"Processor"}
                isClearable={true}
              />
              <InputV2
                formMethods={formMethods}
                name="resourceId"
                label="Filter by resource ID (e.g. incident ID)"
              />
              <InputV2
                formMethods={formMethods}
                name="traceId"
                label="Filter by trace ID"
              />
            </div>
          </Callout>
        )}
        <LoadingWrapper loading={isLoading}>
          {data?.spans.length === 0 ? (
            <EmptyState content="No spans found" />
          ) : (
            <StackedList>
              {data?.spans.map((span) => (
                <ProcessorRow key={span.id} span={span} />
              ))}
            </StackedList>
          )}
        </LoadingWrapper>{" "}
      </Form.Root>
    </WorkbenchSubPageWrapper>
  );
};

const ProcessorRow = ({ span }: { span: AISpan }) => {
  const viewHref = `/workbench/processors/${span.id}`;
  return (
    <Button
      theme={ButtonTheme.Unstyled}
      analyticsTrackingId={null}
      className="flex flex-col gap-4 px-4 group"
      href={viewHref}
    >
      <div className="flex flex-between gap-2 w-full">
        <div className="flex gap-6 py-4">
          <LocalDateTime timestamp={span.created_at} className="font-medium" />
          <div className="max-w-[40vw] truncate">{span.name}</div>
          <div className="max-w-[40vw] truncate mono text-content-tertiary">
            {span.resource_id}
          </div>
        </div>
        <div className="grow" />
        <Button
          theme={ButtonTheme.Naked}
          icon={IconEnum.ArrowRight}
          href={viewHref}
          analyticsTrackingId={null}
          title="View thread"
          className="justify-end py-4 group-hover:text-slate-900"
        />
      </div>
    </Button>
  );
};
