import { SetupInfoNumberedList, SetupInfoSingleLineBlocks } from "./helpers";
import { AlertSourceSetupProps } from "./types";

export const AlertSourceEmailSetupInfo = ({
  alertSourceConfig,
}: AlertSourceSetupProps) => {
  return (
    <SetupInfoNumberedList>
      <SetupInfoSingleLineBlocks
        intro={
          <>
            This source has been configured with a unique email address that you
            can use to create alerts.
          </>
        }
        blocks={[
          {
            title: "Email address",
            code: `${alertSourceConfig.email_options?.email_address}`,
          },
        ]}
      />
      <p>
        Each email sent to this address will create a new alert. You can use
        details such as email subject and body to populate the alert attributes.
      </p>
    </SetupInfoNumberedList>
  );
};
