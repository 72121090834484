import { WorkflowSlimStateEnum as WorkflowStateEnum } from "@incident-io/api";
import { assertUnreachable } from "@incident-io/status-page-ui";
import { Badge, BadgeSize, BadgeTheme, IconEnum, Tooltip } from "@incident-ui";
import { LoadingWrapper } from "@incident-ui/LoadingWrapper/LoadingWrapper";

export const WorkflowsStatePill = ({
  state,
  isEnabling,
  isDisabling,
  errorMessage,
}: {
  state: WorkflowStateEnum;
  isEnabling: boolean;
  isDisabling: boolean;
  errorMessage?: string;
}) => {
  if (isDisabling) {
    <LoadingWrapper loading={isDisabling}>
      <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
        Disabling...
      </Badge>
    </LoadingWrapper>;
  }

  switch (state) {
    case WorkflowStateEnum.Active:
      return null;
    case WorkflowStateEnum.Draft:
      return (
        <Badge theme={BadgeTheme.Warning} size={BadgeSize.ExtraSmall}>
          Draft
        </Badge>
      );
    case WorkflowStateEnum.Disabled:
      return (
        <LoadingWrapper loading={isEnabling}>
          <Badge theme={BadgeTheme.Tertiary} size={BadgeSize.ExtraSmall}>
            Disabled
          </Badge>
        </LoadingWrapper>
      );
    case WorkflowStateEnum.Error:
      return (
        <Tooltip
          content={
            <div
              className="break-words"
              // This is a legit property, that makes the lines all roughly
              // equal widths. Tailwind is supporting it "soon" as text-balance.
              // Without this, `truncate` takes over and our error message is
              // unreadable.
              //
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              style={{ textWrap: "balance" }}
            >
              {errorMessage}
            </div>
          }
        >
          <Badge
            theme={BadgeTheme.Error}
            className={"cursor-pointer"}
            icon={IconEnum.Warning}
            size={BadgeSize.ExtraSmall}
          >
            Error
          </Badge>
        </Tooltip>
      );
    default:
      assertUnreachable(state);
  }
  return <> </>;
};
