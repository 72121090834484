import {
  Trigger,
  Workflow,
  WorkflowPayloadRunsOnIncidentsEnum as RunsOnIncidentsEnum,
  WorkflowStateEnum,
  WorkflowsUpdateWorkflowRequestBodyPendingRunStrategyEnum as PendingRunStrategyEnum,
} from "@incident-io/api";

import {
  referenceToFormOnceFor,
  workflowResponseBodyToFormWorkflow,
} from "../common/marshall";
import { ClonedWorkflow, WorkflowFormData } from "../common/types";

export const makeFormDefaultValues = ({
  workflow,
  template,
  defaultTrigger,
}: {
  workflow?: Workflow | ClonedWorkflow;
  template?: Workflow | ClonedWorkflow;
  defaultTrigger?: Trigger;
}): WorkflowFormData => {
  // We have three scenarios:
  // 1. We have a workflow, and we're populating the form state with a complete workflow
  // 2. We are creating from a template, and we're populating the form state with that
  // 3. We are 'starting from scratch' and want a clean slate.

  // 1. We have a workflow, marshall and be done
  if (workflow) {
    return workflowResponseBodyToFormWorkflow(workflow);
  }

  // 2. We have a template, marshall and apply some in-built defaults.
  if (template) {
    const marshalledTemplate = workflowResponseBodyToFormWorkflow(template);
    const res = {
      ...marshalledTemplate,
      state: WorkflowStateEnum.Draft,
      runs_on_incidents:
        (template.runs_on_incidents as unknown as RunsOnIncidentsEnum) ||
        RunsOnIncidentsEnum.CreatedAndActive,
      pending_run_strategy: PendingRunStrategyEnum.Leave,
    };

    return res;
  }

  // 3. We are starting from scratch, use our default form state.
  return {
    trigger: defaultTrigger?.name || undefined,
    state: WorkflowStateEnum.Draft,
    once_for: defaultTrigger
      ? defaultTrigger.default_once_for.map(referenceToFormOnceFor)
      : [],
    condition_groups: defaultTrigger?.default_condition_groups || [],
    runs_on_incidents: RunsOnIncidentsEnum.CreatedAndActive,
    // By default, when editing a workflow, any changes made to the workflow
    // should not be applied to any existing incidents.
    pending_run_strategy: PendingRunStrategyEnum.Leave,
    expressions: [],
    step_groups: [],
    name: "",
    include_private_incidents: false,
    include_retrospective_incidents: false,
    continue_on_step_error: false,
    include_test_incidents: false,
  };
};
