/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AzureDevOpsConfig,
    AzureDevOpsConfigFromJSON,
    AzureDevOpsConfigFromJSONTyped,
    AzureDevOpsConfigToJSON,
} from './AzureDevOpsConfig';

/**
 * 
 * @export
 * @interface IntegrationsAzureDevOpsGetConfigResponseBody
 */
export interface IntegrationsAzureDevOpsGetConfigResponseBody {
    /**
     * 
     * @type {AzureDevOpsConfig}
     * @memberof IntegrationsAzureDevOpsGetConfigResponseBody
     */
    config: AzureDevOpsConfig;
}

export function IntegrationsAzureDevOpsGetConfigResponseBodyFromJSON(json: any): IntegrationsAzureDevOpsGetConfigResponseBody {
    return IntegrationsAzureDevOpsGetConfigResponseBodyFromJSONTyped(json, false);
}

export function IntegrationsAzureDevOpsGetConfigResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegrationsAzureDevOpsGetConfigResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'config': AzureDevOpsConfigFromJSON(json['config']),
    };
}

export function IntegrationsAzureDevOpsGetConfigResponseBodyToJSON(value?: IntegrationsAzureDevOpsGetConfigResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'config': AzureDevOpsConfigToJSON(value.config),
    };
}

