export type PanelID = CustomPanelID | ExploDashboardID;

export enum CustomPanelID {
  Homepage = "homepage",
  InsightsHomepage = "insights_homepage",
}

export enum ExploDashboardID {
  OnCallPagerImpact = "QRA3mnoxEd", // Deprecated in favour of the below
  OnCallPagerImpactNative = "aBAMpMm1kv",
  OnCallPagerImpactExternal = "bo1qJD2Azw",
  OnCallSchedules = "2k1L0NJ1yJ",
  OnCallByUser = "bo1qpElxzw",
  OverviewSplitByCustomField = "ovARNaXAlV",
  OverviewIncidentCreation = "jDxeVEe1LG",
  OverviewIncidentCreationBySource = "am1D7GyxwO",
  OverviewIncidentCreationByUser = "b81Jvl51ag",
  PincFollowUpsForUser = "7Jx6rz0xwe",
  PincFollowUpsPerUser = "eVx8ezn1pn",
  PincPostIncidentTasks = "dK1odXeAl0",
  PincFollowUpsByCustomField = "b81JkB8Aag",
  PincFollowUpsCreated = "QgYwPXexvR",
  PincPostIncidentFlows = "2WYnbX7YZX",
  ResponseMTTXDurationMetrics = "aQ1yKkKY8o",
  ResponseMTTXDurationMetricByCustomField = "QRA3BJQ1Ed",
  ResponseMTTXDurationMetricBreakdown = "b910rMKYWQ",
  ResponseTimeSpentInIncidents = "d2AQNjLYb5",
  ResponseWorkloadSplitByCustomField = "d6x5PvWYoR",
  StatusPageViewerCount = "b910rN8YWQ",
  TeamsResponseDashboard = "XyAZm3B1dl",
  TeamsPINCDashboard = "2k1LPoo1yJ",
  IncidentPulseGraphDashboard = "ovARW3VYlV",
  IncidentWorkloadPerUserDashboard = "2k1LPJJ1yJ",
}

// Date aggregation options supported in Insights dashboards
export enum DateAggregation {
  Day = "day",
  Week = "week",
  Month = "month",
}
