/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationDataset,
    EvaluationDatasetFromJSON,
    EvaluationDatasetFromJSONTyped,
    EvaluationDatasetToJSON,
} from './EvaluationDataset';

/**
 * 
 * @export
 * @interface AIStaffCreateEvaluationDatasetResponseBody
 */
export interface AIStaffCreateEvaluationDatasetResponseBody {
    /**
     * 
     * @type {EvaluationDataset}
     * @memberof AIStaffCreateEvaluationDatasetResponseBody
     */
    dataset: EvaluationDataset;
}

export function AIStaffCreateEvaluationDatasetResponseBodyFromJSON(json: any): AIStaffCreateEvaluationDatasetResponseBody {
    return AIStaffCreateEvaluationDatasetResponseBodyFromJSONTyped(json, false);
}

export function AIStaffCreateEvaluationDatasetResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffCreateEvaluationDatasetResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataset': EvaluationDatasetFromJSON(json['dataset']),
    };
}

export function AIStaffCreateEvaluationDatasetResponseBodyToJSON(value?: AIStaffCreateEvaluationDatasetResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataset': EvaluationDatasetToJSON(value.dataset),
    };
}

