/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldOptionGroupPayload,
    CustomFieldOptionGroupPayloadFromJSON,
    CustomFieldOptionGroupPayloadFromJSONTyped,
    CustomFieldOptionGroupPayloadToJSON,
} from './CustomFieldOptionGroupPayload';

/**
 * 
 * @export
 * @interface CustomFieldOptionPayload
 */
export interface CustomFieldOptionPayload {
    /**
     * ID of the custom field this option belongs to
     * @type {string}
     * @memberof CustomFieldOptionPayload
     */
    custom_field_id: string;
    /**
     * 
     * @type {CustomFieldOptionGroupPayload}
     * @memberof CustomFieldOptionPayload
     */
    group?: CustomFieldOptionGroupPayload;
    /**
     * Unique identifier for the custom field option
     * @type {string}
     * @memberof CustomFieldOptionPayload
     */
    id?: string;
    /**
     * Sort key used to order the custom field options correctly
     * @type {number}
     * @memberof CustomFieldOptionPayload
     */
    sort_key: number;
    /**
     * Human readable name for the custom field option
     * @type {string}
     * @memberof CustomFieldOptionPayload
     */
    value: string;
}

export function CustomFieldOptionPayloadFromJSON(json: any): CustomFieldOptionPayload {
    return CustomFieldOptionPayloadFromJSONTyped(json, false);
}

export function CustomFieldOptionPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldOptionPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_field_id': json['custom_field_id'],
        'group': !exists(json, 'group') ? undefined : CustomFieldOptionGroupPayloadFromJSON(json['group']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sort_key': json['sort_key'],
        'value': json['value'],
    };
}

export function CustomFieldOptionPayloadToJSON(value?: CustomFieldOptionPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_field_id': value.custom_field_id,
        'group': CustomFieldOptionGroupPayloadToJSON(value.group),
        'id': value.id,
        'sort_key': value.sort_key,
        'value': value.value,
    };
}

