import {
  AIConfig,
  EngineScope,
  IncidentsBuildScopeContextEnum,
  NudgeNudgeTypeEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { Product } from "@incident-shared/billing";
import { conditionGroupsToGroupPayloads } from "@incident-shared/engine/conditions";
import {
  Button,
  ButtonTheme,
  GenericErrorMessage,
  SlackButtonPreview,
  SlackMessagePreview,
  ToastTheme,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerFooter,
} from "@incident-ui/Drawer/Drawer";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useIncidentScope } from "src/hooks/useIncidentScope";
import { useAPI, useAPIMutation } from "src/utils/swr";

import {
  AIFeatureConfigurationDrawer,
  AIFeatureDrawerTitle,
} from "../../ai/AIFeatureConfigurationDrawer";
import {
  buildSuggestedSummariesDefaultValues,
  ConfigureSuggestedSummariesFormData,
  SummariseIncidentsSettingsEditForm,
} from "../../ai/SummariseIncidentsSettingsEditModal";
import {
  AIPrivacyButton,
  AISuggestionFeatureDrawerProps,
  ToggleSuggestionButton,
} from "./SuggestionAICards";

export const SuggestedSummariesDrawer = (
  props: AISuggestionFeatureDrawerProps,
): React.ReactElement => {
  const { data: aiConfigResp, error: aiConfigError } = useAPI(
    "aIShowConfig",
    undefined,
  );

  const { scope, scopeLoading, scopeError } = useIncidentScope(
    IncidentsBuildScopeContextEnum.AiConfig,
  );

  const enabled = aiConfigResp?.config.incident_summaries_enabled;

  const loading = !aiConfigResp || scopeLoading;

  if (loading) {
    return (
      <Drawer width="large" onClose={props.onClose}>
        <DrawerContentsLoading />
      </Drawer>
    );
  }

  if (aiConfigError || scopeError) {
    return (
      <Drawer width="large" onClose={props.onClose}>
        <DrawerContents>
          <DrawerBody>
            <GenericErrorMessage error={aiConfigError || scopeError} />;
          </DrawerBody>
        </DrawerContents>
      </Drawer>
    );
  }

  return (
    <SuggestedSummariesDrawerContent
      aiConfig={aiConfigResp?.config}
      scope={scope}
      enabled={!!enabled}
      {...props}
    />
  );
};

const SuggestedSummariesDrawerContent = ({
  aiConfig,
  scope,
  enabled,
  onClose,
  feature,
}: {
  aiConfig: AIConfig;
  scope: EngineScope;
  enabled: boolean;
} & AISuggestionFeatureDrawerProps): React.ReactElement => {
  const formMethods = useForm<ConfigureSuggestedSummariesFormData>({
    defaultValues: buildSuggestedSummariesDefaultValues(aiConfig),
  });
  const showToast = useToast();

  const { trigger: toggleAIFeature } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        showToast({
          theme: ToastTheme.Success,
          title: "Setting updated",
        });
      },
      showErrorToast: "Toggling suggested summaries",
    },
  );

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, data: ConfigureSuggestedSummariesFormData) => {
      await apiClient.aIUpdateIncidentSummariesConfig({
        updateIncidentSummariesConfigRequestBody: {
          ...data,
          condition_groups: conditionGroupsToGroupPayloads(
            data.condition_groups,
          ),
        },
      });
    },
    {
      onSuccess: () => {
        showToast({
          title: "Settings saved",
          theme: ToastTheme.Success,
        });
        onClose();
      },
      setError: formMethods.setError,
    },
  );

  const [problemLabel, impactLabel, causesLabel, stepsToResolveLabel] =
    formMethods.watch([
      "problem_label",
      "impact_label",
      "causes_label",
      "steps_label",
    ]);

  const example = [
    [problemLabel, "We've run out of coffee in the office"],
    [impactLabel, "Our productivity has dropped by 50%"],
    [causesLabel, "The weekly delivery has not arrived"],
    [
      stepsToResolveLabel,
      "We've ordered more coffee and it will arrive tomorrow",
    ],
  ];

  return (
    <AIFeatureConfigurationDrawer
      onClose={onClose}
      title={
        <AIFeatureDrawerTitle
          {...feature}
          onClose={onClose}
          footer={
            <div className="flex items-center gap-2">
              <ToggleSuggestionButton
                enabled={!!enabled}
                disableModalTitle="Disable suggested summaries"
                disableModalContent="This means you'll no longer see suggested summaries in your incident channels or the dashboard"
                featureSlug={feature.slug}
                requiredScope={ScopeNameEnum.OrganisationSettingsUpdate}
                requiredProduct={Product.Response}
                onEnable={() =>
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfig,
                      incident_summaries_enabled: true,
                    },
                  })
                }
                onDisable={() =>
                  toggleAIFeature({
                    newConfig: {
                      ...aiConfig,
                      incident_summaries_enabled: false,
                    },
                  })
                }
              />
              <AIPrivacyButton />
            </div>
          }
        />
      }
      content={
        <Form.Root
          formMethods={formMethods}
          onSubmit={onSubmit}
          id="configure-suggested-summaries"
          genericError={genericError}
        >
          <div className="flex flex-col gap-6">
            <SummariseIncidentsSettingsEditForm
              formMethods={formMethods}
              scope={scope}
            />
          </div>
        </Form.Root>
      }
      preview={
        <div className="flex flex-col gap-3">
          <div className="text-sm-bold">Example</div>
          <SlackMessagePreview showTitle={false}>
            <div className="flex flex-col gap-2">
              To help people understand the full context here, we&apos;ve
              generated a new incident summary using your update:
              <hr />
              {example.map(([label, text], i) => (
                <div key={i}>
                  <span className="font-semibold">{label}</span>: {text}
                </div>
              ))}
              <div className="flex gap-2 flex-wrap w-full">
                <SlackButtonPreview
                  emoji="white_check_mark"
                  text="Accept and set"
                />
                <SlackButtonPreview emoji="pencil" text="Edit before setting" />
                <SlackButtonPreview emoji="x" text="Dismiss" />
              </div>
            </div>
          </SlackMessagePreview>
        </div>
      }
      footer={
        <DrawerFooter className="flex justify-end items-center gap-2">
          <Button analyticsTrackingId={null} onClick={onClose}>
            Cancel
          </Button>
          <Button
            theme={ButtonTheme.Primary}
            form="configure-suggested-summaries"
            type="submit"
            loading={saving}
            analyticsTrackingId={null}
          >
            Save
          </Button>
        </DrawerFooter>
      }
      width="large"
      nudgeTypes={[NudgeNudgeTypeEnum.Summary]}
    />
  );
};
