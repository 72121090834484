/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIRequestEvals,
    AIRequestEvalsFromJSON,
    AIRequestEvalsFromJSONTyped,
    AIRequestEvalsToJSON,
} from './AIRequestEvals';

/**
 * 
 * @export
 * @interface AIStaffShowAIRequestEvalResponseBody
 */
export interface AIStaffShowAIRequestEvalResponseBody {
    /**
     * 
     * @type {AIRequestEvals}
     * @memberof AIStaffShowAIRequestEvalResponseBody
     */
    eval: AIRequestEvals;
}

export function AIStaffShowAIRequestEvalResponseBodyFromJSON(json: any): AIStaffShowAIRequestEvalResponseBody {
    return AIStaffShowAIRequestEvalResponseBodyFromJSONTyped(json, false);
}

export function AIStaffShowAIRequestEvalResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffShowAIRequestEvalResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eval': AIRequestEvalsFromJSON(json['eval']),
    };
}

export function AIStaffShowAIRequestEvalResponseBodyToJSON(value?: AIStaffShowAIRequestEvalResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eval': AIRequestEvalsToJSON(value.eval),
    };
}

