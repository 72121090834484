import {
  Actor as ActorBody,
  Alert,
  ConditionGroup,
  EscalatedTimelineUserNotificationUpdateReasonEnum,
  Escalation,
  EscalationTimelineItemTypeEnum,
} from "@incident-io/api";
import {
  EscalatedTimelineUserNotification,
  EscalatedTimelineUserNotificationUpdate,
  EscalationTimelineItem,
} from "@incident-io/query-api";
import { ConditionGroupsList } from "@incident-shared/engine/conditions/ConditionGroupsList";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Avatar,
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { Actor } from "@incident-ui/Actor/Actor";
import _ from "lodash";
import React from "react";

import { joinSpansWithCommasAndConnectorWord } from "../../utils/utils";

const EscalatedLevel = ({
  item,
}: {
  item: EscalationTimelineItem;
}): JSX.Element => {
  if ((item.content.escalated_level?.notifications ?? []).length === 0) {
    return <></>;
  }

  return (
    <StackedList>
      {(item.content.escalated_level?.notifications ?? []).map(
        (notification) => (
          <UserNotification
            key={notification.user.id}
            notification={notification}
          />
        ),
      )}
    </StackedList>
  );
};

const NotificationStateGroup = ({
  methods,
  state,
  isFirst,
}: {
  methods: EscalatedTimelineUserNotification["method_updates"];
  state: EscalatedTimelineUserNotificationUpdate["state"];
  isFirst: boolean;
}) => {
  if (methods.length === 0) return null;

  // Special cases for specific failure reasons
  if (
    methods.every(
      (m) =>
        m.reason ===
        EscalatedTimelineUserNotificationUpdateReasonEnum.NoOnCallSeat,
    )
  ) {
    return (
      <span>was not notified as they don&apos;t have an on-call seat</span>
    );
  }
  if (
    methods.every(
      (m) =>
        m.reason ===
        EscalatedTimelineUserNotificationUpdateReasonEnum.DeactivatedUser,
    )
  ) {
    return <span>was not notified as they are deactivated</span>;
  }

  const stateText = {
    sent: isFirst ? "was notified via" : "and via",
    failed: isFirst
      ? "could not be notified via"
      : "they could not be notified via",
    rate_limited: isFirst
      ? "was rate limited for"
      : "they were rate limited for",
  }[state];

  if (!stateText) return null;

  return (
    <span>
      {stateText}{" "}
      {joinSpansWithCommasAndConnectorWord(
        methods.map((m) => m.display_label),
        state === "sent" ? "and" : "or",
      )}
    </span>
  );
};

const UserNotification = ({
  notification,
}: {
  notification: EscalatedTimelineUserNotification;
}) => {
  if (notification.is_live_call) {
    return (
      <StackedListItem
        title={
          <span className="inline-flex gap-2 items-center">
            <Avatar size={IconSize.Medium} url={notification.user.avatar_url} />
            Tried to call {notification.user.name}
          </span>
        }
        key={notification.user.id}
      />
    );
  }

  // If no method statuses, show the skipped message
  if (!notification.method_updates?.length) {
    return (
      <StackedListItem
        title={
          <span className="inline-flex gap-2 items-center">
            <Avatar size={IconSize.Medium} url={notification.user.avatar_url} />
            Skipped notifying {notification.user.name} immediately due to their
            notification rules
          </span>
        }
        key={notification.user.id}
      />
    );
  }

  // Order the states in a specific way: sent first, then failed, then rate_limited
  const stateOrder = {
    sent: 0,
    failed: 1,
    rate_limited: 2,
  };

  // Group methods by their state and sort them
  const groupedMethods = _.groupBy(notification.method_updates, "state");
  const sortedStates = Object.entries(groupedMethods).sort(
    ([a], [b]) => stateOrder[a] - stateOrder[b],
  );

  // Generate the notification text by combining all states
  const notificationText = (
    <>
      <Avatar size={IconSize.Medium} url={notification.user.avatar_url} />
      <span>
        {notification.user.name}{" "}
        {sortedStates.map(([state, methods], index) => (
          <React.Fragment key={state}>
            {index > 0 && <span>, </span>}
            <NotificationStateGroup
              state={state as EscalatedTimelineUserNotificationUpdate["state"]}
              methods={methods}
              isFirst={index === 0}
            />
          </React.Fragment>
        ))}
      </span>
    </>
  );

  return (
    <StackedListItem
      title={
        <span className="inline-flex gap-2 items-center">
          {notificationText}
        </span>
      }
      key={notification.user.id}
    />
  );
};

export const EscalationTimelineItemDisplayInfo: Record<
  EscalationTimelineItemTypeEnum,
  {
    icon: IconEnum;
    color: ColorPaletteEnum;
    Component: React.ComponentType<{
      item: EscalationTimelineItem;
      escalation: Escalation;
      alert?: Alert;
    }> | null;
    renderTitle?: (item: EscalationTimelineItem) => React.ReactNode;
  }
> = {
  [EscalationTimelineItemTypeEnum.Created]: {
    icon: IconEnum.Alert,
    color: ColorPaletteEnum.Red,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => {
      return (
        <div className="align-baseline">
          {item.title} by&nbsp;
          {item.content.created?.creator && (
            <Actor
              className="align-bottom"
              actor={item.content.created?.creator as unknown as ActorBody}
            />
          )}
          {(item.content.created?.deferred_seconds ?? 0) > 0 && (
            <>
              , deferred notifications for{" "}
              {(item.content.created?.deferred_seconds ?? 0) / 60} minutes
            </>
          )}
        </div>
      );
    },
  },
  [EscalationTimelineItemTypeEnum.EscalatedLevel]: {
    icon: IconEnum.Siren,
    color: ColorPaletteEnum.Red,
    Component: EscalatedLevel,
    renderTitle: (item: EscalationTimelineItem) => (
      <>
        {item.title} level {item.content.escalated_level?.level}
        {item.content.escalated_level?.reason === "level_timeout" &&
          " because no one responded in time"}
      </>
    ),
  },
  [EscalationTimelineItemTypeEnum.EscalatedChannel]: {
    icon: IconEnum.Siren,
    color: ColorPaletteEnum.Red,
    renderTitle: (item: EscalationTimelineItem) => (
      <span className="inline-flex items-center gap-1">
        Escalated to{" "}
        {joinSpansWithCommasAndConnectorWord(
          (item.content.escalated_channel?.notifications ?? []).map((n) => (
            <Button
              key={n.slack_channel_url}
              analyticsTrackingId={"escalation-timeline-open-channel"}
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.Small}
              icon={IconEnum.Slack}
              href={n.slack_channel_url}
            >
              #{n.slack_channel_name}
            </Button>
          )),
        )}
      </span>
    ),
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Acknowledged]: {
    icon: IconEnum.Checkmark,
    color: ColorPaletteEnum.Green,
    renderTitle: (item: EscalationTimelineItem) => {
      const acknowledgements =
        item.content.acknowledged?.acknowledgements ?? [];
      const channelAcks = acknowledgements.filter(
        (ack) => ack.slack_channel_url,
      );
      const userAcks = acknowledgements.filter((ack) => !ack.slack_channel_url);

      const channelAcknowledgements = joinSpansWithCommasAndConnectorWord(
        channelAcks.map((ack) => (
          <>
            {ack.user_name} acknowledged in #{ack.slack_channel_name}
          </>
        )),
      );

      const userNames = joinSpansWithCommasAndConnectorWord(
        userAcks.map((ack) => ack.user_name ?? ""),
      );

      const actionText = item.content.acknowledged?.is_live_call
        ? "was connected to the call"
        : userAcks.length > 0
        ? "acknowledged"
        : "";

      return (
        <>
          {channelAcks.length > 0 && channelAcknowledgements}
          {channelAcks.length > 0 && userAcks.length > 0 && " and "}
          {userAcks.length > 0 && userNames} {actionText}
          {item.content.acknowledged?.reason === "user_acked_externally" &&
            userAcks.length === 0 &&
            "Acknowledged in external provider"}
        </>
      );
    },
    Component: ({
      item,
      escalation,
    }: {
      item: EscalationTimelineItem;
      escalation: Escalation;
    }) =>
      item.content.acknowledged?.reason === "alert_marked_related" ? (
        <span className="inline-flex gap-1">
          Alert marked as related to{" "}
          <Button
            icon={IconEnum.Incident}
            className="pl-1"
            theme={ButtonTheme.Secondary}
            size={BadgeSize.Small}
            href={`/incidents/${escalation?.incident?.external_id}`}
            analyticsTrackingId="escalation-timeline--acked-by-related-alert--open-incident"
          >
            {escalation?.incident?.reference}
          </Button>
        </span>
      ) : (
        <></>
      ),
  },
  [EscalationTimelineItemTypeEnum.Nacked]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Red,
    renderTitle: (item: EscalationTimelineItem) => (
      <>{item.content.nacked?.user?.name} was unavailable</>
    ),
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Cancelled]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Slate,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.ConditionsEvaluated]: {
    icon: IconEnum.GitBranch,
    color: ColorPaletteEnum.Blue,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => (
      <span className="inline-flex gap-1 items-center">
        <span>
          Condition{" "}
          {item.content.conditions_evaluated?.outcome === "condition_true"
            ? "met"
            : item.content.conditions_evaluated?.outcome === "condition_false"
            ? "not met"
            : "failed to evaluate"}
          :
        </span>
        <ConditionGroupsList
          groups={
            item.content.conditions_evaluated
              ?.conditions as unknown as ConditionGroup[]
          }
          mini
          boxless
        />
      </span>
    ),
  },
  [EscalationTimelineItemTypeEnum.Repeated]: {
    icon: IconEnum.Loop,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Expired]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Slate,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.Triggered]: {
    icon: IconEnum.Flag,
    color: ColorPaletteEnum.Blue,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => (
      <span className="inline-flex items-center gap-1">
        Escalation sent to{" "}
        <Button
          analyticsTrackingId="escalation-timeline-open-escalation-path"
          size={BadgeSize.Small}
          theme={ButtonTheme.Tertiary}
          icon={IconEnum.EscalationPath}
          href={`/on-call/escalation-paths/${item.content.triggered?.escalation_path?.id}`}
        >
          {item.content.triggered?.escalation_path?.name}
        </Button>
      </span>
    ),
  },
  [EscalationTimelineItemTypeEnum.IncomingCall]: {
    icon: IconEnum.IncomingPhoneCall,
    color: ColorPaletteEnum.Blue,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.IncomingCallEnded]: {
    icon: IconEnum.IncomingPhoneCall,
    color: ColorPaletteEnum.Green,
    Component: null,
  },
  [EscalationTimelineItemTypeEnum.CalledUser]: {
    icon: IconEnum.Phone,
    color: ColorPaletteEnum.Blue,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => {
      if (!item.content.called_user) {
        return "Tried to call";
      }

      return (
        <span className="inline-flex gap-1 items-center">
          Called{" "}
          <Avatar
            size={IconSize.Small}
            url={item.content.called_user.user.avatar_url}
          />
          {item.content.called_user.user.name}
        </span>
      );
    },
  },
  [EscalationTimelineItemTypeEnum.NotifiedUserAfterLevelStarted]: {
    icon: IconEnum.Siren,
    color: ColorPaletteEnum.Red,
    renderTitle: (item: EscalationTimelineItem) => {
      return `Notified ${item.content.notification?.user.name ?? "user"}`;
    },
    Component: ({
      item,
    }: {
      item: EscalationTimelineItem;
      escalation: Escalation;
      alert?: Alert;
    }) => {
      if (!item.content.notification) {
        return "Tried to notify";
      }

      return (
        <StackedList className={"w-full flex-1 grow"}>
          <UserNotification notification={item.content.notification} />
        </StackedList>
      );
    },
  },
  [EscalationTimelineItemTypeEnum.FailedToCallUser]: {
    icon: IconEnum.CloseCircle,
    color: ColorPaletteEnum.Red,
    Component: null,
    renderTitle: (item: EscalationTimelineItem) => {
      if (!item.content.called_user) {
        return "Failed to make a call";
      }

      return (
        <span className="inline-flex gap-1 items-center">
          Failed to call{" "}
          <Avatar
            size={IconSize.Small}
            url={item.content.called_user.user.avatar_url}
          />
          {item.content.called_user.user.name}
        </span>
      );
    },
  },
};
