/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IncidentCallTranscriptsListTranscriptEntriesForCallSessionResponseBody,
    IncidentCallTranscriptsListTranscriptEntriesForCallSessionResponseBodyFromJSON,
    IncidentCallTranscriptsListTranscriptEntriesForCallSessionResponseBodyToJSON,
} from '../models';

export interface IncidentCallTranscriptsDestroyRequest {
    callSessionId: string;
}

export interface IncidentCallTranscriptsListTranscriptEntriesForCallSessionRequest {
    incidentCallSessionId: string;
    pageSize?: number;
    after?: string;
}

/**
 * 
 */
export class IncidentCallTranscriptsApi extends runtime.BaseAPI {

    /**
     * Delete an incident call transcription session, associated entries, and generated content
     * Destroy IncidentCallTranscripts
     */
    async incidentCallTranscriptsDestroyRaw(requestParameters: IncidentCallTranscriptsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.callSessionId === null || requestParameters.callSessionId === undefined) {
            throw new runtime.RequiredError('callSessionId','Required parameter requestParameters.callSessionId was null or undefined when calling incidentCallTranscriptsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_call_transcripts/{call_session_id}`.replace(`{${"call_session_id"}}`, encodeURIComponent(String(requestParameters.callSessionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an incident call transcription session, associated entries, and generated content
     * Destroy IncidentCallTranscripts
     */
    async incidentCallTranscriptsDestroy(requestParameters: IncidentCallTranscriptsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.incidentCallTranscriptsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * List all transcript entries for an incident call session
     * ListTranscriptEntriesForCallSession IncidentCallTranscripts
     */
    async incidentCallTranscriptsListTranscriptEntriesForCallSessionRaw(requestParameters: IncidentCallTranscriptsListTranscriptEntriesForCallSessionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncidentCallTranscriptsListTranscriptEntriesForCallSessionResponseBody>> {
        if (requestParameters.incidentCallSessionId === null || requestParameters.incidentCallSessionId === undefined) {
            throw new runtime.RequiredError('incidentCallSessionId','Required parameter requestParameters.incidentCallSessionId was null or undefined when calling incidentCallTranscriptsListTranscriptEntriesForCallSession.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.incidentCallSessionId !== undefined) {
            queryParameters['incident_call_session_id'] = requestParameters.incidentCallSessionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/incident_call_transcripts/entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentCallTranscriptsListTranscriptEntriesForCallSessionResponseBodyFromJSON(jsonValue));
    }

    /**
     * List all transcript entries for an incident call session
     * ListTranscriptEntriesForCallSession IncidentCallTranscripts
     */
    async incidentCallTranscriptsListTranscriptEntriesForCallSession(requestParameters: IncidentCallTranscriptsListTranscriptEntriesForCallSessionRequest, initOverrides?: RequestInit): Promise<IncidentCallTranscriptsListTranscriptEntriesForCallSessionResponseBody> {
        const response = await this.incidentCallTranscriptsListTranscriptEntriesForCallSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
