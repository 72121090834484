import {
  AggregateInvestigationScorecardsResponseBody,
  EvaluationBacktestEntry,
  InvestigationAggregateScorecard,
  useAiStaffServiceAiStaffAggregateInvestigationScorecards,
} from "@incident-io/query-api";
import {
  ContentBox,
  EmptyState,
  IconEnum,
  Spinner,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useEffect, useState } from "react";

import { useFilterEntries } from "../common/EvaluationFilterContext";
import { InvestigationScorecardGrades } from "./InvestigationScorecardGrades";

type InvestigationAggregateScorecardControllerProps = {
  investigationScorecardIds: string[];
  allEntries: EvaluationBacktestEntry[];
  showFilterBar?: boolean;
  className?: string;
};

export const InvestigationAggregateScorecardController = ({
  investigationScorecardIds,
  allEntries,
  className,
  showFilterBar = true,
}: InvestigationAggregateScorecardControllerProps) => {
  const toast = useToast();
  const [previousData, setPreviousData] =
    useState<AggregateInvestigationScorecardsResponseBody | null>(null);

  const {
    mutate: aggregateScorecard,
    data,
    isPending,
  } = useAiStaffServiceAiStaffAggregateInvestigationScorecards({
    onMutate: () => {
      // Store the current data before mutation starts
      setPreviousData(data || null);
    },
    onError: () => {
      toast({
        title: "Failed to aggregate scorecards",
        theme: ToastTheme.Error,
      });
    },
  });

  // Trigger the aggregation when the component mounts or IDs change
  useEffect(() => {
    if (investigationScorecardIds.length === 0) {
      return;
    }
    aggregateScorecard({
      requestBody: {
        investigation_scorecard_ids: investigationScorecardIds,
      },
    });
  }, [aggregateScorecard, investigationScorecardIds]);

  return (
    <InvestigationAggregateScorecardSection
      scorecard={
        isPending
          ? previousData?.aggregate_scorecard
          : data?.aggregate_scorecard
      }
      isLoading={isPending}
      totalScorecards={investigationScorecardIds.length}
      className={className}
      allEntries={allEntries}
      showFilterBar={showFilterBar}
    />
  );
};

export type InvestigationAggregateScorecardSectionProps = {
  scorecard?: InvestigationAggregateScorecard;
  allEntries: EvaluationBacktestEntry[];
  isLoading: boolean;
  totalScorecards: number;
  showFilterBar: boolean;
  className?: string;
};

export const InvestigationAggregateScorecardSection = ({
  scorecard,
  isLoading,
  allEntries,
  totalScorecards,
  showFilterBar,
  className,
}: InvestigationAggregateScorecardSectionProps) => {
  const filterEntries = useFilterEntries();

  // We want to find all the events that are in our 'superset' -> i.e.
  // excluding our non-grade filters, but ignoring our grade filters.
  // This allows us to display the distribution of the 'whole set' of events
  // so you can choose the right part of the distribution to filter on.
  const allEntriesWithNonGradeFilters = filterEntries(allEntries, {
    gradeFilters: {},
  });
  const allEvents = allEntriesWithNonGradeFilters.flatMap(
    (e) => e.investigation_scorecard?.grades.flatMap((g) => g.events) || [],
  );

  if (isLoading && !scorecard) {
    return (
      <div className={className}>
        <EmptyState
          title="Loading aggregate scorecard"
          content={`Aggregating ${totalScorecards} scorecards`}
          icon={IconEnum.Chart}
        />
      </div>
    );
  }

  const allScorecards = allEntries
    .map((e) => e.investigation_scorecard)
    .filter(Boolean);
  const hasAnyScorecards = allScorecards.length > 0;

  if (!scorecard || !hasAnyScorecards) {
    return (
      <div className={className}>
        <EmptyState
          title="No grades available"
          content="No grades were found in the aggregated scorecards"
          icon={IconEnum.Chart}
        />
      </div>
    );
  }

  return (
    <ContentBox className={className}>
      <div className="flex justify-between items-center p-4 border-b rounded-t-2 border-stroke text-xs-bold bg-surface-secondary">
        <div>Aggregating {totalScorecards} scorecards</div>
        {isLoading && <Spinner />}
      </div>
      <InvestigationScorecardGrades
        grades={scorecard.grades}
        warnings={scorecard.warnings}
        showFilterBar={showFilterBar}
        allEvents={allEvents}
      />
    </ContentBox>
  );
};
