import {
  PostIncidentTaskOptionTaskTypeEnum,
  PostmortemsSetDocumentStatusRequestBodyStatusEnum,
  PostmortemsUpdateStatusRequestBodyStatusEnum,
  ScopeNameEnum,
} from "@incident-io/api";
import { ToastTheme } from "@incident-ui";
import { ToastSideEnum } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { TaskTypeToIcon } from "src/components/settings/post-incident-flow/task-create-edit/TaskTypePicker";
import { usePostmortemName } from "src/utils/postmortem-name";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { usePostmortem } from "../usePostmortem";
import { ButtonOrDropdownItem } from "./ButtonOrDropdownItem";
import { ItemProps } from "./types";

export const CompletePostMortemItem = ({
  incident,
  incidentTask,
  onTaskComplete,
  onError,
  mode,
}: ItemProps): React.ReactElement => {
  const showToast = useToast();

  const { postmortemName, postmortemNameFormatted } =
    usePostmortemName(incident);

  const { postmortemsInHouse: featurePostmortemsInHouse } = useFlags();

  const { availableDocument: document } = usePostmortem(incident);
  // todo liz - does this need changing at all?
  const disabled = !document && !featurePostmortemsInHouse;

  const refetchPostIncidentFlowTasks = useAPIRefetch(
    "postIncidentFlowListTasks",
    { incidentId: incident.id },
  );
  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  // This should be ripped out when we delete the feature flagged code.
  const {
    trigger: completePostmortemLegacy,
    isMutating: isMutatingLegacy,
    genericError: errorLegacy,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident?.id ?? "" },
    async (
      apiClient,
      {
        id,
      }: {
        id: string;
      },
    ) => {
      await apiClient.postmortemsSetDocumentStatus({
        id,
        setDocumentStatusRequestBody: {
          status: PostmortemsSetDocumentStatusRequestBodyStatusEnum.Complete,
        },
      });

      await refetchPostIncidentFlowTasks();

      // Don't bother waiting for this
      refetchIncident();
    },
  );

  const {
    trigger: completePostmortem,
    isMutating: isMutating,
    genericError: error,
  } = useAPIMutation(
    "postmortemsListDocuments",
    { incidentId: incident?.id ?? "" },
    async (apiClient) => {
      await apiClient.postmortemsUpdateStatus({
        updateStatusRequestBody: {
          incident_id: incident.id,
          status: PostmortemsUpdateStatusRequestBodyStatusEnum.Complete,
        },
      });

      await refetchPostIncidentFlowTasks();

      // Don't bother waiting for this
      refetchIncident();
    },
    {
      onError: () => {
        // We're hitting races when people (1) export the PM and (2) update this PINC task.
        showToast({
          theme: ToastTheme.Error,
          title: "Please try again in a few seconds.",
          toastSide: ToastSideEnum.TopRight,
        });
      },
    },
  );

  useEffect(() => {
    if (!isMutatingLegacy && errorLegacy) {
      onError(errorLegacy);
    }
    if (!isMutating && error) {
      onError(error);
    }
    // We want to check for an error after every fetch completes, rather than
    // after the error changes so we can report whenever a mutation fails.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMutatingLegacy, isMutating]);

  return (
    <ButtonOrDropdownItem
      mode={mode}
      incidentTask={incidentTask}
      disabledExplanation={`${postmortemName} has not been created yet`}
      onClick={async () => {
        if (featurePostmortemsInHouse) {
          await completePostmortem({});
          onTaskComplete();
          return;
        } else {
          if (document) {
            await completePostmortemLegacy({
              id: document.id,
            });
            onTaskComplete();
            return;
          }
        }
      }}
      analyticsTrackingId="post-incident-task-complete-postmortem"
      requiredScope={ScopeNameEnum.PostMortemsMarkAsComplete}
      disabled={disabled || isMutatingLegacy || isMutating}
      icon={TaskTypeToIcon(
        PostIncidentTaskOptionTaskTypeEnum.CompletePostmortem,
      )}
      label={`Complete ${postmortemNameFormatted}`}
    />
  );
};
