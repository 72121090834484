/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegacyIssueTemplate
 */
export interface LegacyIssueTemplate {
    /**
     * The context in which the issue template will be used
     * @type {string}
     * @memberof LegacyIssueTemplate
     */
    context: LegacyIssueTemplateContextEnum;
    /**
     * The ID of the issue template
     * @type {string}
     * @memberof LegacyIssueTemplate
     */
    id: string;
    /**
     * The issue template has been marked as broken for a reason.
     * @type {string}
     * @memberof LegacyIssueTemplate
     */
    invalid_reason?: string;
    /**
     * The external tracker service that this template is for
     * @type {string}
     * @memberof LegacyIssueTemplate
     */
    issue_tracker_provider: LegacyIssueTemplateIssueTrackerProviderEnum;
    /**
     * Name of issue template
     * @type {string}
     * @memberof LegacyIssueTemplate
     */
    name: string;
}

/**
* @export
* @enum {string}
*/
export enum LegacyIssueTemplateContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}/**
* @export
* @enum {string}
*/
export enum LegacyIssueTemplateIssueTrackerProviderEnum {
    Asana = 'asana',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function LegacyIssueTemplateFromJSON(json: any): LegacyIssueTemplate {
    return LegacyIssueTemplateFromJSONTyped(json, false);
}

export function LegacyIssueTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegacyIssueTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'id': json['id'],
        'invalid_reason': !exists(json, 'invalid_reason') ? undefined : json['invalid_reason'],
        'issue_tracker_provider': json['issue_tracker_provider'],
        'name': json['name'],
    };
}

export function LegacyIssueTemplateToJSON(value?: LegacyIssueTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'id': value.id,
        'invalid_reason': value.invalid_reason,
        'issue_tracker_provider': value.issue_tracker_provider,
        'name': value.name,
    };
}

