import { ScopeNameEnum } from "@incident-io/api";
import {
  EvaluationDataset,
  UseAiStaffServiceAiStaffListEvaluationDatasetsKeyFn,
  useAiStaffServiceAiStaffShowEvaluationDataset,
  UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn,
  useAiStaffServiceAiStaffUpdateEvaluationDataset,
} from "@incident-io/query-api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { Button, ButtonTheme, ModalFooter, ToastTheme } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "src/components/@shared/forms";
import { useIdentity } from "src/contexts/IdentityContext";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { DatasetShowPageContent } from "./DatasetShowPageContent";

// Show a dataset, rolled-up statistics in its topsheet, and all the entries.
export const DatasetShowPage = () => {
  const { datasetId } = useParams<{ datasetId: string }>();
  const navigate = useOrgAwareNavigate();

  const { data, isLoading } = useAiStaffServiceAiStaffShowEvaluationDataset({
    id: datasetId || "",
  });
  const dataset = data?.dataset;
  const entries = data?.entries;

  // Modals
  const [renameModalOpen, setRenameModalOpen] = useState(false);

  if (!datasetId) {
    navigate("/workbench");
    return null;
  }

  return (
    <WorkbenchSubPageWrapper
      loading={isLoading}
      crumbs={[
        { title: "Workbench", to: `/workbench` },
        { title: "Datasets", to: `/workbench/datasets` },
      ]}
      backHref="/workbench/datasets"
      title={dataset?.name ?? ""}
      onEditTitle={() => setRenameModalOpen(true)}
      accessory={
        <div className={"flex flex-center gap-2"}>
          <Button
            analyticsTrackingId="dataset-add-entries"
            theme={ButtonTheme.Primary}
            href={`/incidents?dataset%5Bnot_in%5D=${datasetId}`}
          >
            Add entries
          </Button>
        </div>
      }
    >
      {dataset && entries && (
        <DatasetShowPageContent dataset={dataset} entries={entries} />
      )}

      {renameModalOpen && dataset && (
        <UpdateDatasetNameModal
          dataset={dataset}
          onClose={() => setRenameModalOpen(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const UpdateDatasetNameModal = ({
  dataset,
  onClose,
}: {
  dataset: EvaluationDataset;
  onClose: () => void;
}): React.ReactElement => {
  const { hasScope } = useIdentity();
  const canEdit = hasScope(ScopeNameEnum.IncidentsUpdate);

  const toast = useToast();
  const queryClient = useQueryClient();

  const formMethods = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: dataset.name,
    },
  });

  const { mutate: updateName, isPending: saving } =
    useAiStaffServiceAiStaffUpdateEvaluationDataset({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffListEvaluationDatasetsKeyFn(),
        });

        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn({
            id: dataset.id,
          }),
        });

        toast({
          title: "Dataset renamed",
          theme: ToastTheme.Success,
        });

        onClose();
      },
      onError: (error) => {
        toast({
          title: "Failed to rename dataset",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    });

  const handleSubmit = (data: { name: string }) => {
    updateName({
      id: dataset.id,
      requestBody: {
        name: data.name,
        description: dataset.description,
        segments: dataset.segments,
      },
    });
  };

  return (
    <Form.Modal
      onClose={onClose}
      title="Rename dataset"
      analyticsTrackingId="rename-incident-dataset"
      formMethods={formMethods}
      onSubmit={handleSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Rename"
          saving={saving}
          confirmButtonType="submit"
          onClose={onClose}
          disabled={!canEdit}
          disabledTooltipContent={
            "You do not have permission to update this dataset."
          }
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Enter a new name"
      />
    </Form.Modal>
  );
};
