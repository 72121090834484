/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationDataset,
    EvaluationDatasetFromJSON,
    EvaluationDatasetFromJSONTyped,
    EvaluationDatasetToJSON,
} from './EvaluationDataset';

/**
 * 
 * @export
 * @interface AIStaffListEvaluationDatasetsResponseBody
 */
export interface AIStaffListEvaluationDatasetsResponseBody {
    /**
     * 
     * @type {Array<EvaluationDataset>}
     * @memberof AIStaffListEvaluationDatasetsResponseBody
     */
    datasets: Array<EvaluationDataset>;
}

export function AIStaffListEvaluationDatasetsResponseBodyFromJSON(json: any): AIStaffListEvaluationDatasetsResponseBody {
    return AIStaffListEvaluationDatasetsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListEvaluationDatasetsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListEvaluationDatasetsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datasets': ((json['datasets'] as Array<any>).map(EvaluationDatasetFromJSON)),
    };
}

export function AIStaffListEvaluationDatasetsResponseBodyToJSON(value?: AIStaffListEvaluationDatasetsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datasets': ((value.datasets as Array<any>).map(EvaluationDatasetToJSON)),
    };
}

