/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IntegrationsAzureDevOpsGetConfigResponseBody,
    IntegrationsAzureDevOpsGetConfigResponseBodyFromJSON,
    IntegrationsAzureDevOpsGetConfigResponseBodyToJSON,
} from '../models';

export interface IntegrationsAzureDevOpsHandleInstallRequest {
    organisationId: string;
}

export interface IntegrationsAzureDevOpsHandleRedirectRequest {
    code?: string;
    state?: string;
    sessionState?: string;
    error?: string;
    errorDescription?: string;
    adminConsent?: boolean;
    tenant?: string;
}

/**
 * 
 */
export class IntegrationsAzureDevOpsApi extends runtime.BaseAPI {

    /**
     * Get Azure DevOps configuration for the organisation
     * GetConfig Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsGetConfigRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<IntegrationsAzureDevOpsGetConfigResponseBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/integrations/azure_devops/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationsAzureDevOpsGetConfigResponseBodyFromJSON(jsonValue));
    }

    /**
     * Get Azure DevOps configuration for the organisation
     * GetConfig Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsGetConfig(initOverrides?: RequestInit): Promise<IntegrationsAzureDevOpsGetConfigResponseBody> {
        const response = await this.integrationsAzureDevOpsGetConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     * HandleInstall Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsHandleInstallRaw(requestParameters: IntegrationsAzureDevOpsHandleInstallRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling integrationsAzureDevOpsHandleInstall.');
        }

        const queryParameters: any = {};

        if (requestParameters.organisationId !== undefined) {
            queryParameters['organisation_id'] = requestParameters.organisationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/azure_devops_install`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * HandleInstall Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsHandleInstall(requestParameters: IntegrationsAzureDevOpsHandleInstallRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsAzureDevOpsHandleInstallRaw(requestParameters, initOverrides);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsHandleRedirectRaw(requestParameters: IntegrationsAzureDevOpsHandleRedirectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.sessionState !== undefined) {
            queryParameters['session_state'] = requestParameters.sessionState;
        }

        if (requestParameters.error !== undefined) {
            queryParameters['error'] = requestParameters.error;
        }

        if (requestParameters.errorDescription !== undefined) {
            queryParameters['error_description'] = requestParameters.errorDescription;
        }

        if (requestParameters.adminConsent !== undefined) {
            queryParameters['admin_consent'] = requestParameters.adminConsent;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/azure_devops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle Microsoft redirect after successful authentication
     * HandleRedirect Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsHandleRedirect(requestParameters: IntegrationsAzureDevOpsHandleRedirectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.integrationsAzureDevOpsHandleRedirectRaw(requestParameters, initOverrides);
    }

    /**
     * Remove the Azure DevOps integration
     * HandleUninstall Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsHandleUninstallRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/azure_devops_uninstall`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove the Azure DevOps integration
     * HandleUninstall Integrations - Azure DevOps
     */
    async integrationsAzureDevOpsHandleUninstall(initOverrides?: RequestInit): Promise<void> {
        await this.integrationsAzureDevOpsHandleUninstallRaw(initOverrides);
    }

}
