/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IncidentGroundTruthCodeChange,
    IncidentGroundTruthCodeChangeFromJSON,
    IncidentGroundTruthCodeChangeFromJSONTyped,
    IncidentGroundTruthCodeChangeToJSON,
} from './IncidentGroundTruthCodeChange';

/**
 * 
 * @export
 * @interface IncidentGroundTruth
 */
export interface IncidentGroundTruth {
    /**
     * List of code changes that caused this incident
     * @type {Array<IncidentGroundTruthCodeChange>}
     * @memberof IncidentGroundTruth
     */
    causing_code_changes?: Array<IncidentGroundTruthCodeChange>;
    /**
     * Timestamp until which code changes are cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    causing_code_changes_cache_until?: Date;
    /**
     * When code changes were human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    causing_code_changes_verified_at?: Date;
    /**
     * 
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    created_at: Date;
    /**
     * Unique identifier
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    id: string;
    /**
     * ID of the incident this ground truth is associated with
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    incident_id: string;
    /**
     * Whether the investigation findings were useful to responders ('not_relevant', 'relevant', 'critical')
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance?: string;
    /**
     * Timestamp until which investigation relevance is cached
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_cache_until?: Date;
    /**
     * Explanation of why the investigation was or wasn't useful
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_reasoning?: string;
    /**
     * Tags describing how the investigation was useful (e.g. 'code-change', 'telemetry')
     * @type {Array<string>}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_tags?: Array<string>;
    /**
     * When investigation relevance was human-verified
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    investigation_relevance_verified_at?: Date;
    /**
     * ID of the owning organisation
     * @type {string}
     * @memberof IncidentGroundTruth
     */
    organisation_id: string;
    /**
     * 
     * @type {Date}
     * @memberof IncidentGroundTruth
     */
    updated_at: Date;
}

export function IncidentGroundTruthFromJSON(json: any): IncidentGroundTruth {
    return IncidentGroundTruthFromJSONTyped(json, false);
}

export function IncidentGroundTruthFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentGroundTruth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'causing_code_changes': !exists(json, 'causing_code_changes') ? undefined : ((json['causing_code_changes'] as Array<any>).map(IncidentGroundTruthCodeChangeFromJSON)),
        'causing_code_changes_cache_until': !exists(json, 'causing_code_changes_cache_until') ? undefined : (new Date(json['causing_code_changes_cache_until'])),
        'causing_code_changes_verified_at': !exists(json, 'causing_code_changes_verified_at') ? undefined : (new Date(json['causing_code_changes_verified_at'])),
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'incident_id': json['incident_id'],
        'investigation_relevance': !exists(json, 'investigation_relevance') ? undefined : json['investigation_relevance'],
        'investigation_relevance_cache_until': !exists(json, 'investigation_relevance_cache_until') ? undefined : (new Date(json['investigation_relevance_cache_until'])),
        'investigation_relevance_reasoning': !exists(json, 'investigation_relevance_reasoning') ? undefined : json['investigation_relevance_reasoning'],
        'investigation_relevance_tags': !exists(json, 'investigation_relevance_tags') ? undefined : json['investigation_relevance_tags'],
        'investigation_relevance_verified_at': !exists(json, 'investigation_relevance_verified_at') ? undefined : (new Date(json['investigation_relevance_verified_at'])),
        'organisation_id': json['organisation_id'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function IncidentGroundTruthToJSON(value?: IncidentGroundTruth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'causing_code_changes': value.causing_code_changes === undefined ? undefined : ((value.causing_code_changes as Array<any>).map(IncidentGroundTruthCodeChangeToJSON)),
        'causing_code_changes_cache_until': value.causing_code_changes_cache_until === undefined ? undefined : (value.causing_code_changes_cache_until.toISOString()),
        'causing_code_changes_verified_at': value.causing_code_changes_verified_at === undefined ? undefined : (value.causing_code_changes_verified_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'incident_id': value.incident_id,
        'investigation_relevance': value.investigation_relevance,
        'investigation_relevance_cache_until': value.investigation_relevance_cache_until === undefined ? undefined : (value.investigation_relevance_cache_until.toISOString()),
        'investigation_relevance_reasoning': value.investigation_relevance_reasoning,
        'investigation_relevance_tags': value.investigation_relevance_tags,
        'investigation_relevance_verified_at': value.investigation_relevance_verified_at === undefined ? undefined : (value.investigation_relevance_verified_at.toISOString()),
        'organisation_id': value.organisation_id,
        'updated_at': (value.updated_at.toISOString()),
    };
}

