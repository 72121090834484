// JS thinks the week starts on Sunday :( so we can't index our Monday-indexed enum directly
import {
  ScheduleConfigPayload,
  ScheduleEntry,
  ScheduleRotation,
  ScheduleRotationHandoverIntervalTypeEnum,
  WeekdayIntervalWeekdayEnum,
} from "@incident-io/api";
import { UpcomingRotaChange } from "@incident-shared/schedules/ScheduleOverview/common/types";
import _ from "lodash";
import { DateTime } from "luxon";
import { ErrorOption, FieldPath, UseFormReturn } from "react-hook-form";

import { getVersionId, isCurrentVersion } from "./marshall";
import {
  RotaFormData,
  RotaId,
  RotationVersions,
  ScheduleFormData,
} from "./types";

export const getWeekdayFromDayOfWeek = (
  dayOfWeek: WeekdayIntervalWeekdayEnum,
): number => {
  switch (dayOfWeek) {
    case WeekdayIntervalWeekdayEnum.Sunday:
      return 0;
    case WeekdayIntervalWeekdayEnum.Monday:
      return 1;
    case WeekdayIntervalWeekdayEnum.Tuesday:
      return 2;
    case WeekdayIntervalWeekdayEnum.Wednesday:
      return 3;
    case WeekdayIntervalWeekdayEnum.Thursday:
      return 4;
    case WeekdayIntervalWeekdayEnum.Friday:
      return 5;
    case WeekdayIntervalWeekdayEnum.Saturday:
      return 6;
    default:
      throw new Error("Invalid day of week");
  }
};

export const dateAndTimestringToUtcInstant = (
  date: DateTime,
  timeString: string,
  timezone: string,
): DateTime => {
  const [h, m] = timeString.split(":").map(Number);
  return date.setZone(timezone).set({ hour: h, minute: m });
};

// The three following functions have quite different purposes:
// getCurrentlyActiveRotas: to use when marshalling API data for our form
// getUpcomingRotaChanges: to power the change markers
// getCurrentAndUpcomingRotaVersions: to power the version picker and user promotion
// Their argument signature should make it clear you shouldn't use one or the other outside of form/API context!

// For an array of rota configs (that may include multiple versions for the same rota id), return rota from the original
// array that represent currently active rota i.e. not deleted, superseded, or taking effect in the future.
// If you're working with form data, you can use the version_id field on RotaFormData alongside isCurrentVersion.
export const getCurrentlyActiveRotas = ({
  rotas,
  now,
}: {
  rotas: ScheduleRotation[];
  now: DateTime;
}): ScheduleRotation[] => {
  return _.chain(rotas)
    .groupBy((r) => r.id)
    .mapValues((rotaVersions) => {
      const activeVersions = rotaVersions.filter(
        (rv) =>
          !rv.effective_from || DateTime.fromJSDate(rv.effective_from) <= now,
      );
      // If we only have one version which starts in the future, we imported this from an external provider.
      if (rotaVersions.length === 1 && activeVersions.length === 0) {
        return rotaVersions[0];
      }
      return _.maxBy(
        activeVersions,
        (rv) =>
          rv.effective_from ? rv.effective_from : new Date(-8640000000000000), // If effective_from is unset, make this lowest priority
      );
    })
    .values()
    .compact()
    .value();
};

export const getUpcomingRotaChanges = (
  rotas: ScheduleRotation[] | RotaFormData[],
  now: DateTime,
): UpcomingRotaChange[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore // TypeScript is being really annoying, both forms of rota have the correct fields
  const upcomingRotas = rotas.filter(
    (r) => r.effective_from && DateTime.fromJSDate(r.effective_from) > now,
  );

  return upcomingRotas.map((r) => ({
    at: DateTime.fromJSDate(r.effective_from as Date), // We've filtered out rotas without effective_from above
    rotaId: r.id ?? "",
  }));
};

export const flattenRotationsRecord = (
  rotationsRecord: Record<RotaId, RotationVersions>,
): RotaFormData[] => {
  return Object.entries(rotationsRecord).flatMap(([_, versions]) =>
    Object.values(versions),
  );
};

// getCurrentAndUpcomingRotaVersions takes rotations ratehr than a record because you'll likely have flattened
// your record for some reason anyway, so it saves doing it twice.
export const getCurrentAndUpcomingRotaVersions = (
  rotations: RotaFormData[],
  now: DateTime,
): RotaFormData[] => {
  return rotations.filter(
    (rotation) =>
      isCurrentVersion(rotation.version_id) ||
      (rotation.effective_from &&
        DateTime.fromJSDate(rotation.effective_from) > now),
  );
};

export const buildPreviewRotas = (
  entries: ScheduleEntry[],
  rotas: RotaFormData[] | ScheduleRotation[],
): ScheduleRotation[] => {
  return rotas.map((rota): ScheduleRotation & { isDraft: boolean } => {
    return {
      name: rota.name,
      isDraft: true,
      layers: _.chain(entries)
        .filter((e) => e.rotation_id === rota.id)
        .map((e) => ({ id: e.layer_id ?? "" }))
        .uniqBy("id")
        .value(),
      id: rota.id as string,
      // None of the below matters for the preview, we've already got the entries
      handover_start_at: new Date(),
      working_intervals: [],
      effective_from: rota.effective_from,
      user_ids: [],
      users: [],
      handovers: [
        {
          interval: 1,
          interval_type: ScheduleRotationHandoverIntervalTypeEnum.Weekly,
        },
      ],
    };
  });
};

// useDynamicArray lets us control how we append/remove/replace/insert values in an array in form data.
// This is because useFieldArray does not support dynamic path indexing, which we need for our use case.
export const useDynamicArray = ({
  formMethods,
}: {
  formMethods: UseFormReturn<ScheduleFormData>;
}) => {
  const append = (path, value) => {
    const currentValues = formMethods.watch(path) || [];
    formMethods.setValue(path, [...currentValues, value], {
      shouldDirty: true,
    });
  };

  const remove = (path, index) => {
    const currentValues = formMethods.watch(path) || [];
    const newValues = [...currentValues];
    newValues.splice(index, 1);
    formMethods.setValue(path, newValues, { shouldDirty: true });
  };

  const replace = (path, value) => {
    formMethods.setValue(path, value, { shouldDirty: true });
  };

  const insert = (path, index, value) => {
    const currentValues = formMethods.watch(path) || [];
    const newValues = [...currentValues];
    newValues.splice(index + 1, 0, value);
    formMethods.setValue(path, newValues, { shouldDirty: true });
  };

  return {
    append,
    remove,
    replace,
    insert,
  };
};

export const setScheduleError = (
  formMethods: UseFormReturn<ScheduleFormData>,
  rotations: Record<RotaId, RotationVersions>,
  payload: ScheduleConfigPayload | undefined,
  path: string,
  error: ErrorOption,
  options?: {
    shouldFocus: boolean;
  },
) => {
  // Remove config prefix as our frontend doesn't use it
  path = path.replace("config.", "");

  // Look for rotation paths in format rotations.${rotaId}.${rotaVersionId}
  const pathToTabIdxMatch = path.match(/rotations\.([^.]+)/);
  if (pathToTabIdxMatch) {
    let rotaId = Object.keys(rotations)[0];
    let versionId = "current";
    const rota = payload
      ? payload.rotations[Number(pathToTabIdxMatch[1])]
      : undefined;
    if (rota) {
      rotaId = rota.id as string;
      versionId = getVersionId({ effectiveFrom: rota.effective_from });
    }
    path = path.replace(/rotations\.[^.]+/, `rotations.${rotaId}.${versionId}`);
  }

  let fieldPath: FieldPath<ScheduleFormData>;

  // Handle user_ids -> users conversion
  if (path.search(/rotations\.[^.]+\.[^.]+\.user_ids/g) !== -1) {
    fieldPath = path.replace(
      "user_ids",
      "users",
    ) as FieldPath<ScheduleFormData>;
  } else if (path.includes("working_intervals.")) {
    // Handle working intervals errors
    fieldPath = (path.split("working_intervals.")[0] +
      "working_intervals") as FieldPath<ScheduleFormData>;
    error.message = error.message?.slice(0, -4);
  } else {
    fieldPath = path as FieldPath<ScheduleFormData>;
  }

  formMethods.setError(fieldPath, error, options);
};
