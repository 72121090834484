import { AIStaffListEvaluationNotesResourceTypeEnum } from "@incident-io/api";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { PageLayoutWithSidebar } from "@incident-ui/PageLayoutWithSidebar/PageLayoutWithSidebar";
import { useRef, useState } from "react";
import { useLocation } from "react-router";
import { EvaluationNotesWidget } from "src/components/workbench/evaluation-notes/EvaluationNotesWidget";
import {
  IncidentDrawer,
  IncidentHeaderModal,
} from "src/routes/legacy/IncidentRoute";
import { useQueryParams } from "src/utils/query-params";
import { useEventListener } from "use-hooks";

import {
  PageWidth,
  PageWrapper,
} from "../../@shared/layout/PageWrapper/PageWrapper";
import { IncidentBody } from "./body/IncidentBody";
import { IncidentHeader } from "./header/IncidentHeader";
import { useIncident } from "./hooks";
import { IncidentSidebar } from "./sidebar/IncidentSidebar";

export function IncidentComponent({
  incidentId,
}: {
  incidentId: string | null;
}): React.ReactElement {
  const [detailSidebarOpen, setDetailSidebarOpen] = useState(false);
  const { incident } = useIncident(incidentId);
  const navigate = useOrgAwareNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { pathname } = location;
  const modalInPath = Object.values(IncidentHeaderModal).find((m) =>
    pathname.includes(m),
  );
  const streamDrawerInPath = pathname.includes(IncidentDrawer.Streams);
  const tabBarRef = useRef<HTMLDivElement>(null);

  const setModalOpen = (modal: IncidentHeaderModal | null) => {
    if (modal) {
      const newPath =
        `${pathname + (pathname.endsWith("/") ? "" : "/")}${modal}?` +
        queryParams.toString();
      navigate(newPath, { replace: true });
    } else {
      navigate(pathname.replace(modalInPath ?? "", ""), { replace: true });
    }
  };

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "Esc" || (e.key === "Escape" && detailSidebarOpen)) {
      setDetailSidebarOpen(false);
    }
  });

  return (
    <PageWrapper
      width={PageWidth.Full}
      title={incident ? `INC-${incident.external_id}` : ""}
      headerNode={
        <IncidentHeader
          incident={incident}
          detailSidebarOpen={detailSidebarOpen}
          setDetailSidebarOpen={setDetailSidebarOpen}
          tabBarRef={tabBarRef}
        />
      }
      overflowY={false}
      overflowX={false}
      noPadding
      className="h-full"
    >
      {incidentId && (
        <EvaluationNotesWidget
          resourceId={incidentId}
          resourceType={AIStaffListEvaluationNotesResourceTypeEnum.Incident}
        />
      )}
      <PageLayoutWithSidebar
        drawerTitle={"Details"}
        isSidebarOpen={detailSidebarOpen && !streamDrawerInPath}
        setIsSidebarOpen={setDetailSidebarOpen}
        sidebarContent={
          <IncidentSidebar
            incidentId={incidentId}
            setModalOpen={setModalOpen}
            className={"px-6 pt-6 overflow-y-auto h-full"}
          />
        }
      >
        <IncidentBody incidentId={incidentId} tabBarRef={tabBarRef} />
      </PageLayoutWithSidebar>
    </PageWrapper>
  );
}
