/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AtlassianConnectAppInstallationInfo,
    AtlassianConnectAppInstallationInfoFromJSON,
    AtlassianConnectAppInstallationInfoFromJSONTyped,
    AtlassianConnectAppInstallationInfoToJSON,
} from './AtlassianConnectAppInstallationInfo';
import {
    JiraUser,
    JiraUserFromJSON,
    JiraUserFromJSONTyped,
    JiraUserToJSON,
} from './JiraUser';

/**
 * 
 * @export
 * @interface ConfluenceConfig
 */
export interface ConfluenceConfig {
    /**
     * All installations of connect app in Confluence sites for the current organisation
     * @type {Array<AtlassianConnectAppInstallationInfo>}
     * @memberof ConfluenceConfig
     */
    connect_app_installations: Array<AtlassianConnectAppInstallationInfo>;
    /**
     * 
     * @type {JiraUser}
     * @memberof ConfluenceConfig
     */
    connecting_user?: JiraUser;
    /**
     * Whether we managed to fetch data on the connecting user
     * @type {boolean}
     * @memberof ConfluenceConfig
     */
    connecting_user_available: boolean;
}

export function ConfluenceConfigFromJSON(json: any): ConfluenceConfig {
    return ConfluenceConfigFromJSONTyped(json, false);
}

export function ConfluenceConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfluenceConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connect_app_installations': ((json['connect_app_installations'] as Array<any>).map(AtlassianConnectAppInstallationInfoFromJSON)),
        'connecting_user': !exists(json, 'connecting_user') ? undefined : JiraUserFromJSON(json['connecting_user']),
        'connecting_user_available': json['connecting_user_available'],
    };
}

export function ConfluenceConfigToJSON(value?: ConfluenceConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connect_app_installations': ((value.connect_app_installations as Array<any>).map(AtlassianConnectAppInstallationInfoToJSON)),
        'connecting_user': JiraUserToJSON(value.connecting_user),
        'connecting_user_available': value.connecting_user_available,
    };
}

