import {
  EscalationPathTargetTypeEnum,
  IdentityUserStateEnum,
  UserOptionStateEnum,
  UserWithRolesStateEnum,
} from "@incident-io/api";
import { compact } from "lodash";
import uniq from "lodash/uniq";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { EscalationPathUserTargetFormData } from "src/components/escalation-paths/common/types";
import { useClient } from "src/contexts/ClientContext";

import { isOnCallSeatCount } from "../../../../hooks/useCanPromoteToOnCall";
import { useAPI } from "../../../../utils/swr";
import { isOnCallUser } from "../../../settings/users/users/utils";
import { PromotionState } from "./OnCallPromotionConfirmationModal";

interface UseOnCallPromotionStateProps {
  users: string[];
  userCache: {
    getHydratedUser: (id: string) =>
      | {
          state:
            | UserWithRolesStateEnum
            | UserOptionStateEnum
            | IdentityUserStateEnum;
          label: string;
        }
      | undefined;
  };
}

interface UseOnCallPromotionStateReturn<T extends FieldValues> {
  promotionState: PromotionState<T> | null;
  setPromotionState: (state: PromotionState<T>) => void;
  handlePromotion: (formData: T) => Promise<{
    shouldPromote: boolean;
    userIdsToPromote: string[];
  }>;
  currentSeatCount: number;
  onCallSeatCountLimit?: number;
}

export function useOnCallPromotionState<T extends FieldValues>({
  users,
  userCache,
}: UseOnCallPromotionStateProps): UseOnCallPromotionStateReturn<T> {
  const [promotionState, setPromotionState] = useState<PromotionState<T>>(null);
  const client = useClient();

  const {
    data: { seat_counts: seatCounts },
  } = useAPI("billingListSeatCounts", undefined, {
    fallbackData: { seat_counts: [] },
  });

  const currentSeatCount = seatCounts?.find(isOnCallSeatCount)?.used ?? 0;
  const onCallSeatCountLimit = seatCounts?.find(isOnCallSeatCount)?.limit;

  const handlePromotion = async (formData: T) => {
    const usersToPromote = compact(
      uniq(users).map(
        (id): EscalationPathUserTargetFormData =>
          ({
            ...userCache.getHydratedUser(id),
            type: EscalationPathTargetTypeEnum.User,
            value: id,
          }) as EscalationPathUserTargetFormData,
      ),
    ).filter((user) => user.value !== "NOBODY" && !isOnCallUser(user.state));

    if (usersToPromote.length > 0) {
      const { requires_billing_scope } =
        await client.billingRequiresBillingScopeToPromoteOnCallResponders({
          requiresBillingScopeToPromoteOnCallRespondersRequestBody: {
            number_of_promotions: usersToPromote.length,
          },
        });

      if (requires_billing_scope) {
        setPromotionState({
          formData,
          usersToPromote,
        });
        return { shouldPromote: false, userIdsToPromote: [] };
      }

      if (
        onCallSeatCountLimit !== undefined &&
        currentSeatCount + usersToPromote.length > onCallSeatCountLimit
      ) {
        setPromotionState({
          formData,
          usersToPromote,
          requiresSeatCountIncrease: true,
        });
        return { shouldPromote: false, userIdsToPromote: [] };
      }
    }

    return {
      shouldPromote: true,
      userIdsToPromote: usersToPromote.map((x) => x.value),
    };
  };

  return {
    promotionState,
    setPromotionState,
    handlePromotion,
    currentSeatCount,
    onCallSeatCountLimit,
  };
}
