import { IntegrationsListObject } from "@incident-shared/integrations";
import {
  Button,
  Callout,
  CalloutTheme,
  IconEnum,
  Link,
  Modal,
  ModalContent,
} from "@incident-ui";
import { CodeBlock } from "@incident-ui/CodeBlock/CodeBlock";
import React from "react";

import { useQueryParams } from "../../../../../utils/query-params";

export const ConnectToAzureDevOpsModal = ({
  integration,
  onClose,
}: {
  integration: IntegrationsListObject;
  onClose: () => void;
}): React.ReactElement | null => {
  const params = useQueryParams();
  const error = params.get("microsoft_error");

  return (
    <Modal
      title={`Connect to ${integration.provider_name}`}
      isOpen
      onClose={onClose}
      analyticsTrackingId={`connect-${integration.provider}-modal`}
    >
      <ModalContent>
        <div className="flex flex-col space-y-3">
          <p className="text-sm text-slate-700">
            We&apos;re about to send you to Microsoft authentication where (if
            you have permission) you can set up the incident.io integration.
          </p>
          <Callout theme={CalloutTheme.Info}>
            <div className={"flex flex-col gap-2"}>
              <p>
                You&apos;ll need to use a service account for this, as
                we&apos;ll be creating issues on behalf of this user.
              </p>
              <Link
                to={`https://help.incident.io/articles/5470687337-using-a-service-account-to-integrate-with-microsoft-outlook-and-teams`}
                className={"inline-block"}
                analyticsTrackingId={"connect-microsoft-help-link"}
                openInNewTab
              >
                <p className="font-normal mr-1">{`Learn more.`}</p>
              </Link>
            </div>
          </Callout>
          {error && (
            <Callout theme={CalloutTheme.Danger}>
              An error occurred when connecting to Microsoft. Please try again
              later.
              <CodeBlock className={"py-2"} title={"Error code"} code={error} />
            </Callout>
          )}
          <div className="flex flex-row w-full justify-center pt-2">
            <Button
              analyticsTrackingId="login-with-micr"
              href={integration.add_integration_url}
              className="inline-flex items-center cursor-pointer"
              icon={IconEnum.Microsoft}
            >
              Sign in with Microsoft
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
