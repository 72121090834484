import {
  EvaluationDataset,
  EvaluationDatasetSegment,
  UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn,
  useAiStaffServiceAiStaffUpdateEvaluationDataset,
} from "@incident-io/query-api";
import {
  AppliedFilters,
  AppliedFiltersBannerReadOnly,
  ExtendedFormFieldValue,
  filtersToListParams,
  incidentFiltersToExtendedFormValues,
  useFiltersContext,
} from "@incident-shared/filters";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  IconEnum,
  ModalFooter,
  StackedListItem,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { snakeCase } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { DatasetCollapsibleSection } from "./DatasetCollapsibleSection";

export const DatasetSegments = ({
  dataset,
  entriesBySegment,
  filters,
  className,
}: {
  dataset: EvaluationDataset;
  entriesBySegment: { [segmentName: string]: string[] };
  filters: ExtendedFormFieldValue[];
  className?: string;
}): React.ReactElement => {
  const [createSegmentModalOpen, setCreateSegmentModalOpen] = useState(false);

  return (
    <>
      {createSegmentModalOpen && (
        <CreateSegmentModal
          dataset={dataset}
          onClose={() => setCreateSegmentModalOpen(false)}
        />
      )}
      <DatasetCollapsibleSection
        title="Segments"
        icon={IconEnum.BarChart}
        accessory={
          <GatedButton
            size={BadgeSize.Small}
            icon={IconEnum.Add}
            analyticsTrackingId={null}
            onClick={() => setCreateSegmentModalOpen(true)}
            disabled={filters.length === 0}
            disabledTooltipContent="Please apply filters to create a segment"
          >
            Create segment
          </GatedButton>
        }
        content={dataset.segments.map((segment, index) => (
          <Segment
            key={index}
            segment={segment}
            dataset={dataset}
            entryIDs={entriesBySegment[segment.name]}
          />
        ))}
        isEmpty={dataset.segments.length === 0}
        emptyStateText="No segments created yet"
        className={className}
      />
    </>
  );
};

const Segment = ({
  segment,
  dataset,
  entryIDs,
}: {
  segment: EvaluationDatasetSegment;
  dataset: EvaluationDataset;
  entryIDs: string[] | undefined;
}) => {
  const { setFilters, availableFilterFields, filters } = useFiltersContext();
  const segmentFilters = incidentFiltersToExtendedFormValues(
    segment.filters,
    availableFilterFields,
  );
  const queryClient = useQueryClient();

  const isApplied = areFiltersEquivalent(filters, segmentFilters);
  const showToast = useToast();

  const { mutate: updateDataset, isPending: deleting } =
    useAiStaffServiceAiStaffUpdateEvaluationDataset({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn({
            id: dataset.id,
          }),
        });

        showToast({
          title: "Segment deleted",
          theme: ToastTheme.Success,
        });
      },
      onError: (error) => {
        showToast({
          title: "Failed to delete segment",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    });

  const onDelete = () => {
    updateDataset({
      id: dataset.id,
      requestBody: {
        ...dataset,
        segments: [...dataset.segments.filter((s) => s.name !== segment.name)],
      },
    });
  };

  return (
    <StackedListItem
      title={segment.name}
      accessory={
        <Button
          analyticsTrackingId={null}
          onClick={onDelete}
          theme={ButtonTheme.Tertiary}
          size={BadgeSize.Small}
          title="Delete"
          loading={deleting}
          icon={IconEnum.Delete}
        />
      }
      badgeNode={
        <div className="flex items-center gap-2">
          <AppliedFilters
            availableFilterFields={availableFilterFields}
            appliedFilters={segmentFilters}
            readonly={true}
            badgeTheme="slate"
            mini
          />
          {entryIDs !== undefined && (
            <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Secondary}>
              {entryIDs.length} incidents
            </Badge>
          )}
          {isApplied ? (
            <Badge size={BadgeSize.ExtraSmall} theme={BadgeTheme.Success}>
              Viewing
            </Badge>
          ) : (
            <Button
              analyticsTrackingId={null}
              onClick={() => setFilters(segmentFilters)}
              theme={ButtonTheme.Tertiary}
              size={BadgeSize.ExtraSmall}
              icon={IconEnum.Eye}
            >
              View segment
            </Button>
          )}
        </div>
      }
    />
  );
};

const CreateSegmentModal = ({
  dataset,
  onClose,
}: {
  dataset: EvaluationDataset;
  onClose: () => void;
}): React.ReactElement => {
  const formMethods = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: "",
    },
  });

  const showToast = useToast();
  const queryClient = useQueryClient();
  const { filters } = useFiltersContext();

  const { mutate: updateDataset, isPending: saving } =
    useAiStaffServiceAiStaffUpdateEvaluationDataset({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffShowEvaluationDatasetKeyFn({
            id: dataset.id,
          }),
        });

        showToast({
          title: "Segment created",
          theme: ToastTheme.Success,
        });

        onClose();
      },
      onError: (error) => {
        showToast({
          title: "Failed to create segment",
          theme: ToastTheme.Error,
          description: JSON.stringify(error),
        });
      },
    });

  const handleSubmit = (data: { name: string }) => {
    // For _reasons_ the codegen works differently when the list is embedded vs. when
    // it's used in the rest of our filter code, so we have to snake case all the keys
    const filterObj = filtersToListParams(filters);
    const snakeCasedFilterObj = {};
    Object.entries(filterObj).forEach(([key, val]) => {
      snakeCasedFilterObj[snakeCase(key)] = val;
    });
    updateDataset({
      id: dataset.id,
      requestBody: {
        name: dataset.name,
        description: dataset.description,
        segments: [
          ...dataset.segments,
          { name: data.name, filters: snakeCasedFilterObj },
        ],
      },
    });
  };

  return (
    <Form.Modal
      onClose={onClose}
      title="Create segment"
      analyticsTrackingId="create-segment"
      formMethods={formMethods}
      onSubmit={handleSubmit}
      footer={
        <ModalFooter
          confirmButtonText="Create"
          confirmButtonType="submit"
          onClose={onClose}
          saving={saving}
        />
      }
    >
      <InputV2
        required
        autoFocus
        name="name"
        formMethods={formMethods}
        label="Segment name"
      />
      <Form.InputWrapper label="Filters" name="filters">
        <AppliedFiltersBannerReadOnly
          totalNumberOfItems={null}
          itemsLabel={null}
          style="partOfPage"
        />
      </Form.InputWrapper>
    </Form.Modal>
  );
};

/**
 * Compares two arrays of ExtendedFormFieldValue to determine if they are functionally equivalent,
 * ignoring metadata fields that don't affect the filter's behavior.
 */
const areFiltersEquivalent = (
  filters1: ExtendedFormFieldValue[],
  filters2: ExtendedFormFieldValue[],
): boolean => {
  if (filters1.length !== filters2.length) {
    return false;
  }

  // Sort the arrays to ensure consistent comparison
  const identity = (f: ExtendedFormFieldValue) => {
    return f.field_id + f.field_key;
  };

  const sortFilters = (
    a: ExtendedFormFieldValue,
    b: ExtendedFormFieldValue,
  ) => {
    return identity(a).localeCompare(identity(b));
  };

  const sorted1 = filters1.sort(sortFilters);
  const sorted2 = filters2.sort(sortFilters);

  // Compare each filter
  return sorted1.every((filter1, index) => {
    const filter2 = sorted2[index];
    return JSON.stringify(filter1) === JSON.stringify(filter2);
  });
};
