import { useFiltersContext } from "@incident-shared/filters";
import { IntegrationConfigFor } from "@incident-shared/integrations";
import {
  GetInstalledIssueTrackers,
  IssueTrackerProviderEnum,
} from "@incident-shared/issue-trackers";
import {
  Checkbox,
  DropdownMenu,
  DropdownMenuItem,
  EmptyState,
  Heading,
  IconEnum,
  Loader,
  SearchBar,
  StaticSingleSelect,
  Txt,
} from "@incident-ui";
import { Button } from "@incident-ui";
import React, { useEffect, useState } from "react";
import { UseInfiniteScrollHookRefCallback } from "react-infinite-scroll-hook";
import {
  FollowUp,
  FollowUpStatusEnum,
  FollowUpsUpdateRequestBodyStatusEnum,
  Incident,
  IncidentsListSortByEnum,
  IncidentVisibilityEnum,
  RemindersCreateRequestBodyReminderTypeEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { usePrimaryCommsPlatformFeatures } from "src/hooks/usePrimaryCommsPlatform";
import { useAPI } from "src/utils/swr";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { SendRemindersModal } from "../../common/SendRemindersModal";
import { BulkExportToJiraCloudModal } from "../bulk-update/bulk-export/BulkExportToJiraCloudModal";
import { BulkExportToLinearModal } from "../bulk-update/bulk-export/BulkExportToLinearModal";
import { BulkFollowUpUpdatePriorityModal } from "../bulk-update/BulkUpdateFollowUpPriorityModal";
import { BulkStateChangeModal } from "../bulk-update/BulkUpdateFollowUpStateModal";
import { filterFollowUps } from "../common/followUpFilters";
import {
  usePoliciesWithViolations,
  useSortBy,
} from "../FollowUpFiltersProvider";
import styles from "../FollowUps.module.scss";
import { FollowUpsTable } from "./FollowUpsTable";

export const IncidentsFollowUpsList = ({
  incidents,
  allIncidentsLoaded,
  loadRef,
  totalFollowupsCount,
  refetchIncidents,
  refetchPolicyViolations,
}: {
  incidents: Incident[];
  allIncidentsLoaded: boolean;
  loadRef: UseInfiniteScrollHookRefCallback;
  totalFollowupsCount: number;
  refetchIncidents: () => Promise<void>;
  refetchPolicyViolations?: () => Promise<void>;
}): React.ReactElement => {
  const { identity } = useIdentity();
  const { platformHasReminders } = usePrimaryCommsPlatformFeatures();

  const {
    data: { slack_team_configs: slackTeamConfigs },
  } = useAPI("slackTeamConfigsList", undefined, {
    fallbackData: { slack_team_configs: [] },
  });

  const [bulkExportToProvider, setBulkExportToProvider] = useState<{
    provider: IssueTrackerProviderEnum;
    selectedFollowUpIDs: string[];
  } | null>(null);
  const [selectedFollowUpIDs, setSelectedFollowUpIDs] = useState<string[]>([]);

  const { filters } = useFiltersContext();
  const [sendReminders, setSendReminders] = useState<{
    selectedFollowUpIDs: string[];
  } | null>(null);
  const [changeStatus, setChangeStatus] = useState<{
    selectedFollowUpIDs: string[];
    targetStatus: FollowUpsUpdateRequestBodyStatusEnum;
  } | null>(null);
  const [changePriority, setChangePriority] = useState<{
    selectedFollowUpIDs: string[];
  } | null>(null);
  const [searchFilter, setSearchFilter] = useState<string>("");

  const {
    data: { policies, policy_violations: policyViolations },
  } = usePoliciesWithViolations();

  const { sortBy, setSortBy } = useSortBy();

  const { integrations } = useIntegrations();

  const installedIssueTrackers = GetInstalledIssueTrackers(integrations || []);

  const onChangeSearch = (str: string): void => {
    setSearchFilter(str || "");
  };

  const updateCallback = async () => {
    await Promise.all([
      refetchIncidents(),
      refetchPolicyViolations && refetchPolicyViolations(),
    ]);
  };

  const incidentsWithFollowUps = filterFollowUps(
    incidents,
    identity?.user_id,
    policyViolations || [],
    searchFilter,
  );

  const selectAllFollowUps = () => {
    const allFollowUpIds = incidentsWithFollowUps.reduce(
      (followUpsList: string[], inc) =>
        followUpsList.concat(inc?.follow_ups?.map((x) => x.id) || []),
      [],
    );
    setSelectedFollowUpIDs(allFollowUpIds);
  };

  const clearSelectedFollowUps = () => setSelectedFollowUpIDs([]);

  const compareFns = {
    [IncidentsListSortByEnum.NewestFirst]: (a: Incident, b: Incident): number =>
      b.reported_at.valueOf() - a.reported_at.valueOf(),
    [IncidentsListSortByEnum.OldestFirst]: (a: Incident, b: Incident): number =>
      a.reported_at.valueOf() - b.reported_at.valueOf(),
    [IncidentsListSortByEnum.TotalFollowUps]: (
      a: Incident,
      b: Incident,
    ): number => (b?.follow_ups?.length || 0) - (a?.follow_ups?.length || 0),
    [IncidentsListSortByEnum.TotalOutstandingFollowUps]: (
      a: Incident,
      b: Incident,
    ): number =>
      (b?.follow_ups?.filter((x) => x.status === FollowUpStatusEnum.Outstanding)
        .length || 0) -
      (a?.follow_ups?.filter((x) => x.status === FollowUpStatusEnum.Outstanding)
        .length || 0),
  };

  incidentsWithFollowUps.sort(compareFns[sortBy]);

  // Everytime the incident filters get updated, clear the selected follow-up IDs array.
  // If we don't do this, the count of "X follow-ups selected" might not be right anymore.
  useEffect(() => {
    setSelectedFollowUpIDs([]);
  }, [filters]);

  const allFollowUpsSelected =
    selectedFollowUpIDs.length ===
      incidentsWithFollowUps.reduce(
        (n, x) => n + (x?.follow_ups?.length || 0),
        0,
      ) && selectedFollowUpIDs.length !== 0;

  return (
    <>
      <div className="flex flex-col xl:flex-row xl:flex-between w-full !font-small gap-y-2 lg:gap-x-2">
        <Heading
          level={2}
          size="medium"
          className="grow shrink-0 flex flex-row"
        >
          <Checkbox
            id="select_all"
            checked={allFollowUpsSelected}
            onChange={() =>
              allFollowUpsSelected
                ? clearSelectedFollowUps()
                : selectAllFollowUps()
            }
            disabled={incidentsWithFollowUps.length === 0}
            className="block mr-2"
          />
          <div className="flex flex-row items-center">
            <div className="flex-shrink-0">Incidents with follow-ups</div>
          </div>
        </Heading>
        <div className="flex flex-col lg:flex-row lg:items-center gap-y-2 lg:gap-x-2">
          {/* When there are follow-ups selected, show bulk actions alongside the header */}
          {selectedFollowUpIDs.length > 0 ? (
            <>
              <div className="flex flex-row mr-2">
                <CountFollowUpsSelected
                  totalFollowupsCount={totalFollowupsCount}
                  numSelected={selectedFollowUpIDs.length}
                />{" "}
              </div>
              <DropdownMenu
                triggerButton={
                  <Button
                    analyticsTrackingId="follow-ups-bulk-actions-button"
                    className="mb-1"
                  >
                    Apply bulk actions
                  </Button>
                }
              >
                {platformHasReminders && (
                  <DropdownMenuItem
                    analyticsTrackingId="follow-ups-send-reminders"
                    onSelect={() => setSendReminders({ selectedFollowUpIDs })}
                    icon={IconEnum.SpeechImportant}
                    iconProps={{ className: "!w-5 !h-5 -ml-1" }}
                    label={"Send reminders"}
                  />
                )}
                <DropdownMenuItem
                  analyticsTrackingId="follow-ups-mark-as-completed"
                  onSelect={() =>
                    setChangeStatus({
                      selectedFollowUpIDs,
                      targetStatus:
                        FollowUpsUpdateRequestBodyStatusEnum.Completed,
                    })
                  }
                  icon={IconEnum.Tick}
                  iconProps={{
                    className:
                      "!w-5 !h-5 p-0.5 -ml-1 bg-green-surface text-green-content rounded-full",
                  }}
                  label={"Mark as completed"}
                />
                <DropdownMenuItem
                  analyticsTrackingId="follow-ups-mark-as-not-doing"
                  onSelect={() =>
                    setChangeStatus({
                      selectedFollowUpIDs,
                      targetStatus:
                        FollowUpsUpdateRequestBodyStatusEnum.NotDoing,
                    })
                  }
                  icon={IconEnum.Close}
                  iconProps={{
                    className:
                      "!w-5 !h-5 p-0.5 -ml-1 bg-surface-tertiary text-slate-600 rounded-full",
                  }}
                  label={"Mark as not doing"}
                />
                {identity?.feature_gates.follow_up_priorities && (
                  <DropdownMenuItem
                    analyticsTrackingId="follow-ups-set-priority"
                    onSelect={() => setChangePriority({ selectedFollowUpIDs })}
                    icon={IconEnum.Warning}
                    iconProps={{ className: "!w-5 !h-5 -ml-1" }}
                    label={"Set priority"}
                  />
                )}
                {installedIssueTrackers.map((integration) => {
                  // TODO(PINC-2965): We don't support bulk-export for v2 issue trackers right now, it's coming soon!
                  if (
                    [
                      IssueTrackerProviderEnum.ClickUp,
                      IssueTrackerProviderEnum.Github,
                      IssueTrackerProviderEnum.Gitlab,
                      IssueTrackerProviderEnum.JiraServer,
                    ].some((provider) => provider === integration.provider)
                  ) {
                    return null;
                  }

                  return (
                    <DropdownMenuItem
                      analyticsTrackingId="follow-ups-bulk-export"
                      analyticsTrackingMetadata={{
                        provider: integration.provider,
                      }}
                      key={integration.provider}
                      onSelect={() =>
                        setBulkExportToProvider({
                          provider: integration.provider,
                          selectedFollowUpIDs,
                        })
                      }
                      icon={IntegrationConfigFor(integration.provider).icon}
                      iconProps={{
                        className: "!w-5 !h-5 -ml-1",
                      }}
                      label={`Create or link a ${
                        IntegrationConfigFor(integration.provider).label
                      } issue`}
                      disabled={integration.disabled}
                      tooltipContent={
                        integration.disabled
                          ? `There is a problem with your connection to ${
                              IntegrationConfigFor(integration.provider).label
                            }`
                          : ""
                      }
                    />
                  );
                })}
              </DropdownMenu>
            </>
          ) : (
            // Otherwise, show the option to sort follow-ups and search.
            <>
              <Txt grey className="lg:ml-4 shrink-0">
                Sort by
              </Txt>
              <StaticSingleSelect
                className="min-w-[230px]"
                options={sortByEnumOptions}
                value={sortBy}
                isClearable={false}
                disabled={false}
                // @ts-expect-error we are using an enum, which makes TS confused.
                onChange={(value: IncidentsListSortByEnum) => {
                  setSortBy(value);
                }}
              />
              <SearchBar
                id="search_follow-ups"
                placeholder={"Search follow-ups"}
                onChange={onChangeSearch}
                value={searchFilter}
                className={tcx(
                  "bg-white lg:ml-4 min-w-max",
                  styles.makeInputTextSizeCorrect,
                )}
              />
            </>
          )}
        </div>
      </div>
      {incidentsWithFollowUps.map((inc) => {
        const slackTeam =
          (slackTeamConfigs ?? []).length > 1
            ? slackTeamConfigs?.find(
                (config) => config.slack_team_id === inc.slack_team_id,
              )
            : undefined;
        return (
          <FollowUpsTable
            key={inc.id}
            incident={inc}
            slackTeam={slackTeam}
            policies={policies || []}
            policyViolations={policyViolations || []}
            installedIssueTrackers={installedIssueTrackers}
            selectedFollowUpIDs={selectedFollowUpIDs}
            setSelectedFollowUpIDs={setSelectedFollowUpIDs}
            refetchIncidents={refetchIncidents}
          />
        );
      })}
      {/* if there isn't anything in the list, let them know why */}
      {incidentsWithFollowUps.length === 0 && (
        <EmptyIncidentFollowUpsList allIncidentsLoaded={allIncidentsLoaded} />
      )}

      {bulkExportToProvider?.provider && (
        <BulkExportToProviderModal
          provider={bulkExportToProvider.provider}
          selectedFollowUpIDs={bulkExportToProvider.selectedFollowUpIDs}
          incidents={incidents}
          onClose={async () => {
            // clear our selected follow-ups
            setSelectedFollowUpIDs([]);
            await refetchIncidents();
            setBulkExportToProvider(null);
          }}
          onCancel={() => {
            setBulkExportToProvider(null);
          }}
          updateCallback={updateCallback}
        />
      )}
      {sendReminders != null && (
        <SendRemindersModal
          selectedResourceIDs={sendReminders.selectedFollowUpIDs}
          numResourcesToIgnore={countFollowUpsToIgnore(
            incidents
              .flatMap((i) => i.follow_ups)
              .filter((x) => x) as FollowUp[],
            sendReminders.selectedFollowUpIDs,
          )}
          resourceType={RemindersCreateRequestBodyReminderTypeEnum.FollowUp}
          onClose={async () => {
            // clear our selected follow-ups
            setSelectedFollowUpIDs([]);
            await refetchIncidents();
            setSendReminders(null);
          }}
          onCancel={() => setSendReminders(null)}
        />
      )}
      {changePriority && (
        <BulkFollowUpUpdatePriorityModal
          followUps={
            incidents
              .flatMap((i) => i.follow_ups)
              .filter((x) => x) as FollowUp[]
          }
          selectedFollowUpIDs={changePriority.selectedFollowUpIDs}
          onClose={async () => {
            // clear our selected follow-ups
            setSelectedFollowUpIDs([]);
            await refetchIncidents();
            setChangePriority(null);
          }}
          onCancel={() => setChangePriority(null)}
        />
      )}
      {changeStatus != null && (
        <BulkStateChangeModal
          followUps={
            incidents
              .flatMap((i) => i.follow_ups)
              .filter((x) => x) as FollowUp[]
          }
          selectedFollowUpIDs={changeStatus.selectedFollowUpIDs}
          onClose={async () => {
            await refetchIncidents();
            setChangeStatus(null);
            // clear our selected follow-ups
            setSelectedFollowUpIDs([]);
          }}
          onCancel={() => setChangeStatus(null)}
          targetStatus={changeStatus.targetStatus}
        />
      )}
      {/* if we're still loading incidents, show something at the bottom to say we're still going! */}
      {!allIncidentsLoaded ? (
        <div ref={loadRef}>
          <Loader />
        </div>
      ) : null}
    </>
  );
};

const CountFollowUpsSelected = ({
  numSelected,
  totalFollowupsCount,
}: {
  numSelected: number;
  totalFollowupsCount: number;
}): React.ReactElement => {
  const followupsOrPlural = numSelected === 1 ? "follow-up" : "follow-ups";

  if (numSelected === totalFollowupsCount) {
    return (
      <Txt grey inline>
        <Txt bold inline>
          {numSelected}
        </Txt>{" "}
        {followupsOrPlural} selected
      </Txt>
    );
  }

  return (
    <Txt grey inline>
      <Txt bold inline>
        {numSelected}
      </Txt>{" "}
      {followupsOrPlural} {"selected out of "}
      <Txt bold inline>
        {totalFollowupsCount}
      </Txt>
      {" total"}
    </Txt>
  );
};

const countFollowUpsToIgnore = (
  allFollowUps: FollowUp[],
  selectedFollowUpIDs: string[],
) => {
  const followUpsToRemindAbout = allFollowUps.filter((followUp) => {
    if (!selectedFollowUpIDs.includes(followUp.id)) {
      return false;
    }
    // Only remind people about follow-ups that are open
    return followUp.status === FollowUpStatusEnum.Outstanding;
  });

  return selectedFollowUpIDs.length - followUpsToRemindAbout.length;
};

const sortByEnumOptions: {
  sort_key: string;
  value: IncidentsListSortByEnum;
  label: string;
}[] = [
  {
    sort_key: "0",
    value: IncidentsListSortByEnum.NewestFirst,
    label: "Newly reported incidents",
  },
  {
    sort_key: "1",
    value: IncidentsListSortByEnum.OldestFirst,
    label: "Oldest reported incidents",
  },
  {
    sort_key: "2",
    value: IncidentsListSortByEnum.TotalFollowUps,
    label: "Total follow-ups",
  },
  {
    sort_key: "3",
    value: IncidentsListSortByEnum.TotalOutstandingFollowUps,
    label: "Total open follow-ups",
  },
];

// EmptyIncidentFollowUpsList provides an explanation message when the list of incidents with
// follow-ups is empty.
const EmptyIncidentFollowUpsList = ({
  allIncidentsLoaded,
}: {
  allIncidentsLoaded: boolean;
}): React.ReactElement => {
  // Don't do anything if we're still loading, because there might be more to come.
  if (!allIncidentsLoaded) {
    return <></>;
  }

  return (
    <EmptyState
      icon={IconEnum.Filter}
      className="mt-4 grow"
      title="No matching follow-ups"
      content="We couldn't find any follow-ups that match your filters."
    />
  );
};

const BulkExportToProviderModal = ({
  provider,
  selectedFollowUpIDs,
  incidents,
  onClose,
  onCancel,
  updateCallback,
}: {
  provider: IssueTrackerProviderEnum;
  selectedFollowUpIDs: string[];
  incidents: Incident[];
  onClose: () => void;
  onCancel: () => void;
  updateCallback: () => Promise<void>;
}): React.ReactElement | null => {
  const followUpsToExport = incidents
    .flatMap((inc) => inc.follow_ups || [])
    .filter((followUp) => {
      if (!selectedFollowUpIDs.includes(followUp.id)) {
        return false;
      }
      // Only export the ones that haven't already been exported.
      return !followUp.external_issue_reference;
    });

  // We ignore the ones that have already been exported.
  const numFollowUpsAlreadyExported =
    selectedFollowUpIDs.length - followUpsToExport.length;

  const numFollowUpsToExportFromPrivate = followUpsToExport.filter(
    (followUp) => {
      const incident = incidents.find(
        (incident) => incident.id === followUp?.incident_id,
      );
      // This shouldn't really happen, but if it does then we risk not warning someone
      // that they're exporting a follow-up from a private incident.
      if (!incident) {
        throw new Error("could not find incident for follow-up");
      }
      return incident?.visibility === IncidentVisibilityEnum.Private;
    },
  ).length;

  switch (provider) {
    case IssueTrackerProviderEnum.Jira:
      return (
        <BulkExportToJiraCloudModal
          followUpsToExport={followUpsToExport}
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          onClose={onClose}
          onCancel={onCancel}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Linear:
      return (
        <BulkExportToLinearModal
          followUpsToExport={followUpsToExport}
          numFollowUpsToExportFromPrivate={numFollowUpsToExportFromPrivate}
          numFollowUpsAlreadyExported={numFollowUpsAlreadyExported}
          onClose={onClose}
          onCancel={onCancel}
          updateCallback={updateCallback}
        />
      );

    case IssueTrackerProviderEnum.Asana:
    case IssueTrackerProviderEnum.AzureDevops:
    case IssueTrackerProviderEnum.ClickUp:
    case IssueTrackerProviderEnum.Github:
    case IssueTrackerProviderEnum.Gitlab:
    case IssueTrackerProviderEnum.JiraServer:
    case IssueTrackerProviderEnum.Shortcut:
      // TODO(PINC-2965): We don't support bulk-export for v2 issue trackers right now.
      // We might bring it back, but given no one has asked for it we might just drop it.
      return null;

    default:
      assertUnreachable(provider);
  }

  return <></>;
};
