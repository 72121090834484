/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationAggregateScorecard,
    InvestigationAggregateScorecardFromJSON,
    InvestigationAggregateScorecardFromJSONTyped,
    InvestigationAggregateScorecardToJSON,
} from './InvestigationAggregateScorecard';

/**
 * 
 * @export
 * @interface AIStaffAggregateInvestigationScorecardsResponseBody
 */
export interface AIStaffAggregateInvestigationScorecardsResponseBody {
    /**
     * 
     * @type {InvestigationAggregateScorecard}
     * @memberof AIStaffAggregateInvestigationScorecardsResponseBody
     */
    aggregate_scorecard: InvestigationAggregateScorecard;
}

export function AIStaffAggregateInvestigationScorecardsResponseBodyFromJSON(json: any): AIStaffAggregateInvestigationScorecardsResponseBody {
    return AIStaffAggregateInvestigationScorecardsResponseBodyFromJSONTyped(json, false);
}

export function AIStaffAggregateInvestigationScorecardsResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffAggregateInvestigationScorecardsResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregate_scorecard': InvestigationAggregateScorecardFromJSON(json['aggregate_scorecard']),
    };
}

export function AIStaffAggregateInvestigationScorecardsResponseBodyToJSON(value?: AIStaffAggregateInvestigationScorecardsResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregate_scorecard': InvestigationAggregateScorecardToJSON(value.aggregate_scorecard),
    };
}

