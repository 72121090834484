/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessorBackfillPreview
 */
export interface ProcessorBackfillPreview {
    /**
     * Best-effort guess of the number of resources that will not be re-processed as they have a recently calculated value
     * @type {number}
     * @memberof ProcessorBackfillPreview
     */
    cached_resource_count_estimate?: number;
    /**
     * Based on recent processor runs, the estimated cost per resource
     * @type {number}
     * @memberof ProcessorBackfillPreview
     */
    cost_per_resource_estimate: number;
    /**
     * Number of resources that will be processed
     * @type {number}
     * @memberof ProcessorBackfillPreview
     */
    total_resource_count: number;
}

export function ProcessorBackfillPreviewFromJSON(json: any): ProcessorBackfillPreview {
    return ProcessorBackfillPreviewFromJSONTyped(json, false);
}

export function ProcessorBackfillPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBackfillPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cached_resource_count_estimate': !exists(json, 'cached_resource_count_estimate') ? undefined : json['cached_resource_count_estimate'],
        'cost_per_resource_estimate': json['cost_per_resource_estimate'],
        'total_resource_count': json['total_resource_count'],
    };
}

export function ProcessorBackfillPreviewToJSON(value?: ProcessorBackfillPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cached_resource_count_estimate': value.cached_resource_count_estimate,
        'cost_per_resource_estimate': value.cost_per_resource_estimate,
        'total_resource_count': value.total_resource_count,
    };
}

