import { ExternalSchedule } from "@incident-io/api";
import { Badge, BadgeSize, BadgeTheme, IconEnum } from "@incident-ui";

const ScheduleBadge = ({ name }: { name: string }) => (
  <Badge
    icon={IconEnum.Calendar}
    theme={BadgeTheme.Tertiary}
    size={BadgeSize.Medium}
    className={"text-ellipsis overflow-hidden max-w-[40ch]"}
  >
    <span className={"overflow-hidden text-ellipsis"}>{name}</span>
  </Badge>
);

export const ExternalScheduleBadges = ({
  schedules,
}: {
  schedules: ExternalSchedule[];
}) => {
  if (schedules.length === 0) {
    return <span className="text-slate-300">{"\u2014"}</span>;
  }

  return (
    <>
      {schedules.map((schedule, index) => (
        <ScheduleBadge key={index} name={schedule.name} />
      ))}
    </>
  );
};
