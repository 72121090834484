import { Icon, IconEnum, IconSize } from "@incident-ui/Icon/Icon";
import React from "react";
import { tcx } from "src/utils/tailwind-classes";

export type EmptyStateProps = {
  title?: React.ReactNode;
  content: React.ReactNode;
  cta?: React.ReactNode;
  className?: string;
  fullWidthContent?: boolean;
} & (
  | { icon?: never; iconColorProps?: never; illustration?: never }
  | { icon: IconEnum; iconColorProps?: IconColorProps; illustration?: never }
  | { icon?: never; iconColorProps?: never; illustration: React.ReactNode }
);

type IconColorProps = {
  className?: string;
  strokeColor?: string;
};

export const EmptyState = ({
  className,
  icon,
  iconColorProps,
  illustration,
  title,
  content,
  cta,
  fullWidthContent,
}: EmptyStateProps): React.ReactElement => {
  return (
    <div
      className={tcx(
        // If possible, empty states should always fill the space
        "w-full grow",
        // Rounded grey box
        "rounded-2 border-stroke border bg-surface-primary",
        // Position children
        "flex flex-col items-center justify-center p-8",
        className,
      )}
    >
      <div
        className={tcx("flex flex-col items-center justify-center gap-4", {
          "max-w-md": !fullWidthContent,
        })}
      >
        {icon && <EmptyStateIcon icon={icon} colorProps={iconColorProps} />}
        {illustration}
        <div className="flex flex-col gap-1">
          {title && <div className="text-base-bold text-center">{title}</div>}
          {content && (
            <div className="text-sm-normal text-center text-content-tertiary">
              {content}
            </div>
          )}
        </div>
        {cta}
      </div>
    </div>
  );
};

const EmptyStateIcon = ({
  icon,
  colorProps,
}: {
  icon: IconEnum;
  colorProps?: IconColorProps;
}) => (
  <div className={tcx("flex items-center justify-center w-12 h-12 relative")}>
    <svg
      width="47"
      height="48"
      viewBox="0 0 47 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute top-0"
    >
      <path
        d="M1 9C1 4.58172 4.58172 1 9 1H38C42.4183 1 46 4.58172 46 9V39C46 43.4183 42.4183 47 38 47H9C4.58172 47 1 43.4183 1 39V9Z"
        stroke={colorProps?.strokeColor || "#E4E4E7"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="6 6"
      />
    </svg>
    <Icon
      id={icon}
      size={IconSize.Large}
      className={tcx("text-content-tertiary z-10", colorProps?.className)}
    />
  </div>
);
