/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ScheduleTerraformPayload,
    ScheduleTerraformPayloadFromJSON,
    ScheduleTerraformPayloadFromJSONTyped,
    ScheduleTerraformPayloadToJSON,
} from './ScheduleTerraformPayload';

/**
 * 
 * @export
 * @interface SchedulesGenerateScheduleExportRequestBody
 */
export interface SchedulesGenerateScheduleExportRequestBody {
    /**
     * The mode to generate Terraform in
     * @type {string}
     * @memberof SchedulesGenerateScheduleExportRequestBody
     */
    mode: SchedulesGenerateScheduleExportRequestBodyModeEnum;
    /**
     * 
     * @type {ScheduleTerraformPayload}
     * @memberof SchedulesGenerateScheduleExportRequestBody
     */
    schedule: ScheduleTerraformPayload;
}

/**
* @export
* @enum {string}
*/
export enum SchedulesGenerateScheduleExportRequestBodyModeEnum {
    Terraform = 'terraform'
}

export function SchedulesGenerateScheduleExportRequestBodyFromJSON(json: any): SchedulesGenerateScheduleExportRequestBody {
    return SchedulesGenerateScheduleExportRequestBodyFromJSONTyped(json, false);
}

export function SchedulesGenerateScheduleExportRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchedulesGenerateScheduleExportRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mode': json['mode'],
        'schedule': ScheduleTerraformPayloadFromJSON(json['schedule']),
    };
}

export function SchedulesGenerateScheduleExportRequestBodyToJSON(value?: SchedulesGenerateScheduleExportRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mode': value.mode,
        'schedule': ScheduleTerraformPayloadToJSON(value.schedule),
    };
}

