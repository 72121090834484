/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * An override for an AI request evaluation result
 * @export
 * @interface AIRequestEvalOverride
 */
export interface AIRequestEvalOverride {
    /**
     * ID of AI request this override is for
     * @type {string}
     * @memberof AIRequestEvalOverride
     */
    ai_request_id: string;
    /**
     * When the override was created
     * @type {Date}
     * @memberof AIRequestEvalOverride
     */
    created_at: Date;
    /**
     * Unique identifier
     * @type {string}
     * @memberof AIRequestEvalOverride
     */
    id: string;
    /**
     * ID of organisation that owns this override
     * @type {string}
     * @memberof AIRequestEvalOverride
     */
    organisation_id: string;
    /**
     * Result of the eval override
     * @type {object}
     * @memberof AIRequestEvalOverride
     */
    result: object;
    /**
     * When the override was last updated
     * @type {Date}
     * @memberof AIRequestEvalOverride
     */
    updated_at: Date;
}

export function AIRequestEvalOverrideFromJSON(json: any): AIRequestEvalOverride {
    return AIRequestEvalOverrideFromJSONTyped(json, false);
}

export function AIRequestEvalOverrideFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIRequestEvalOverride {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ai_request_id': json['ai_request_id'],
        'created_at': (new Date(json['created_at'])),
        'id': json['id'],
        'organisation_id': json['organisation_id'],
        'result': json['result'],
        'updated_at': (new Date(json['updated_at'])),
    };
}

export function AIRequestEvalOverrideToJSON(value?: AIRequestEvalOverride | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ai_request_id': value.ai_request_id,
        'created_at': (value.created_at.toISOString()),
        'id': value.id,
        'organisation_id': value.organisation_id,
        'result': value.result,
        'updated_at': (value.updated_at.toISOString()),
    };
}

