import {
  Button,
  ButtonTheme,
  ConfirmationDialog,
  GenericErrorMessage,
  Tooltip,
} from "@incident-ui";
import { InputType } from "@incident-ui/Input/Input";
import _, { get } from "lodash";
import React, { useState } from "react";
import {
  FieldNamesMarkedBoolean,
  FieldValues,
  Path,
  UseFormReturn,
} from "react-hook-form";
import {
  CustomField,
  CustomFieldEntry,
  CustomFieldEntryPayload,
  CustomFieldFieldModeEnum,
  CustomFieldFieldTypeEnum as FieldTypeEnum,
  CustomFieldRequiredV2Enum as RequiredEnum,
  CustomFieldValuePayload,
  IncidentManualEdit,
} from "src/contexts/ClientContext";
import { useAPIMutation } from "src/utils/swr";
import { assertUnreachable } from "src/utils/utils";

import {
  CUSTOM_FIELD_NO_VALUE,
  CustomFieldDynamicSelect,
} from "./CustomFieldDynamicSelect";
import { InputV2 } from "./inputs/InputV2";

// Options are stored as a plain string, which we then marshal into either a
// value_option_id or value_option_value when submitting the form
type FormCustomFieldValue =
  | Omit<CustomFieldValuePayload, "value_option_id" | "value_option_value">
  | string;

export type FormCustomFieldEntry = {
  values: FormCustomFieldValue[];
  hasBeenManuallyEdited: boolean;
};
export type FormCustomFieldEntries = {
  [key: string]: FormCustomFieldEntry;
};

export const CustomFieldFormElement = <FormType extends FieldValues>(
  props: CustomFieldFormElementInputProps<FormType>,
): React.ReactElement | null => {
  const { customField, hideLabel } = props;

  if (customField.field_mode === CustomFieldFieldModeEnum.FullyDerived) {
    return (
      <Tooltip
        content="This field is controlled by an expression and cannot be edited."
        side="bottom"
        bubbleProps={{ className: "-mt-5" }}
      >
        <div>
          <CustomFieldFormElementInput<FormType> {...props} disabled />
        </div>
      </Tooltip>
    );
  }

  const isInSensibleDefaultMode =
    props.customField.field_mode === CustomFieldFieldModeEnum.SensibleDefault;

  return (
    <div className="flex flex-col text-content-tertiary gap-1">
      <CustomFieldFormElementInput<FormType> {...props} />{" "}
      {/* If the custom field is in sensible default mode, we'll show some help text underneath. */}
      {isInSensibleDefaultMode && !hideLabel && (
        <SensibleDefaultModeText {...props} canReset />
      )}
    </div>
  );
};

export const SensibleDefaultModeText = ({
  customField,
  manualEdits,
  incidentId,
  canReset,
}: {
  customField: CustomField;
  manualEdits?: IncidentManualEdit[];
  incidentId?: string;
  canReset: boolean;
}): React.ReactElement => {
  const hasBeenManuallyEdited = manualEdits?.some(
    (edit) => edit.custom_field_id === customField.id,
  );

  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const {
    trigger: resetManuallyEditedCustomField,
    isMutating: isResetting,
    genericError,
  } = useAPIMutation(
    "incidentsShow",
    {
      id: incidentId || "",
    },
    async (apiClient) => {
      await apiClient.customFieldsResetManuallyEditedDerivedCustomFieldValues({
        id: customField.id,
        resetManuallyEditedDerivedCustomFieldValuesRequestBody: {
          incident_id: incidentId || "",
        },
      });
    },
    {
      onSuccess: () => {
        setShowConfirmResetModal(false);
      },
    },
  );

  return (
    <>
      <div className="flex flex-row items-center gap-1">
        <span className="text-sm">
          {incidentId && hasBeenManuallyEdited ? (
            <>
              This field has been manually overridden.{" "}
              {canReset && (
                <>
                  <Button
                    theme={ButtonTheme.Naked}
                    analyticsTrackingId="reset-manually-edited-custom-field"
                    onClick={() => setShowConfirmResetModal(true)}
                    className="underline"
                    disabled={isResetting}
                  >
                    Click here
                  </Button>{" "}
                  to reset it.
                </>
              )}
            </>
          ) : (
            <>
              This field updates automatically unless you override it manually.
            </>
          )}
        </span>
      </div>
      <ConfirmationDialog
        onConfirm={() => resetManuallyEditedCustomField({})}
        onCancel={() => setShowConfirmResetModal(false)}
        title="Are you sure?"
        analyticsTrackingId="reset-manually-edited-custom-field-confirm-modal"
        isOpen={showConfirmResetModal}
      >
        {genericError ? (
          <GenericErrorMessage description={genericError} />
        ) : (
          <>
            Resetting this field will revert it to its automatically set value.
          </>
        )}
      </ConfirmationDialog>
    </>
  );
};

type CustomFieldFormElementInputProps<FormType extends FieldValues> = {
  customField: CustomField;
  formMethods: UseFormReturn<FormType>;
  fieldKeyPrefix: Path<FormType>;
  required: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  label?: string;
  hideLabel?: boolean; // Stops us from automatically adding a label when one has not been provided
  includeNoValue: boolean;
  placeholder?: string;
  entryPayloads: CustomFieldEntryPayload[];
  manualEdits?: IncidentManualEdit[];
  incidentId?: string;
};

const CustomFieldFormElementInput = <FormType extends FieldValues>({
  customField,
  formMethods,
  fieldKeyPrefix,
  required: fieldIsRequired,
  disabled,
  autoFocus,
  label,
  hideLabel,
  placeholder,
  includeNoValue,
  entryPayloads,
}: CustomFieldFormElementInputProps<FormType>): React.ReactElement | null => {
  // We need to hide any static single or multi-select fields if they don't have
  // any options: the is_usable flag tells us whether this is true. Previously,
  // this logic lived on the client side (see ENG-5218).
  if (!customField.is_usable) {
    return null;
  }

  let helpText: string | undefined;
  if (!label && !hideLabel) {
    label = customField.name;
    helpText = customField.description;
  }

  let fieldKey: Path<FormType> = fieldKeyPrefix;
  switch (customField.field_type) {
    case FieldTypeEnum.SingleSelect:
    case FieldTypeEnum.MultiSelect: {
      return (
        <CustomFieldDynamicSelect
          formMethods={formMethods}
          customField={customField}
          helptext={helpText}
          name={fieldKey}
          fieldKeyPrefix={fieldKey}
          label={label}
          required={fieldIsRequired ? "Please select a value" : false}
          placeholder={placeholder ? placeholder : "Select value"}
          isClearable={
            !fieldIsRequired && customField.required_v2 !== RequiredEnum.Always
          }
          isDisabled={disabled}
          autoFocus={autoFocus}
          includeNoValue={includeNoValue}
          entryPayloads={entryPayloads}
        />
      );
    }

    case FieldTypeEnum.Text:
      fieldKey = `${fieldKeyPrefix}.0.value_text` as unknown as Path<FormType>;
      return (
        <InputV2
          formMethods={formMethods}
          placeholder={placeholder ? placeholder : "Enter value..."}
          name={fieldKey}
          label={label}
          helptext={helpText}
          required={fieldIsRequired ? "Please enter a value" : false}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      );

    case FieldTypeEnum.Link:
      fieldKey = `${fieldKeyPrefix}.0.value_link` as unknown as Path<FormType>;

      return (
        <InputV2
          formMethods={formMethods}
          label={label}
          helptext={helpText}
          name={fieldKey}
          placeholder={placeholder ? placeholder : "https://docs.new/"}
          required={fieldIsRequired ? "Please enter a value" : false}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      );

    case FieldTypeEnum.Numeric:
      fieldKey =
        `${fieldKeyPrefix}.0.value_numeric` as unknown as Path<FormType>;
      return (
        <InputV2
          type={InputType.Number}
          name={fieldKey}
          formMethods={formMethods}
          label={label}
          helptext={helpText}
          placeholder={placeholder ? placeholder : "123"}
          required={fieldIsRequired ? "Please enter a value" : false}
          disabled={disabled}
          autoFocus={autoFocus}
          step="any"
        />
      );

    default:
      assertUnreachable(customField.field_type);
  }
  throw new Error("unreachable");
};

// Our form data ALMOST matches the API request struct, but not quite.
// this takes the data we've passed to the form, and fixes the couple of
// small differences.
export const marshallCustomFieldEntriesToRequestPayload = <
  TFormData extends FieldValues,
>(
  customFields: CustomField[],
  touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<TFormData>>>,
  maybeFormEntries?: FormCustomFieldEntries,
): CustomFieldEntryPayload[] => {
  const result: CustomFieldEntryPayload[] = [];

  const formEntries = maybeFormEntries || {};
  const fieldIdsInForm = Object.keys(formEntries);

  fieldIdsInForm.forEach((custom_field_id) => {
    const field = customFields.find((field) => field.id === custom_field_id);
    if (!field) {
      // This means the form was holding on to some data for a field that's not
      // applicable any more: time to throw that away.
      return undefined;
    } else if (field.field_mode === CustomFieldFieldModeEnum.FullyDerived) {
      // We don't need to do anything here, as the field is fully derived
      // we should return 0 value as the user / form cannot set this themselves.
      return undefined;
    }

    const entry = formEntries[custom_field_id];
    let values: CustomFieldValuePayload[] = [];

    // If an object is empty, let's remove it (or the API complains)
    if (
      entry.values &&
      entry.values[0] &&
      // Filter for string values only, as this data can be populated from a
      // broader type than TypeScript believes, and might have a value_option
      // (or more) embedded that would cause us to fail this empty check.
      Object.values(entry.values[0]).every(
        (x) => typeof x !== "string" || x === "" || x === undefined,
      )
    ) {
      values = [];
    } else {
      // We need to fudge some types as our form's representation doesn't quite match the API.
      switch (field.field_type) {
        case FieldTypeEnum.Numeric:
        case FieldTypeEnum.Text:
        case FieldTypeEnum.Link:
          // In these cases we can just use the representation from the form,
          // without having to do any fudging. We strip any strings out to
          // convince TypeScript things are golden.
          values = _.compact(
            entry.values.map((value) => {
              // Remove strings
              if (typeof value === "string") return undefined;

              // Then strip empty values for each of the special types
              if (
                field.field_type === FieldTypeEnum.Link &&
                !value.value_link
              ) {
                return undefined;
              }

              if (
                field.field_type === FieldTypeEnum.Numeric &&
                value.value_numeric === undefined
              ) {
                return undefined;
              }
              if (
                field.field_type === FieldTypeEnum.Text &&
                !value.value_text
              ) {
                return undefined;
              }

              return value;
            }),
          );
          break;
        case FieldTypeEnum.SingleSelect:
        case FieldTypeEnum.MultiSelect:
          const nonEmptyValues = _.compact(
            (entry.values || []) as string[],
          ).filter((val) => val !== CUSTOM_FIELD_NO_VALUE);

          if (field.catalog_type_id) {
            values = nonEmptyValues.map((val) => {
              return { value_catalog_entry_id: val };
            });
          } else {
            values = nonEmptyValues.map((val) => {
              return optionValueToPayload(field, val);
            });
          }
          break;
        default:
          assertUnreachable(field.field_type);
      }
    }

    const touched = get(
      touchedFields,
      `custom_field_entries.${custom_field_id}.values`,
    );

    result.push({
      custom_field_id,
      values,
      not_manually_edited: touched == null,
    });
    return undefined;
  });

  return result;
};

// The option values that come from the select field can be one of three things.
// It will either be catalog entry ID, an option ID, or a brand new value
// (signified by a prefix of CREATE:::). This function takes the value, and
// turns it into a payload, where each of the three cases outlined here has a
// different key in the payload object.
const optionValueToPayload = (
  field: CustomField,
  val: string,
): CustomFieldValuePayload => {
  // If the field has a catalog type, the value relates to a catalog entry
  if (field.catalog_type_id) {
    return { value_catalog_entry_id: val };
  }

  // If it's a "CREATE" field, add it as an option value
  if (val.startsWith("CREATE:::")) {
    return {
      value_option_value: val.substring("CREATE:::".length),
    };
  }

  // Otherwise, default to an option ID
  return { value_option_id: val };
};

export const marshallCustomFieldsToFormData = ({
  customFields,
  entries,
  manualEdits,
}: {
  customFields: CustomField[];
  entries: CustomFieldEntry[];
  manualEdits?: IncidentManualEdit[];
}): FormCustomFieldEntries => {
  const customFieldEntries: FormCustomFieldEntries = {};
  const manuallyEditedFieldIds =
    manualEdits?.map((edit) => edit.custom_field_id) || [];

  customFields.forEach((field) => {
    let values: FormCustomFieldValue[] = [];

    const existingEntry = entries.find(
      (entry) => entry.custom_field.id === field.id,
    );

    if (existingEntry) {
      // The response type serializes the full option, but the request type only
      // has the option ID in it
      values = existingEntry.values.map(
        ({ value_option, value_catalog_entry, ...rest }) => ({
          value_option_id: value_option?.id,
          value_catalog_entry_id: value_catalog_entry?.id,
          ...rest,
        }),
      );

      // We need to fudge some of the types a bit so that our form understands them
      switch (field.field_type) {
        case FieldTypeEnum.Numeric:
        case FieldTypeEnum.Text:
        case FieldTypeEnum.Link:
          // do nothing
          break;
        case FieldTypeEnum.SingleSelect:
        case FieldTypeEnum.MultiSelect:
          if (field.catalog_type_id) {
            values = _.compact(
              existingEntry.values.map((val) => val.value_catalog_entry?.id),
            );
          } else {
            values = _.compact(
              existingEntry.values.map((val) => val.value_option?.id),
            );
          }
          break;
        default:
          assertUnreachable(field.field_type);
      }
    }

    customFieldEntries[field.id] = {
      values,
      hasBeenManuallyEdited: manuallyEditedFieldIds.includes(field.id),
    };
  });

  return customFieldEntries;
};
