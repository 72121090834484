import { IconEnum } from "@incident-ui";
import { StaffPermissionEnum } from "src/hooks/useCheckStaffPermissions";

import { StaffRoomOperation } from "../Operation";
import { UpdateFeatureGatesForm } from "./UpdateFeatureGatesForm";

export const UpdateFeatureGatesOperation: StaffRoomOperation = {
  id: "update-feature-gates",
  title: "Change feature access",
  description:
    "This won’t affect how much the customer is charged, only what access to features they have",
  requiredPermission: StaffPermissionEnum.ManageFeatureAccess,
  icon: IconEnum.Billing,
  drawerWidth: "large",
  Component: UpdateFeatureGatesForm,
};
