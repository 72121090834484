/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EscalatedTimelineUserNotificationUpdate
 */
export interface EscalatedTimelineUserNotificationUpdate {
    /**
     * Human readable label for the notification type
     * @type {string}
     * @memberof EscalatedTimelineUserNotificationUpdate
     */
    display_label: string;
    /**
     * The reason for the state, if any
     * @type {string}
     * @memberof EscalatedTimelineUserNotificationUpdate
     */
    reason?: EscalatedTimelineUserNotificationUpdateReasonEnum;
    /**
     * The state this notification went into at this point in time
     * @type {string}
     * @memberof EscalatedTimelineUserNotificationUpdate
     */
    state: EscalatedTimelineUserNotificationUpdateStateEnum;
    /**
     * The type of notification method
     * @type {string}
     * @memberof EscalatedTimelineUserNotificationUpdate
     */
    type: EscalatedTimelineUserNotificationUpdateTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum EscalatedTimelineUserNotificationUpdateReasonEnum {
    AlertMarkedRelated = 'alert_marked_related',
    CallConnected = 'call_connected',
    IncidentTriaged = 'incident_triaged',
    CallRejected = 'call_rejected',
    InboundCallEnded = 'inbound_call_ended',
    AckedInExternalEscalator = 'acked_in_external_escalator',
    EscalationExpired = 'escalation_expired',
    EscalationCancelled = 'escalation_cancelled',
    DeactivatedUser = 'deactivated_user',
    NoOnCallSeat = 'no_on_call_seat'
}/**
* @export
* @enum {string}
*/
export enum EscalatedTimelineUserNotificationUpdateStateEnum {
    Pending = 'pending',
    Sent = 'sent',
    Failed = 'failed',
    Acked = 'acked',
    AckedElsewhere = 'acked_elsewhere',
    Nacked = 'nacked',
    NackedElsewhere = 'nacked_elsewhere',
    Cancelled = 'cancelled',
    RateLimited = 'rate_limited'
}/**
* @export
* @enum {string}
*/
export enum EscalatedTimelineUserNotificationUpdateTypeEnum {
    App = 'app',
    Email = 'email',
    MicrosoftTeams = 'microsoft_teams',
    Phone = 'phone',
    Slack = 'slack'
}

export function EscalatedTimelineUserNotificationUpdateFromJSON(json: any): EscalatedTimelineUserNotificationUpdate {
    return EscalatedTimelineUserNotificationUpdateFromJSONTyped(json, false);
}

export function EscalatedTimelineUserNotificationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EscalatedTimelineUserNotificationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'display_label': json['display_label'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'state': json['state'],
        'type': json['type'],
    };
}

export function EscalatedTimelineUserNotificationUpdateToJSON(value?: EscalatedTimelineUserNotificationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'display_label': value.display_label,
        'reason': value.reason,
        'state': value.state,
        'type': value.type,
    };
}

