import { Callout, CalloutTheme } from "@incident-ui";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useOutlet } from "react-router";
import { NotFoundPage } from "src/components/not-found/NotFoundPage";
import { useIdentity } from "src/contexts/IdentityContext";

import { SettingsSubPageWrapper } from "../../SettingsRoute";
import { SuggestionAICards } from "../ai/SuggestionAICards";

export const SuggestionsListPage = (): React.ReactElement => {
  const drawer = useOutlet();
  const { identity } = useIdentity();

  if (!identity.feature_gates.suggestions) {
    return <NotFoundPage />;
  }

  // Currently, you can only get here if you have access to suggestions.
  // TODO: [AI-356] - handle it if you don't have access

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <SettingsSubPageWrapper>
        <Callout
          theme={CalloutTheme.Plain}
          className="text-white bg-pink-600"
          title="We're building out suggestions (only incident.io staff can see this right now)"
        />
        <SuggestionAICards />
      </SettingsSubPageWrapper>
    </>
  );
};
