import {
  IncidentGroundTruth,
  IncidentGroundTruthCodeChange,
  useAiStaffServiceAiStaffCreateIncidentGroundTruth,
  useAiStaffServiceAiStaffListIncidentGroundTruths,
  UseAiStaffServiceAiStaffListIncidentGroundTruthsKeyFn,
  useAiStaffServiceAiStaffUpdateIncidentGroundTruth,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  Icon,
  IconEnum,
  ToastTheme,
} from "@incident-ui";
import { LocalRelativeDateTime } from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

export const useGroundTruth = (incidentId: string | null) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  // Queries
  const { data, isLoading } = useAiStaffServiceAiStaffListIncidentGroundTruths({
    incidentId: incidentId || "",
  });
  const groundTruth = data?.ground_truths[0];

  // Common invalidation logic
  const invalidateQueries = () => {
    if (!incidentId) return;

    queryClient.invalidateQueries({
      queryKey: UseAiStaffServiceAiStaffListIncidentGroundTruthsKeyFn({
        incidentId,
      }),
    });
  };

  // Mutations
  const { mutate: generateGroundTruth, isPending: isCreating } =
    useAiStaffServiceAiStaffCreateIncidentGroundTruth({
      onSettled: invalidateQueries,
      onError: () => {
        toast({
          title: "Failed to generate ground truth",
          theme: ToastTheme.Error,
        });
      },
    });

  const { mutate: updateGroundTruth, isPending: isUpdating } =
    useAiStaffServiceAiStaffUpdateIncidentGroundTruth({
      onSettled: invalidateQueries,
      onError: () => {
        toast({
          title: "Failed to update ground truth",
          theme: ToastTheme.Error,
        });
      },
    });

  // Handlers
  const handleGenerate = () => {
    if (!incidentId) return;

    generateGroundTruth({
      requestBody: {
        incident_id: incidentId,
        force: true, // always bypass the cache
      },
    });
  };

  const handleSaveCodeChanges = async (
    changes: IncidentGroundTruthCodeChange[],
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      id: groundTruth.id,
      requestBody: {
        ...groundTruth,
        investigation_relevance_verified_at:
          groundTruth.investigation_relevance_verified_at?.toISOString(),
        causing_code_changes: changes,
        causing_code_changes_verified_at: isVerified
          ? new Date().toISOString()
          : "",
      },
    });
  };

  const handleSaveInvestigation = async (
    relevance: string,
    tags: string[],
    reasoning: string,
    isVerified: boolean,
  ) => {
    if (!groundTruth || !incidentId) return;

    updateGroundTruth({
      id: groundTruth.id,
      requestBody: {
        ...groundTruth,
        causing_code_changes_verified_at:
          groundTruth.causing_code_changes_verified_at?.toISOString(),
        investigation_relevance: relevance,
        investigation_relevance_tags: tags,
        investigation_relevance_reasoning: reasoning,
        investigation_relevance_verified_at: isVerified
          ? new Date().toISOString()
          : "",
      },
    });
  };

  // We should add more of these as we add more ground truths.
  let requiresVerification = false;
  if (groundTruth) {
    requiresVerification = !groundTruth.causing_code_changes_verified_at;
  }

  return {
    groundTruth,
    requiresVerification,
    isLoading: isLoading || isCreating || isUpdating,
    handleGenerate,
    handleSaveCodeChanges,
    handleSaveInvestigation,
  };
};

interface GroundTruthSectionControllerProps {
  incidentId: string;
  className?: string;
}

export const GroundTruthSectionController = ({
  incidentId,
  className,
}: GroundTruthSectionControllerProps) => {
  const {
    groundTruth,
    isLoading,
    handleGenerate,
    handleSaveCodeChanges,
    handleSaveInvestigation,
  } = useGroundTruth(incidentId);

  return (
    <GroundTruthSection
      groundTruth={groundTruth}
      className={className}
      onGenerateGroundTruth={handleGenerate}
      onSaveCodeChanges={handleSaveCodeChanges}
      onSaveInvestigation={handleSaveInvestigation}
      isGenerating={isLoading}
    />
  );
};

export type GroundTruthSectionProps = {
  groundTruth?: IncidentGroundTruth;
  className?: string;
  onGenerateGroundTruth?: () => void;
  onSaveCodeChanges?: (
    changes: IncidentGroundTruthCodeChange[],
    isVerified: boolean,
  ) => Promise<void>;
  onSaveInvestigation?: (
    relevance: string,
    tags: string[],
    reasoning: string,
    isVerified: boolean,
  ) => Promise<void>;
  isGenerating?: boolean;
};

// Main Component
export const GroundTruthSection = ({
  groundTruth,
  className,
  onGenerateGroundTruth,
  onSaveCodeChanges,
  onSaveInvestigation,
  isGenerating,
}: GroundTruthSectionProps) => {
  if (!groundTruth) {
    return (
      <div className={className}>
        <ContentBox>
          <EmptyState
            title="No ground truth available"
            content="Generate ground truth to help understand what happened during this incident"
            icon={IconEnum.MagicWand}
            cta={
              <Button
                onClick={onGenerateGroundTruth}
                disabled={isGenerating}
                analyticsTrackingId={null}
                icon={IconEnum.MagicWand}
                loading={isGenerating}
              >
                {isGenerating ? "Generating..." : "Generate ground truth"}
              </Button>
            }
          />
        </ContentBox>
      </div>
    );
  }

  return (
    <div className={className}>
      <ContentBox>
        <div className="relative">
          <div className="flex justify-between items-center p-4 border-b border-stroke">
            <div className="text-sm text-content-secondary">
              Updated <LocalRelativeDateTime date={groundTruth.updated_at} />
            </div>
            <Button
              onClick={onGenerateGroundTruth}
              disabled={isGenerating}
              analyticsTrackingId={null}
              icon={IconEnum.MagicWand}
              loading={isGenerating}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
            >
              {isGenerating ? "Generating..." : "Refresh"}
            </Button>
          </div>

          <GroundTruthInvestigation
            relevance={groundTruth.investigation_relevance}
            tags={groundTruth.investigation_relevance_tags}
            reasoning={groundTruth.investigation_relevance_reasoning}
            verifiedAt={groundTruth.investigation_relevance_verified_at}
            onSave={onSaveInvestigation}
            className={"-mt-px"}
          />

          <GroundTruthCodeChanges
            changes={groundTruth.causing_code_changes}
            verifiedAt={groundTruth.causing_code_changes_verified_at}
            onSave={onSaveCodeChanges}
            className={"-mt-px"}
          />
        </div>
      </ContentBox>
    </div>
  );
};

type EditableCodeChange = IncidentGroundTruthCodeChange & { id: string };

const GroundTruthCodeChanges = ({
  changes = [],
  verifiedAt,
  onSave,
  className,
}: {
  changes?: IncidentGroundTruthCodeChange[];
  verifiedAt?: Date;
  onSave?: (
    changes: IncidentGroundTruthCodeChange[],
    isVerified: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableChanges, setEditableChanges] = useState<EditableCodeChange[]>(
    [],
  );
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableChanges(
      changes.map((change, idx) => ({ ...change, id: `change-${idx}` })),
    );
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setEditableChanges([]);
    setIsVerified(!!verifiedAt);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      // Strip out the temporary ids before saving
      const cleanChanges = editableChanges.map(
        ({ id: _, ...change }) => change,
      );
      await onSave(cleanChanges, isVerified);
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const addNewChange = () => {
    setEditableChanges([
      ...editableChanges,
      { id: `new-${Date.now()}`, permalink: "", detail: "" },
    ]);
  };

  const updateChange = (
    id: string,
    field: keyof IncidentGroundTruthCodeChange,
    value: string,
  ) => {
    setEditableChanges((changes) =>
      changes.map((change) =>
        change.id === id ? { ...change, [field]: value } : change,
      ),
    );
  };

  const removeChange = (id: string) => {
    setEditableChanges((changes) =>
      changes.filter((change) => change.id !== id),
    );
  };

  const isEmpty = !changes?.length;

  return (
    <div className={tcx("border-t border-stroke first:border-t-0", className)}>
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Causing code changes</h4>
            {isEditing ? (
              <Button
                onClick={() => setIsVerified(!isVerified)}
                theme={ButtonTheme.Ghost}
                size={BadgeSize.Small}
                icon={isVerified ? IconEnum.TickCircle : IconEnum.Circle}
                analyticsTrackingId={null}
                className={tcx(
                  isVerified ? "text-green-500" : "text-amber-500",
                )}
              >
                {isVerified ? "Verified" : "Mark as verified"}
              </Button>
            ) : (
              <VerifiedBadge verifiedAt={verifiedAt} />
            )}
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            {editableChanges.map((change) => (
              <EditableCodeChangeItem
                key={change.id}
                change={change}
                onUpdate={(field, value) =>
                  updateChange(change.id, field, value)
                }
                onRemove={() => removeChange(change.id)}
              />
            ))}

            <div className="flex gap-2 mt-4">
              <Button
                onClick={addNewChange}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                icon={IconEnum.Add}
                analyticsTrackingId={null}
              >
                Add code change
              </Button>
            </div>

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-stroke">
              <Button
                onClick={cancelEditing}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                theme={ButtonTheme.Primary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                loading={isSaving}
              >
                Save changes
              </Button>
            </div>
          </div>
        ) : isEmpty ? (
          <p className="text-sm text-content-secondary">
            No code changes have been identified yet.
          </p>
        ) : (
          <div className="space-y-4">
            {changes.map((change, index) => (
              <CodeChangeItem key={index} codeChange={change} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CodeChangeItem = ({
  codeChange,
}: {
  codeChange: IncidentGroundTruthCodeChange;
}) => {
  return (
    <div className="flex items-start gap-2">
      <Icon
        id={IconEnum.GitBranch}
        className="mt-1 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0">
        <a
          href={codeChange.permalink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-sm font-medium text-content-primary hover:underline break-all"
        >
          {codeChange.permalink}
        </a>
        <p className="text-sm text-content-secondary mt-1">
          {codeChange.detail}
        </p>
      </div>
    </div>
  );
};

const EditableCodeChangeItem = ({
  change,
  onUpdate,
  onRemove,
}: {
  change: EditableCodeChange;
  onUpdate: (field: keyof IncidentGroundTruthCodeChange, value: string) => void;
  onRemove: () => void;
}) => {
  return (
    <div className="flex items-start gap-2 p-3 rounded border border-stroke bg-surface-secondary">
      <Icon
        id={IconEnum.GitBranch}
        className="mt-2 text-content-secondary shrink-0"
      />
      <div className="flex-1 min-w-0 space-y-2">
        <input
          type="url"
          value={change.permalink}
          onChange={(e) => onUpdate("permalink", e.target.value)}
          placeholder="Enter code change URL"
          className="w-full p-2 text-sm border rounded bg-white"
        />
        <textarea
          value={change.detail}
          onChange={(e) => onUpdate("detail", e.target.value)}
          placeholder="Describe the code change"
          className="w-full p-2 text-sm border rounded bg-white resize-none"
          rows={2}
        />
      </div>
      <Button
        onClick={onRemove}
        theme={ButtonTheme.Ghost}
        size={BadgeSize.Small}
        icon={IconEnum.Close}
        analyticsTrackingId={null}
        className="text-content-tertiary hover:text-content-primary"
      >
        Remove
      </Button>
    </div>
  );
};

const VerifiedBadge = ({
  verifiedAt,
  className = "text-xs",
}: {
  verifiedAt: Date | undefined;
  className?: string;
}) => {
  return (
    <Badge theme={BadgeTheme.Secondary} className={className}>
      {verifiedAt ? (
        <>
          <Icon id={IconEnum.TickCircle} className="text-green-500" />
          <span>
            Verified{" "}
            <LocalRelativeDateTime
              className={"text-xs"}
              date={new Date(verifiedAt)}
            />
          </span>
        </>
      ) : (
        <>
          <Icon id={IconEnum.Circle} className="text-amber-500" />
          <span>Unverified</span>
        </>
      )}
    </Badge>
  );
};

const GroundTruthInvestigation = ({
  relevance,
  tags = [],
  reasoning,
  verifiedAt,
  onSave,
  className,
}: {
  relevance?: string;
  tags?: string[];
  reasoning?: string;
  verifiedAt?: Date;
  onSave?: (
    relevance: string,
    tags: string[],
    reasoning: string,
    isVerified: boolean,
  ) => Promise<void>;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editableRelevance, setEditableRelevance] = useState(relevance || "");
  const [editableTags, setEditableTags] = useState(tags);
  const [editableReasoning, setEditableReasoning] = useState(reasoning || "");
  const [isVerified, setIsVerified] = useState(!!verifiedAt);
  const [isSaving, setIsSaving] = useState(false);

  const startEditing = () => {
    setEditableRelevance(relevance || "");
    setEditableTags(tags);
    setEditableReasoning(reasoning || "");
    setIsVerified(!!verifiedAt);
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (!onSave) return;

    setIsSaving(true);
    try {
      await onSave(
        editableRelevance,
        editableTags,
        editableReasoning,
        isVerified,
      );
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className={tcx("border-t border-stroke first:border-t-0", className)}>
      <div className="p-4">
        <div className="flex items-center justify-between gap-2 mb-3">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium">Investigation relevance</h4>
            {isEditing ? (
              <Button
                onClick={() => setIsVerified(!isVerified)}
                theme={ButtonTheme.Ghost}
                size={BadgeSize.Small}
                icon={isVerified ? IconEnum.TickCircle : IconEnum.Circle}
                analyticsTrackingId={null}
                className={tcx(
                  isVerified ? "text-green-500" : "text-amber-500",
                )}
              >
                {isVerified ? "Verified" : "Mark as verified"}
              </Button>
            ) : (
              <VerifiedBadge verifiedAt={verifiedAt} />
            )}
          </div>

          {!isEditing && onSave && (
            <Button
              onClick={startEditing}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
              icon={IconEnum.Edit}
              analyticsTrackingId={null}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditing ? (
          <div className="space-y-4">
            <select
              value={editableRelevance}
              onChange={(e) => setEditableRelevance(e.target.value)}
              className="w-full p-2 text-sm border rounded bg-white"
            >
              <option value="">Select relevance...</option>
              <option value="not_relevant">Not relevant</option>
              <option value="relevant">Relevant</option>
              <option value="critical">Critical</option>
            </select>

            <input
              type="text"
              value={editableTags.join(", ")}
              onChange={(e) =>
                setEditableTags(e.target.value.split(", ").filter(Boolean))
              }
              placeholder="Enter tags (comma separated)"
              className="w-full p-2 text-sm border rounded bg-white"
            />

            <textarea
              value={editableReasoning}
              onChange={(e) => setEditableReasoning(e.target.value)}
              placeholder="Explain why the investigation was or wasn't relevant"
              className="w-full p-2 text-sm border rounded bg-white resize-none"
              rows={3}
            />

            <div className="flex justify-end gap-2 mt-4 pt-4 border-t border-stroke">
              <Button
                onClick={cancelEditing}
                theme={ButtonTheme.Secondary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                theme={ButtonTheme.Primary}
                size={BadgeSize.Small}
                analyticsTrackingId={null}
                loading={isSaving}
              >
                Save changes
              </Button>
            </div>
          </div>
        ) : (
          <div className="space-y-4">
            {relevance ? (
              <>
                <div className="flex items-center gap-2">
                  <Badge
                    theme={
                      relevance === "critical"
                        ? BadgeTheme.Error
                        : relevance === "relevant"
                        ? BadgeTheme.Success
                        : BadgeTheme.Secondary
                    }
                  >
                    {relevance.replace("_", " ")}
                  </Badge>
                </div>
                {tags.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {tags.map((tag) => (
                      <Badge key={tag} theme={BadgeTheme.Secondary}>
                        {tag}
                      </Badge>
                    ))}
                  </div>
                )}
                {reasoning && (
                  <p className="text-sm text-content-secondary">{reasoning}</p>
                )}
              </>
            ) : (
              <p className="text-sm text-content-secondary">
                No investigation relevance has been identified yet.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
