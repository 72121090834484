import { WorkflowSlim, WorkflowSlimStateEnum } from "@incident-io/api";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { DragHandle } from "@incident-shared/settings";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  IconSize,
  LoadingWrapper,
  StackedList,
} from "@incident-ui";
import { SelectOption } from "@incident-ui/Select/types";
import { useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from "react-beautiful-dnd";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SLACK_EMOJI_TO_UNICODE } from "src/utils/slack";
import { useAPI } from "src/utils/swr";

import { NudgeCreateEditFormData } from "./NudgesCreateEditForm";

export const WorkflowButtonsEditor = () => {
  const formMethods = useFormContext<NudgeCreateEditFormData>();
  const { control } = formMethods;

  const {
    data: { workflows },
    error: workflowsError,
    isLoading: workflowsLoading,
  } = useAPI(
    "workflowsListWorkflows",
    {},
    {
      fallbackData: { workflows: [] },
    },
  );

  const {
    append,
    move,
    remove,
    fields: buttons,
  } = useFieldArray({
    control,
    name: "workflow_buttons",
  });

  const onAddButton = () => {
    append({
      workflow_id: "",
      emoji: "robot_face",
      text: "",
    });
  };

  const onDragEnd = useCallback(
    (result) => {
      // Only listen for drop events (ignore things like 'CANCEL' events, where
      // the user just cancelled/aborted)
      if (result.reason !== "DROP") {
        return;
      }

      // If we dropped it outside the list, no-op
      if (!result.destination) {
        return;
      }

      const fromIndex = result.source.index;
      const toIndex = result.destination.index;
      move(fromIndex, toIndex);
    },
    [move],
  );

  if (workflowsError) {
    return <GenericErrorMessage error={workflowsError} />;
  }

  const manualWorkflows = workflows.filter(
    (w) =>
      w.trigger?.name === "manual" && w.state === WorkflowSlimStateEnum.Active,
  );
  if (!workflowsLoading && manualWorkflows.length === 0) {
    return (
      <Callout
        theme={CalloutTheme.Danger}
        title="No manual workflows found"
        subtitle="You need to create at least one manual workflow to use it in a nudge."
        cta={
          <Button
            analyticsTrackingId="create-manual-workflow-from-nudge"
            theme={ButtonTheme.Secondary}
            href="/workflows/create?trigger=manual"
          >
            Create a manual workflow
          </Button>
        }
      />
    );
  }

  return (
    <LoadingWrapper loading={workflowsLoading}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="nudge_create_edit">
          {(provided) => {
            return (
              <StackedList
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="mb-4 !bg-white rounded-2"
              >
                {buttons.map((button, index) => {
                  return (
                    <Draggable
                      key={button.id}
                      draggableId={button.id}
                      index={index}
                    >
                      {(provided) => (
                        <>
                          <WorkflowRow
                            index={index}
                            manualWorkflows={manualWorkflows}
                            draggableProvided={provided}
                            onDelete={() => remove(index)}
                            isLastButton={buttons.length === 1}
                          />
                        </>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </StackedList>
            );
          }}
        </Droppable>
      </DragDropContext>
      <Button
        theme={ButtonTheme.Naked}
        analyticsTrackingId="workflows-add-new-expression"
        className="shrink-0"
        icon={IconEnum.Add}
        onClick={onAddButton}
      >
        Add another button
      </Button>
    </LoadingWrapper>
  );
};

const WorkflowRow = ({
  index,
  draggableProvided,
  onDelete,
  isLastButton,
  manualWorkflows,
}: {
  index: number;
  draggableProvided: DraggableProvided;
  onDelete: (index: number) => void;
  isLastButton: boolean;
  manualWorkflows: WorkflowSlim[];
}) => {
  const formMethods = useFormContext<NudgeCreateEditFormData>();
  const prefix = `workflow_buttons.${index}` as `workflow_buttons.${number}`;

  const manualWorkflowOptions: SelectOption[] = manualWorkflows.map((w) => ({
    value: w.id,
    label: w.name,
    sortKey: w.name,
  }));

  const emojiOptions: SelectOption[] = [];
  Object.entries(SLACK_EMOJI_TO_UNICODE).forEach(([key, value]) => {
    emojiOptions.push({
      label: value + "  " + key,
      sort_key: key,
      value: key.slice(1, -1),
    });
  });

  return (
    <div
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      className="flex items-center gap-4 justify between px-3 py-2 text-content-primary w-full"
    >
      <div className="flex items-center gap-2 grow">
        <DragHandle
          className="shrink-0 py-2.5"
          {...draggableProvided.dragHandleProps}
        />
        <StaticSingleSelectV2
          formMethods={formMethods}
          name={`${prefix}.workflow_id`}
          placeholder="Choose workflow"
          className="min-w-1/2 grow"
          options={manualWorkflowOptions}
        />
        <StaticSingleSelectV2
          formMethods={formMethods}
          name={`${prefix}.emoji`}
          className="w-[180px]"
          options={emojiOptions}
        />
        <InputV2
          formMethods={formMethods}
          name={`${prefix}.text`}
          className="w-[150px]"
          placeholder="Button text"
        />
      </div>

      <GatedButton
        analyticsTrackingId={null}
        className="ml-1"
        theme={ButtonTheme.Naked}
        onClick={() => onDelete(index)}
        icon={IconEnum.Delete}
        iconProps={{ size: IconSize.Medium }}
        title="Remove button"
        disabled={isLastButton}
        disabledTooltipContent={
          isLastButton ? "You must have at least one button" : undefined
        }
      />
    </div>
  );
};
