/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationScorecardMetricSample,
    InvestigationScorecardMetricSampleFromJSON,
    InvestigationScorecardMetricSampleFromJSONTyped,
    InvestigationScorecardMetricSampleToJSON,
} from './InvestigationScorecardMetricSample';

/**
 * Single metric score within a grade
 * @export
 * @interface InvestigationScorecardMetric
 */
export interface InvestigationScorecardMetric {
    /**
     * Denominator is the total count of items, if this is a ratio
     * @type {number}
     * @memberof InvestigationScorecardMetric
     */
    denominator?: number;
    /**
     * Description of what this metric measures
     * @type {string}
     * @memberof InvestigationScorecardMetric
     */
    description: string;
    /**
     * Additional context explaining the metric score
     * @type {object}
     * @memberof InvestigationScorecardMetric
     */
    metadata?: object;
    /**
     * Name of the metric
     * @type {string}
     * @memberof InvestigationScorecardMetric
     */
    name: string;
    /**
     * Numerator is the absolute count of correct items, if this is a ratio
     * @type {number}
     * @memberof InvestigationScorecardMetric
     */
    numerator?: number;
    /**
     * 
     * @type {InvestigationScorecardMetricSample}
     * @memberof InvestigationScorecardMetric
     */
    sample?: InvestigationScorecardMetricSample;
    /**
     * Score between 0 and 1 (if the metric applies)
     * @type {number}
     * @memberof InvestigationScorecardMetric
     */
    score?: number;
}

export function InvestigationScorecardMetricFromJSON(json: any): InvestigationScorecardMetric {
    return InvestigationScorecardMetricFromJSONTyped(json, false);
}

export function InvestigationScorecardMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationScorecardMetric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'denominator': !exists(json, 'denominator') ? undefined : json['denominator'],
        'description': json['description'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'name': json['name'],
        'numerator': !exists(json, 'numerator') ? undefined : json['numerator'],
        'sample': !exists(json, 'sample') ? undefined : InvestigationScorecardMetricSampleFromJSON(json['sample']),
        'score': !exists(json, 'score') ? undefined : json['score'],
    };
}

export function InvestigationScorecardMetricToJSON(value?: InvestigationScorecardMetric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'denominator': value.denominator,
        'description': value.description,
        'metadata': value.metadata,
        'name': value.name,
        'numerator': value.numerator,
        'sample': InvestigationScorecardMetricSampleToJSON(value.sample),
        'score': value.score,
    };
}

