/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Expression,
    ExpressionFromJSON,
    ExpressionFromJSONTyped,
    ExpressionToJSON,
} from './Expression';
import {
    IssueTrackerFieldValue,
    IssueTrackerFieldValueFromJSON,
    IssueTrackerFieldValueFromJSONTyped,
    IssueTrackerFieldValueToJSON,
} from './IssueTrackerFieldValue';

/**
 * 
 * @export
 * @interface IssueTemplate
 */
export interface IssueTemplate {
    /**
     * The context of the issue template
     * @type {string}
     * @memberof IssueTemplate
     */
    context: IssueTemplateContextEnum;
    /**
     * Expressions available for use in issue template fields
     * @type {Array<Expression>}
     * @memberof IssueTemplate
     */
    expressions: Array<Expression>;
    /**
     * The values to use for each field
     * @type {Array<IssueTrackerFieldValue>}
     * @memberof IssueTemplate
     */
    field_values: Array<IssueTrackerFieldValue>;
    /**
     * The unique ID for the issue template
     * @type {string}
     * @memberof IssueTemplate
     */
    id: string;
    /**
     * The issue template has been marked as broken for a reason.
     * @type {string}
     * @memberof IssueTemplate
     */
    invalid_reason: IssueTemplateInvalidReasonEnum;
    /**
     * The name of the issue template
     * @type {string}
     * @memberof IssueTemplate
     */
    name: string;
    /**
     * The issue tracker provider to create the template for
     * @type {string}
     * @memberof IssueTemplate
     */
    provider: IssueTemplateProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum IssueTemplateContextEnum {
    FollowUp = 'follow-up',
    IncidentTicket = 'incident-ticket'
}/**
* @export
* @enum {string}
*/
export enum IssueTemplateInvalidReasonEnum {
    Empty = '',
    ValidationError = 'validation_error'
}/**
* @export
* @enum {string}
*/
export enum IssueTemplateProviderEnum {
    Asana = 'asana',
    AzureDevops = 'azure_devops',
    ClickUp = 'click_up',
    Linear = 'linear',
    Jira = 'jira',
    JiraServer = 'jira_server',
    Github = 'github',
    Gitlab = 'gitlab',
    Shortcut = 'shortcut'
}

export function IssueTemplateFromJSON(json: any): IssueTemplate {
    return IssueTemplateFromJSONTyped(json, false);
}

export function IssueTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': json['context'],
        'expressions': ((json['expressions'] as Array<any>).map(ExpressionFromJSON)),
        'field_values': ((json['field_values'] as Array<any>).map(IssueTrackerFieldValueFromJSON)),
        'id': json['id'],
        'invalid_reason': json['invalid_reason'],
        'name': json['name'],
        'provider': json['provider'],
    };
}

export function IssueTemplateToJSON(value?: IssueTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': value.context,
        'expressions': ((value.expressions as Array<any>).map(ExpressionToJSON)),
        'field_values': ((value.field_values as Array<any>).map(IssueTrackerFieldValueToJSON)),
        'id': value.id,
        'invalid_reason': value.invalid_reason,
        'name': value.name,
        'provider': value.provider,
    };
}

