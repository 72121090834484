import { BadgeSize, Button, ButtonTheme, IconEnum } from "@incident-ui";
import React, { useEffect, useRef, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { useClipboard } from "src/utils/useClipboard";

import { YAMLEditor } from "./YamlEditor";

type Mode = "yaml" | "json" | "text";

interface CodeViewerProps {
  mode: Mode;
  content: string;
  title?: React.ReactNode;
  highlightSearchTerm?: string;
  isEditing?: boolean;
  setIsEditing?: (v: boolean) => void;
  onEdit?: (v: string) => void;
  unlimitedHeight?: boolean;
  disabled?: boolean;
  className?: string;
}

export const CodeViewer = ({
  mode,
  content,
  title,
  highlightSearchTerm,
  disabled = false,
  isEditing,
  setIsEditing,
  unlimitedHeight = false,
  onEdit,
  className,
}: CodeViewerProps) => {
  const { copyTextToClipboard, hasCopied } = useClipboard();
  const containerRef = useRef<HTMLDivElement>(null);
  const [editedContent, setEditedContent] = useState(content);
  const onSave = () => {
    if (onEdit) {
      onEdit(editedContent);
    }
    if (setIsEditing) {
      setIsEditing(false);
    }
  };
  const onCancel = () => {
    if (setIsEditing) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setEditedContent(content);
  }, [setEditedContent, content]);

  // Process content based on mode
  const processedContent = React.useMemo(() => {
    switch (mode) {
      case "yaml":
        return content.replace(/\\n/g, "\n").replace(/\\t/g, "\t");
      case "json":
        try {
          return JSON.stringify(JSON.parse(content), null, 2);
        } catch {
          return content;
        }
      default:
        return content;
    }
  }, [content, mode]);

  // Scroll to first highlight when mounted or when search term changes
  useEffect(() => {
    if (!highlightSearchTerm) return;

    const timeoutId = setTimeout(() => {
      const highlightedElement =
        containerRef.current?.querySelector(".highlighted-text");
      if (highlightedElement) {
        highlightedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId);
  }, [highlightSearchTerm, processedContent]);

  const defaultTitle = mode.toUpperCase();

  const renderContent = () => {
    if (mode === "json") {
      // For JSON, we still want to maintain line-by-line formatting
      return processedContent.split("\n").map((line, index) => (
        <div
          key={index}
          style={{
            paddingLeft: `${(line.match(/^\s*/)?.[0]?.length ?? 0) * 3}px`,
          }}
        >
          <HighlightedText text={line} searchTerm={highlightSearchTerm} />
        </div>
      ));
    }

    // For text and YAML, render as a single block to maintain text flow
    return (
      <HighlightedText
        text={processedContent}
        searchTerm={highlightSearchTerm}
      />
    );
  };

  if (!!onEdit && mode !== "yaml") {
    return <div>Editing is only supported for YAML right now!</div>;
  }

  return (
    <div
      className={tcx(
        "w-full bg-white rounded-2 shadow-sm border font-mono",
        className,
      )}
    >
      <div className="flex justify-between items-center p-2 border-b">
        <div className="flex items-center gap-2">
          <span className={tcx("text-sm font-medium text-content-primary")}>
            {title ?? defaultTitle}
          </span>
          {!!setIsEditing &&
            (isEditing ? (
              <>
                <Button
                  analyticsTrackingId={null}
                  onClick={onSave}
                  size={BadgeSize.Small}
                  icon={IconEnum.Tick}
                  theme={ButtonTheme.Primary}
                  title=""
                />
                <Button
                  analyticsTrackingId={null}
                  onClick={onCancel}
                  size={BadgeSize.Small}
                  icon={IconEnum.Close}
                  title=""
                />
              </>
            ) : (
              <Button
                onClick={() => setIsEditing(true)}
                analyticsTrackingId={null}
                size={BadgeSize.Small}
                theme={ButtonTheme.Secondary}
                icon={IconEnum.Edit}
                disabled={disabled}
                title=""
              />
            ))}
        </div>
        {!isEditing && (
          <Button
            theme={ButtonTheme.Naked}
            onClick={() => copyTextToClipboard(processedContent)}
            icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
            disabled={disabled}
            analyticsTrackingId={null}
          >
            Copy
          </Button>
        )}
      </div>
      {isEditing ? (
        <YAMLEditor value={editedContent} onChange={setEditedContent} />
      ) : (
        <div
          ref={containerRef}
          className={tcx(
            "p-4 bg-surface-secondary rounded-b-2 font-mono overflow-y-auto text-xs",
            !unlimitedHeight && "max-h-96",
            disabled && "opacity-50",
          )}
        >
          <pre className="whitespace-pre-wrap">{renderContent()}</pre>
        </div>
      )}
    </div>
  );
};

const HighlightedText = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm?: string;
}) => {
  if (!searchTerm) return <>{text}</>;

  const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === searchTerm?.toLowerCase() ? (
          <span
            key={i}
            className="highlighted-text bg-yellow-200 border-yellow-800 border-2 font-semibold px-1 rounded"
          >
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        ),
      )}
    </>
  );
};
