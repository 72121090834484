import {
  EmbeddedCatalogEntry,
  ExternalEscalationPath,
  ExternalEscalationPathNativeConfigUnavailableReasonsEnum,
  ExternalSchedule,
  ExternalScheduleExternalProviderEnum,
  ExternalScheduleNativeConfigUnavailableReasonEnum,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  User,
  UserOptionStateEnum,
} from "@incident-io/api";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  GenericErrorMessage,
  Icon,
  IconEnum,
  Input,
  Link,
  Loader,
  TabSection,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { InputType } from "@incident-ui/Input/Input";
import { SelectableTable } from "@incident-ui/Table/SelectableTable";
import { TableCell, TableHeaderCell, TableRow } from "@incident-ui/Table/Table";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import _ from "lodash";
import pluralize from "pluralize";
import { ChangeEvent, useMemo, useState } from "react";
import { useAPIInfinite, useAPIMutation } from "src/utils/swr";
import { useDebounce } from "use-debounce";

import { useIntegrations } from "../../../hooks/useIntegrations";
import { tcx } from "../../../utils/tailwind-classes";
import { getColor } from "../../../utils/twConfig";
import { useRevalidate } from "../../../utils/use-revalidate";
import { OnCallPromotionConfirmationModal } from "../../legacy/on-call/common/OnCallPromotionConfirmationModal";
import { useOnCallPromotionState } from "../../legacy/on-call/common/useOnCallPromotionState";
import { UnresolvedUsersTooltipContent } from "../../legacy/on-call/schedules/ScheduleImportDrawer";
import { ExternalScheduleBadges } from "./ExternalScheduleBadges";

export const EscalationPathsImportDrawer = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const [search, setSearch] = useState("");
  const [selectedPolicies, setSelectedPolicies] = useState<Set<string>>(
    new Set(),
  );

  const [selectedSchedules, setSelectedSchedules] = useState<Set<string>>(
    new Set(),
  );
  const [debouncedSearch] = useDebounce(search, 500);

  const { integrations, integrationsError, integrationsLoading } =
    useIntegrations();

  const installedPagerduty = integrations?.find(
    (i) => i.provider === IntegrationProvider.Pagerduty && i.installed,
  );

  const {
    responses,
    isLoading,
    isFullyLoaded: allEntriesLoaded,
    loadMore: loadMoreEntries,
  } = useAPIInfinite(
    "escalationPathsListExternal",
    {
      pageSize: 15,
      search: debouncedSearch,
    },
    {
      revalidateOnMount: true,
      revalidateOnFocus: true,
    },
  );

  const showToast = useToast();

  const policies: ExternalEscalationPath[] = responses.flatMap(
    (r) => r.external_escalation_paths,
  );

  const policiesForTab = {
    ready: policies.filter(
      (p) =>
        p.native_config_unavailable_reasons.length === 0 &&
        !p.created_native_escalation_path_id,
    ),
    incompatible: policies.filter(
      (p) =>
        p.native_config_unavailable_reasons.length > 0 &&
        !p.created_native_escalation_path_id,
    ),
    imported: policies.filter((p) => p.created_native_escalation_path_id),
  };

  const alreadyImportedPolicies = policies
    .filter((p) => p.created_native_escalation_path_id)
    .map((p) => p.id);

  const schedulesToCreate = policies
    .filter((p) => selectedPolicies.has(p.id))
    .flatMap((p) => p.referenced_external_schedules || [])
    .filter((s) => s.created_native_schedule_id === undefined)
    .map((s) => s.id);

  const pathsToImport = useMemo(
    () => policies.filter((p) => selectedPolicies.has(p.id)),
    [policies, selectedPolicies],
  );

  const usersToUpgrade = pathsToImport.reduce<Record<string, User>>(
    (acc, policy) => {
      (policy.users_needing_upgrades ?? []).forEach((user) => {
        acc[user.id] = user;
      });
      (policy.referenced_external_schedules ?? []).forEach((schedule) => {
        (schedule.users_needing_upgrades ?? []).forEach((user) => {
          acc[user.id] = user;
        });
      });

      return acc;
    },
    {},
  );

  const { promotionState, setPromotionState, handlePromotion } =
    useOnCallPromotionState<ExternalEscalationPath[]>({
      users: Object.keys(usersToUpgrade),
      userCache: {
        getHydratedUser: (id) => {
          return {
            ...usersToUpgrade[id],
            state: UserOptionStateEnum.Viewer, // The backend's told us these users need upgrades, so we set this.
            label: usersToUpgrade[id].name,
          };
        },
      },
    });

  const revalidateEPs = useRevalidate([
    "escalationPathsList",
    "escalationPathsListExternal",
    "escalationPathsShow",
    "usersTypeahead",
  ]);

  const { trigger: createPolicies } = useAPIMutation(
    "escalationPathsListExternal",
    {},
    async (
      apiClient,
      data: {
        paths_to_import: ExternalEscalationPath[];
        user_ids_to_promote: string[];
      },
    ) => {
      await apiClient.escalationPathsCreateExternal({
        createExternalRequestBody: data,
      });
    },
    {
      onSuccess: async () => {
        showToast({
          title: `${selectionSummaryText(
            selectedPolicies.size,
            schedulesToCreate.length,
          )} created`,
          theme: ToastTheme.Success,
        });
        revalidateEPs();
        onClose();
      },
      showErrorToast: "Failed to import escalation policies",
    },
  );

  const createPoliciesOrPromoteUsers = async () => {
    const paths = policies.filter((p) => selectedPolicies.has(p.id));

    const { shouldPromote, userIdsToPromote } = await handlePromotion(paths);
    if (shouldPromote) {
      await createPolicies({
        paths_to_import: paths,
        user_ids_to_promote: userIdsToPromote,
      });
    }
  };

  const togglePolicy = (policyId: string, selected: boolean) => {
    setSelectedPolicies((prev) => {
      if (selected) {
        return prev.add(policyId);
      } else {
        prev.delete(policyId);
        return prev;
      }
    });
    setSelectedSchedules((prev) => {
      const next = new Set(prev);

      policies
        .find((p) => p.id === policyId)
        ?.referenced_external_schedules?.forEach((s) => {
          if (selected) {
            next.add(s.id);
          } else {
            next.delete(s.id);
          }
        });
      return next;
    });
  };

  const [currentTab, setCurrentTab] = useState("ready");

  if (integrationsError) {
    return <GenericErrorMessage error={integrationsError} />;
  }

  return (
    <Drawer onClose={onClose} width="large">
      <DrawerContents>
        <DrawerTitle
          title="Import escalation policies"
          icon={IconEnum.Download}
          onClose={onClose}
          color={ColorPaletteEnum.Slate200}
          hexColor={getColor("slate", "50")}
          subtitle={
            "Select which escalation policies, and related schedules you want to import. We won’t duplicate schedules that you have already imported, or that appear across multiple policies."
          }
          className={"sticky top-0 z-[50]"}
        />
        {integrationsLoading ? (
          <Loader />
        ) : !installedPagerduty ? (
          <EmptyState />
        ) : (
          <>
            <OnCallPromotionConfirmationModal
              noun="escalation paths"
              state={promotionState}
              onClose={() => setPromotionState(null)}
              onSubmit={(paths, userIdsToPromote) =>
                createPolicies({
                  paths_to_import: paths,
                  user_ids_to_promote: userIdsToPromote,
                })
              }
            />
            <DrawerBody className="flex flex-col p-6 gap-4">
              <Input
                id="search"
                placeholder="Search for policy"
                type={InputType.Search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value ?? "")
                }
                value={search}
                iconName={IconEnum.Search}
                iconProps={{
                  id: IconEnum.Search,
                  className: "text-content-tertiary",
                }}
                className="rounded-lg justify-start items-center gap-2 inline-flex bg-surface-secondary"
              />
              <TabSection
                tabs={[
                  {
                    label: "Ready to import",
                    id: "ready",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${policiesForTab["ready"].length}`}
                      </Badge>
                    ),
                  },
                  {
                    label: "Incompatible",
                    id: "incompatible",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${policiesForTab["incompatible"].length}`}
                      </Badge>
                    ),
                  },
                  {
                    label: "Already imported",
                    id: "imported",
                    accessory: (
                      <Badge theme={BadgeTheme.Secondary}>
                        {`${policiesForTab["imported"].length}`}
                      </Badge>
                    ),
                  },
                ]}
                value={currentTab}
                onTabChange={(newTab) => {
                  setCurrentTab(newTab);
                }}
                withIndicator
                tabBarClassName="border-b border-stroke mb-4"
              >
                {policiesForTab[currentTab].length > 0 && (
                  <SelectableTable<ExternalEscalationPath>
                    gridTemplateColumns="1fr minmax(0, 1fr) auto"
                    header={
                      <>
                        <TableHeaderCell title="Escalation policy" />
                        <TableHeaderCell title="Schedules" />
                        <TableHeaderCell className={"justify-end"} />
                      </>
                    }
                    wrappedInBox
                    data={policiesForTab[currentTab]}
                    isRowDisabled={(policy) =>
                      policy.native_config_unavailable_reasons.length > 0 ||
                      !!policy.created_native_escalation_path_id
                    }
                    renderRow={(policy, index, checkbox) => {
                      if (!policy) {
                        return null;
                      }
                      const isDisabled =
                        policy.native_config_unavailable_reasons.length > 0 ||
                        !!policy.created_native_escalation_path_id;

                      return (
                        <TableRow
                          key={index}
                          isLastRow={
                            index === policiesForTab[currentTab].length - 1
                          }
                        >
                          {checkbox}
                          <TableCell
                            key={`${index}-policy`}
                            className={tcx("flex items-center gap-2", {
                              "opacity-40": isDisabled,
                            })}
                          >
                            <Badge
                              icon={IconEnum.Pagerduty}
                              theme={BadgeTheme.Unstyled}
                              size={BadgeSize.Medium}
                              className={
                                "flex flex-row gap-1 bg-[#E6F6EB] items-center text-ellipsis overflow-hidden max-w-[40ch]"
                              }
                            >
                              <span className={"overflow-hidden text-ellipsis"}>
                                {policy.name}
                              </span>
                            </Badge>
                          </TableCell>
                          <TableCell
                            key={`${index}-schedule`}
                            className={tcx(
                              "flex items-center gap-2 flex-wrap",
                              {
                                "opacity-40": isDisabled,
                              },
                              "max-w-[30ch]",
                            )}
                          >
                            <ExternalScheduleBadges
                              schedules={
                                policy.referenced_external_schedules || []
                              }
                            />
                          </TableCell>
                          <TableCell>{renderTooltipAndBadge(policy)}</TableCell>
                        </TableRow>
                      );
                    }}
                    selected={Array.from([
                      ...selectedPolicies,
                      ...alreadyImportedPolicies,
                    ])}
                    onSelectChanged={(id, newValue) => {
                      togglePolicy(id, newValue);
                    }}
                    selectAll={
                      selectedPolicies.size > 0 &&
                      _.isEqual(
                        Array.from(selectedPolicies).sort(),
                        policiesForTab[currentTab].map((p) => p.id).sort(),
                      )
                    }
                    onSelectAllChanged={(selected) =>
                      setSelectedPolicies(
                        new Set(
                          selected
                            ? policiesForTab[currentTab]
                                .filter(
                                  (p) =>
                                    p.native_config_unavailable_reasons
                                      .length === 0 &&
                                    p.created_native_escalation_path_id ===
                                      undefined,
                                )
                                .map((p) => p.id)
                            : [],
                        ),
                      )
                    }
                  />
                )}
                {!isLoading && allEntriesLoaded && policies.length === 0 && (
                  <div className="p-4 text-center text-content-secondary">
                    No escalation policies found
                  </div>
                )}
              </TabSection>
              {isLoading && <Loader />}
              {!allEntriesLoaded && !isLoading && (
                <div>
                  <Button
                    analyticsTrackingId={"load-more-escalation-paths"}
                    onClick={loadMoreEntries}
                    className={"w-fit"}
                  >
                    Load more
                  </Button>
                </div>
              )}
            </DrawerBody>
            <DrawerFooter
              className={
                "flex items-center justify-between gap-2 sticky bottom-0 z-[50] bg-white"
              }
            >
              {policies.length > 0 ? (
                <span className="text-sm font-medium text-content-tertiary">
                  {`${selectionSummaryText(
                    selectedPolicies.size,
                    selectedSchedules.size,
                  )} selected`}
                </span>
              ) : (
                <div className={"grow"} />
              )}
              <div className={"flex gap-2"}>
                <Button
                  theme={ButtonTheme.Secondary}
                  onClick={onClose}
                  analyticsTrackingId="cancel-policy-import"
                >
                  Back
                </Button>
                <Button
                  onClick={() => createPoliciesOrPromoteUsers()}
                  disabled={selectedPolicies.size === 0}
                  analyticsTrackingId="confirm-policy-import"
                  theme={ButtonTheme.Primary}
                >
                  Import
                </Button>
              </div>
            </DrawerFooter>
          </>
        )}
      </DrawerContents>
    </Drawer>
  );
};

const selectionSummaryText = (
  selectedPoliciesCount: number,
  selectedSchedulesCount: number,
) => {
  if (selectedPoliciesCount === 0) {
    return "No escalation policies";
  }

  const schedulesText =
    selectedSchedulesCount > 0
      ? `and ${selectedSchedulesCount} ${pluralize(
          "schedule",
          selectedSchedulesCount,
        )} `
      : "";

  return `${selectedPoliciesCount} escalation ${pluralize(
    "policy",
    selectedPoliciesCount,
  )} ${schedulesText}`;
};

const EmptyState = () => {
  const { integrations } = useIntegrations();
  const hasOpsgenie = integrations?.some(
    (i) => i.provider === IntegrationProvider.Opsgenie && i.installed,
  );

  if (hasOpsgenie) {
    return (
      <div className="p-6 space-y-4">
        <span>
          We can only import escalation policies from PagerDuty at the moment.
          You can still import schedules from Opsgenie.
        </span>
        <div className="flex flex-wrap gap-4">
          <Button
            analyticsTrackingId={"configure-pagerduty-for-import"}
            href="/settings/integrations/pagerduty"
            icon={IconEnum.Pagerduty}
          >
            Connect PagerDuty
          </Button>
          <Button
            analyticsTrackingId={"ep-import-not-available-import-schedule"}
            href="/on-call/schedules/import"
            icon={IconEnum.Calendar}
          >
            Import schedules
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={"p-6 space-y-4"}>
      <span>
        To import escalation policies, you need to connect your PagerDuty or
        Opsgenie account.
      </span>
      <div className="flex flex-wrap gap-4">
        <Button
          analyticsTrackingId={`configure-pagerduty-for-import`}
          href="/settings/integrations/pagerduty"
          icon={IconEnum.Pagerduty}
        >
          Connect PagerDuty
        </Button>
        <Button
          analyticsTrackingId={`configure-opsgenie-for-import`}
          href="/settings/integrations/opsgenie"
          icon={IconEnum.Opsgenie}
        >
          Connect Opsgenie
        </Button>
      </div>
    </div>
  );
};

const tooltipTextForScheduleReason = (
  schedule: ExternalSchedule,
): string | undefined => {
  switch (schedule.native_config_unavailable_reason) {
    case ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval:
      return `The ${schedule.name} schedule has an interval that isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture:
      return `The ${schedule.name} schedule ends in the future, which isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers:
      return `The ${schedule.name} schedule has no usable layers, which isn't supported by incident.io.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced:
      return `The ${schedule.name} schedule has no configuration synced.`;
    case ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions:
      return `The ${schedule.name} schedule has a restriction that isn't supported by incident.io.`;
    default:
      return undefined;
  }
};

const tooltipTextForEPReason = (
  reason: ExternalEscalationPathNativeConfigUnavailableReasonsEnum,
): string | undefined => {
  switch (reason) {
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.TooManyRepeats:
      return "This escalation policy repeats too many times to be supported by incident.io.";
    case ExternalEscalationPathNativeConfigUnavailableReasonsEnum.UnsupportedFeature:
      return "This escalation policy uses a feature that isn't supported by incident.io.";
    default:
      return undefined;
  }
};

const tooltipComponentForReason = (
  reasonText: string | undefined,
): React.ReactNode => {
  if (reasonText === undefined) {
    return null;
  }

  return (
    <span className="text-sm">
      {reasonText} Learn more about importing{" "}
      <Link
        className={"text-sm"}
        openInNewTab
        href={
          "https://help.incident.io/articles/7709430939-importing-schedules-and-escalation-policies-from-pagerduty"
        }
        analyticsTrackingId={"connected-users-helpcenter"}
      >
        here
      </Link>
    </span>
  );
};

const renderTooltipAndBadge = (
  ep: ExternalEscalationPath,
): React.ReactNode | null => {
  // If we've imported this, show the imported badge
  if (ep.created_native_escalation_path_id) {
    return (
      <Badge theme={BadgeTheme.Info}>
        Already imported <Icon id={IconEnum.ExternalLink} />
      </Badge>
    );
  }

  // Else if this importable, nothing to display
  if (ep.native_config_unavailable_reasons.length === 0) {
    return <></>;
  }

  // Otherwise we need to figure out why this isn't importable
  // and show the appropriate badge and tooltip.
  const tooltipMessages: Array<React.ReactNode> = [];
  const missingUsers: Array<EmbeddedCatalogEntry> = [];
  let badge: React.ReactNode;

  // If we see any of these, we need to show the unsupported badge
  const incompatibleReasons = [
    ExternalEscalationPathNativeConfigUnavailableReasonsEnum.UnsupportedFeature,
    ExternalEscalationPathNativeConfigUnavailableReasonsEnum.TooManyRepeats,
    ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval,
    ExternalScheduleNativeConfigUnavailableReasonEnum.EndsInFuture,
    ExternalScheduleNativeConfigUnavailableReasonEnum.NoUsableLayers,
    ExternalScheduleNativeConfigUnavailableReasonEnum.NoConfigSynced,
    ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedInterval,
    ExternalScheduleNativeConfigUnavailableReasonEnum.UnsupportedRestrictions,
  ];

  // Add any missing users from the ep to missingUsers
  ep.unresolvable_directly_referenced_external_users.forEach((user) => {
    missingUsers.push(user);
  });

  ep.referenced_external_schedules?.forEach((schedule: ExternalSchedule) => {
    // Push any missing users to missingUsers
    schedule.unresolvable_external_users?.forEach((user) => {
      missingUsers.push(user);
    });

    // Set the badge and push a tooltip message if we need to
    if (
      schedule.native_config_unavailable_reason !== undefined &&
      incompatibleReasons.includes(schedule.native_config_unavailable_reason)
    ) {
      badge = (
        <Badge theme={BadgeTheme.Error}>
          Incompatible <Icon id={IconEnum.Alert} />
        </Badge>
      );
      // Push a tooltip message
      tooltipMessages.push(
        tooltipComponentForReason(tooltipTextForScheduleReason(schedule)),
      );
    }
  });

  // Render tooltip messages for missing users if required
  if (missingUsers.length > 0) {
    tooltipMessages.push(
      <UnresolvedUsersTooltipContent
        users={missingUsers}
        provider={ep.external_provider as ExternalScheduleExternalProviderEnum}
        entity={"this escalation policy or a referenced schedule"}
      />,
    );
    // Set the badge
    badge = (
      <Badge theme={BadgeTheme.Warning}>
        {`Missing ${
          missingUsers.length === 0
            ? "users"
            : pluralize("user", missingUsers.length)
        }`}
        <Icon id={IconEnum.Info} />
      </Badge>
    );
  }

  // Update the badge and push a tooltip message if we need to
  ep.native_config_unavailable_reasons.forEach((reason) => {
    if (incompatibleReasons.includes(reason)) {
      badge = (
        <Badge theme={BadgeTheme.Error}>
          Incompatible <Icon id={IconEnum.CloseCircle} />
        </Badge>
      );
    }
    tooltipMessages.push(
      tooltipComponentForReason(tooltipTextForEPReason(reason)),
    );
  });

  return (
    <Tooltip content={tooltipMessages.map((t) => t)}>
      {/* We need the div for the tooltip hover to work */}
      <div>{badge}</div>
    </Tooltip>
  );
};
