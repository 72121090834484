import {
  EvaluationBacktest,
  useAiStaffServiceAiStaffListEvaluationBacktests,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeTheme,
  Button,
  ButtonTheme,
  EmptyState,
  Icon,
  IconEnum,
  IconSize,
  LoadingWrapper,
  LocalDateTime,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import { isEmpty } from "lodash";
import { useState } from "react";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { BacktestCreateModal } from "./BacktestCreateModal";
import { BacktestShowState } from "./BacktestsShowPage";

export const BacktestsListPage = () => {
  const { data, isLoading } = useAiStaffServiceAiStaffListEvaluationBacktests();

  const backtests = data?.backtests ?? [];

  const [showCreateBacktestModal, setShowCreateBacktestModal] = useState(false);

  return (
    <WorkbenchSubPageWrapper
      accessory={
        <Button
          analyticsTrackingId={null}
          onClick={() => setShowCreateBacktestModal(true)}
          theme={ButtonTheme.Primary}
        >
          Create backtest
        </Button>
      }
    >
      <LoadingWrapper loading={isLoading}>
        {isEmpty(backtests) ? (
          <EmptyState content="No backtests created yet" />
        ) : (
          <StackedList>
            {backtests.map((backtest) => (
              <BacktestRow backtest={backtest} key={backtest.id} />
            ))}
          </StackedList>
        )}
      </LoadingWrapper>
      {showCreateBacktestModal && (
        <BacktestCreateModal
          onClose={() => setShowCreateBacktestModal(false)}
        />
      )}
    </WorkbenchSubPageWrapper>
  );
};

const BacktestRow = ({ backtest }: { backtest: EvaluationBacktest }) => {
  return (
    <StackedListItem
      rowHref={`/workbench/backtests/${backtest.id}`}
      key={backtest.id}
      title={
        <div className="flex items-center gap-2">
          <LocalDateTime
            timestamp={backtest.created_at}
            className="font-semibold"
          />
        </div>
      }
      badgeNode={
        <div className="flex items-center gap-2">
          <Badge theme={BadgeTheme.Secondary}>
            {backtest.investigation_plan_type}
          </Badge>
          <BacktestShowState backtest={backtest} />
          <div className="text-sm text-gray-500">
            <span className="font-semibold">{backtest.processed_entries}</span>{" "}
            of <span className="font-semibold">{backtest.total_entries}</span>{" "}
            entries processed
          </div>
        </div>
      }
      accessory={
        <Icon
          id={IconEnum.ChevronRight}
          className="text-content-tertiary"
          size={IconSize.Medium}
        />
      }
      description={<div className="pt-2">{backtest.notes}</div>}
    />
  );
};
