/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessorBackfillRunOptions
 */
export interface ProcessorBackfillRunOptions {
    /**
     * Number of resources to process
     * @type {number}
     * @memberof ProcessorBackfillRunOptions
     */
    batch_size: number;
    /**
     * The number of goroutines to run concurrently
     * @type {number}
     * @memberof ProcessorBackfillRunOptions
     */
    concurrency?: number;
    /**
     * Flag to determine if we should run the backfill inline or publish events to the queue
     * @type {boolean}
     * @memberof ProcessorBackfillRunOptions
     */
    run_inline: boolean;
}

export function ProcessorBackfillRunOptionsFromJSON(json: any): ProcessorBackfillRunOptions {
    return ProcessorBackfillRunOptionsFromJSONTyped(json, false);
}

export function ProcessorBackfillRunOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessorBackfillRunOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batch_size': json['batch_size'],
        'concurrency': !exists(json, 'concurrency') ? undefined : json['concurrency'],
        'run_inline': json['run_inline'],
    };
}

export function ProcessorBackfillRunOptionsToJSON(value?: ProcessorBackfillRunOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batch_size': value.batch_size,
        'concurrency': value.concurrency,
        'run_inline': value.run_inline,
    };
}

