/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverviewWakeUpDatapoint
 */
export interface OverviewWakeUpDatapoint {
    /**
     * 
     * @type {number}
     * @memberof OverviewWakeUpDatapoint
     */
    number_of_wake_ups: number;
    /**
     * 
     * @type {string}
     * @memberof OverviewWakeUpDatapoint
     */
    theme?: string;
    /**
     * 
     * @type {string}
     * @memberof OverviewWakeUpDatapoint
     */
    title?: string;
}

export function OverviewWakeUpDatapointFromJSON(json: any): OverviewWakeUpDatapoint {
    return OverviewWakeUpDatapointFromJSONTyped(json, false);
}

export function OverviewWakeUpDatapointFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverviewWakeUpDatapoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number_of_wake_ups': json['number_of_wake_ups'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function OverviewWakeUpDatapointToJSON(value?: OverviewWakeUpDatapoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number_of_wake_ups': value.number_of_wake_ups,
        'theme': value.theme,
        'title': value.title,
    };
}

