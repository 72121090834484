/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CancelledTimelineData
 */
export interface CancelledTimelineData {
    /**
     * The reason the escalation was cancelled
     * @type {string}
     * @memberof CancelledTimelineData
     */
    reason?: CancelledTimelineDataReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum CancelledTimelineDataReasonEnum {
    AlertResolved = 'alert_resolved',
    IncidentDeclined = 'incident_declined',
    InboundCallEnded = 'inbound_call_ended'
}

export function CancelledTimelineDataFromJSON(json: any): CancelledTimelineData {
    return CancelledTimelineDataFromJSONTyped(json, false);
}

export function CancelledTimelineDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelledTimelineData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
    };
}

export function CancelledTimelineDataToJSON(value?: CancelledTimelineData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': value.reason,
    };
}

