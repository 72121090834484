import { Schedule, ScheduleEntry } from "@incident-io/api";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { FieldPath, UseFormReturn } from "react-hook-form";

import { getVersionId } from "../common/marshall";
import {
  CustomHandoverRule,
  CustomHandoverRuleType,
  RotaFormData,
  RotaId,
  RotaVersionId,
  ScheduleFormData,
} from "../common/types";
import { getUpcomingRotaChanges } from "../common/util";

// All the logic below is about bumping your handover time if you make changes to your rotation users so
// that it maintains the current on-caller's shift and only comes into effect at the next rotation change i.e. when
// the next user comes on. This does not work in some cases:
// - If you have asymmetric schedules (through intervals or number of people)
// - If you're viewing a schedule where there is no shift before the current time.
// We do this "expected behaviour" by bumping the handover to when the whole rotation would have last
// started given the next configuration, and then set the effective time of this change to next rotation to preserve
// scheduled history. This is best-effort.
export const useHandoverStartAtAdjustments = ({
  rotaId,
  rotaVersionId,
  currentShifts,
  scheduledEntries,
  now,
  adjustHandoverStartAt,
  selectedUsers,
  usersAreDirty,
  customHandovers,
  initialData,
  formMethods,
  getPath,
}: {
  rotaId: string | undefined;
  rotaVersionId: string;
  currentShifts: ScheduleEntry[] | undefined;
  scheduledEntries: ScheduleEntry[] | undefined;
  now: DateTime;
  adjustHandoverStartAt: boolean;
  selectedUsers: { id: string }[];
  usersAreDirty: boolean | undefined;
  customHandovers: CustomHandoverRule[];
  initialData: Schedule | undefined;
  formMethods: UseFormReturn<ScheduleFormData>;
  getPath: (
    key: FieldPath<RotaFormData>,
  ) => `rotations.${RotaId}.${RotaVersionId}.${FieldPath<RotaFormData>}`;
}): {
  suggestBump: boolean | undefined;
} => {
  const currentOrPreviousShifts = useMemo(
    () =>
      getPreviousOrCurrentShifts(
        currentShifts || [],
        scheduledEntries || [],
        now,
      ),
    [currentShifts, scheduledEntries, now],
  );

  // Grab the next rotation by finding the next entry where the current/previous on-caller changes.
  const nextRotationShift = useMemo(
    () =>
      findNextRotationEntry(
        now.toJSDate(),
        scheduledEntries || [],
        currentOrPreviousShifts.map((entry) => entry.user_id),
      ),
    [now, scheduledEntries, currentOrPreviousShifts],
  );

  // We use the changed on-caller's position to calculate the offset needed for the new handover time.
  const currentUserIndex =
    currentOrPreviousShifts.length > 0
      ? selectedUsers.findIndex(
          (user) => user.id === currentOrPreviousShifts[0].user_id,
        )
      : undefined;

  const alreadyHasUpcomingRotaChanges = useMemo(
    () =>
      getUpcomingRotaChanges(initialData?.config?.rotations || [], now).filter(
        (r) => r.rotaId === rotaId,
      ).length > 0,
    [initialData, now, rotaId],
  );

  const suggestBump =
    usersAreDirty &&
    customHandovers.length === 1 &&
    currentOrPreviousShifts.length > 0 &&
    nextRotationShift &&
    currentUserIndex !== undefined &&
    !alreadyHasUpcomingRotaChanges;

  const [userOrderState, setUserOrderState] = useState<string>(() => {
    const rotation = initialData?.config?.rotations.find(
      (r) =>
        r.id === rotaId &&
        getVersionId({ effectiveFrom: r.effective_from }) === rotaVersionId,
    );
    return JSON.stringify(rotation?.user_ids ?? []);
  });
  useEffect(() => {
    if (!nextRotationShift || currentUserIndex === undefined) {
      return;
    }

    if (alreadyHasUpcomingRotaChanges) {
      return;
    }

    const hasChangedUserOrdering =
      userOrderState !== JSON.stringify(selectedUsers);

    if (hasChangedUserOrdering && adjustHandoverStartAt) {
      setUserOrderState(JSON.stringify(selectedUsers));

      // calculateNewHandoverTime will calculate the new handover time by taking the next rotation change time
      // and walking that back by the number of people who should have already been on call.
      // Imagine Martha, Rory and Lawrence are currently scheduled, and Rory is on-call.
      // We want to add Leo after Martha. Lawrence is on-call next, so the new handover will be Lawrence.start_at
      // minus 3 people's shifts (which should be equivalent to Martha.start_at.
      const newHandoverStartAt = calculateNewHandoverTime(
        -1 * (currentUserIndex + 1),
        customHandovers,
        DateTime.fromJSDate(nextRotationShift.start_at),
      );
      formMethods.setValue(getPath("handover_start_at"), newHandoverStartAt);
      formMethods.setValue(getPath("is_deferred"), "true");
      formMethods.setValue(
        getPath("effective_from"),
        nextRotationShift.start_at,
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userOrderState,
    adjustHandoverStartAt,
    nextRotationShift,
    currentUserIndex,
    alreadyHasUpcomingRotaChanges,
  ]);

  return { suggestBump };
};

const calculateNewHandoverTime = (
  offset: number,
  customHandovers: CustomHandoverRule[],
  handoverStartAt: DateTime,
): Date => {
  // If people have a really custom setup, we don't want to do any kind of
  // touching of their handover time: they already know they're in a custom situation.
  if (customHandovers.length !== 1) {
    return handoverStartAt.toJSDate();
  }

  const handover = customHandovers[0];
  const handoverInterval = handover.handover_interval as unknown as number;
  if (handover.handover_interval_type === CustomHandoverRuleType.Daily) {
    handoverStartAt = handoverStartAt.plus({
      days: offset * handoverInterval,
    });
  } else if (
    handover.handover_interval_type === CustomHandoverRuleType.Weekly
  ) {
    handoverStartAt = handoverStartAt.plus({
      weeks: offset * handoverInterval,
    });
  } else {
    handoverStartAt = handoverStartAt.plus({
      hours: offset * handoverInterval,
    });
  }

  return handoverStartAt.toJSDate();
};

// Grab the current shift or the last shift that happened so that we find the next rotation shift.
// We can't just grab the next shift because schedules can have working intervals.
const getPreviousOrCurrentShifts = (
  currentShifts: ScheduleEntry[],
  scheduledEntries: ScheduleEntry[],
  now: DateTime,
): ScheduleEntry[] => {
  if (currentShifts.length > 0) {
    return currentShifts;
  }
  const entryBefore = scheduledEntries.findLast(
    (entry) => DateTime.fromJSDate(entry.end_at) < now,
  );
  return scheduledEntries.filter(
    (entry) => entry.end_at === entryBefore?.end_at,
  );
};

const findNextRotationEntry = (
  currentTime: Date,
  entriesData: ScheduleEntry[],
  currentShiftUserIds?: string[],
): ScheduleEntry | undefined => {
  // If we haven't found an entry here, we're likely in a sad situation where
  // our first entry in this time frame is just for no one.
  if (!currentShiftUserIds) {
    return undefined;
  }
  return entriesData.find(
    (entry) =>
      new Date(entry.start_at) > currentTime &&
      !currentShiftUserIds.includes(entry.user_id),
  );
};
