import { RadioButtonGroupV2 } from "@incident-shared/forms/v2/inputs/RadioButtonGroupV2";
import { Heading, Spinner } from "@incident-ui";
import _ from "lodash";
import { useFormContext } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import {
  StatusPage,
  StatusPageDateViewEnum,
  StatusPagePageTypeEnum,
  StatusPageThemeEnum,
} from "src/contexts/ClientContext";

import { PreviewItem } from "../../common/ComponentsEditor/utils";
import { usePageType } from "../../common/PageTypeContext";
import {
  LogoData,
  StatusPagesLogoUploader,
} from "../../edit/StatusPagesLogoUploader";
import {
  PageStructurePreview,
  PageType,
} from "../../view/PageStructurePreview";

export type PageSetupFormData = {
  logo: LogoData;
  favicon: LogoData;
  theme: StatusPageThemeEnum;
  date_view: StatusPageDateViewEnum;
};

export const buildPageSetupFormData = (
  page?: Pick<
    StatusPage,
    | "favicon_key"
    | "favicon_url"
    | "lightmode_logo_key"
    | "lightmode_logo_url"
    | "darkmode_logo_key"
    | "darkmode_logo_url"
    | "theme"
    | "date_view"
  >,
): PageSetupFormData => {
  const logo =
    page &&
    page.theme === StatusPageThemeEnum.Dark &&
    page.darkmode_logo_key &&
    page.darkmode_logo_url
      ? {
          key: page.darkmode_logo_key,
          preview_url: page.darkmode_logo_url,
        }
      : page &&
        page.theme === StatusPageThemeEnum.Light &&
        page.lightmode_logo_key &&
        page.lightmode_logo_url
      ? {
          key: page.lightmode_logo_key,
          preview_url: page.lightmode_logo_url,
        }
      : null;

  const favicon =
    page && page.favicon_key && page.favicon_url
      ? {
          key: page.favicon_key,
          preview_url: page.favicon_url,
        }
      : null;

  return {
    logo,
    favicon,
    theme: page?.theme ?? StatusPageThemeEnum.Dark,
    date_view: page?.date_view ?? StatusPageDateViewEnum.Calendar,
  };
};

export const PageSetupForm = ({
  name,
  subPageName,
  subpath,
  previewItems,
}: Pick<StatusPage, "name" | "subpath"> & {
  previewItems: PreviewItem[];
  subPageName?: string;
}): React.ReactElement => {
  const formMethods = useFormContext<PageSetupFormData>();
  const theme = formMethods.watch("theme");

  const logoUploading = formMethods.watch("logo.uploading");
  const logoPreviewURL = formMethods.watch("logo.preview_url");
  const faviconUploading = formMethods.watch("favicon.uploading");
  const faviconPreviewURL = formMethods.watch("favicon.preview_url");

  const isUploading = logoUploading || faviconUploading;

  const pageType = usePageType();
  const parentPageType =
    pageType === StatusPagePageTypeEnum.Customer
      ? PageType.Customer
      : PageType.PublicParentPage;

  const supportsListView =
    pageType === StatusPagePageTypeEnum.Standalone ||
    pageType === StatusPagePageTypeEnum.Parent;

  return (
    <>
      <div className="space-y-4">
        <Heading level={3} size="small" className="!-mb-2">
          Theming
        </Heading>
        <Form.Helptext className="text-sm text-slate-600">
          {subPageName &&
            `The selected theme and logos will apply across all of your created ${
              parentPageType === PageType.Customer
                ? "customer pages"
                : "sub-pages"
            }.`}
        </Form.Helptext>
        <RadioButtonGroupV2
          name="theme"
          formMethods={formMethods}
          srLabel="Theme"
          boxed
          horizontal
          options={[
            {
              label: "Dark mode",
              value: StatusPageThemeEnum.Dark,
            },
            {
              label: "Light mode",
              value: StatusPageThemeEnum.Light,
            },
          ]}
        />
      </div>
      {supportsListView && (
        <RadioButtonGroupV2
          name="date_view"
          formMethods={formMethods}
          srLabel="Date view"
          label="Date view"
          boxed
          horizontal
          options={[
            {
              label: "Calendar",
              value: StatusPageDateViewEnum.Calendar,
            },
            {
              label: "List",
              value: StatusPageDateViewEnum.List,
            },
          ]}
        />
      )}
      <StatusPagesLogoUploader
        key="logo"
        name="logo"
        subpath={subpath}
        label={"Company logo"}
        displayFilename={(previewURL) =>
          `logo.${_.last(previewURL.split(".")) || "png"}`
        }
        dropText="Drop your logo here in PNG or JPEG format"
      />

      <StatusPagesLogoUploader
        name="favicon"
        subpath={subpath}
        label={"Favicon"}
        displayFilename={(previewURL) =>
          `favicon.${_.last(previewURL.split(".")) || "png"}`
        }
        dropText="Drop a PNG favicon here"
        extraHelp=" We recommend a 512px x 512px image."
        dropzoneOpts={{
          accept: { "image/png": [".png"] },
          maxSize: 1 * 1024 * 1024, // 1MB
        }}
      />

      {isUploading ? (
        <Spinner className="mx-auto" />
      ) : (
        <PageStructurePreview
          name={name}
          items={previewItems}
          logoPreviewURL={logoPreviewURL ?? null}
          faviconPreviewURL={faviconPreviewURL ?? null}
          theme={theme}
          type={subPageName ? parentPageType : PageType.PublicStandalone}
          subPageName={subPageName}
        />
      )}
    </>
  );
};
