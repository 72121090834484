/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AzureDevOpsConfig
 */
export interface AzureDevOpsConfig {
    /**
     * The user that installed the integration
     * @type {string}
     * @memberof AzureDevOpsConfig
     */
    installer_user_id: string;
    /**
     * If set, this indicates an issue with the connection
     * @type {string}
     * @memberof AzureDevOpsConfig
     */
    reconnection_reason: AzureDevOpsConfigReconnectionReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum AzureDevOpsConfigReconnectionReasonEnum {
    Empty = '',
    PagerdutyLimitedUser = 'pagerduty_limited_user',
    GithubMissingScopes = 'github_missing_scopes',
    TokenExpired = 'token_expired',
    InvalidConfig = 'invalid_config',
    InsufficientPermissions = 'insufficient_permissions',
    IncompleteConfig = 'incomplete_config',
    SalesforceTooManyAccounts = 'salesforce_too_many_accounts',
    MultipleAccounts = 'multiple_accounts',
    NotACalendarUser = 'not_a_calendar_user',
    MissingScheduleWriteScope = 'missing_schedule_write_scope',
    GmeetUnavailableOnPrimaryCalendar = 'gmeet_unavailable_on_primary_calendar',
    InvalidConnectedUser = 'invalid_connected_user'
}

export function AzureDevOpsConfigFromJSON(json: any): AzureDevOpsConfig {
    return AzureDevOpsConfigFromJSONTyped(json, false);
}

export function AzureDevOpsConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AzureDevOpsConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'installer_user_id': json['installer_user_id'],
        'reconnection_reason': json['reconnection_reason'],
    };
}

export function AzureDevOpsConfigToJSON(value?: AzureDevOpsConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'installer_user_id': value.installer_user_id,
        'reconnection_reason': value.reconnection_reason,
    };
}

