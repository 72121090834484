/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationNote,
    EvaluationNoteFromJSON,
    EvaluationNoteFromJSONTyped,
    EvaluationNoteToJSON,
} from './EvaluationNote';

/**
 * 
 * @export
 * @interface AIStaffListEvaluationNotesResponseBody
 */
export interface AIStaffListEvaluationNotesResponseBody {
    /**
     * 
     * @type {Array<EvaluationNote>}
     * @memberof AIStaffListEvaluationNotesResponseBody
     */
    evaluation_notes: Array<EvaluationNote>;
}

export function AIStaffListEvaluationNotesResponseBodyFromJSON(json: any): AIStaffListEvaluationNotesResponseBody {
    return AIStaffListEvaluationNotesResponseBodyFromJSONTyped(json, false);
}

export function AIStaffListEvaluationNotesResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIStaffListEvaluationNotesResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evaluation_notes': ((json['evaluation_notes'] as Array<any>).map(EvaluationNoteFromJSON)),
    };
}

export function AIStaffListEvaluationNotesResponseBodyToJSON(value?: AIStaffListEvaluationNotesResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evaluation_notes': ((value.evaluation_notes as Array<any>).map(EvaluationNoteToJSON)),
    };
}

