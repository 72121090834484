import {
  BadgeSize,
  Button,
  ButtonTheme,
  IconEnum,
  IconSize,
} from "@incident-ui";
import React from "react";
import { useLocalStorage } from "use-hooks";

import { tcx } from "./tailwind-classes";
import { useClipboard } from "./useClipboard";

const ShowDebugIDWindowKey = "incident-show-ids";
const ShowEvaluationNotesWindowKey = "incident-show-evaluation-notes";

type StaffOverlayContextType = {
  showDebugID: boolean;
  setShowDebugID: (show: boolean) => void;
  showEvaluationNotes: boolean;
  setShowEvaluationNotes: (show: boolean) => void;
};

const StaffOverlayContext = React.createContext<StaffOverlayContextType | null>(
  null,
);

export const useStaffOverlay = (): StaffOverlayContextType => {
  const context = React.useContext(StaffOverlayContext);

  if (!context) {
    throw new Error("useShowDebugID must be used within a ShowDebugIDProvider");
  }

  return context;
};

export const StaffOverlayProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showDebugID, setShowDebugID] = useLocalStorage(
    ShowDebugIDWindowKey,
    false,
  );
  const [showEvaluationNotes, setShowEvaluationNotes] = useLocalStorage(
    ShowEvaluationNotesWindowKey,
    false,
  );

  return (
    <StaffOverlayContext.Provider
      value={{
        showDebugID,
        setShowDebugID,
        showEvaluationNotes,
        setShowEvaluationNotes,
      }}
    >
      {children}
    </StaffOverlayContext.Provider>
  );
};

export const CopyDebugID = ({
  id,
  className,
}: {
  id: string | undefined;
  className?: string;
}) => {
  const { hasCopied, copyTextToClipboard } = useClipboard();

  const { showDebugID } = useStaffOverlay();

  if (!id) {
    return null;
  }

  if (!showDebugID) {
    return null;
  }

  return (
    <Button
      className={tcx("shrink truncate", className)}
      theme={ButtonTheme.Naked}
      icon={hasCopied ? IconEnum.Success : IconEnum.Copy}
      analyticsTrackingId="copy-token"
      type="button"
      size={BadgeSize.Medium}
      title="Copy to clipboard"
      iconProps={{
        className: hasCopied ? "text-green-content" : "text-slate-700",
        size: IconSize.XS,
      }}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        copyTextToClipboard(id);
      }}
    >
      {id}
    </Button>
  );
};
