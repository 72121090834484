import {
  CustomField,
  CustomFieldFieldModeEnum,
  CustomFieldRequiredV2Enum,
  EnrichedImage,
  Incident,
  PostmortemsCreateTemplateResponseRequestBody,
  PostmortemTemplateSection,
  PostmortemTemplateSectionConfig,
  PostmortemTemplateSectionResponse,
} from "@incident-io/api";
import {
  TemplatedTextDisplay,
  TemplatedTextDisplayStyle,
} from "@incident-shared/forms/v1/TemplatedText";
import {
  CustomFieldFormElement,
  FormCustomFieldEntries,
  marshallCustomFieldEntriesToRequestPayload,
  marshallCustomFieldsToFormData,
  SensibleDefaultModeText,
} from "@incident-shared/forms/v2/CustomFieldFormElement";
import { TemplatedTextInputV2 } from "@incident-shared/forms/v2/inputs/TemplatedTextInputV2";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ImageUploader } from "@incident-shared/images/ImageUploader";
import { Prompt } from "@incident-shared/utils/Prompt";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Icon,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "src/components/@shared/forms";
import { useAPIMutation, useAPIRefetch } from "src/utils/swr";

import { SectionWrapper } from "../body/SectionWrapper";
import { PostmortemSectionCustomFieldWrapper } from "./PostmortemCustomSection";

type FormData = Omit<
  PostmortemsCreateTemplateResponseRequestBody,
  "custom_field_entries"
> & {
  custom_field_entries: FormCustomFieldEntries;
};

export const PostmortemSectionEditor = ({
  incident,
  templateId,
  section,
  config,
  existingResponse,
  onSave,
  customFieldsToShow,
}: {
  incident: Incident;
  templateId: string;
  section: PostmortemTemplateSection;
  config: PostmortemTemplateSectionConfig;
  existingResponse?: PostmortemTemplateSectionResponse;
  onSave: () => void;
  customFieldsToShow: CustomField[];
}): React.ReactElement => {
  const entries = incident.custom_field_entries;
  const manualEdits = incident.manual_edits;

  const isEditing = !!existingResponse;

  const formMethods = useForm<FormData>({
    defaultValues: {
      // If there's already a response, use that. Otherwise, prefill with the resolved template.
      response: existingResponse?.response || section.resolved_template,
      section_id: section.id,
      custom_field_entries: marshallCustomFieldsToFormData({
        customFields: customFieldsToShow,
        entries,
        manualEdits,
      }),
      images: existingResponse?.images || [],
    },
  });

  const {
    watch,
    setError,
    formState: { isDirty },
  } = formMethods;

  const [imagePreviews, setImagePreviews] = useState<EnrichedImage[]>(
    existingResponse?.images || [],
  );

  const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);

  const onAddImage = () => {
    // Trigger the system dialog by clicking the hidden file input
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const customFieldEntries = watch("custom_field_entries");

  const entryPayloads = marshallCustomFieldEntriesToRequestPayload(
    customFieldsToShow,
    formMethods.formState.touchedFields,
    customFieldEntries,
  );

  const refetchIncident = useAPIRefetch("incidentsShow", { id: incident.id });

  const {
    trigger: onSubmit,
    isMutating: saving,
    genericError,
  } = useAPIMutation(
    "postmortemsListTemplateResponses",
    { incidentId: incident.id, postmortemTemplateId: templateId },
    async (apiClient, formData: FormData) => {
      // Clear out any undefined or non-object values
      const sanitisedImages = formData.images.filter(
        (item) => item !== undefined && typeof item === "object",
      );

      if (isEditing) {
        await apiClient.postmortemsUpdateTemplateResponse({
          responseId: existingResponse.id,
          updateTemplateResponseRequestBody: {
            response: formData.response,
            images: sanitisedImages,
            custom_field_entries: marshallCustomFieldEntriesToRequestPayload(
              customFieldsToShow,
              formMethods.formState.touchedFields,
              formData.custom_field_entries,
            ),
          },
        });
      } else {
        await apiClient.postmortemsCreateTemplateResponse({
          incidentId: incident.id,
          postmortemTemplateId: templateId,
          createTemplateResponseRequestBody: {
            response: formData.response,
            images: sanitisedImages,
            section_id: formData.section_id,
            custom_field_entries: marshallCustomFieldEntriesToRequestPayload(
              customFieldsToShow,
              formMethods.formState.touchedFields,
              formData.custom_field_entries,
            ),
          },
        });
      }
      // Refetch the incident so that we're ready to show the new custom field values in the display view.
      await refetchIncident();
    },
    {
      onSuccess: () => {
        onSave();
      },
      setError,
    },
  );

  const hasCustomFields = customFieldsToShow.length > 0;

  return (
    <SectionWrapper sectionName={config.name}>
      <Form.Root<FormData>
        formMethods={formMethods}
        aria-live="polite"
        onSubmit={onSubmit}
        genericError={genericError}
        saving={saving}
        submitOnCmdEnter
      >
        <Prompt
          when={isDirty}
          message="You have unsaved changes. Are you sure you want to navigate away?"
        />
        <div className="rounded-lg shadow-md border border-stroke-hover bg-white p-6 is-editing flex flex-col gap-4">
          {hasCustomFields && (
            <div className="grid grid-cols-[auto_1fr] gap-y-1">
              {customFieldsToShow.map((customField) => {
                const fieldIsRequired =
                  customField.required_v2 === CustomFieldRequiredV2Enum.Always;

                const isInSensibleDefaultMode =
                  customField.field_mode ===
                  CustomFieldFieldModeEnum.SensibleDefault;

                return (
                  <PostmortemSectionCustomFieldWrapper
                    customField={customField}
                    key={customField.id}
                    tooltipContent={
                      isInSensibleDefaultMode && (
                        <SensibleDefaultModeText
                          customField={customField}
                          manualEdits={manualEdits}
                          incidentId={incident.id}
                          // We don't let users reset sensible default fields from the tooltip, because we'd also have to reset the form state which gets a bit tricky.
                          canReset={false}
                        />
                      )
                    }
                    isEditing
                  >
                    <CustomFieldFormElement<FormData>
                      fieldKeyPrefix={`custom_field_entries.${customField.id}.values`}
                      key={customField?.id}
                      formMethods={formMethods}
                      incidentId={incident?.id}
                      customField={customField}
                      required={fieldIsRequired}
                      includeNoValue={false}
                      entryPayloads={entryPayloads}
                      manualEdits={manualEdits}
                      hideLabel={true} // Because we render labels to the left of the input
                    />
                  </PostmortemSectionCustomFieldWrapper>
                );
              })}
            </div>
          )}
          <div className="space-y-2">
            {config.help_text && (
              <div className="bg-slate-50 p-4 rounded-2">
                <TemplatedTextDisplay
                  className="text-content-secondary text-xs-med"
                  value={config.help_text}
                  style={TemplatedTextDisplayStyle.Naked}
                />
              </div>
            )}
            <TemplatedTextInputV2
              formMethods={formMethods}
              style={TemplatedTextDisplayStyle.Naked}
              name="response"
              autoFocus
              includeVariables={false}
              format="rich"
              includeExpressions={false}
              placeholder="Click to start writing..."
              required
            />
          </div>
          <div>
            <ImageUploader
              formMethods={formMethods}
              name="images"
              incidentId={incident.id}
              imagePreviews={imagePreviews}
              setImagePreviews={setImagePreviews}
              hiddenFileInputRef={hiddenFileInputRef}
              emptyState={({ isUploading }) => (
                <div className={"flex-center-y justify-between"}>
                  <Button
                    size={BadgeSize.Medium}
                    onClick={onAddImage}
                    analyticsTrackingId={"upload-image"}
                    icon={IconEnum.Add}
                    theme={ButtonTheme.Naked}
                    disabled={isUploading}
                  >
                    Add image
                    {isUploading && (
                      <Icon
                        id={IconEnum.Loader}
                        size={IconSize.Small}
                        className="animate-spin"
                      />
                    )}
                  </Button>
                  <FormButtons saving={saving} onSave={onSave} />
                </div>
              )}
            />
          </div>
          {imagePreviews.length > 0 && (
            <div className="flex-center-y justify-end">
              <FormButtons saving={saving} onSave={onSave} />
            </div>
          )}
        </div>
      </Form.Root>
    </SectionWrapper>
  );
};

const FormButtons = ({
  saving,
  onSave,
}: {
  saving: boolean;
  onSave: () => void;
}): React.ReactElement => {
  return (
    <div className="flex gap-2">
      <Button
        analyticsTrackingId="cancel-saving-postmortem-section"
        type="reset"
        size={BadgeSize.Medium}
        disabled={saving}
        onClick={() => {
          onSave();
        }}
      >
        Cancel
      </Button>
      <GatedButton
        analyticsTrackingId="save-postmortem-section"
        theme={ButtonTheme.Primary}
        size={BadgeSize.Medium}
        type="submit"
        disabled={saving}
      >
        Save
      </GatedButton>
    </div>
  );
};
