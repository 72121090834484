/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvestigationArtifact,
    InvestigationArtifactFromJSON,
    InvestigationArtifactFromJSONTyped,
    InvestigationArtifactToJSON,
} from './InvestigationArtifact';

/**
 * 
 * @export
 * @interface InvestigationCheck
 */
export interface InvestigationCheck {
    /**
     * Artifacts generated by this check
     * @type {Array<InvestigationArtifact>}
     * @memberof InvestigationCheck
     */
    artifacts?: Array<InvestigationArtifact>;
    /**
     * Type classification of the check
     * @type {string}
     * @memberof InvestigationCheck
     */
    check_type: string;
    /**
     * When the check first completed running (went to either complete or error)
     * @type {Date}
     * @memberof InvestigationCheck
     */
    completed_at?: Date;
    /**
     * Timestamp when the check was created
     * @type {Date}
     * @memberof InvestigationCheck
     */
    created_at: Date;
    /**
     * Execution duration in seconds
     * @type {number}
     * @memberof InvestigationCheck
     */
    duration_seconds: number;
    /**
     * Error message if check execution failed
     * @type {string}
     * @memberof InvestigationCheck
     */
    error_message?: string;
    /**
     * Assessment of finding's relevance to incident
     * @type {string}
     * @memberof InvestigationCheck
     */
    finding_relevance: string;
    /**
     * Unique identifier of the check
     * @type {string}
     * @memberof InvestigationCheck
     */
    id: string;
    /**
     * Parameters supplied to the check
     * @type {string}
     * @memberof InvestigationCheck
     */
    params?: string;
    /**
     * Detailed results from the check execution
     * @type {string}
     * @memberof InvestigationCheck
     */
    result?: string;
    /**
     * When the check first started running
     * @type {Date}
     * @memberof InvestigationCheck
     */
    started_at?: Date;
    /**
     * Summary of check findings
     * @type {string}
     * @memberof InvestigationCheck
     */
    summary: string;
    /**
     * Title describing the check's purpose
     * @type {string}
     * @memberof InvestigationCheck
     */
    title: string;
    /**
     * Total cost of AI interactions for this check
     * @type {number}
     * @memberof InvestigationCheck
     */
    total_cost_in_cents: number;
    /**
     * Unique identifier of the GCP trace
     * @type {string}
     * @memberof InvestigationCheck
     */
    trace_id: string;
}

export function InvestigationCheckFromJSON(json: any): InvestigationCheck {
    return InvestigationCheckFromJSONTyped(json, false);
}

export function InvestigationCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvestigationCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifacts': !exists(json, 'artifacts') ? undefined : ((json['artifacts'] as Array<any>).map(InvestigationArtifactFromJSON)),
        'check_type': json['check_type'],
        'completed_at': !exists(json, 'completed_at') ? undefined : (new Date(json['completed_at'])),
        'created_at': (new Date(json['created_at'])),
        'duration_seconds': json['duration_seconds'],
        'error_message': !exists(json, 'error_message') ? undefined : json['error_message'],
        'finding_relevance': json['finding_relevance'],
        'id': json['id'],
        'params': !exists(json, 'params') ? undefined : json['params'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'started_at': !exists(json, 'started_at') ? undefined : (new Date(json['started_at'])),
        'summary': json['summary'],
        'title': json['title'],
        'total_cost_in_cents': json['total_cost_in_cents'],
        'trace_id': json['trace_id'],
    };
}

export function InvestigationCheckToJSON(value?: InvestigationCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifacts': value.artifacts === undefined ? undefined : ((value.artifacts as Array<any>).map(InvestigationArtifactToJSON)),
        'check_type': value.check_type,
        'completed_at': value.completed_at === undefined ? undefined : (value.completed_at.toISOString()),
        'created_at': (value.created_at.toISOString()),
        'duration_seconds': value.duration_seconds,
        'error_message': value.error_message,
        'finding_relevance': value.finding_relevance,
        'id': value.id,
        'params': value.params,
        'result': value.result,
        'started_at': value.started_at === undefined ? undefined : (value.started_at.toISOString()),
        'summary': value.summary,
        'title': value.title,
        'total_cost_in_cents': value.total_cost_in_cents,
        'trace_id': value.trace_id,
    };
}

