import {
  EvaluationBacktest,
  EvaluationBacktestEntry,
  ResumeEvaluationBacktestRequestBody,
  useAiStaffServiceAiStaffResumeEvaluationBacktest,
  useAiStaffServiceAiStaffShowEvaluationBacktest,
  UseAiStaffServiceAiStaffShowEvaluationBacktestKeyFn,
} from "@incident-io/query-api";
import { Form } from "@incident-shared/forms";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Banner,
  BannerTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  ContentBox,
  IconEnum,
  ModalFooter,
  Spinner,
  ToastTheme,
  Tooltip,
} from "@incident-ui";
import { SingleLineCodeBlock } from "@incident-ui/CodeBlock/SingleLineCodeBlock";
import { InputType } from "@incident-ui/Input/Input";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { min, sum } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { WorkbenchSubPageWrapper } from "src/routes/WorkbenchRoute";

import { LabeledValue } from "../common/LabeledValue";
import { BacktestEntriesList } from "./BacktestEntriesList";

export const BacktestsShowPage = () => {
  const { backtestId } = useParams<{ backtestId: string }>();
  const [showResumeModal, setShowResumeModal] = useState(false);

  const {
    data,
    isLoading: isLoadingBacktest,
    isRefetching,
  } = useAiStaffServiceAiStaffShowEvaluationBacktest(
    {
      id: backtestId || "",
    },
    undefined,
    {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: true,
      // Poll continually while the investigation is active.
      refetchInterval: (query) =>
        query.state.data?.backtest?.state === "pending" ||
        query.state.data?.backtest?.state === "running"
          ? 3000
          : false,
    },
  );

  const queryClient = useQueryClient();

  const refetchBacktest = () => {
    queryClient.invalidateQueries({
      queryKey: UseAiStaffServiceAiStaffShowEvaluationBacktestKeyFn({
        id: backtestId || "",
      }),
    });
  };

  const backtest = data?.backtest;
  const entries = data?.entries || [];

  return (
    <>
      {backtest && showResumeModal && (
        <BacktestResumeModal
          entries={entries}
          backtest={backtest}
          onClose={() => setShowResumeModal(false)}
        />
      )}
      <WorkbenchSubPageWrapper
        title="View backtest"
        loading={isLoadingBacktest}
        backHref="/workbench/backtests"
        accessory={
          <div className={"flex flex-center gap-2"}>
            <Button
              theme={ButtonTheme.Secondary}
              analyticsTrackingId={null}
              onClick={refetchBacktest}
              icon={IconEnum.Refresh}
              loading={isRefetching}
            >
              Refresh Data
            </Button>
            <Button
              analyticsTrackingId="backtest-resume"
              theme={ButtonTheme.Primary}
              onClick={() => setShowResumeModal(true)}
            >
              Resume
            </Button>
          </div>
        }
        banner={
          backtest ? (
            <BacktestRunningBanner backtest={backtest} entries={entries} />
          ) : null
        }
      >
        {backtest && (
          <BacktestsShowPageContents backtest={backtest} entries={entries} />
        )}
      </WorkbenchSubPageWrapper>
    </>
  );
};

const BacktestsShowPageContents = ({
  backtest,
  entries,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
}) => {
  const formatValue = (value) => {
    if (!value) return "–";
    if (value instanceof Date) return value.toLocaleString();

    return value.toString();
  };

  const totalCost = sum(entries.map((e) => e.investigation_cost_cents || 0));

  return (
    <div className="flex flex-col gap-6">
      <ContentBox className="p-4 flex gap-4">
        <div className="flex flex-col gap-2">
          <LabeledValue
            label="ID"
            value={<SingleLineCodeBlock code={backtest.id} />}
          />
          <LabeledValue
            label="Dataset ID"
            value={
              backtest.dataset_id ? (
                <SingleLineCodeBlock code={backtest.dataset_id} />
              ) : (
                "–"
              )
            }
          />
          <LabeledValue
            label="State"
            value={<BacktestShowState backtest={backtest} />}
          />
          <LabeledValue
            label="Total cost"
            value={`$${(totalCost / 100.0).toFixed(2)}`}
          />
          <LabeledValue
            label="Investigation plan type"
            value={formatValue(backtest.investigation_plan_type)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <LabeledValue label="Credit" value={formatValue(backtest.credit)} />
          <LabeledValue label="Notes" value={formatValue(backtest.notes)} />
          <LabeledValue
            label="Created"
            value={formatValue(backtest.created_at)}
          />
          <LabeledValue
            label="Updated"
            value={formatValue(backtest.updated_at)}
          />
          <LabeledValue
            label="Completed"
            value={formatValue(backtest.completed_at)}
          />
          <LabeledValue
            label="Check status"
            value={<BacktestShowFailedChecks entries={entries} />}
          />
        </div>
      </ContentBox>
      <BacktestEntriesList backtest={backtest} entries={entries} />
    </div>
  );
};

export const BacktestShowState = ({
  backtest,
}: {
  backtest: EvaluationBacktest;
}) => {
  let badgeTheme = BadgeTheme.Info;

  switch (backtest.state) {
    case "pending":
      badgeTheme = BadgeTheme.Tertiary;
      break;
    case "complete":
      badgeTheme = BadgeTheme.Success;
      break;
    case "paused":
      badgeTheme = BadgeTheme.Warning;
      break;
    case "error":
      badgeTheme = BadgeTheme.Error;
      break;
  }

  return (
    <Badge theme={badgeTheme} size={BadgeSize.ExtraSmall}>
      {backtest.state}
    </Badge>
  );
};

export const BacktestShowFailedChecks = ({
  entries,
}: {
  entries: EvaluationBacktestEntry[];
}) => {
  let numFailingChecks = 0;
  entries.forEach((entry) => {
    if (
      entry.investigation_failing_checks &&
      entry.investigation_failing_checks.length > 0
    ) {
      numFailingChecks += entry.investigation_failing_checks.length;
    }
  });

  if (numFailingChecks === 0) {
    return <Badge theme={BadgeTheme.Success}>All checks successful</Badge>;
  }

  const checkFailures = entries.reduce((acc, entry) => {
    if (
      entry.investigation_failing_checks &&
      entry.investigation_failing_checks.length > 0
    ) {
      entry.investigation_failing_checks.forEach((check) => {
        acc[check] = (acc[check] || 0) + 1;
      });
    }
    return acc;
  }, {});

  const tooltipContent = Object.entries(checkFailures)
    .map(([check, count]) => `${check}: ${count}`)
    .join("\n");

  return (
    <Tooltip content={tooltipContent}>
      <Badge theme={BadgeTheme.Warning} icon={IconEnum.Warning}>
        {numFailingChecks} check
        {numFailingChecks === 1 ? " failed" : "s failed"}
      </Badge>
    </Tooltip>
  );
};

const BacktestResumeModal = ({
  backtest,
  entries,
  onClose,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const invalidateQueries = () => {
    if (!backtest.id) return;

    queryClient.invalidateQueries({
      queryKey: UseAiStaffServiceAiStaffShowEvaluationBacktestKeyFn({
        id: backtest.id,
      }),
    });
  };
  const toast = useToast();

  const outstanding = entries.filter(
    (e) => e.investigation_scorecard == null,
  ).length;

  const formMethods = useForm<ResumeEvaluationBacktestRequestBody>({
    defaultValues: {
      credit: min([10, outstanding]),
    },
  });

  const { mutate: resumeBacktest, isPending: saving } =
    useAiStaffServiceAiStaffResumeEvaluationBacktest({
      onSettled: invalidateQueries,
      onError: () => {
        toast({
          title: "Failed to resume backtest",
          theme: ToastTheme.Error,
        });
      },
    });

  const handleResume = (requestBody: ResumeEvaluationBacktestRequestBody) => {
    resumeBacktest({
      id: backtest.id,
      requestBody,
    });
  };

  return (
    <Form.Modal
      onClose={onClose}
      title="Resume backtest"
      analyticsTrackingId="resume-backtest"
      formMethods={formMethods}
      onSubmit={handleResume}
      footer={
        <ModalFooter
          confirmButtonText="Resume"
          saving={saving}
          disabled={!outstanding}
          confirmButtonType="submit"
          onClose={onClose}
        />
      }
    >
      <Callout theme={CalloutTheme.Info}>
        There are <span className="text-sm-bold">{outstanding}</span> out of{" "}
        <span className="text-sm-bold">{entries.length}</span> entries left to
        process.
      </Callout>
      {!!outstanding && (
        <InputV2
          type={InputType.Number}
          required
          autoFocus
          name="credit"
          formMethods={formMethods}
          label="Credit"
          helptext={
            "How many entries should be processed before pausing and asking a human to review."
          }
        />
      )}
    </Form.Modal>
  );
};

const BacktestRunningBanner = ({
  backtest,
  entries,
}: {
  backtest: EvaluationBacktest;
  entries: EvaluationBacktestEntry[];
}) => {
  const { total, processed } = getBacktestEntryCounts(entries);
  if (backtest.state === "running") {
    return (
      <Banner
        theme={BannerTheme.Info}
        className="bg-blue-surface text-blue-800"
        iconNode={<Spinner className="text-yellow-content" />}
      >
        This backtest is currently running.{" "}
        <span className="font-semibold">{processed}</span> of{" "}
        <span className="font-semibold">{total}</span> entries have been
        processed so far
      </Banner>
    );
  }

  if (backtest.state === "paused") {
    return (
      <Banner theme={BannerTheme.Warning}>
        This backtest is currently paused.{" "}
        <span className="font-semibold">{processed}</span> of{" "}
        <span className="font-semibold">{total}</span> entries have been
        processed so far
      </Banner>
    );
  }

  return null;
};

export const getBacktestEntryCounts = (entries: EvaluationBacktestEntry[]) => {
  return {
    total: entries.length,
    processed: entries.filter((e) => e.investigation_scorecard != null).length,
  };
};
