import {
  Incident,
  IncidentStatus,
  IncidentStatusCategoryEnum,
} from "@incident-io/api";
import {
  Avatar,
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  ContentBox,
  Icon,
  IconEnum,
  IconSize,
  IncidentStatusBadge,
  LoadingWrapper,
  OrgAwareLink,
  SeverityBadge,
  StackedList,
  StackedListItem,
} from "@incident-ui";
import _, { take } from "lodash";
import React from "react";
import { useSettings } from "src/hooks/useSettings";
import { incidentTypesEnabled } from "src/utils/incident-types";
import { tcx } from "src/utils/tailwind-classes";

import { CopyDebugID } from "../../../utils/StaffOverlayProvider";
import {
  getIncidentLead,
  IconWithLabel,
  IncidentDuration,
} from "../incident-grid/card/IncidentCard";
import styles from "./IncidentKanban.module.scss";

type GroupedIncidents = {
  status: IncidentStatus;
  incidents: Incident[];
}[];

export const IncidentsKanbanBoard = ({
  incidents,
  statuses,
  isLoading,
  maxPerColumn,
  fullHeight = maxPerColumn === undefined,
}: {
  incidents: Incident[];
  statuses: IncidentStatus[];
  isLoading: boolean;
  maxPerColumn?: number;
  fullHeight?: boolean;
}): React.ReactElement => {
  const groupedIncidents: GroupedIncidents = [];
  const emptyStatuses: IncidentStatus[] = [];
  statuses.forEach((status) => {
    if (status.category === IncidentStatusCategoryEnum.Paused) {
      // We never show this as its own column anyway
      return;
    }

    const incidentsForStatus = incidents.filter((inc) => {
      if (inc.incident_status.category === IncidentStatusCategoryEnum.Paused) {
        return inc.paused_in_status?.id === status.id;
      }

      return inc.incident_status.id === status.id;
    });

    incidentsForStatus.length === 0
      ? emptyStatuses.push(status)
      : groupedIncidents.push({
          status,
          incidents: incidentsForStatus,
        });
  });

  return (
    <LoadingWrapper
      loading={isLoading}
      className={tcx(
        "flex gap-3 overflow-x-auto px-4 md:px-8 pb-3 h-full",
        fullHeight && "grow",
      )}
    >
      {groupedIncidents.map(({ status, incidents }) => (
        <IncidentsKanbanColumn
          key={status.id}
          status={status}
          incidents={incidents}
          maxPerColumn={maxPerColumn}
          fullHeight={fullHeight}
        />
      ))}
      {emptyStatuses.length > 0 && (
        <EmptyKanbanColumns fullHeight={fullHeight} statuses={emptyStatuses} />
      )}
    </LoadingWrapper>
  );
};

const IncidentsKanbanColumn = ({
  status,
  incidents,
  maxPerColumn,
  fullHeight,
}: {
  status: IncidentStatus;
  incidents: Incident[];
  maxPerColumn?: number;
  fullHeight: boolean;
}): React.ReactElement => {
  const sortedIncidents = _.orderBy(
    incidents,
    [(inc) => inc.severity?.rank, (inc) => inc.updated_at],
    ["desc", "desc"],
  );

  const [pausedIncidents, unpausedIncidents] = _.partition(
    sortedIncidents,
    (inc) => inc.incident_status.category === IncidentStatusCategoryEnum.Paused,
  );

  const {
    unpausedIncidentsToDisplay,
    pausedIncidentsToDisplay,
    overflowCount,
  } = capIncidentsInColumn({
    pausedIncidents,
    unpausedIncidents,
    maxPerColumn,
  });

  return (
    <KanbanColumn
      status={status}
      count={unpausedIncidents.length}
      fullHeight={fullHeight}
    >
      {unpausedIncidentsToDisplay.map((incident) => (
        <IncidentCompactCard key={incident.id} incident={incident} />
      ))}
      {pausedIncidentsToDisplay.length > 0 && (
        <>
          <div className="flex gap-2 pt-2">
            <IncidentStatusBadge
              status={{
                category: IncidentStatusCategoryEnum.Paused,
                name: "Paused",
              }}
              naked
              iconOnly
              size={BadgeSize.Small}
            />
            <span className="text-base-bold text-content-primary">Paused</span>
            <Badge theme={BadgeTheme.Secondary} className="px-2">
              {`${pausedIncidents.length}`}
            </Badge>
          </div>
          {pausedIncidentsToDisplay.map((incident) => (
            <IncidentCompactCard key={incident.id} incident={incident} />
          ))}
        </>
      )}
      {overflowCount > 0 && (
        <div className="flex gap-2 md:gap-4 text-sm items-center justify-center flex-wrap">
          <Button
            href={`/incidents?display_layout=kanban&status_category[one_of]=active&status_category[one_of]=triage&status_category[one_of]=paused`}
            openInNewTab={false}
            theme={ButtonTheme.Naked}
            className="text-content-tertiary transition"
            analyticsTrackingId="internal-homepage-view-all-incidents"
            title="View all incidents"
          >
            View {overflowCount} more
            <Icon size={IconSize.Small} id={IconEnum.ExternalLink} />
          </Button>
        </div>
      )}
    </KanbanColumn>
  );
};

const capIncidentsInColumn = ({
  maxPerColumn,
  pausedIncidents,
  unpausedIncidents,
}: {
  maxPerColumn?: number;
  pausedIncidents: Incident[];
  unpausedIncidents: Incident[];
}) => {
  if (maxPerColumn === undefined) {
    return {
      unpausedIncidentsToDisplay: unpausedIncidents,
      pausedIncidentsToDisplay: pausedIncidents,
      overflowCount: 0,
    };
  }

  // If there are paused incidents, we leave space to show at least one!
  const unpausedCapacity =
    pausedIncidents.length === 0 ? maxPerColumn : maxPerColumn - 1;

  const unpausedIncidentsToDisplay = maxPerColumn
    ? take(unpausedIncidents, unpausedCapacity)
    : unpausedIncidents;

  const pausedIncidentsToDisplay = maxPerColumn
    ? take(pausedIncidents, maxPerColumn - unpausedIncidentsToDisplay.length)
    : pausedIncidents;

  const overflowCount =
    unpausedIncidents.length -
    unpausedIncidentsToDisplay.length +
    (pausedIncidents.length - pausedIncidentsToDisplay.length);

  return {
    unpausedIncidentsToDisplay,
    pausedIncidentsToDisplay,
    overflowCount,
  };
};

const EmptyKanbanColumns = ({
  fullHeight,
  statuses,
}: {
  fullHeight: boolean;
  statuses: IncidentStatus[];
}): React.ReactElement => {
  return (
    <KanbanColumn status="empty" count={null} fullHeight={fullHeight}>
      <StackedList>
        {statuses.map((status) => (
          <StackedListItem
            key={status.id}
            title={
              <div className="flex gap-2">
                <IncidentStatusBadge
                  status={status}
                  naked
                  iconOnly
                  size={BadgeSize.Small}
                />
                <span className="text-base-bold text-content-primary">
                  {status.name}
                </span>
              </div>
            }
          />
        ))}
      </StackedList>
    </KanbanColumn>
  );
};

const KanbanColumn = ({
  status,
  count,
  fullHeight,
  children,
}: {
  status: IncidentStatus | "empty";
  count: number | null;
  fullHeight: boolean;
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <div className="flex-shrink-0 w-[400px] bg-surface-secondary rounded-xl overflow-y-none">
      <div className="flex gap-2 pt-4 p-3">
        <IncidentStatusBadge
          status={status}
          naked
          iconOnly
          size={BadgeSize.Small}
        />
        <span className="text-base-bold text-content-primary">
          {status === "empty" ? "Empty columns" : status.name}
        </span>
        {count != null ? (
          <Badge theme={BadgeTheme.Secondary} className="px-2">
            {`${count}`}
          </Badge>
        ) : null}
      </div>
      <div
        className={tcx(
          "flex flex-col gap-3 px-3 pb-3",
          fullHeight ? "overflow-y-auto h-[calc(100%-56px)]" : null,
        )}
      >
        {children}
      </div>
    </div>
  );
};

const IncidentCompactCard = ({
  incident,
}: {
  incident: Incident;
}): React.ReactElement => {
  const incidentLead = getIncidentLead(incident);
  const { settings } = useSettings();
  const incidentTypesAreEnabled = incidentTypesEnabled(settings);

  const isPausedIncident =
    incident.incident_status.category === IncidentStatusCategoryEnum.Paused;

  return (
    <OrgAwareLink
      to={`/incidents/${incident.external_id}`}
      className={"w-full"}
    >
      <ContentBox
        className={tcx("over:shadow-md hover:border-stroke-hover", {
          [styles.pausedCompactCard]: isPausedIncident,
        })}
      >
        <div
          className={tcx("flex flex-col p-4 gap-3 rounded-2", {
            "bg-gradient-to-b from-transparent to-surface-primary":
              isPausedIncident,
          })}
        >
          <div className="flex justify-between truncate items-center">
            <div className="text-xs-med text-content-tertiary">
              {incident.reference}
            </div>
            <IncidentDuration
              incident={incident}
              naked
              className="!text-content-tertiary"
            />
          </div>
          <div className="overflow-hidden">
            <span className="text-sm-med line-clamp-2">{incident.name}</span>
          </div>
          <CopyDebugID id={incident.id} />
          {/* Badges */}
          <div className="flex justify-between items-end gap-2">
            <div className="flex flex-wrap gap-1 items-center min-w-0">
              <SeverityBadge severity={incident.severity} />
              <IncidentStatusBadge
                key={incident.incident_status.name}
                status={incident.incident_status}
              />
              {incidentTypesAreEnabled &&
                (incident.incident_type ? (
                  <IconWithLabel
                    label={incident.incident_type.name}
                    icon={IconEnum.IncidentType}
                  />
                ) : null)}
            </div>
            <Avatar
              size={IconSize.Large}
              url={incidentLead?.assignee?.avatar_url}
              name={incidentLead?.assignee?.name}
              className="border border-transparent"
            />
          </div>
        </div>
      </ContentBox>
    </OrgAwareLink>
  );
};
