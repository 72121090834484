import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { numericGateLimitReached } from "@incident-shared/gates/gates";
import {
  getBrokenConfigNames,
  getBrokenIntegrations,
  getBrokenIntegrationsLookup,
  IntegrationConfigFor,
} from "@incident-shared/integrations";
import { PageWidth, PageWrapper } from "@incident-shared/layout/PageWrapper";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  GenericErrorMessage,
  IconEnum,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  Tooltip,
  Txt,
} from "@incident-ui";
import { ToastTheme } from "@incident-ui/Toast/Toast";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { captureException } from "@sentry/react";
import { differenceInSeconds } from "date-fns";
import { AnimatePresence } from "framer-motion";
import { compact } from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Route, Routes, useOutlet, useParams } from "react-router";
import { CustomFieldCreateEditDrawer } from "src/components/settings/custom-fields/create-edit/CustomFieldCreateEditDrawer";
import { DeletionConfirmationModal } from "src/components/settings/DeletionConfirmationModal";
import {
  CatalogListEntriesResponseBody,
  CatalogType,
  CatalogTypeCategoriesEnum,
  CatalogTypeModeEnum,
  CatalogTypeRequiredIntegrationsEnum as RequiredIntegrationEnum,
  CustomFieldType,
  DependentResource,
  IntegrationSettingsProviderEnum,
  RankUpdate,
  ScopeNameEnum,
  useClient,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useIntegrations } from "src/hooks/useIntegrations";
import { useAPI, useAPIInfinite, useAPIMutation } from "src/utils/swr";

import { CatalogNotFoundPage } from "../common/NotFoundPage";
import { SourceRepoBadge } from "../common/SourceRepoBadge";
import { CatalogEntryEditDrawer } from "../entry-view/CatalogEntryEditDrawer";
import { CatalogEntryViewDrawer } from "../entry-view/CatalogEntryViewDrawer";
import { SetUpTeamsBanner } from "../type-list/CatalogTypeSetupBanner";
import { useHasTypeOfCategory } from "../wizards/useHasTypeOfCategory";
import { CatalogEntryBulkCreateDrawer } from "./CatalogEntryBulkCreateDrawer";
import { CatalogEntryCreateDrawer } from "./CatalogEntryCreateDrawer";
import { CatalogEntryList } from "./CatalogEntryList";
import { CatalogEntryListOptionsBar } from "./CatalogEntryListOptionsBar";
import {
  catalogHeaderButtonProps,
  CatalogListHeaderAccessory,
} from "./CatalogEntryListPageHeaderAccessories";
import { CatalogEntryRankingList } from "./CatalogEntryRankingList";
import { CatalogTypeDependentResourcesBar } from "./CatalogTypeDependentResourcesBar";
import { CATALOG_ENTRY_BULK_EDIT_PAGE_SIZE } from "./constants";

export const CatalogEntryListPageRoute = () => {
  const { id: idOrTypeName } = useParams<{ id: string }>() as { id: string };

  const {
    data: typeData,
    isLoading: typeLoading,
    error: typeError,
  } = useAPI("catalogShowType", { id: idOrTypeName });

  if (typeError?.status === 404) {
    return (
      <CatalogNotFoundPage
        notFoundNoun="catalog type"
        returnPath="/catalog"
        returnPageName="catalog"
      />
    );
  }

  if (typeError) {
    return <GenericErrorMessage error={typeError} />;
  }

  if (typeLoading || !typeData) {
    return <Loader />;
  }

  return (
    <CatalogEntryListPageRouteInner
      catalogType={typeData.catalog_type}
      dependentResources={typeData.dependent_resources}
      idOrTypeName={idOrTypeName}
    />
  );
};

const CatalogEntryListPageRouteInner = ({
  catalogType,
  dependentResources,
  idOrTypeName,
}: {
  catalogType: CatalogType;
  dependentResources: DependentResource[];
  idOrTypeName: string;
}) => {
  const [search, setSearch] = useState("");
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const {
    responses: entriesPages,
    isLoading: entriesLoading,
    refetch: refetchEntries,
    isFullyLoaded: allEntriesLoaded,
    loadMore: loadMoreEntries,
    error: listEntriesError,
  } = useAPIInfinite(
    "catalogListEntries",
    {
      catalogTypeId: catalogType.id,
      includeDerivedAttributes: true,
      includeReferences: true,
      pageSize: 25,
      search: search,
      revalidateFirstPage: true,
      revalidateAll: true,
    },
    {
      revalidateOnMount: true,
    },
  );

  if (listEntriesError) {
    return <GenericErrorMessage error={listEntriesError} />;
  }

  const onCloseDrawer = (entryId?: string) =>
    entryId
      ? navigate(`/catalog/${idOrTypeName}/${entryId}`)
      : navigate(`/catalog/${idOrTypeName}`);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <CatalogEntryListRoute
              entriesPages={entriesPages}
              entriesLoading={entriesLoading}
              refetchEntries={refetchEntries}
              allEntriesLoaded={allEntriesLoaded}
              loadMoreEntries={loadMoreEntries}
              setSearch={setSearch}
              search={search}
              catalogType={catalogType}
              dependentResources={dependentResources}
            />
          }
        >
          <Route
            path="create"
            element={
              <CatalogEntryCreateDrawer
                catalogType={catalogType}
                onClose={onCloseDrawer}
                refetchEntries={refetchEntries}
              />
            }
          />
          <Route
            path="bulk-create"
            element={
              <CatalogEntryBulkCreateDrawer
                catalogType={catalogType}
                onClose={onCloseDrawer}
                refetchEntries={refetchEntries}
              />
            }
          />
          <Route
            path="custom-field-create"
            element={
              <CustomFieldCreateEditDrawer
                onClose={onCloseDrawer}
                onSuccess={() => {
                  navigate(`/settings/custom-fields`);
                  showToast({
                    theme: ToastTheme.Success,
                    title: "Custom field created",
                  });
                }}
                template={{
                  name: catalogType.name,
                  description: `The ${pluralize(
                    catalogType.name,
                  )} related to this incident`,
                  field_type: CustomFieldType.MultiSelect,
                  catalog_type_id: catalogType.id,
                }}
              />
            }
          />
          <Route
            path=":entry_id/*"
            element={
              <CatalogEntryViewDrawerRoute
                onClose={onCloseDrawer}
                refetchEntries={refetchEntries}
              />
            }
          >
            <Route
              path="edit"
              element={
                <CatalogEntryEditDrawerWrapper
                  refetchEntries={refetchEntries}
                  onClose={onCloseDrawer}
                />
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

const CatalogEntryEditDrawerWrapper = ({
  refetchEntries,
  onClose,
}: {
  refetchEntries: () => void;
  onClose: () => void;
}) => {
  const { entry_id: entryIdOrExternalID } = useParams<{
    entry_id: string;
  }>();

  if (!entryIdOrExternalID) {
    onClose();
  }

  return (
    <CatalogEntryEditDrawer
      refetchEntries={refetchEntries}
      onClose={onClose}
      entryReference={entryIdOrExternalID || ""}
    />
  );
};

const CatalogEntryListRoute = (props: CatalogEntryListPageProps) => {
  const drawer = useOutlet();

  return (
    <>
      <AnimatePresence>{drawer}</AnimatePresence>
      <CatalogEntryListPage {...props} />
    </>
  );
};

const CatalogEntryViewDrawerRoute = ({
  refetchEntries,
  onClose,
}: {
  refetchEntries: () => void;
  onClose: () => void;
}) => {
  const stackedDrawer = useOutlet();

  // This component is rendered by a permissive route
  // so we need to check if we're actually on a valid entry_id
  const { entry_id } = useParams() as {
    entry_id: string;
  };
  if (!entry_id || entry_id === "bulk-edit") {
    return null;
  }

  return (
    <>
      <AnimatePresence>{stackedDrawer}</AnimatePresence>
      <CatalogEntryViewDrawer
        refetchEntries={refetchEntries}
        onClose={onClose}
      />
    </>
  );
};

type CatalogEntryListPageProps = {
  entriesPages: CatalogListEntriesResponseBody[];
  entriesLoading: boolean;
  refetchEntries: () => void;
  allEntriesLoaded: boolean;
  loadMoreEntries: () => void;
  setSearch: (search: string) => void;
  search: string;
  catalogType: CatalogType;
  dependentResources: DependentResource[];
};

const CatalogEntryListPage = ({
  entriesPages,
  entriesLoading,
  refetchEntries,
  allEntriesLoaded,
  loadMoreEntries,
  setSearch,
  search,
  catalogType,
  dependentResources,
}: CatalogEntryListPageProps) => {
  const { id: idOrTypeName } = useParams<{ id: string }>() as { id: string };
  const { identity, hasScope } = useIdentity();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const showToast = useToast();
  const apiClient = useClient();
  const [isRanking, setIsRanking] = useState(false);
  const [numberOfRanksUpdated, setNumberOfRanksUpdated] = useState(0);
  const [rankedEntriesRequestData, setRankedEntriesRequestData] = useState<
    RankUpdate[]
  >([]);

  const navigate = useOrgAwareNavigate();

  const numEntries: number | undefined = entriesPages
    ? entriesPages[0]?.pagination_meta.total_record_count
    : undefined;

  const hasOverMaxEntriesForBulkEdit = numEntries
    ? numEntries > CATALOG_ENTRY_BULK_EDIT_PAGE_SIZE
    : false;

  const {
    allMatchingTypes: allTeamTypes,
    typesError,
    typesLoading,
  } = useHasTypeOfCategory(CatalogTypeCategoriesEnum.Team);

  const { integrations, integrationsError } = useIntegrations();

  const resyncType = async () => {
    await apiClient.catalogSyncType({ id: catalogType.id });
    showToast({
      theme: ToastTheme.Success,
      title:
        "We've started syncing this type, which might take a bit of time. Refresh the page to see updated entries.",
    });
  };

  const setNewRankings = (updatedRankings: RankUpdate[], numUpdated) => {
    setNumberOfRanksUpdated(numUpdated);
    setRankedEntriesRequestData(updatedRankings);
  };

  const onRankingSave = async () => {
    try {
      await apiClient.catalogUpdateEntriesRanks({
        updateEntriesRanksRequestBody: {
          catalog_type_id: catalogType.id,
          rank_updates: rankedEntriesRequestData,
        },
      });
      toggleRankingView();
      refetchEntries();
    } catch (error) {
      showToast({
        theme: ToastTheme.Error,
        title: `Sorry, we had an unexpected problem re-ranking your catalog entries.`,
      });
      captureException(error);
      console.error(error);
    }
  };

  const error = typesError || integrationsError;
  if (error) {
    return <GenericErrorMessage error={error} />;
  }

  if (!integrations || typesLoading) {
    return <Loader />;
  }

  const brokenIntegrations = getBrokenIntegrations(
    compact(catalogType.required_integrations),
    getBrokenIntegrationsLookup(integrations),
  );

  const brokenIntegrationConfigs = brokenIntegrations.map((x) =>
    IntegrationConfigFor(x as unknown as IntegrationSettingsProviderEnum),
  );

  const hasBrokenConfig =
    brokenIntegrationConfigs && brokenIntegrationConfigs.length > 0;
  // Some "integrations" are actually incident.io features, for which we don't
  // want to send someone to the integrations page.
  const isStatusPageType = catalogType?.required_integrations?.includes(
    RequiredIntegrationEnum.IncidentIoStatusPages,
  );

  // You can only create entries for manual catalog types
  const canCreateEntries = catalogType.mode === CatalogTypeModeEnum.Manual;

  // You can delete a catalog type if:
  // 1. It's a manual type
  // 2. It has a dynamic resource parameter (i.e. it's a parameterised resource)
  // 3. The integration has now been broken, and you don't intend to reinstall
  const canBeDeleted =
    catalogType.mode === CatalogTypeModeEnum.Manual ||
    catalogType.dynamic_resource_parameter != null ||
    hasBrokenConfig;

  const canBeReSynced = catalogType.mode === CatalogTypeModeEnum.External;

  const isManagedExternally = catalogType.source_repo_url != null;

  const createdInLast5Mins =
    differenceInSeconds(catalogType.created_at, new Date()) < 5 * 60;

  const probablyStillSyncing =
    !!catalogType.required_integrations?.[0] &&
    !catalogType.last_synced_at &&
    createdInLast5Mins;

  // TODO RESP-2814 Consider refactoring these buttons (move to CatalogEntryListPageHeaderAccessories.tsx, maybe simplify and stop using button props list?)
  // can also move common strings into constants file
  let buttons: catalogHeaderButtonProps[] = compact([
    canBeReSynced
      ? {
          onClick: resyncType,
          analyticsTrackingId: "catalog-sync-type",
          buttonText: "Sync",
          action: "sync",
          iconName: IconEnum.Refresh1,
          theme: ButtonTheme.Secondary,
          alwaysShownTooltipContent: (
            <LastSyncedAt
              lastSyncedAt={catalogType.last_synced_at}
              frequency={catalogType.sync_frequency}
            />
          ),
          disabledTooltipContent: (
            <LastSyncedAt
              lastSyncedAt={catalogType.last_synced_at}
              frequency={catalogType.sync_frequency}
            />
          ),
          disabled: probablyStillSyncing || isRanking,
        }
      : null,
    canBeDeleted
      ? {
          onClick: () => setShowConfirmDeleteModal(true),
          analyticsTrackingId: "catalog-delete-type",
          buttonText: "Delete type",
          action: "delete",
          theme: ButtonTheme.DestroySecondary,
          requiredScope: ScopeNameEnum.CatalogTypesDestroy,
          disabled: isRanking || isManagedExternally,
        }
      : null,
    {
      onClick: () => navigate(`/catalog/${idOrTypeName}/edit`),
      analyticsTrackingId: "catalog-edit-type",
      buttonText: "Edit type",
      action: "edit",
      theme: ButtonTheme.Secondary,
      requiredScope: ScopeNameEnum.CatalogTypesEdit,
      disabled: isRanking || isManagedExternally,
    },
    canCreateEntries
      ? {
          onClick: () => navigate(`/catalog/${idOrTypeName}/create`),
          analyticsTrackingId: "catalog-create-entry",
          buttonText: "Create entry",
          action: "create",
          theme: ButtonTheme.Primary,
          requiredScope: ScopeNameEnum.CatalogEntriesCreate,
          disabled: isRanking || isManagedExternally,
        }
      : null,
  ]);

  if (catalogType.native_resource_path) {
    // If this is a native resource, we want to push you to the native resource page as your primary
    // 'change something' and then have the 'edit catalog type' as a kind of secondary action.
    buttons = [
      {
        onClick: () => navigate(`/catalog/${idOrTypeName}/edit`),
        analyticsTrackingId: "catalog-edit-type",
        icon: IconEnum.Edit,
        action: "edit",
        // Make this more prominent (with button text) for our user type, as we expect folks to edit
        // that type much more than other native resources.
        buttonText:
          catalogType.registry_type === "User" ? "Edit type" : undefined,
        theme: ButtonTheme.Secondary,
        requiredScope: ScopeNameEnum.CatalogTypesEdit,
        disabled: isRanking || isManagedExternally,
      },
      {
        href: catalogType.native_resource_path as string,
        buttonText: `${pluralize(catalogType.name)}`,
        openInNewTab: true,
        analyticsTrackingId: "catalog-view-native-resources",
        icon: catalogType.icon as unknown as IconEnum,
        action: "list-native",
        theme: ButtonTheme.Secondary,
      },
    ];
  }

  const shouldCollapse = buttons.length > 1;

  const toggleRankingView = () => {
    setNumberOfRanksUpdated(0);
    setIsRanking(!isRanking);
  };

  const isFromCatalogImporter =
    "incident.io/catalog-importer/last-sync-at" in catalogType.annotations;

  const CatalogTypeBadge = () => {
    if (isFromCatalogImporter) {
      return (
        <SourceRepoBadge
          sourceRepoURL={catalogType.source_repo_url}
          lastSyncedAt={catalogType.last_synced_at}
          showTooltip
        />
      );
    }

    if (hasBrokenConfig && !isStatusPageType) {
      return (
        <Tooltip
          content={
            <>
              We can&rsquo;t sync this type due to a problem with your{" "}
              {getBrokenConfigNames(brokenIntegrationConfigs)}{" "}
              {pluralize("integration", brokenIntegrationConfigs.length)}.{" "}
            </>
          }
          delayDuration={0}
          side="right"
        >
          <span className="w-fit cursor-not-allowed flex items-center">
            <Badge
              icon={IconEnum.Warning}
              theme={BadgeTheme.Error}
              size={BadgeSize.ExtraSmall}
            >
              Connection broken
            </Badge>
          </span>
        </Tooltip>
      );
    }
    return null;
  };

  let enableTeamWizardCTA = false;

  if (identity) {
    const isTeamType = allTeamTypes.some((t) => t.id === catalogType.id);
    const hasCatalogCreateScope = hasScope(ScopeNameEnum.CatalogTypesCreate);
    // Pass zero as the number of catalog types as we aren't creating anything new.
    const upgradeRequired = numericGateLimitReached(
      identity.feature_gates.custom_catalog_types_count,
      0,
    );
    const isExternallyManaged = !!catalogType.source_repo_url;
    enableTeamWizardCTA =
      isTeamType &&
      hasCatalogCreateScope &&
      !upgradeRequired &&
      !isExternallyManaged;
  }

  return (
    <>
      <PageWrapper
        icon={catalogType.icon}
        color={catalogType.color as unknown as ColorPaletteEnum}
        title={catalogType.name}
        titleAccessory={
          <div className="flex items-center gap-2">
            {catalogType.description ? (
              <Tooltip content={catalogType.description} />
            ) : undefined}
            <CatalogTypeBadge />
          </div>
        }
        width={PageWidth.Full}
        crumbs={[{ title: "Catalog", to: `/catalog` }]}
        backHref="/catalog"
        overflowX={false}
        overflowY={false}
        accessory={
          <CatalogListHeaderAccessory
            shouldCollapse={shouldCollapse}
            buttons={buttons}
            isManagedExternally={isManagedExternally}
            catalogType={catalogType}
          />
        }
      >
        <div className="flex-1 space-y-5 overflow-hidden min-h-0 flex flex-col">
          {enableTeamWizardCTA && (
            <SetUpTeamsBanner catalogType={catalogType} />
          )}
          <CatalogTypeDependentResourcesBar
            dependentResources={dependentResources}
          />
          <CatalogEntryListOptionsBar
            isRankedType={catalogType.ranked}
            isManagedExternally={isManagedExternally}
            hasPermissions={hasScope(ScopeNameEnum.CatalogEntriesEdit)}
            hasOverMaxEntriesForBulkEdit={hasOverMaxEntriesForBulkEdit}
            toggleRankingView={toggleRankingView}
            setSearch={setSearch}
            search={search}
            isRanking={isRanking}
            numberOfRanksUpdated={numberOfRanksUpdated}
            onRankingSave={onRankingSave}
            numEntries={numEntries}
          />
          {isRanking ? (
            <CatalogEntryRankingList
              catalogType={catalogType}
              setNewRankings={setNewRankings}
            />
          ) : (
            <CatalogEntryList
              hasOverMaxEntriesForBulkEdit={hasOverMaxEntriesForBulkEdit}
              catalogType={catalogType}
              probablyStillSyncing={probablyStillSyncing}
              catalogEntries={entriesPages}
              entriesLoading={entriesLoading}
              refetchEntries={refetchEntries}
              allEntriesLoaded={allEntriesLoaded}
              loadMoreEntries={loadMoreEntries}
              editDrawerBackHref={`/catalog/${idOrTypeName}`}
              canClickEntries
            />
          )}
        </div>
        {showConfirmDeleteModal && (
          <ConfirmDeleteModal
            onCancel={() => setShowConfirmDeleteModal(false)}
            catalogType={catalogType}
            dependentResources={dependentResources}
          />
        )}
      </PageWrapper>
    </>
  );
};

const ConfirmDeleteModal = ({
  onCancel,
  catalogType,
  dependentResources,
}: {
  onCancel: () => void;
  catalogType: CatalogType;
  dependentResources: DependentResource[];
}) => {
  const formMethods = useForm({ defaultValues: { confirm: "" } });
  const confirmValue = formMethods.watch("confirm");
  const navigate = useOrgAwareNavigate();
  const showToast = useToast();

  const { trigger, isMutating, genericError } = useAPIMutation(
    "catalogListTypes",
    {},
    async (client, payload) => {
      await client.catalogDestroyType({ id: payload.id });
    },
    {
      onSuccess: () => {
        navigate("/catalog");
        showToast({
          theme: ToastTheme.Success,
          title: `${catalogType.name} deleted.`,
        });
      },
    },
  );

  const onDelete = () => {
    trigger({ id: catalogType.id });
  };

  if (dependentResources.length > 0) {
    return (
      <DeletionConfirmationModal
        resourceTitle={catalogType.name}
        onDelete={onDelete}
        isDeleting={false}
        isOpen={true}
        onClose={onCancel}
        title="This catalog type is in use elsewhere"
        analyticsTrackingId="delete-catalog-type"
        dependentResources={dependentResources}
      />
    );
  }

  // We require the user to type this to confirm deletion
  const requiredConfirmValue = catalogType.name;

  return (
    <Modal
      analyticsTrackingId="catalog-delete-type-modal"
      title="Are you sure?"
      isOpen
      onClose={onCancel}
    >
      <ModalContent className="space-y-4">
        {genericError && <GenericErrorMessage description={genericError} />}
        <Callout theme={CalloutTheme.Warning}>
          This action will result in permanent loss of data.
        </Callout>
        <Txt className="space-y-2">
          <p>
            Pressing continue will permanently delete{" "}
            <Txt bold inline>
              {catalogType.name}
            </Txt>{" "}
            and all of its entries.
          </p>
          <p>
            If{" "}
            <Txt bold inline>
              {catalogType.name}
            </Txt>{" "}
            is referenced in any catalog attributes, these will be converted to
            String attributes.
          </p>
          <p>
            Please type{" "}
            <Txt bold inline>
              {requiredConfirmValue}
            </Txt>{" "}
            below to confirm.
          </p>
        </Txt>
        <FormProvider<{ confirm: string }> {...formMethods}>
          <InputV2
            formMethods={formMethods}
            placeholder={requiredConfirmValue}
            name="confirm"
            autoFocus={true}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                confirmValue.toLowerCase() ===
                  requiredConfirmValue.toLowerCase()
              ) {
                onDelete();
              }
            }}
          />
        </FormProvider>
      </ModalContent>
      <ModalFooter>
        <div className="flex-center-y space-x-2">
          <Button
            analyticsTrackingId="catalog-delete-type-cancel"
            onClick={onCancel}
            theme={ButtonTheme.Secondary}
          >
            Cancel
          </Button>
          <Button
            analyticsTrackingId="catalog-delete-type-confirm"
            onClick={onDelete}
            disabled={
              confirmValue.toLowerCase() !== requiredConfirmValue.toLowerCase()
            }
            loading={isMutating}
            theme={ButtonTheme.Destroy}
          >
            Continue
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export const LastSyncedAt = ({
  lastSyncedAt,
  frequency,
}: {
  lastSyncedAt?: Date;
  frequency?: string;
}) => {
  if (!lastSyncedAt) {
    return <>Syncing...</>;
  }
  return (
    <>
      <p>
        Last sync at{" "}
        {lastSyncedAt.toLocaleTimeString("default", {
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </p>
      {frequency && <p className="pt-2">Syncs {frequency.toLowerCase()}</p>}
    </>
  );
};
