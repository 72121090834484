import { Alert } from "@incident-io/api";
import { CatalogSetupWidget } from "@incident-shared/catalog/CatalogSetupWidget";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
} from "@incident-ui";
import { captureException } from "@sentry/react";
import _ from "lodash";
import { useAPI } from "src/utils/swr";

import { useCatalogSetup } from "./useCatalogSetup";

export const AlertsCatalogSetupWidget = ({
  populatedAttributes,
  openAttributesDrawer,
  openTaggingInstructionsDrawer,
  recentAlerts,
}: {
  populatedAttributes?: string[];
  openAttributesDrawer?: () => void;
  openTaggingInstructionsDrawer?: () => void;
  recentAlerts?: Alert[];
}): React.ReactElement => {
  const widgetConfig = useCatalogSetup();
  const {
    data: schemaData,
    isLoading: schemaIsLoading,
    error: schemaError,
  } = useAPI("alertsShowSchema", {});

  if (!widgetConfig || !schemaData) {
    return <></>;
  }

  if (schemaError) {
    captureException(schemaError, {
      tags: { component: "AlertsCatalogSetupWidget" },
    });
    return <></>;
  }

  const { catalogConfig, props } = widgetConfig;

  if (props) {
    return <CatalogSetupWidget {...props} />;
  }

  if (!schemaData || schemaIsLoading) {
    return <></>;
  }

  if (populatedAttributes) {
    // Check that we have at least one bound attribute which has a type that is in
    // the escalation types.
    const boundEscalationAttributes = schemaData.alert_schema.attributes.filter(
      (attribute) => {
        // If we're not bound, we don't care.
        if (!populatedAttributes.includes(attribute.id)) {
          return false;
        }

        return _.some(catalogConfig.escalation_types, (escalationType) => {
          return attribute.type === escalationType.engine_type_name;
        });
      },
    );

    const hasBoundEscalationAttributes = !!boundEscalationAttributes?.length;
    const boundCatalogAttributeIds = boundEscalationAttributes?.map(
      (attribute) => attribute.id,
    );

    const hasAlertsWithBoundEscalationAttributesSet = recentAlerts?.some(
      (alert) => {
        return boundCatalogAttributeIds.some((catalogTypeId) => {
          const attribute = alert.attribute_values[catalogTypeId];
          return attribute && (attribute.value || attribute.array_value);
        });
      },
    );
    if (
      !hasBoundEscalationAttributes ||
      (recentAlerts?.length && !hasAlertsWithBoundEscalationAttributesSet)
    ) {
      const description = !hasBoundEscalationAttributes
        ? "Alert routes use attributes to know where to send alerts. Currently, none of your attributes can be linked to an escalation path. We recommend tagging alerts with a Team and having alert routes send alerts to that team’s escalation path."
        : "Alert routes use attributes to know where to send alerts. Currently, none of your recent alerts have set attributes which can be linked to an escalation path. We recommend tagging alerts with a Team and having alert routes send alerts to that team’s escalation path.";
      return (
        <CatalogSetupWidget
          title="Tag alerts with their owner"
          description={description}
          fixButton={
            openAttributesDrawer
              ? {
                  title: "Review attributes",
                  onClick: () => openAttributesDrawer(),
                }
              : openTaggingInstructionsDrawer && {
                  title: "Show me how",
                  onClick: () => openTaggingInstructionsDrawer(),
                }
          }
        />
      );
    }
  }

  if (openTaggingInstructionsDrawer) {
    return (
      <Callout
        showIcon={false}
        theme={CalloutTheme.Plain}
        title="Add attributes to your alerts by sending data from your alert source"
        cta={
          <Button
            size={BadgeSize.Medium}
            theme={ButtonTheme.Secondary}
            analyticsTrackingId="alert-source-tagging-instructions"
            onClick={openTaggingInstructionsDrawer}
          >
            Learn how
          </Button>
        }
      />
    );
  }

  return <></>;
};
