import { NudgeNudgeTypeEnum, ScopeNameEnum } from "@incident-io/api";
import { useNudgesServiceNudgesListAvailableNudges } from "@incident-io/query-api";
import { Product } from "@incident-shared/billing";
import { GatedButton } from "@incident-shared/gates/GatedButton/GatedButton";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import {
  ButtonTheme,
  Card,
  DropdownMenu,
  DropdownMenuItem,
  IconBadge,
  IconEnum,
  IconSize,
} from "@incident-ui";
import { CardProps } from "@incident-ui/Card/Card";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerContentsLoading,
  DrawerProps,
  DrawerTitle,
  DrawerTitleProps,
  DrawerTitleTheme,
} from "@incident-ui/Drawer/Drawer";
import React from "react";
import { useOutlet } from "react-router";
import { useProductAccess } from "src/hooks/useProductAccess";
import { tcx } from "src/utils/tailwind-classes";

import { NudgesList } from "../nudges/list/NudgesList";
import { Plurality, ProductUpsellNotice } from "../ProductUpsellNotice";

export type AIFeatureConfigurationDrawerProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  preview?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
  nudgeTypes?: NudgeNudgeTypeEnum[];
} & Omit<DrawerProps, "children">;

export const AIFeatureConfigurationDrawer = ({
  content,
  preview,
  title,
  footer,
  loading,
  onClose,
  width,
  nudgeTypes,
}: AIFeatureConfigurationDrawerProps): React.ReactElement => {
  const childDrawer = useOutlet();
  const { hasResponse } = useProductAccess();

  return (
    <>
      <Drawer width={width} onClose={onClose} isInBackground={!!childDrawer}>
        {loading ? (
          <DrawerContentsLoading />
        ) : (
          <div className="flex flex-col h-full !overflow-hidden">
            {title}
            <DrawerContents>
              <DrawerBody className="flex flex-row min-h-0 overflow-y-hidden h-full !p-0 !gap-0">
                {/* Left hand, input side of the form */}
                <div className="h-full p-6 overflow-y-auto flex flex-col gap-6 flex-[1] border-r border-stroke">
                  {content}

                  {hasResponse ? (
                    nudgeTypes && (
                      <div className="flex flex-col gap-4">
                        <div className="flex">
                          <div className="flex-1">
                            <div className="text-base-bold text-content-primary">
                              Slack suggestions
                            </div>
                            <div className="text-sm-normal text-content-seconday">
                              Send suggestion messages to incident channels to
                              help your team respond effectively
                            </div>
                          </div>
                          <div className="flex flex-col justify-center items-center">
                            <NudgeButton nudgeTypes={nudgeTypes} />
                          </div>
                        </div>
                        <NudgesList
                          nudgeTypes={nudgeTypes}
                          hasFeatureGate
                          renameToChannelSuggestion
                        />
                      </div>
                    )
                  ) : (
                    <ProductUpsellNotice
                      featureName="Channel suggestions"
                      plurality={Plurality.Plural}
                      requiredProduct={Product.Response}
                    />
                  )}
                </div>

                {/* Right hand, preview side of the form */}
                {preview && (
                  <div
                    className={
                      "flex flex-col items-stretch flex-[1] p-6 bg-surface-secondary overflow-auto gap-3"
                    }
                  >
                    {preview}
                  </div>
                )}
              </DrawerBody>
              {footer}
            </DrawerContents>
          </div>
        )}
      </Drawer>
      {childDrawer}
    </>
  );
};

type NudgeButtonProps = {
  nudgeTypes: NudgeNudgeTypeEnum[];
};

const NudgeButton = ({ nudgeTypes }: NudgeButtonProps) => {
  const { data: nudgesRepsonse, isLoading } =
    useNudgesServiceNudgesListAvailableNudges();
  if (nudgeTypes.length === 0) {
    const [nudgeType] = nudgeTypes;

    return (
      <GatedButton
        analyticsTrackingId={`create-nudge`}
        theme={ButtonTheme.Primary}
        href={`create?nudge_type=${nudgeType}`}
        requiredScope={ScopeNameEnum.NudgesCreate}
        requiredProduct={Product.Response}
      >
        Add
      </GatedButton>
    );
  }

  const nudges =
    nudgesRepsonse?.available_nudges.filter((nudge) =>
      nudgeTypes.map((t) => t.toString()).includes(nudge.nudge_type),
    ) ?? [];

  return (
    <DropdownMenu
      align="end"
      triggerButton={
        <GatedButton
          analyticsTrackingId={`create-nudge`}
          theme={ButtonTheme.Primary}
          requiredScope={ScopeNameEnum.NudgesCreate}
          requiredProduct={Product.Response}
          loading={isLoading}
        >
          Add
        </GatedButton>
      }
    >
      {nudges.map((nudge) => (
        <DropdownMenuItem
          openInNewTab={false}
          analyticsTrackingId={`create-nudge-${nudge.nudge_type}`}
          key={nudge.nudge_type}
          to={`create?nudge_type=${nudge.nudge_type}`}
          icon={nudge.icon as IconEnum}
          label={`${nudge.label}`}
        />
      ))}
    </DropdownMenu>
  );
};

export const AIFeatureDrawerTitle = (props: DrawerTitleProps) => {
  return (
    <DrawerTitle
      color={ColorPaletteEnum.SlateOnWhite}
      iconClassName="text-alarmalade-500 bg-alarmalade-50 border-none"
      invertIcon={false}
      theme={DrawerTitleTheme.Bordered}
      {...props}
    />
  );
};

export const AIProductMarketingCards = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className="grid grid-cols-2 gap-4">{children}</div>;
};

export const AIProductMarketingCard = (props: CardProps) => {
  return (
    <Card
      {...props}
      iconOverride={
        <IconBadge
          color={ColorPaletteEnum.SlateOnWhite}
          icon={props.icon}
          size={IconSize.Small}
          className="text-alarmalade-500 border-none"
        />
      }
      className={tcx("bg-surface-secondary border-none", props.className)}
    />
  );
};
