import {
  CatalogType,
  IntegrationSettings,
  IntegrationSettingsReconnectionReasonEnum,
} from "@incident-io/api";

import { IntegrationConfig } from "./IntegrationConfig";

export const getBrokenIntegrationsLookup = (
  integrations: IntegrationSettings[],
): { [key: string]: IntegrationSettings } =>
  integrations
    .filter(
      // If an integration isn't installed, or has a reconnection reason set,
      // it is "broken" for our purposes - we can't sync it.
      (x) => {
        return !x.installed || x.reconnection_reason;
      },
    )
    .reduce((lookup, integration) => {
      lookup[integration.provider] = integration;
      return lookup;
    }, {});

export const getBrokenIntegrations = (
  requiredIntegrations: string[],
  lookup: { [key: string]: IntegrationSettings },
): string[] => {
  return requiredIntegrations.filter((provider) => lookup[provider]) || [];
};

export type IntegrationStatus = {
  broken: boolean;
  reason: IntegrationSettingsReconnectionReasonEnum | "not_installed" | "";
};

export const getIntegrationStatus = (
  type: CatalogType,
  lookup: { [key: string]: IntegrationSettings },
): IntegrationStatus => {
  if (type.required_integrations && type.required_integrations.length > 0) {
    const brokenIntegration = lookup[type.required_integrations[0]];
    return {
      broken: !!brokenIntegration,
      reason: brokenIntegration?.installed
        ? brokenIntegration.reconnection_reason
        : "not_installed",
    };
  }
  return {
    broken: false,
    reason: "",
  };
};

export const getBrokenConfigNames = (
  brokenConfigs: IntegrationConfig[],
): string => {
  if (brokenConfigs.length === 1) {
    return brokenConfigs[0].label;
  } else {
    const lastConfig = brokenConfigs.pop();
    return (
      brokenConfigs.map((x) => x.label).join(",") + " and " + lastConfig?.label
    );
  }
};
