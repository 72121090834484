import { IssueTemplateContextEnum } from "@incident-shared/issue-trackers/useAllTemplates";
import { useEnabledExportIssueTrackers } from "@incident-shared/issue-trackers/useEnabledExportIssueTrackers";
import {
  Button,
  ButtonTheme,
  Checkbox,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconEnum,
  IconSize,
  Loader,
  Txt,
} from "@incident-ui";
import { useContextualItems } from "@incident-ui/CommandPalette/CommandPaletteProvider";
import React, { useCallback, useState } from "react";
import { useIncidentsListContext } from "src/components/@shared/incidents";
import {
  BulkAction,
  useBulkActions,
} from "src/components/legacy/incidents-list/bulk-actions/useBulkActions";
import {
  Incident,
  IncidentRoleRoleTypeEnum,
  ScopeNameEnum,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import { useAPI } from "src/utils/swr";

import { useFiltersContext } from "./FiltersContext";
import { SearchBar } from "./SearchBar";

// IncidentsFilterControlSearchBar controls the search bar and incident type filter that are rendered
// just below the list of applied filters.
export const IncidentsFilterControlSearchBar = ({
  incidents,
  selectedIncidentIDs,
  selectAllIncidents: selectAll,
  setSelectAllIncidents: setSelectAll,
  refetchIncidents,
  includesTriageIncidents,
  isAllTriageIncidents,
  totalNumberOfIncidents,
  canCustomiseDisplayedInfo = true,
  bulkActionsOverride,
}: {
  incidents: Incident[];
  selectedIncidentIDs: string[];
  selectAllIncidents: boolean;
  setSelectAllIncidents: (newValue: boolean) => void;
  refetchIncidents: () => Promise<void>;
  includesTriageIncidents: boolean;
  isAllTriageIncidents: boolean;
  totalNumberOfIncidents: number;
  canCustomiseDisplayedInfo?: boolean;
  bulkActionsOverride?: BulkAction[];
}): React.ReactElement | null => {
  const {
    filters,
    addFilter,
    editFilter,
    deleteFilter,
    availableFilterFields,
  } = useFiltersContext();
  const { hasScope } = useIdentity();
  const defaultBulkActions = useBulkActions();
  const bulkActions = bulkActionsOverride ?? defaultBulkActions;

  const userCanBulkUpdateIncidents = hasScope(
    ScopeNameEnum.IncidentsBulkUpdate,
  );

  const [selectedAction, setSelectedAction] = useState<BulkAction | null>(null);

  const cmdkTitle =
    selectedIncidentIDs.length > 0 && userCanBulkUpdateIncidents
      ? `${selectedIncidentIDs.length} selected incidents`
      : "";
  const cmdkItems =
    selectedIncidentIDs.length > 0 && userCanBulkUpdateIncidents
      ? bulkActions.map((bulkAction) => ({
          label: bulkAction.label,
          analyticsId: `bulk_action_${bulkAction.name}`,
          key: `bulk_action_${bulkAction.name}`,
          icon: bulkAction.icon,
          onSelect: () => {
            if (bulkAction.form) {
              setSelectedAction(bulkAction);
            }
          },
        }))
      : [];
  useContextualItems(cmdkTitle, cmdkItems);

  const actionDisabledContent = (action: BulkAction) => {
    if (action.name === "set_incident_status" && includesTriageIncidents) {
      return "Your selection includes triage incidents, which must be accepted before setting a status";
    }
    if (action.name === "decline_incidents" && !isAllTriageIncidents) {
      return "Your selection includes non-triage incidents, which cannot be declined";
    }
    return null;
  };

  return (
    <div className="flex gap-2 justify-between text-sm items-center grow">
      <div className="flex flex-row items-center gap-2 grow">
        <SearchBar
          id="search_incidents"
          placeholder="Search incidents"
          availableFilterFields={availableFilterFields}
          appliedFilters={filters}
          onEditFilter={editFilter}
          onDeleteFilter={deleteFilter}
          onAddFilter={addFilter}
          className="w-full"
        />
        {/* only check bulk operations here, as non-admins can select for export */}
        {canCustomiseDisplayedInfo && (
          <div className="items-center flex-row lg:items-center gap-y-2 lg:gap-x-2 hidden md:flex shrink-0">
            <Checkbox
              className="!text-content-primary mr-2 ml-2 cursor-pointer"
              id="select-all"
              onChange={() => {
                if (selectedIncidentIDs.length === incidents.length) {
                  setSelectAll(false);
                } else {
                  setSelectAll(true);
                }
              }}
              label={"Select all"}
              checked={
                (incidents.length > 0 &&
                  selectedIncidentIDs.length === incidents.length) ||
                selectAll
              }
            />
            <CustomiseDisplayedInfo />
          </div>
        )}
      </div>
      {selectedIncidentIDs.length > 0 && (
        <div className="flex flex-row items-center gap-2 shrink-0">
          <CountIncidentsSelected
            numSelected={selectedIncidentIDs.length}
            totalNumberOfIncidents={totalNumberOfIncidents}
          />
          <Button
            theme={ButtonTheme.Naked}
            analyticsTrackingId="clear-selected-incidents"
            onClick={() => setSelectAll(false)}
          >
            Deselect all
            <Icon
              id={IconEnum.Close}
              className="text-content-tertiary ml-1"
              size={IconSize.Small}
            />
          </Button>
          <DropdownMenu
            tooltipContent={
              !userCanBulkUpdateIncidents &&
              "Only admin users can apply bulk actions"
            }
            align="end"
            triggerButton={
              <Button
                className="disabled:!opacity-50 !font-medium !font-lg"
                theme={ButtonTheme.Secondary}
                analyticsTrackingId="incidents-bulk-action"
              >
                Apply bulk actions
              </Button>
            }
          >
            {bulkActions.map((action) => (
              <DropdownMenuItem
                key={action.name}
                label={action.label}
                icon={action.icon}
                disabled={
                  !userCanBulkUpdateIncidents || !!actionDisabledContent(action)
                }
                iconProps={{
                  className: "text-content-tertiary group-hover:text-slate-700",
                  size: IconSize.Large,
                }}
                onSelect={() => {
                  setSelectedAction(action);
                }}
                analyticsTrackingId={`incidents-bulk-action-${action.name}`}
                tooltipContent={actionDisabledContent(action)}
                tooltipSide="left"
              />
            ))}
          </DropdownMenu>
        </div>
      )}
      {selectedAction && selectedAction.form && (
        <selectedAction.form
          incidentIDs={selectedIncidentIDs}
          onSubmit={() => {
            setSelectedAction(null);
            refetchIncidents();
          }}
          onClose={() => {
            setSelectedAction(null);
          }}
        />
      )}
    </div>
  );
};

const CountIncidentsSelected = ({
  numSelected,
  totalNumberOfIncidents,
}: {
  numSelected: number;
  totalNumberOfIncidents: number;
}): React.ReactElement => {
  const incidentOrPlural = numSelected === 1 ? "incident" : "incidents";

  if (numSelected === totalNumberOfIncidents) {
    return (
      <Txt grey inline>
        <Txt bold inline>
          {numSelected}
        </Txt>{" "}
        {incidentOrPlural} selected
      </Txt>
    );
  }

  return (
    <Txt grey inline>
      <Txt bold inline>
        {numSelected}
      </Txt>{" "}
      {incidentOrPlural} {"selected out of "}
      <Txt bold inline>
        {totalNumberOfIncidents}
      </Txt>
      {" total"}
    </Txt>
  );
};

export interface FieldInfo {
  type: "role" | "custom_field" | "other";
  id: string;
}
const CustomiseDisplayedInfo = () => {
  const {
    data: { incident_roles: roles },
    isLoading: loadingRoles,
  } = useAPI("incidentRolesList", undefined, {
    fallbackData: { incident_roles: [] },
  });

  const {
    data: { custom_fields: customFields },
    isLoading: loadingFields,
  } = useAPI("customFieldsList", undefined, {
    fallbackData: { custom_fields: [] },
  });

  const {
    fieldsToDisplay: fieldsToDisplay,
    setFieldsToDisplay: setFieldsToDisplay,
  } = useIncidentsListContext();

  const { installedTrackers } = useEnabledExportIssueTrackers(
    IssueTemplateContextEnum.IncidentTicket,
  );
  const hasIncidentTickets = installedTrackers.length > 0;

  // group by role, custom field etc
  const fields: {
    role: Set<string>;
    custom_field: Set<string>;
    other: Set<string>;
  } = fieldsToDisplay.reduce(
    (output, fieldInfo) => {
      output[fieldInfo.type].add(fieldInfo.id);
      return output;
    },
    {
      role: new Set<string>(),
      custom_field: new Set<string>(),
      other: new Set<string>(),
    },
  );

  const onSelectItem = (fieldType: "role" | "other", fieldID: string) => {
    if (fields[fieldType].has(fieldID)) {
      // we are unchecking it
      setFieldsToDisplay(
        fieldsToDisplay.filter((field) => field.id !== fieldID),
      );
    } else {
      setFieldsToDisplay([
        ...fieldsToDisplay,
        { id: fieldID, type: fieldType },
      ]);
    }
  };

  const onClickField = useCallback(
    (fieldID: string) => {
      if (fields.custom_field.has(fieldID)) {
        // we are unchecking it
        setFieldsToDisplay(
          fieldsToDisplay.filter((field) => field.id !== fieldID),
        );
      } else {
        setFieldsToDisplay([
          ...fieldsToDisplay,
          { id: fieldID, type: "custom_field" },
        ]);
      }
    },
    [fieldsToDisplay, setFieldsToDisplay, fields.custom_field],
  );

  return (
    // Using <p> instead instead of the label prop in Checkbox to prevent both
    // the DropdownMenuItem and Checkbox being clicked at the same time, leading
    // to us checking then instantly unchecking
    <DropdownMenu
      menuClassName={"!overflow-auto"}
      triggerButton={
        <Button
          theme={ButtonTheme.Naked}
          analyticsTrackingId={"incidents-list.customise-displayed-info"}
          icon={IconEnum.Sliders}
          iconProps={{
            size: IconSize.Medium,
          }}
        >
          Customise view
        </Button>
      }
      scroll
    >
      <DropdownMenuItem
        onSelect={(e) => {
          onSelectItem("other", "mode");
          e.preventDefault();
        }}
        className="group/check"
        analyticsTrackingId={null}
        label="Mode"
      >
        <Checkbox
          id="mode"
          onChange={() => {
            onSelectItem("other", "mode");
          }}
          checked={fields.other.has("mode")}
          className="!cursor-pointer"
        />
        <p className="ml-2 text-slate-700">Mode</p>
      </DropdownMenuItem>
      {hasIncidentTickets && (
        <DropdownMenuItem
          onSelect={(e) => {
            onSelectItem("other", "external_ref");
            e.preventDefault();
          }}
          className="group/check"
          analyticsTrackingId={null}
          label="Incident ticket ID"
        >
          <Checkbox
            id="external_ref"
            onChange={() => {
              onSelectItem("other", "external_ref");
            }}
            checked={fields.other.has("external_ref")}
            className="!cursor-pointer"
          />
          <p className="ml-2 text-slate-700">Incident ticket ID</p>
        </DropdownMenuItem>
      )}

      <DropdownGroupHeading title="Custom Fields" />
      {loadingFields ? (
        <Loader />
      ) : (
        customFields?.map((field) => {
          return (
            <DropdownMenuItem
              key={field.id}
              onSelect={(e) => {
                onClickField(field.id);
                e.preventDefault();
              }}
              className="group/check"
              analyticsTrackingId={null}
              label={field.name}
            >
              <Checkbox
                id={field.id}
                onChange={() => {
                  onClickField(field.id);
                }}
                checked={fields.custom_field.has(field.id)}
                className="!cursor-pointer"
              />
              <p className="ml-2 text-slate-700">{field.name}</p>
            </DropdownMenuItem>
          );
        })
      )}

      <DropdownGroupHeading title="Roles" />
      {loadingRoles ? (
        <Loader />
      ) : (
        roles
          ?.filter((role) => role.role_type !== IncidentRoleRoleTypeEnum.Lead)
          .map((role) => {
            return (
              <DropdownMenuItem
                key={role.id}
                // don't close the menu immediately. let user choose multiple items
                onSelect={(e) => {
                  onSelectItem("role", role.id);
                  e.preventDefault();
                }}
                analyticsTrackingId={null}
                label={role.name}
              >
                <Checkbox
                  id={role.id}
                  onChange={() => onSelectItem("role", role.id)}
                  checked={fields.role.has(role.id)}
                />
                <p className="ml-2 text-slate-700">{role.name}</p>
              </DropdownMenuItem>
            );
          })
      )}
    </DropdownMenu>
  );
};

const DropdownGroupHeading = ({ title }: { title: string }) => {
  return (
    <div className="p-2 font-semibold text-content-tertiary text-xs pb-1 pl-1.5 tracking-widest uppercase">
      {title}
    </div>
  );
};
