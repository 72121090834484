import { usePylon } from "@bolasim/react-use-pylon";
import {
  AIConfig,
  IncidentCallSettingsUpdateAutoCallRequestBody,
  IntegrationSettingsProviderEnum,
} from "@incident-io/api";
import { useIntegrationsMicrosoftTeamsOnlineMeetingsServiceIntegrationsMicrosoftTeamsOnlineMeetingsGetConfig } from "@incident-io/query-api";
import { Product } from "@incident-shared/billing";
import { ProductRequiredMessage } from "@incident-shared/billing/ProductRequired/ProductRequiredMessage";
import {
  ConfigureDrawerProps,
  IntegrationConfigFor,
  IntegrationListProvider,
  IntegrationsListObject,
} from "@incident-shared/integrations";
import {
  Button,
  ButtonTheme,
  Callout,
  CalloutTheme,
  IconEnum,
  IconSize,
  LoadingBar,
  SharedToasts,
  StackedList,
  StackedListItem,
  ToastTheme,
  Toggle,
  Tooltip,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { integrationProvidersWithScribeSupport } from "src/components/incident-calls/useIsScribeAvailable";
import {
  AutoCallConfigProviderEnum,
  IncidentCallSettings,
  IntegrationSettingsProviderEnum as IntegrationProvider,
  ScopeNameEnum,
  useIsAuthenticated,
} from "src/contexts/ClientContext";
import { useIdentity } from "src/contexts/IdentityContext";
import {
  AutoSavingIndicator,
  useOptimisticAutoSave,
} from "src/hooks/useOptimisticAutoSave";
import { useProductAccess } from "src/hooks/useProductAccess";
import { useAPI, useAPIMutation } from "src/utils/swr";

import { GoogleMeetMissingPermissionsForScribe } from "../../../incident-calls/useShowScribeBanner";
import { GenericConfigureDrawerContents } from "../list/IntegrationDrawer";
import { IntegrationConnectedUser } from "./IntegrationConnectedUser";

export const CallProviderConfigureDrawer = (
  props: ConfigureDrawerProps,
): React.ReactElement | null => {
  const connectedUser = useConnectedUser(props.integration.provider);
  const isAuthenticated = useIsAuthenticated();
  const { data, isLoading } = useAPI(
    isAuthenticated ? "incidentCallSettingsShow" : null,
    undefined,
  );

  const { showKnowledgeBaseArticle } = usePylon();
  const { hasScope } = useIdentity();
  const showToast = useToast();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);

  const { identity } = useIdentity();
  const { data: aiConfigData } = useAPI(
    identity ? "aIShowConfig" : null,
    undefined,
  );

  const { featureLiveCallTranscription } = useFlags();
  const notYetEnabled =
    aiConfigData?.config.openai_subprocessor_enabled &&
    !aiConfigData?.config.scribe_enabled;

  const { trigger: enableScribe } = useAPIMutation(
    "aIShowConfig",
    undefined,
    async (apiClient, { newConfig }: { newConfig: AIConfig }) =>
      await apiClient.aIToggleFeature({
        toggleFeatureRequestBody: newConfig,
      }),
    {
      onSuccess: () => {
        showToast({
          title: "Scribe successfully activated",
          theme: ToastTheme.Success,
        });
      },
      onError: () => {
        showToast(SharedToasts.SETTINGS_SAVE_ERROR);
        showToast({
          title: "Could not activate Scribe",
          theme: ToastTheme.Error,
        });
      },
    },
  );

  const missingGooglePermissionsForScribe =
    GoogleMeetMissingPermissionsForScribe();

  return (
    <>
      {featureLiveCallTranscription &&
        notYetEnabled &&
        integrationProvidersWithScribeSupport.includes(
          props.integration.provider,
        ) && (
          <div className="px-6 pt-6">
            <Callout
              className="text-white"
              theme={CalloutTheme.Scribe}
              title="Never miss a call detail"
              subtitle={
                <p>
                  Scribe auto-joins video calls to take notes and deliver
                  concise summaries, allowing everyone to participate freely.
                </p>
              }
              ctaPosition="right"
              cta={
                canEditSettings ? (
                  <Tooltip
                    content={
                      missingGooglePermissionsForScribe ? (
                        <span>
                          Before you can activate Scribe, you need to reconnect
                          this integration to ensure we have the latest
                          permissions.
                        </span>
                      ) : undefined
                    }
                  >
                    <Button
                      analyticsTrackingId="enable-scribe"
                      theme={ButtonTheme.Secondary}
                      onClick={() =>
                        enableScribe({
                          newConfig: {
                            ...aiConfigData.config,
                            scribe_enabled: true,
                          },
                        })
                      }
                      disabled={missingGooglePermissionsForScribe}
                    >
                      Activate
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    analyticsTrackingId="dismiss-richer-calls-banner"
                    theme={ButtonTheme.Secondary}
                    onClick={() => showKnowledgeBaseArticle("3340727910")}
                  >
                    Learn more
                  </Button>
                )
              }
            />
          </div>
        )}
      <GenericConfigureDrawerContents {...props}>
        {isLoading || !data ? (
          <LoadingBar />
        ) : (
          <>
            {connectedUser && (
              <IntegrationConnectedUser
                {...connectedUser}
                provider={props.integration.provider}
              />
            )}
            <RelatedSettings
              integration={props.integration}
              settings={data.settings}
              scribeEnabledAlready={!notYetEnabled}
            />
          </>
        )}
      </GenericConfigureDrawerContents>
    </>
  );
};

const RelatedSettings = ({
  integration,
  settings,
  scribeEnabledAlready,
}: {
  integration: IntegrationsListObject;
  settings: IncidentCallSettings;
  scribeEnabledAlready?: boolean;
}): React.ReactElement | null => {
  const { hasScope } = useIdentity();
  const canEditSettings = hasScope(ScopeNameEnum.OrganisationSettingsUpdate);
  const autoCallSettings = settings?.auto_call;
  const { hasResponse } = useProductAccess();
  const { featureLiveCallTranscription } = useFlags();

  const { trigger: onSubmit } = useAPIMutation(
    "incidentCallSettingsShow",
    undefined,
    async (apiClient, data: IncidentCallSettingsUpdateAutoCallRequestBody) => {
      if (data.auto_call?.enabled) {
        data.auto_call.config = {
          provider: integration.provider as AutoCallConfigProviderEnum,
        };
      }
      await apiClient.incidentCallSettingsUpdateAutoCall({
        updateAutoCallRequestBody: data,
      });
    },
  );

  const alreadyEnabledOnWrongProvider =
    autoCallSettings.enabled &&
    integration.provider.toString() !== autoCallSettings.config?.provider;

  const {
    setState,
    hasSaved,
    saving,
    state: enabled,
  } = useOptimisticAutoSave<boolean>({
    initialState: !alreadyEnabledOnWrongProvider && autoCallSettings.enabled,
    saveState: async (enabled: boolean) => {
      await onSubmit({ auto_call: { enabled } });
    },
  });

  const otherIntegrationConfig = autoCallSettings.config?.provider
    ? IntegrationConfigFor(
        autoCallSettings.config?.provider as unknown as IntegrationProvider,
      )
    : undefined;

  return (
    <>
      {alreadyEnabledOnWrongProvider && otherIntegrationConfig ? (
        <Callout theme={CalloutTheme.Warning}>
          Auto call creation is already configured for{" "}
          {otherIntegrationConfig?.label}. If you&apos;d like to enable it here,
          you&apos;ll need to first disable it.
        </Callout>
      ) : null}
      <StackedList>
        <StackedListItem
          key="auto_call.setting"
          icon={IconEnum.Call}
          title={
            <div className="flex items-center gap-2">
              Automatically create incident call
              <AutoSavingIndicator saving={saving} hasSaved={hasSaved} />
            </div>
          }
          description={`When enabled, we'll use ${
            IntegrationConfigFor(integration.provider).label
          } to start a video conferencing call whenever we create an incident, and assign it as the incident call link.`}
          accessory={
            <Toggle
              id="auto_call.enabled"
              on={enabled}
              disabled={
                !canEditSettings ||
                saving ||
                !hasResponse ||
                alreadyEnabledOnWrongProvider
              }
              isDisabledTooltipContent={
                !hasResponse ? (
                  <ProductRequiredMessage requiredProduct={Product.Response} />
                ) : null
              }
              label=""
              description={`When enabled, we'll use ${
                IntegrationConfigFor(integration.provider).label
              } to start a video conferencing call whenever we create an incident, and assign it as the incident call link.`}
              onToggle={() => {
                setState(!enabled);
              }}
            />
          }
        />
        {featureLiveCallTranscription &&
          scribeEnabledAlready &&
          integrationProvidersWithScribeSupport.includes(
            integration.provider,
          ) && (
            <StackedListItem
              key="scribe.setting"
              icon={IconEnum.Scribe}
              title="Scribe"
              description="Scribe auto-joins video calls to take notes and deliver concise summarise, allowing everyone to participate freely."
              accessory={
                <Button
                  iconProps={{
                    size: IconSize.Large,
                  }}
                  analyticsTrackingId="call-provider-configure-scribe"
                  theme={ButtonTheme.Naked}
                  icon={IconEnum.Cog}
                  title=""
                  href="/settings/ai"
                />
              }
            />
          )}
      </StackedList>
    </>
  );
};

const useConnectedUser = (integration: IntegrationListProvider) => {
  const { data } =
    useIntegrationsMicrosoftTeamsOnlineMeetingsServiceIntegrationsMicrosoftTeamsOnlineMeetingsGetConfig();

  switch (integration) {
    case IntegrationSettingsProviderEnum.MicrosoftTeamsOnlineMeetings:
      return data?.config.connected_user;
  }

  return null;
};
