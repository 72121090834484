import { EscalationPathNodeTypeEnum as NodeTypes } from "@incident-io/api";
import { useFormContext } from "react-hook-form";
import { Handle, Position } from "reactflow";
import { tcx } from "src/utils/tailwind-classes";
import { assertUnreachable } from "src/utils/utils";

import { CopyDebugID } from "../../../utils/StaffOverlayProvider";
import {
  EscalationPathFormData,
  ReactFlowDataType,
  ReactFlowNodeCustomType,
} from "../common/types";
import { NodeDoNothing } from "../nodes/NodeDoNothing";
import { NodeIfElse } from "../nodes/NodeIfElse";
import { NodeLevel } from "../nodes/NodeLevel";
import { NodeNotifyChannel } from "../nodes/NodeNotifyChannel";
import { NodeRepeat } from "../nodes/NodeRepeat";
import { NodeStart } from "../nodes/NodeStart";
import { useZoomContext } from "./ZoomContext";

// EscalationPathGridNode is a wrapper around the different types of nodes in an escalation path
// such that react-flow can render them and draw edges between them.
export const CustomNode = ({
  id,
  data,
}: {
  id: string;
  data: ReactFlowDataType;
}) => {
  const formMethods = useFormContext<EscalationPathFormData>();
  const nodes = formMethods.getValues("nodes");

  // When we delete a node from the form state, it will attempt to re-render the node even though
  // it no longer exists. This check prevents that from happening.
  const isRenderOnlyNode = Object.values(ReactFlowNodeCustomType).includes(
    data.type as ReactFlowNodeCustomType,
  );
  if (!isRenderOnlyNode && !nodes[id]) {
    return null;
  }
  if (data.type === ReactFlowNodeCustomType.Terminus) {
    // For a terminus node, we just say "this is where to plug an edge in", and
    // no actual visible node. Weirdly we have to do quite a lot of work to make sure reactflow 'sees' the handle, including faking a non-zero-size node
    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={{
            opacity: 0,
            top: 40,
            cursor: "default",
            pointerEvents: "none",
          }}
        />
        <div className="w-[400px] h-px" />
      </>
    );
  }

  const NodeComponent =
    data.type === NodeTypes.Level
      ? NodeLevel
      : data.type === NodeTypes.NotifyChannel
      ? NodeNotifyChannel
      : data.type === NodeTypes.Repeat
      ? NodeRepeat
      : data.type === NodeTypes.IfElse
      ? NodeIfElse
      : data.type === ReactFlowNodeCustomType.Start
      ? NodeStart
      : data.type === ReactFlowNodeCustomType.DoNothing
      ? NodeDoNothing
      : assertUnreachable(data);

  return (
    <>
      <NodeOverlay />
      <div className="relative z-10 cursor-default">
        <Handle
          type="target"
          position={Position.Top}
          style={{
            opacity: 0,
            top: 40,
            cursor: "default",
            pointerEvents: "none",
          }}
        />
        <div
          className="nodrag nopan"
          style={{
            pointerEvents: "all",
            cursor: "default",
          }}
        >
          <CopyDebugID
            className="absolute top-0 right-0 text-xs text-slate-600"
            id={id}
          />
          <NodeComponent key={id} id={id} />
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            opacity: 0,
            bottom: 40,
            cursor: "default",
            pointerEvents: "none",
          }}
        />
      </div>
    </>
  );
};

// NodeOverlay is a dummy overlay component that we use to disable the node's
// inputs if we're too zoomed in or out.
//
// For context, this is because if we're at a zoom level other than 1, the inputs
// will appear comically small or big. Instead, when a user clicks on a card,
// we'll zoom them back in to the center of that card and then remove the
// overlay to allow them to click as normal.
const NodeOverlay = () => {
  const { zoomLevel } = useZoomContext();

  return (
    <div
      className={tcx(
        "h-full w-full absolute top-0 left-0 z-20 cursor-default",
        zoomLevel === 1 ? "hidden" : "opacity-0",
      )}
    />
  );
};
