import { InvestigationScorecardDimensionTypeEnum } from "@incident-io/api";
import {
  InvestigationScorecardDimension,
  InvestigationScorecardEvent,
  InvestigationScorecardGrade,
} from "@incident-io/query-api";
import {
  Badge,
  BadgeSize,
  BadgeTheme,
  EmptyState,
  Tooltip,
} from "@incident-ui";
import { Button, ButtonTheme } from "@incident-ui/Button/Button";
import { IconEnum } from "@incident-ui/Icon/Icon";
import { PopoverDropdownMenu } from "@incident-ui/PopoverDropdownMenu/PopoverDropdownMenu";
import { isEmpty, sumBy } from "lodash";
import React, { useMemo, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";
import { getColor } from "src/utils/twConfig";

import {
  BoxPlot,
  BoxPlotValue,
  calculateBoxPlotStats,
} from "../common/BoxPlot";
import {
  EvaluationFilterBar,
  filterGradeEvents,
  formatScorecardDuration,
  GradeEventFilter,
  gradeEventFilterIsEmpty,
  GradeEventFilterValue,
  iconForDimension,
  useEvaluationFilterContext,
} from "../common/EvaluationFilterContext";

interface InvestigationGradeEventsProps {
  grade: InvestigationScorecardGrade;
  showFilterBar: boolean;
  allEvents: InvestigationScorecardEvent[];
  className?: string;
}

export const InvestigationGradeEvents: React.FC<
  InvestigationGradeEventsProps
> = ({ grade, className, showFilterBar, allEvents }) => {
  const { events, dimensions } = grade;
  const {
    gradeFilters,
    setGradeFilters,
    selectedFilterDimension,
    setSelectedFilterDimension,
  } = useEvaluationFilterContext();
  const thisGradeFilters = gradeFilters[grade.name] || [];

  const onFilter = ({
    dimension,
    filterValue,
  }: {
    dimension: InvestigationScorecardDimension;
    filterValue: GradeEventFilterValue;
  }) => {
    let newFilters = [...thisGradeFilters];
    if (gradeEventFilterIsEmpty(filterValue)) {
      newFilters = newFilters.filter((f) => f.dimensionId !== dimension.id);
      if (
        selectedFilterDimension?.dimensionId === dimension.id &&
        selectedFilterDimension?.gradeName === grade.name
      ) {
        setSelectedFilterDimension(undefined);
      }
    } else {
      const existingIndex = newFilters.findIndex(
        (f) => f.dimensionId === dimension.id,
      );
      if (existingIndex !== -1) {
        newFilters[existingIndex] = {
          dimensionId: dimension.id,
          dimensionName: dimension.name,
          dimensionType:
            dimension.type as unknown as InvestigationScorecardDimensionTypeEnum,
          filter_value: filterValue,
        };
      } else {
        newFilters.push({
          dimensionId: dimension.id,
          dimensionName: dimension.name,
          dimensionType:
            dimension.type as unknown as InvestigationScorecardDimensionTypeEnum,
          filter_value: filterValue,
        });
      }
    }
    setGradeFilters({ ...gradeFilters, [grade.name]: newFilters });
  };

  const filteredEvents = filterGradeEvents(events, thisGradeFilters);

  const filteredEventsWithoutSelected = filterGradeEvents(
    allEvents,
    selectedFilterDimension?.gradeName === grade.name
      ? thisGradeFilters.filter(
          (f) => f.dimensionId !== selectedFilterDimension?.dimensionId,
        )
      : thisGradeFilters,
  );

  // Build the color lookup once when events or dimensions change
  const colorLookup = useMemo(
    () => buildColorLookup(allEvents, dimensions),
    [allEvents, dimensions],
  );

  return (
    <div className={tcx("flex flex-col gap-4", className)}>
      {showFilterBar && <EvaluationFilterBar />}
      <div className="flex flex-col gap-2">
        {dimensions.map((dimension) => {
          const isSelected =
            selectedFilterDimension?.dimensionId === dimension.id &&
            selectedFilterDimension?.gradeName === grade.name;
          return (
            <DimensionRow
              key={dimension.id}
              dimension={dimension}
              grade={grade}
              allDimensions={dimensions}
              // If this dimension is 'selected', show all events that match this filter
              events={
                isSelected ? filteredEventsWithoutSelected : filteredEvents
              }
              isSelected={isSelected}
              onDoneFiltering={() => setSelectedFilterDimension(undefined)}
              onFilter={(v) => {
                const isEmpty = gradeEventFilterIsEmpty(v);
                setSelectedFilterDimension(
                  isEmpty
                    ? undefined
                    : {
                        dimensionId: dimension.id,
                        dimensionName: dimension.name,
                        gradeName: grade.name,
                      },
                );
                onFilter({ dimension, filterValue: v });
              }}
              filters={thisGradeFilters}
              colorLookup={colorLookup}
            />
          );
        })}
      </div>
    </div>
  );
};

const DimensionRow = ({
  dimension,
  allDimensions,
  events,
  isSelected,
  onDoneFiltering,
  onFilter,
  filters,
  grade,
  colorLookup,
}: {
  dimension: InvestigationScorecardDimension;
  allDimensions: InvestigationScorecardDimension[];
  events: InvestigationScorecardEvent[];
  onFilter: (filter_value: GradeEventFilterValue) => void;
  filters: GradeEventFilter[];
  isSelected: boolean;
  onDoneFiltering: () => void;
  grade: InvestigationScorecardGrade;
  colorLookup: ColorLookup;
}) => {
  const { setSelectedFilterDimension } = useEvaluationFilterContext();

  const [isExpanded, setIsExpanded] = useState(false);

  const [colorByDimension, setColorByDimension] = useState<
    string | undefined
  >();

  const getNumericValues = (dimensionId: string): BoxPlotValue[] => {
    return events
      .map((event) => {
        const eventVal = event.values[dimensionId];
        if (!eventVal) return null;
        let value: number | undefined;
        if ("int" in eventVal) value = eventVal.int as number;
        if ("number" in eventVal) value = eventVal.number;
        if ("duration_seconds" in eventVal) value = eventVal.duration_seconds;
        if (!value) {
          return null;
        }

        let fill: string | undefined;
        if (colorByDimension) {
          const colorValue = event.values[colorByDimension];
          if (colorValue?.string) {
            fill = colorLookup[colorByDimension][colorValue.string].fill;
          }
        }

        return {
          value,
          label: event.id,
          fill: fill || getHexColour("bg-blue-400"),
        };
      })
      .filter((v): v is BoxPlotValue => v != null);
  };

  const filter = filters.find((f) => f.dimensionId === dimension.id);
  const showDistribution = ["int", "numer", "duration"].includes(
    dimension.type,
  );

  const isFiltered = !gradeEventFilterIsEmpty(filter?.filter_value || {});

  const hasContent = showDistribution
    ? getNumericValues(dimension.id).length > 0
    : Object.keys(getDimensionValues(events, dimension.id)).length > 0;

  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between gap-1">
        <div className="flex items-center gap-1">
          <h4 className="font-medium text-[10px]">{dimension.name}</h4>
          {dimension.description && <Tooltip content={dimension.description} />}
          {isSelected ? (
            <>
              <Badge
                size={BadgeSize.ExtraSmall}
                className="text-[10px]"
                icon={IconEnum.Filter}
                theme={BadgeTheme.Info}
              >
                Filtering
              </Badge>
              <Button
                size={BadgeSize.ExtraSmall}
                className="text-[10px]"
                icon={IconEnum.Tick}
                analyticsTrackingId={null}
                onClick={onDoneFiltering}
                theme={ButtonTheme.Secondary}
              >
                Done
              </Button>
              <Button
                size={BadgeSize.ExtraSmall}
                className="text-[10px]"
                icon={IconEnum.Close}
                analyticsTrackingId={null}
                onClick={() => onFilter({})}
                theme={ButtonTheme.Secondary}
              >
                Clear filter
              </Button>
            </>
          ) : (
            <>
              {isFiltered && (
                <Tooltip content="This dimension is currently filtered">
                  <Button
                    analyticsTrackingId={null}
                    title=""
                    icon={IconEnum.Filter}
                    size={BadgeSize.ExtraSmall}
                    theme={ButtonTheme.Tertiary}
                    iconProps={{ className: "size-3" }}
                    className="size-4"
                    onClick={() =>
                      setSelectedFilterDimension({
                        dimensionId: dimension.id,
                        dimensionName: dimension.name,
                        gradeName: grade.name,
                      })
                    }
                  />
                </Tooltip>
              )}
              {hasContent && (
                <DimensionRowAccessory
                  allDimensions={allDimensions}
                  showDistribution={showDistribution}
                  setColorByDimension={setColorByDimension}
                  colorByDimension={colorByDimension}
                />
              )}
            </>
          )}
        </div>
        <Button
          icon={isExpanded ? IconEnum.Collapse : IconEnum.Expand}
          disabled={!hasContent}
          analyticsTrackingId={null}
          onClick={() => setIsExpanded((prev) => !prev)}
          theme={ButtonTheme.Naked}
          title=""
        />
      </div>
      {!hasContent ? (
        <EmptyState
          className="p-1"
          content={<div className="text-[10px]">No matching events</div>}
        />
      ) : showDistribution ? (
        <BoxPlot
          values={getNumericValues(dimension.id)}
          formatter={
            dimension.type === "duration" ? formatScorecardDuration : undefined
          }
          onSelectionChange={(between) => {
            onFilter(between ? { between } : {});
          }}
          selection={filter?.filter_value.between}
          showSelectionOverlay={isSelected}
          scatterVertically={false}
        />
      ) : (
        <DistributionBar
          values={getDimensionValues(events, dimension.id)}
          onFilter={onFilter}
          colorLookup={colorLookup[dimension.id]}
          filter={filter?.filter_value || {}}
        />
      )}
      {isExpanded &&
        (showDistribution ? (
          <DistributionEventList
            dimension={dimension}
            events={events}
            mode={events.length > 5 ? "percentiles" : "items"}
            formatter={
              dimension.type === "duration"
                ? (v) => formatScorecardDuration(v, 2)
                : (v) => v.toString()
            }
          />
        ) : (
          <DiscreteEventList
            dimension={dimension}
            colorLookup={colorLookup[dimension.id]}
            events={events}
            filter={filter?.filter_value || {}}
          />
        ))}
    </div>
  );
};

const DimensionRowAccessory = ({
  allDimensions,
  showDistribution,
  colorByDimension,
  setColorByDimension,
}: {
  allDimensions: InvestigationScorecardDimension[];
  showDistribution: boolean;
  colorByDimension: string | undefined;
  setColorByDimension: (dimensionId: string | undefined) => void;
}) => {
  if (!showDistribution) {
    return null;
  }

  const colorByDimensionLabel = allDimensions.find(
    (d) => d.id === colorByDimension,
  )?.name;

  return (
    <div className="flex items-center">
      <PopoverDropdownMenu
        elementName="dimension"
        onSelect={(x) => setColorByDimension(x.value)}
        triggerButton={
          <Button
            theme={
              colorByDimension ? ButtonTheme.Primary : ButtonTheme.Secondary
            }
            size={BadgeSize.ExtraSmall}
            analyticsTrackingId={null}
            className="rounded-r-none text-[10px]"
          >
            {colorByDimension
              ? `Coloured by ${colorByDimensionLabel}`
              : "Colour by dimension"}
          </Button>
        }
        options={allDimensions
          .filter((x) => x.type === "string")
          .map((d) => ({
            icon: iconForDimension(
              d.type as unknown as InvestigationScorecardDimensionTypeEnum,
            ),
            label: d.name,
            value: d.id,
          }))}
      />
      {colorByDimension && (
        <Button
          theme={colorByDimension ? ButtonTheme.Primary : ButtonTheme.Secondary}
          title=""
          size={BadgeSize.ExtraSmall}
          analyticsTrackingId={null}
          className="rounded-l-none"
          icon={IconEnum.Close}
          onClick={() => setColorByDimension(undefined)}
        />
      )}
    </div>
  );
};

const getDimensionValues = (
  events: InvestigationScorecardEvent[],
  dimensionId: string,
) => {
  const values: { [key: string]: number } = {};
  events.forEach((event) => {
    const value = event.values[dimensionId];
    if (!value) {
      values["unknown"] = (values["unknown"] || 0) + 1;
      return;
    }

    // Handle different value types
    let key: string;
    if ("string" in value) {
      key = value.string || "unknown";
    } else if ("duration_seconds" in value && value.duration_seconds) {
      key = formatScorecardDuration(value.duration_seconds);
    } else if ("int" in value && value.int) {
      key = value.int.toString();
    } else if ("number" in value && value.number) {
      key = value.number.toString();
    } else {
      key = "unknown";
    }

    values[key] = (values[key] || 0) + 1;
  });
  return values;
};

const DiscreteEventList = ({
  dimension,
  events,
  filter,
  colorLookup,
}: {
  dimension: InvestigationScorecardDimension;
  events: InvestigationScorecardEvent[];
  filter: GradeEventFilterValue;
  colorLookup: ColorLookupEntry;
}) => {
  // Order the events by cardinality
  const values = getDimensionValues(events, dimension.id);
  const sortedValues = Object.entries(values)
    .sort((a, b) => b[1] - a[1])
    .map(([key, count]) => ({ key, count }));

  const topValues = sortedValues.slice(0, 5);
  const rest = sortedValues.slice(5);
  const restSum = sumBy(rest, (val) => val.count);

  const toRender = [...topValues];
  if (restSum > 0) {
    toRender.push({ key: "other", count: restSum });
  }

  const total = sumBy(sortedValues, (val) => val.count);

  return (
    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-2 w-fit text-[10px]">
      {toRender.map(({ key, count }) => {
        const percentage = ((count / total) * 100).toFixed(0);
        const filterApplied = !isEmpty(filter.one_of);
        const isInFilter = filter.one_of?.includes(key) || false;
        const isBackgrounded = filterApplied && !isInFilter;

        return (
          <>
            <div
              className={tcx(
                "rounded-full size-3",
                key === "other"
                  ? "bg-slate-400"
                  : isBackgrounded
                  ? colorLookup[key].background
                  : colorLookup[key].bg,
              )}
            />
            <div className="text-content-primary font-semibold">{key}</div>
            <div className="text-content-secondary text-right">{`${percentage}%(${count})`}</div>
          </>
        );
      })}
    </div>
  );
};

const DistributionEventList = ({
  dimension,
  events,
  formatter,
  mode,
}: {
  dimension: InvestigationScorecardDimension;
  events: InvestigationScorecardEvent[];
  formatter: (value: number) => string;
  mode?: "percentiles" | "items";
}) => {
  const values = getDimensionValues(events, dimension.id);
  const stats = calculateBoxPlotStats(Object.values(values));

  if (mode === "items") {
    return (
      <div className="flex flex-col gap-1 text-[10px]">
        {events.map((event) => {
          const value = event.values[dimension.id];
          const valueScalar =
            value?.int || value?.number || value?.duration_seconds;
          if (!value || !valueScalar) return null;

          return (
            <div
              key={event.id}
              className="grid grid-cols-[auto_1fr_auto] items-center gap-2 min-w-0"
            >
              <div className="rounded-full size-3 bg-blue-400" />
              <span
                className="truncate text-content-primary font-semibold"
                title={event.id}
              >
                {event.id}
              </span>
              <span className="text-content-secondary text-right flex-shrink-0">
                {formatter(valueScalar)}
              </span>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-[auto_1fr_auto] items-center gap-2 w-fit text-[10px]">
      {["min", "p25", "p50", "p75", "max"].map((key) => {
        return (
          <>
            <div className={tcx("rounded-full size-3 bg-blue-400")} />
            <div className="text-content-primary font-semibold">{key}</div>
            <div className="text-content-secondary text-right">
              {formatter(stats[key])}
            </div>
          </>
        );
      })}
    </div>
  );
};

const DistributionBar: React.FC<{
  values: { [key: string]: number };
  filter: GradeEventFilterValue;
  onFilter: (filter: GradeEventFilterValue) => void;
  colorLookup: ColorLookupEntry;
}> = ({ values, filter, onFilter, colorLookup }) => {
  const total = Object.values(values).reduce((sum, count) => sum + count, 0);
  const sortedEntries = Object.entries(values).sort((a, b) => b[1] - a[1]);

  const onToggleLabel = (label: string) => {
    const isInFilter = filter.one_of?.includes(label) || false;
    if (isInFilter) {
      onFilter({ one_of: filter.one_of?.filter((v) => v !== label) });
    } else {
      onFilter({ one_of: [...(filter.one_of || []), label] });
    }
  };

  return (
    <div className="space-y-1">
      <div className="flex h-4 w-full overflow-hidden rounded">
        {sortedEntries.map(([label, count]) => {
          const percentage = (count / total) * 100;
          if (percentage < 1) return null;

          const filterApplied = !isEmpty(filter.one_of);
          const isInFilter = filter.one_of?.includes(label) || false;
          const isBackgrounded = filterApplied && !isInFilter;

          // This should always be there, BUT the data is racy so there's a short
          // moment when there's no matching lookup value. we should be resilient to that!
          const entryColours = colorLookup[label] || {
            bg: "bg-alarmalade-600",
            background: "bg-slate-500",
          };

          return (
            <div
              key={label}
              className={tcx(
                "flex items-center justify-center text-[10px] text-white transition-all cursor-pointer hover:bg-opacity-80",
                isBackgrounded ? entryColours.background : entryColours.bg,
              )}
              onClick={() => onToggleLabel(label)}
              style={{ width: `${percentage}%` }}
            >
              {label} {Math.round(percentage)}%
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Type for our color lookup map
type ColorLookup = {
  [dimensionId: string]: ColorLookupEntry;
};
type ColorLookupEntry = {
  [label: string]: {
    bg: string;
    fill?: string;
    background: string;
  };
};

// Build the color lookup map for a dimension's values
const buildColorLookup = (
  events: InvestigationScorecardEvent[],
  dimensions: InvestigationScorecardDimension[],
): ColorLookup => {
  const lookup: ColorLookup = {};

  dimensions.forEach((dimension) => {
    const values = getDimensionValues(events, dimension.id);
    const sortedLabels = Object.entries(values)
      .sort((a, b) => b[1] - a[1])
      .map(([label]) => label);

    lookup[dimension.id] = {};
    sortedLabels.forEach((label, idx) => {
      let classes: { bg: string; background: string };
      if (idx === 0) {
        classes = {
          bg: "bg-alarmalade-600",
          background: "bg-slate-500",
        };
      } else if (idx === 1) {
        classes = {
          bg: "bg-blue-600",
          background: "bg-slate-400",
        };
      } else if (idx === 2) {
        classes = {
          bg: "bg-green-600",
          background: "bg-slate-300",
        };
      } else if (idx === 3) {
        classes = {
          bg: "bg-orange-400",
          background: "bg-slate-200",
        };
      } else {
        classes =
          idx % 2 === 0
            ? {
                bg: "bg-purple-500",
                background: "bg-slate-200",
              }
            : {
                bg: "bg-pink-500",
                background: "bg-slate-100",
              };
      }

      lookup[dimension.id][label] = {
        ...classes,
        fill: getHexColour(classes.bg),
      };
    });
  });

  return lookup;
};

const getHexColour = (colorClass: string) => {
  const [_, colorName, shade] = colorClass.split("-");

  // Handle regular colors
  return getColor(colorName, shade);
};
