import {
  InvestigationScorecard,
  useAiStaffServiceAiStaffCreateInvestigationScorecard,
  UseAiStaffServiceAiStaffListIncidentGroundTruthsKeyFn,
  useAiStaffServiceAiStaffListInvestigationScorecards,
  UseAiStaffServiceAiStaffListInvestigationScorecardsKeyFn,
} from "@incident-io/query-api";
import {
  BadgeSize,
  Button,
  ButtonTheme,
  ContentBox,
  EmptyState,
  IconEnum,
  LocalRelativeDateTime,
  ToastTheme,
} from "@incident-ui";
import { useToast } from "@incident-ui/Toast/ToastProvider";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { EvaluationFilterContextProvider } from "../common/EvaluationFilterContext";
import { InvestigationScorecardGrades } from "./InvestigationScorecardGrades";
import { InvestigationScorecardTrace } from "./InvestigationScorecardTrace";

export const InvestigationScorecardSectionController = ({
  investigationId,
  incidentId,
  className,
}: {
  investigationId: string;
  incidentId: string;
  className?: string;
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data, isLoading } =
    useAiStaffServiceAiStaffListInvestigationScorecards({
      investigationId: investigationId || "",
    });
  const scorecard = data?.scorecards[0];

  const { mutate: generateScorecard, isPending: isCreating } =
    useAiStaffServiceAiStaffCreateInvestigationScorecard({
      onSettled() {
        // We must fetch the latest scorecards
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffListInvestigationScorecardsKeyFn({
            investigationId: investigationId || "",
          }),
        });
        // And we need the latest ground truths, as this may have generated them
        queryClient.invalidateQueries({
          queryKey: UseAiStaffServiceAiStaffListIncidentGroundTruthsKeyFn({
            incidentId: incidentId || "",
          }),
        });
      },
      onError: () => {
        toast({
          title: "Failed to generate scorecard",
          theme: ToastTheme.Error,
        });
      },
    });

  const handle = () => {
    generateScorecard({
      requestBody: {
        investigation_id: investigationId || "",
      },
    });
  };

  return (
    <InvestigationScorecardSection
      scorecard={scorecard}
      onGenerateScorecard={handle}
      isGenerating={isCreating || isLoading}
      className={className}
    />
  );
};

export type InvestigationScorecardSectionProps = {
  scorecard?: InvestigationScorecard;
  onGenerateScorecard: () => void;
  isGenerating?: boolean;
  className?: string;
};

export const InvestigationScorecardSection = ({
  scorecard,
  onGenerateScorecard,
  isGenerating,
  className,
}: InvestigationScorecardSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!scorecard || scorecard.grades.length === 0) {
    const attrs = scorecard
      ? {
          title: "Last scorecard included no grades",
          content:
            "Generate a new scorecard to evaluate the investigation's performance with the latest graders",
        }
      : {
          title: "No scorecard available",
          content:
            "Generate a scorecard to evaluate the investigation's performance",
        };

    return (
      <div className={className}>
        <ContentBox>
          <EmptyState
            {...attrs}
            icon={IconEnum.Chart}
            cta={
              <Button
                onClick={onGenerateScorecard}
                disabled={isGenerating}
                analyticsTrackingId={null}
                icon={IconEnum.Chart}
                loading={isGenerating}
              >
                {isGenerating
                  ? "Generating scorecard..."
                  : "Generate scorecard"}
              </Button>
            }
          />
        </ContentBox>
      </div>
    );
  }

  return (
    <div className={className}>
      <ContentBox>
        <div className="flex justify-between items-center p-4 border-b border-stroke">
          <div className="text-sm text-content-secondary">
            Graded{" "}
            <LocalRelativeDateTime date={new Date(scorecard.graded_at)} />
          </div>

          <div className="flex items-center gap-2">
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              analyticsTrackingId={null}
              icon={IconEnum.BarChart}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
            >
              {isExpanded ? "Hide trace" : "Show trace"}
            </Button>
            <Button
              onClick={onGenerateScorecard}
              disabled={isGenerating}
              analyticsTrackingId={null}
              icon={IconEnum.Chart}
              loading={isGenerating}
              theme={ButtonTheme.Secondary}
              size={BadgeSize.Small}
            >
              {isGenerating ? "Generating..." : "Refresh"}
            </Button>
          </div>
        </div>
        <EvaluationFilterContextProvider enableSelectingMetrics={false}>
          <InvestigationScorecardGrades
            allEvents={scorecard.grades.flatMap((g) => g.events)}
            grades={scorecard.grades}
            warnings={scorecard.warnings}
            showFilterBar={true}
          />
        </EvaluationFilterContextProvider>

        {isExpanded && (
          <div className="p-4">
            <InvestigationScorecardTrace scorecard={scorecard} />{" "}
          </div>
        )}
      </ContentBox>
    </div>
  );
};
