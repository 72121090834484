import { Route, Routes } from "react-router";

import { AIRequestShowDrawer } from "./AIRequestShowDrawer";
import { AIRequestsListPage } from "./AIRequestsListPage";

export const AIRequestsRoute = () => {
  return (
    <Routes>
      <Route path="*" element={<AIRequestsListPage />}>
        <Route path=":id" element={<AIRequestShowDrawer />} />
      </Route>
    </Routes>
  );
};
