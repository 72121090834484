export type SerializableObject = {
  [key: string]: SerializableValue;
};

export type SerializableValue = string | number | Date | null | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const objsToCSV = (objs: SerializableObject[]): SerializableValue[][] => {
  const output: SerializableValue[][] = [];

  // assume the first object has all our keys
  const keys = Object.keys(objs[0]);

  output.push(keys);

  objs.forEach((obj) => {
    const row = keys.map((key) => obj[key]);
    output.push(row);
  });

  return output;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processRow = (row: SerializableValue[], separator: string): string => {
  let finalVal = "";
  row.forEach((cell, i) => {
    let innerValue = cell == null ? "" : cell.toString();
    if (cell instanceof Date) {
      innerValue = cell.toISOString();
    }
    // Check if we need to quote this field - is there a quote, newline, or the separator?
    const needsQuoting =
      innerValue.includes(separator) ||
      innerValue.includes('"') ||
      innerValue.includes("\n");
    // Escape any existing quotes by doubling them
    let result = needsQuoting ? innerValue.replace(/"/g, '""') : innerValue;
    // Wrap in quotes if needed
    if (needsQuoting) {
      result = '"' + result + '"';
    }
    if (i > 0) {
      finalVal += separator;
    }
    finalVal += result;
  });

  return finalVal + "\n";
};

// adapted from https://stackoverflow.com/a/24922761
// takes an array of objects, converts to a CSV and downloads it.
export const downloadObjsAsCsv = (
  filename: string,
  objs: SerializableObject[],
  separator = ",",
): void => {
  const rows = objsToCSV(objs);
  let csvFile = "";
  rows.forEach((row) => {
    csvFile += processRow(row, separator);
  });

  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  // @ts-expect-error - TS seems to have removed its IE 10 type definitions
  if (navigator.msSaveBlob) {
    // IE 10+

    // @ts-expect-error - TS seems to have removed its IE 10 type definitions
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
