/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConditionGroupPayload,
    ConditionGroupPayloadFromJSON,
    ConditionGroupPayloadFromJSONTyped,
    ConditionGroupPayloadToJSON,
} from './ConditionGroupPayload';
import {
    CustomFieldFilterByOptions,
    CustomFieldFilterByOptionsFromJSON,
    CustomFieldFilterByOptionsFromJSONTyped,
    CustomFieldFilterByOptionsToJSON,
} from './CustomFieldFilterByOptions';
import {
    CustomFieldOptionPayload,
    CustomFieldOptionPayloadFromJSON,
    CustomFieldOptionPayloadFromJSONTyped,
    CustomFieldOptionPayloadToJSON,
} from './CustomFieldOptionPayload';
import {
    ExpressionPayload,
    ExpressionPayloadFromJSON,
    ExpressionPayloadFromJSONTyped,
    ExpressionPayloadToJSON,
} from './ExpressionPayload';

/**
 * 
 * @export
 * @interface CustomFieldsUpdateRequestBody
 */
export interface CustomFieldsUpdateRequestBody {
    /**
     * Whether to backfill derived values for this field on existing incidents
     * @type {boolean}
     * @memberof CustomFieldsUpdateRequestBody
     */
    backfill_derived_values?: boolean;
    /**
     * If yes, a custom field cannot be unset once it has been set.
     * @type {boolean}
     * @memberof CustomFieldsUpdateRequestBody
     */
    cannot_be_unset: boolean;
    /**
     * What conditions on the incident should be true for this field to be available?
     * @type {Array<ConditionGroupPayload>}
     * @memberof CustomFieldsUpdateRequestBody
     */
    condition_groups: Array<ConditionGroupPayload>;
    /**
     * Description of the custom field
     * @type {string}
     * @memberof CustomFieldsUpdateRequestBody
     */
    description: string;
    /**
     * For option fields, enables creating of new options during an incident, such as from `/incident fields` or the Incident Homepage.
     * @type {boolean}
     * @memberof CustomFieldsUpdateRequestBody
     */
    dynamic_options?: boolean;
    /**
     * 
     * @type {ExpressionPayload}
     * @memberof CustomFieldsUpdateRequestBody
     */
    engine_expression?: ExpressionPayload;
    /**
     * How the value of this field is set
     * @type {string}
     * @memberof CustomFieldsUpdateRequestBody
     */
    field_mode: CustomFieldsUpdateRequestBodyFieldModeEnum;
    /**
     * 
     * @type {CustomFieldFilterByOptions}
     * @memberof CustomFieldsUpdateRequestBody
     */
    filter_by?: CustomFieldFilterByOptions;
    /**
     * For catalog fields, the ID of the attribute used to group catalog entries (if applicable)
     * @type {string}
     * @memberof CustomFieldsUpdateRequestBody
     */
    group_by_catalog_attribute_id?: string;
    /**
     * Which catalog attribute provides helptext for the options
     * @type {string}
     * @memberof CustomFieldsUpdateRequestBody
     */
    helptext_catalog_attribute_id?: string;
    /**
     * Human readable name for the custom field
     * @type {string}
     * @memberof CustomFieldsUpdateRequestBody
     */
    name: string;
    /**
     * What options are available for this custom field
     * @type {Array<CustomFieldOptionPayload>}
     * @memberof CustomFieldsUpdateRequestBody
     */
    options?: Array<CustomFieldOptionPayload>;
}

/**
* @export
* @enum {string}
*/
export enum CustomFieldsUpdateRequestBodyFieldModeEnum {
    Manual = 'manual',
    SensibleDefault = 'sensible_default',
    FullyDerived = 'fully_derived'
}

export function CustomFieldsUpdateRequestBodyFromJSON(json: any): CustomFieldsUpdateRequestBody {
    return CustomFieldsUpdateRequestBodyFromJSONTyped(json, false);
}

export function CustomFieldsUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldsUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backfill_derived_values': !exists(json, 'backfill_derived_values') ? undefined : json['backfill_derived_values'],
        'cannot_be_unset': json['cannot_be_unset'],
        'condition_groups': ((json['condition_groups'] as Array<any>).map(ConditionGroupPayloadFromJSON)),
        'description': json['description'],
        'dynamic_options': !exists(json, 'dynamic_options') ? undefined : json['dynamic_options'],
        'engine_expression': !exists(json, 'engine_expression') ? undefined : ExpressionPayloadFromJSON(json['engine_expression']),
        'field_mode': json['field_mode'],
        'filter_by': !exists(json, 'filter_by') ? undefined : CustomFieldFilterByOptionsFromJSON(json['filter_by']),
        'group_by_catalog_attribute_id': !exists(json, 'group_by_catalog_attribute_id') ? undefined : json['group_by_catalog_attribute_id'],
        'helptext_catalog_attribute_id': !exists(json, 'helptext_catalog_attribute_id') ? undefined : json['helptext_catalog_attribute_id'],
        'name': json['name'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(CustomFieldOptionPayloadFromJSON)),
    };
}

export function CustomFieldsUpdateRequestBodyToJSON(value?: CustomFieldsUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backfill_derived_values': value.backfill_derived_values,
        'cannot_be_unset': value.cannot_be_unset,
        'condition_groups': ((value.condition_groups as Array<any>).map(ConditionGroupPayloadToJSON)),
        'description': value.description,
        'dynamic_options': value.dynamic_options,
        'engine_expression': ExpressionPayloadToJSON(value.engine_expression),
        'field_mode': value.field_mode,
        'filter_by': CustomFieldFilterByOptionsToJSON(value.filter_by),
        'group_by_catalog_attribute_id': value.group_by_catalog_attribute_id,
        'helptext_catalog_attribute_id': value.helptext_catalog_attribute_id,
        'name': value.name,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(CustomFieldOptionPayloadToJSON)),
    };
}

