import {
  conditionGroupsToConditions,
  conditionsToGroupPayload,
} from "@incident-shared/engine/conditions/marshall";
import { groupBy, min, sortBy } from "lodash";
import {
  CustomField,
  CustomFieldFieldModeEnum,
  CustomFieldFieldTypeEnum,
  CustomFieldOption,
  CustomFieldOptionPayload,
  CustomFieldsCreateRequestBody,
  CustomFieldsCreateRequestBodyFieldModeEnum,
  CustomFieldsCreateRequestBodyFieldTypeEnum,
  CustomFieldsUpdateRequestBody,
  CustomFieldsUpdateRequestBodyFieldModeEnum,
} from "src/contexts/ClientContext";
import { v4 as uuidv4 } from "uuid";

import { expressionToPayload } from "../../../@shared/engine/expressions/expressionToPayload";
import { CustomFieldFormData } from "./CustomFieldCreateEditDrawer";
import { ManagementType } from "./types";

const UNGROUPED_GROUP_ID = "undefined";

export const transformFormStateToUpdateRequestBody = (
  existingField: CustomField,
  data: CustomFieldFormData,
): CustomFieldsUpdateRequestBody => {
  const isSelectField =
    data.field_type === CustomFieldFieldTypeEnum.SingleSelect ||
    CustomFieldFieldTypeEnum.MultiSelect;

  return {
    ...data,
    options:
      isSelectField && data.management !== ManagementType.Catalog
        ? optionsFormDataToPayload(data.options)
        : [],
    dynamic_options:
      isSelectField && data.management === ManagementType.Dynamic,
    condition_groups: conditionsToGroupPayload(
      // react-hook-form refuses to track data where there is no form field
      // watching it, so we fall back to the existing conditions here, without
      // edits
      data.conditions ||
        conditionGroupsToConditions(existingField?.condition_groups) ||
        [],
    ),
    field_mode:
      data.field_mode as unknown as CustomFieldsUpdateRequestBodyFieldModeEnum,
    engine_expression:
      data.engine_expression &&
      data.field_mode !== CustomFieldFieldModeEnum.Manual
        ? expressionToPayload(data.engine_expression)
        : undefined,
    backfill_derived_values: data.backfill_derived_values === "true",
    cannot_be_unset: isSelectField ? data.cannot_be_unset : false,
    filter_by: data.filtering_by_composite_id?.includes("/")
      ? {
          custom_field_id: data.filtering_by_composite_id.split("/")[0],
          catalog_attribute_id: data.filtering_by_composite_id.split("/")[1],
        }
      : undefined,
  };
};

export const transformFormStateToCreateRequestBody = (
  data: CustomFieldFormData,
): CustomFieldsCreateRequestBody => {
  const fieldType =
    data.field_type as unknown as CustomFieldsCreateRequestBodyFieldTypeEnum;
  const isSelectField = [
    CustomFieldsCreateRequestBodyFieldTypeEnum.SingleSelect,
    CustomFieldsCreateRequestBodyFieldTypeEnum.MultiSelect,
  ].includes(fieldType);

  return {
    name: data.name,
    description: data.description,
    field_type: fieldType,
    dynamic_options:
      isSelectField && data.management === ManagementType.Dynamic,
    catalog_type_id:
      data.management === ManagementType.Catalog
        ? data.catalog_type_id
        : undefined,
    group_by_catalog_attribute_id:
      data.management === ManagementType.Catalog
        ? data.group_by_catalog_attribute_id
        : undefined,

    helptext_catalog_attribute_id:
      data.management === ManagementType.Catalog
        ? data.helptext_catalog_attribute_id
        : undefined,

    options:
      isSelectField && data.management !== ManagementType.Catalog
        ? optionsFormDataToPayload(data.options)
        : [],
    // react-hook-form refuses to track data where there is no form field
    // watching it, so we fall back to the existing conditions here, without
    // edits
    condition_groups: conditionsToGroupPayload(data.conditions || []),
    field_mode:
      data.field_mode as unknown as CustomFieldsCreateRequestBodyFieldModeEnum,
    engine_expression:
      data.engine_expression &&
      data.field_mode !== CustomFieldFieldModeEnum.Manual
        ? expressionToPayload(data.engine_expression)
        : undefined,
    backfill_derived_values: data.backfill_derived_values === "true",
    cannot_be_unset: isSelectField ? data.cannot_be_unset : false,
    filter_by: data.filtering_by_composite_id?.includes("/")
      ? {
          custom_field_id: data.filtering_by_composite_id.split("/")[0],
          catalog_attribute_id: data.filtering_by_composite_id.split("/")[1],
        }
      : undefined,
  };
};

export type CustomFieldOptionOrGroup = {
  option?: CustomFieldOptionData;
  group?: { id?: string; label: string };
  itemType: "option" | "group";
  key: string;
};

export type CustomFieldOptionData = {
  id?: string;
  value: string;
  sort_key: number;
};

export const optionsResponseBodyToFormData = (
  options: CustomFieldOption[],
): CustomFieldOptionOrGroup[] => {
  const grouped = groupBy(options, (o) => o.group?.id || UNGROUPED_GROUP_ID);
  let groups = Object.entries(grouped).map(([id, group]) => ({
    id,
    label: group[0].group?.label,
  }));

  if (groups.length === 1 && groups[0].label === "") {
    return options.map(optionResponseBodyToFormData);
  }

  // order the groups by the minimum sort key inside them
  groups = sortBy(groups, (g) => {
    if (g.id === UNGROUPED_GROUP_ID) {
      // Put the empty group at the start
      return "000000000";
    }
    return min(grouped[g.id].map((o) => o.sort_key));
  });

  return groups.flatMap((group): CustomFieldOptionOrGroup[] => {
    const mappedOptions = grouped[group.id].map(optionResponseBodyToFormData);
    if (group.id === UNGROUPED_GROUP_ID) {
      return mappedOptions;
    }

    return [
      {
        itemType: "group",
        group: { id: group.id, label: group.label || "" },
        key: uuidv4(),
      },
      ...mappedOptions,
    ];
  });
};

const optionResponseBodyToFormData = (
  option: CustomFieldOptionData,
): CustomFieldOptionOrGroup => {
  return {
    itemType: "option",
    option,
    key: uuidv4(),
  };
};

const optionsFormDataToPayload = (
  optionsOrGroups: CustomFieldOptionOrGroup[],
  customFieldID?: string,
): CustomFieldOptionPayload[] => {
  const grouped = groupOptions(optionsOrGroups);

  let sortKey = 0;
  return Object.values(grouped).flatMap(({ group, options }) => {
    return options
      .filter((opt) => !!opt.value)
      .map((opt) => {
        sortKey = sortKey + 10;
        const groupPayload =
          group && group.label
            ? {
                id: group.id,
                label: group.label,
              }
            : undefined;
        return {
          ...opt,
          custom_field_id: customFieldID || "",
          group: groupPayload,
          sort_key: sortKey,
        };
      });
  });
};

// sortOptionsOrGroups takes our list of options/groups and sorts all the options
// within each group (but leaves the options in the groups that they're in). We do
// this by grouping them all, and ungrouping them, relying on the fact that JS will
// output the keys of the map in the order that they went in (unlike Go)
export const sortOptionsOrGroups = (
  optionsOrGroups: CustomFieldOptionOrGroup[],
): CustomFieldOptionOrGroup[] => {
  const grouped = groupOptions(optionsOrGroups);

  return Object.values(grouped).flatMap(
    ({ group, options }): CustomFieldOptionOrGroup[] => {
      // Sort the options
      const sortedOptions: CustomFieldOptionOrGroup[] = sortBy(
        options,
        (o) => o.value,
      ).map((option) => ({ itemType: "option", option, key: uuidv4() }));

      if (!group || group.label === "") {
        return sortedOptions;
      }
      return [
        {
          itemType: "group",
          group,
          key: uuidv4(),
        },
        ...sortedOptions,
      ];
    },
  );
};

function groupOptions(optionsOrGroups: CustomFieldOptionOrGroup[]): {
  [key: string]: {
    options: CustomFieldOptionData[];
    group?: { id?: string; label: string };
  };
} {
  const grouped: {
    [key: string]: {
      options: CustomFieldOptionData[];
      group?: { id?: string; label: string };
    };
  } = {};

  let currentGroup: CustomFieldOptionOrGroup = {
    key: uuidv4(),
    group: { label: "" },
    itemType: "group",
  };
  grouped[currentGroup.key] = {
    options: [],
  };

  optionsOrGroups.forEach((optOrGroup) => {
    switch (optOrGroup.itemType) {
      case "group":
        currentGroup = optOrGroup;
        if (!optOrGroup.group) {
          throw new Error(
            "Unreachable: expected group to exist for group item type",
          );
        }
        grouped[currentGroup.key] = {
          options: [],
          group: optOrGroup.group,
        };
        break;
      case "option":
        grouped[currentGroup.key].options.push(
          optOrGroup.option as CustomFieldOptionData,
        );
    }
  });
  return grouped;
}
