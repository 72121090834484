/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizeConfig,
    LocalizeConfigFromJSON,
    LocalizeConfigFromJSONTyped,
    LocalizeConfigToJSON,
} from './LocalizeConfig';
import {
    StatusPageAffectedComponent,
    StatusPageAffectedComponentFromJSON,
    StatusPageAffectedComponentFromJSONTyped,
    StatusPageAffectedComponentToJSON,
} from './StatusPageAffectedComponent';
import {
    StatusPageComponent,
    StatusPageComponentFromJSON,
    StatusPageComponentFromJSONTyped,
    StatusPageComponentToJSON,
} from './StatusPageComponent';
import {
    StatusPageContentIncident,
    StatusPageContentIncidentFromJSON,
    StatusPageContentIncidentFromJSONTyped,
    StatusPageContentIncidentToJSON,
} from './StatusPageContentIncident';
import {
    StatusPageContentSubPagesParentSummary,
    StatusPageContentSubPagesParentSummaryFromJSON,
    StatusPageContentSubPagesParentSummaryFromJSONTyped,
    StatusPageContentSubPagesParentSummaryToJSON,
} from './StatusPageContentSubPagesParentSummary';
import {
    StatusPageStructure,
    StatusPageStructureFromJSON,
    StatusPageStructureFromJSONTyped,
    StatusPageStructureToJSON,
} from './StatusPageStructure';
import {
    TextNode,
    TextNodeFromJSON,
    TextNodeFromJSONTyped,
    TextNodeToJSON,
} from './TextNode';
import {
    UnbabelConfig,
    UnbabelConfigFromJSON,
    UnbabelConfigFromJSONTyped,
    UnbabelConfigToJSON,
} from './UnbabelConfig';

/**
 * 
 * @export
 * @interface StatusPageContentSummary
 */
export interface StatusPageContentSummary {
    /**
     * Which components are impacted, rolled-up to highest severity
     * @type {Array<StatusPageAffectedComponent>}
     * @memberof StatusPageContentSummary
     */
    affected_components: Array<StatusPageAffectedComponent>;
    /**
     * Whether this status page should be indexed by search engines
     * @type {boolean}
     * @memberof StatusPageContentSummary
     */
    allow_search_engine_indexing: boolean;
    /**
     * All the available components that can be shown on this status page
     * @type {Array<StatusPageComponent>}
     * @memberof StatusPageContentSummary
     */
    components: Array<StatusPageComponent>;
    /**
     * How far back should we let someone scroll in the uptime charts and the incident calendar?
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    data_available_since: string;
    /**
     * Whether to use Calendar or List view
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    date_view: StatusPageContentSummaryDateViewEnum;
    /**
     * How should we display uptime against this page's components?
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    display_uptime_mode: StatusPageContentSummaryDisplayUptimeModeEnum;
    /**
     * Whether this public page exposes a JSON endpoint with its current status
     * @type {boolean}
     * @memberof StatusPageContentSummary
     */
    expose_status_summary_api: boolean;
    /**
     * The publicly-accessible URL of the favicon for this status page
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    favicon_url?: string;
    /**
     * The unique tag for this page from the organisation's google analytics account
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    google_analytics_tag?: string;
    /**
     * The ID of the status page
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    id: string;
    /**
     * 
     * @type {TextNode}
     * @memberof StatusPageContentSummary
     */
    intro_text?: TextNode;
    /**
     * Which locale should this page be rendered in?
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    locale: StatusPageContentSummaryLocaleEnum;
    /**
     * 
     * @type {LocalizeConfig}
     * @memberof StatusPageContentSummary
     */
    localize_config?: LocalizeConfig;
    /**
     * 
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    logo_url?: string;
    /**
     * The name of the status page
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    name: string;
    /**
     * Any on-going incidents
     * @type {Array<StatusPageContentIncident>}
     * @memberof StatusPageContentSummary
     */
    ongoing_incidents: Array<StatusPageContentIncident>;
    /**
     * The type of page this is
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    page_type: StatusPageContentSummaryPageTypeEnum;
    /**
     * Whether we should disable tracking of page views for this status page
     * @type {boolean}
     * @memberof StatusPageContentSummary
     */
    page_view_tracking_disabled: boolean;
    /**
     * 
     * @type {StatusPageContentSubPagesParentSummary}
     * @memberof StatusPageContentSummary
     */
    parent?: StatusPageContentSubPagesParentSummary;
    /**
     * The publicly accessible URL of the privacy policy for this status page
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    privacy_policy_url?: string;
    /**
     * The primary URL where this status page can be accessed.
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    public_url: string;
    /**
     * Any scheduled maintenance windows
     * @type {Array<StatusPageContentIncident>}
     * @memberof StatusPageContentSummary
     */
    scheduled_maintenances: Array<StatusPageContentIncident>;
    /**
     * 
     * @type {StatusPageStructure}
     * @memberof StatusPageContentSummary
     */
    structure: StatusPageStructure;
    /**
     * The subpath of the status page for our standard domain
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    subpath: string;
    /**
     * Whether subscriptions have been entirely disabled for this status page
     * @type {boolean}
     * @memberof StatusPageContentSummary
     */
    subscriptions_disabled: boolean;
    /**
     * The text to be displayed on the button redirecting users to a support channel
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    support_label: string;
    /**
     * A URL where users can report issues to the company that runs this status page
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    support_url?: string;
    /**
     * The publicly accessible URL of the terms of service for this status page
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    terms_of_service_url?: string;
    /**
     * Whether to use Light mode or Dark mode
     * @type {string}
     * @memberof StatusPageContentSummary
     */
    theme: StatusPageContentSummaryThemeEnum;
    /**
     * 
     * @type {UnbabelConfig}
     * @memberof StatusPageContentSummary
     */
    unbabel_config?: UnbabelConfig;
}

/**
* @export
* @enum {string}
*/
export enum StatusPageContentSummaryDateViewEnum {
    Calendar = 'calendar',
    List = 'list'
}/**
* @export
* @enum {string}
*/
export enum StatusPageContentSummaryDisplayUptimeModeEnum {
    ChartAndPercentage = 'chart_and_percentage',
    ChartOnly = 'chart_only',
    Nothing = 'nothing'
}/**
* @export
* @enum {string}
*/
export enum StatusPageContentSummaryLocaleEnum {
    EnUs = 'en-US',
    PtBr = 'pt-BR',
    JaJp = 'ja-JP'
}/**
* @export
* @enum {string}
*/
export enum StatusPageContentSummaryPageTypeEnum {
    Standalone = 'standalone',
    Parent = 'parent',
    Customer = 'customer'
}/**
* @export
* @enum {string}
*/
export enum StatusPageContentSummaryThemeEnum {
    Light = 'light',
    Dark = 'dark'
}

export function StatusPageContentSummaryFromJSON(json: any): StatusPageContentSummary {
    return StatusPageContentSummaryFromJSONTyped(json, false);
}

export function StatusPageContentSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusPageContentSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'affected_components': ((json['affected_components'] as Array<any>).map(StatusPageAffectedComponentFromJSON)),
        'allow_search_engine_indexing': json['allow_search_engine_indexing'],
        'components': ((json['components'] as Array<any>).map(StatusPageComponentFromJSON)),
        'data_available_since': json['data_available_since'],
        'date_view': json['date_view'],
        'display_uptime_mode': json['display_uptime_mode'],
        'expose_status_summary_api': json['expose_status_summary_api'],
        'favicon_url': !exists(json, 'favicon_url') ? undefined : json['favicon_url'],
        'google_analytics_tag': !exists(json, 'google_analytics_tag') ? undefined : json['google_analytics_tag'],
        'id': json['id'],
        'intro_text': !exists(json, 'intro_text') ? undefined : TextNodeFromJSON(json['intro_text']),
        'locale': json['locale'],
        'localize_config': !exists(json, 'localize_config') ? undefined : LocalizeConfigFromJSON(json['localize_config']),
        'logo_url': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'name': json['name'],
        'ongoing_incidents': ((json['ongoing_incidents'] as Array<any>).map(StatusPageContentIncidentFromJSON)),
        'page_type': json['page_type'],
        'page_view_tracking_disabled': json['page_view_tracking_disabled'],
        'parent': !exists(json, 'parent') ? undefined : StatusPageContentSubPagesParentSummaryFromJSON(json['parent']),
        'privacy_policy_url': !exists(json, 'privacy_policy_url') ? undefined : json['privacy_policy_url'],
        'public_url': json['public_url'],
        'scheduled_maintenances': ((json['scheduled_maintenances'] as Array<any>).map(StatusPageContentIncidentFromJSON)),
        'structure': StatusPageStructureFromJSON(json['structure']),
        'subpath': json['subpath'],
        'subscriptions_disabled': json['subscriptions_disabled'],
        'support_label': json['support_label'],
        'support_url': !exists(json, 'support_url') ? undefined : json['support_url'],
        'terms_of_service_url': !exists(json, 'terms_of_service_url') ? undefined : json['terms_of_service_url'],
        'theme': json['theme'],
        'unbabel_config': !exists(json, 'unbabel_config') ? undefined : UnbabelConfigFromJSON(json['unbabel_config']),
    };
}

export function StatusPageContentSummaryToJSON(value?: StatusPageContentSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'affected_components': ((value.affected_components as Array<any>).map(StatusPageAffectedComponentToJSON)),
        'allow_search_engine_indexing': value.allow_search_engine_indexing,
        'components': ((value.components as Array<any>).map(StatusPageComponentToJSON)),
        'data_available_since': value.data_available_since,
        'date_view': value.date_view,
        'display_uptime_mode': value.display_uptime_mode,
        'expose_status_summary_api': value.expose_status_summary_api,
        'favicon_url': value.favicon_url,
        'google_analytics_tag': value.google_analytics_tag,
        'id': value.id,
        'intro_text': TextNodeToJSON(value.intro_text),
        'locale': value.locale,
        'localize_config': LocalizeConfigToJSON(value.localize_config),
        'logo_url': value.logo_url,
        'name': value.name,
        'ongoing_incidents': ((value.ongoing_incidents as Array<any>).map(StatusPageContentIncidentToJSON)),
        'page_type': value.page_type,
        'page_view_tracking_disabled': value.page_view_tracking_disabled,
        'parent': StatusPageContentSubPagesParentSummaryToJSON(value.parent),
        'privacy_policy_url': value.privacy_policy_url,
        'public_url': value.public_url,
        'scheduled_maintenances': ((value.scheduled_maintenances as Array<any>).map(StatusPageContentIncidentToJSON)),
        'structure': StatusPageStructureToJSON(value.structure),
        'subpath': value.subpath,
        'subscriptions_disabled': value.subscriptions_disabled,
        'support_label': value.support_label,
        'support_url': value.support_url,
        'terms_of_service_url': value.terms_of_service_url,
        'theme': value.theme,
        'unbabel_config': UnbabelConfigToJSON(value.unbabel_config),
    };
}

