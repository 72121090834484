import {
  EscalationPathTargetTypeEnum,
  UserOptionStateEnum,
} from "@incident-io/api";
import {
  useCallRoutingServiceCallRoutingCreate,
  UseCallRoutingServiceCallRoutingListKeyFn,
} from "@incident-io/query-api";
import { Form } from "@incident-shared/forms";
import { FormHelpText } from "@incident-shared/forms/v2/helpers";
import { InputV2 } from "@incident-shared/forms/v2/inputs/InputV2";
import { StaticSingleSelectV2 } from "@incident-shared/forms/v2/inputs/StaticSelectV2";
import { TextareaV2 } from "@incident-shared/forms/v2/inputs/TextareaV2";
import { useOrgAwareNavigate } from "@incident-shared/org-aware";
import { ColorPaletteEnum } from "@incident-shared/utils/ColorPalettes";
import { Button, ButtonTheme, IconEnum } from "@incident-ui";
import {
  Drawer,
  DrawerBody,
  DrawerContents,
  DrawerFooter,
  DrawerTitle,
} from "@incident-ui/Drawer/Drawer";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { setFormErrors } from "src/contexts/TanstackQueryProvider";

import { useIdentity } from "../../contexts/IdentityContext";
import { EscalationPathUserTargetFormData } from "../escalation-paths/common/types";
import {
  OnCallPromotionConfirmationModal,
  PromotionState,
} from "../legacy/on-call/common/OnCallPromotionConfirmationModal";
import { isOnCallUser } from "../settings/users/users/utils";

// We do this rather than importing the type, because the actual type
// has a gross number at the end, which can change if you rename
// the goa design files :ick:
type CreateRouteRequestBody = Parameters<
  ReturnType<typeof useCallRoutingServiceCallRoutingCreate>["mutate"]
>[0]["requestBody"];

export const CallRoutingCreateRoute = () => {
  const navigate = useOrgAwareNavigate();

  const onClose = () => navigate("/on-call/call-routes");

  return <CallRouteCreateDrawer onClose={onClose} />;
};

const CallRouteCreateDrawer = ({ onClose }: { onClose: () => void }) => {
  const formMethods = useForm<CreateRouteRequestBody>({
    defaultValues: {
      name: "",
      country_code: "1",
      message: "",
    },
  });
  const [promotionState, setPromotionState] =
    useState<PromotionState<CreateRouteRequestBody>>(null);
  const { identity, fetchIdentity } = useIdentity();

  // Try to save, or fallback to the on-call promotion modal.
  const createOrPromoteUsers = async (formData: CreateRouteRequestBody) => {
    const valid = await formMethods.trigger();
    if (!valid) {
      return;
    }

    // This is a bit gross: currently, we don't have a way to return a nice error from
    // the backend to tell you that a user needs their seat upgraded. So instead, we
    // check this on both the frontend and backend separately. If we change the
    // default behaviour of who gets added to the call route (now the current user),
    // we will need to update the logic both here and on the backend in
    // `app/callrouting/create.go` (`callrouting.Create`).
    const usersToPromote: EscalationPathUserTargetFormData[] = isOnCallUser(
      identity.user_state,
    )
      ? []
      : [
          {
            type: EscalationPathTargetTypeEnum.User,
            state: identity.user_state as unknown as UserOptionStateEnum,
            value: identity.user_id,
            label: identity.user_name,
          },
        ];

    if (usersToPromote.length > 0) {
      setPromotionState({
        formData,
        usersToPromote,
      });
      return;
    }

    await mutate({ requestBody: formData });
    await fetchIdentity();
  };

  const client = useQueryClient();
  const navigate = useOrgAwareNavigate();
  const { mutate, isPending, error } = useCallRoutingServiceCallRoutingCreate({
    onSettled() {
      client.invalidateQueries({
        queryKey: UseCallRoutingServiceCallRoutingListKeyFn(),
      });
    },
    onSuccess: (res) => {
      // Navigate to the edit drawer, to set up the path
      navigate(`/on-call/call-routes/${res.call_route.id}`);
    },
    onError: setFormErrors(formMethods),
  });
  const genericError = (error as Error | null)?.message;

  return (
    <Drawer onClose={onClose} width={"medium"}>
      <DrawerContents>
        <DrawerTitle
          title={"Request phone number"}
          icon={IconEnum.IncomingPhoneCall}
          color={ColorPaletteEnum.Slate}
          onClose={onClose}
        />
        <Form.Root
          onSubmit={(data) => createOrPromoteUsers(data)}
          formMethods={formMethods}
          genericError={genericError}
          submitOnCmdEnter
          fullHeight
          innerClassName="flex flex-col space-y-6"
        >
          <DrawerBody className="overflow-y-auto">
            <FormHelpText>
              We&rsquo;ll notify you once your number is activated. Meanwhile
              you can configure where to route calls to this number.
            </FormHelpText>
            <InputV2
              formMethods={formMethods}
              name="name"
              label="Name"
              required
              placeholder="e.g. Urgent support"
              helptext="For internal use only - this will not be shown to your customers."
            />
            <StaticSingleSelectV2
              formMethods={formMethods}
              name="country_code"
              label="Country code"
              helptext={
                "The country code for the phone number your customers will be able to call. "
              }
              options={[
                {
                  label: "United Kingdom (+44)",
                  value: "44",
                },
                {
                  label: "United States (+1)",
                  value: "1",
                },
                {
                  label: "Other",
                  value: "other",
                },
              ]}
              required
            />
            <TextareaV2
              formMethods={formMethods}
              name="message"
              label="More details"
              helptext={
                <>
                  Please specify any additional requirements, such as a country
                  or area code, or if you need a local or toll-free number.
                </>
              }
            />
          </DrawerBody>

          <DrawerFooter className="flex justify-end gap-2">
            <Button
              analyticsTrackingId={null}
              onClick={onClose}
              theme={ButtonTheme.Secondary}
            >
              Cancel
            </Button>
            <Button
              type={"submit"}
              analyticsTrackingId={null}
              theme={ButtonTheme.Primary}
              disabled={isPending}
              loading={isPending}
            >
              Request
            </Button>
          </DrawerFooter>
        </Form.Root>
      </DrawerContents>
      <OnCallPromotionConfirmationModal
        noun="call route"
        state={promotionState}
        onClose={() => setPromotionState(null)}
        onSubmit={(formData, userIdsToPromote) =>
          mutate({
            requestBody: { ...formData, user_ids_to_promote: userIdsToPromote },
          })
        }
      />
    </Drawer>
  );
};
