import {
  ExternalSchedule,
  Schedule,
  ScheduleRotation,
  ScheduleRotationHandover,
  ScheduleRotationHandoverIntervalTypeEnum,
  ScheduleRotationPayload,
  SchedulesCreateRequest,
  SchedulesUpdateRequest,
} from "@incident-io/api";
import { WeekdayIntervalWeekdayEnum } from "@incident-io/api/models/WeekdayInterval";
import { captureException } from "@sentry/react";
import _, { flatMap, groupBy } from "lodash";
import { DateTime } from "luxon";
import { ulid } from "ulid";

import { timezoneToCountries } from "./timezoneToCountries";
import {
  CustomHandoverRule,
  CustomHandoverRuleType,
  IntervalData,
  RotaFormData,
  RotaHandoverType,
  RotaId,
  RotationVersions,
  ScheduleFormData,
  WorkingInterval,
} from "./types";
import { flattenRotationsRecord, getCurrentlyActiveRotas } from "./util";

export const rotaFormDataToPayload = (
  data: RotaFormData,
  existingRotation: ScheduleRotation | ScheduleRotationPayload | null,
): ScheduleRotationPayload => {
  const layers = Array(Number(data.layer_count))
    .fill({})
    .map((_, i) => ({
      id: (existingRotation?.layers ?? [])[i]?.id,
    }));

  let handovers: ScheduleRotationHandover[];
  if (data.rota_handover_type !== RotaHandoverType.Custom) {
    handovers = [
      {
        interval_type:
          data.rota_handover_type as unknown as ScheduleRotationHandoverIntervalTypeEnum,
        interval: 1,
      },
    ];
  } else {
    handovers = data.custom_handovers.map((r): ScheduleRotationHandover => {
      return {
        interval_type:
          r.handover_interval_type as unknown as ScheduleRotationHandoverIntervalTypeEnum,
        interval: Number(r.handover_interval),
      };
    });
  }

  const effectiveFrom = data.effective_from ? data.effective_from : undefined;

  return {
    id: existingRotation?.id ?? data?.id,
    name: data?.name,
    user_ids: data.users.map((x) => x.id),
    handover_start_at: data.handover_start_at,
    layers: layers,
    handovers: handovers,
    working_intervals:
      data.has_working_intervals === "specific_times"
        ? intervalFormDataToPayload(data.working_intervals)
        : [],
    effective_from: effectiveFrom,
  };
};

const intervalFormDataToPayload = (data: IntervalData) => {
  // Each interval has a start time, end time and one or more days
  // We need to create an object for each day
  const intervals = [] as {
    weekday: WeekdayIntervalWeekdayEnum;
    start_time: string;
    end_time: string;
  }[];

  // First, we're going to merge any intervals that have the same start and end
  // time together
  const groupedData = groupBy(
    data,
    (interval) => `${interval.start_time}-${interval.end_time}`,
  );
  const mergedIntervals = [] as IntervalData;
  Object.values(groupedData).forEach((group) => {
    const mergedInterval = generateFormInterval(
      // Grab only the enabled days from each interval in the group
      // it doesn't matter if there are duplicates here, they'll just be set to `true` several times
      flatMap(group, (x) => Object.keys(x.days).filter((day) => x.days[day])),
      group[0].start_time,
      group[0].end_time,
    );
    mergedIntervals.push(mergedInterval);
  });

  // Now we can create a new interval payload for each day unique combination of active times & day
  mergedIntervals.forEach((interval) => {
    const activeDays = Object.entries(interval.days).filter(([_, day]) => day);
    activeDays.forEach((day) => {
      intervals.push({
        start_time: interval.start_time,
        end_time: interval.end_time,
        weekday: day[0] as WeekdayIntervalWeekdayEnum,
      });
    });
  });
  return intervals;
};

export const scheduleFormDataToCreatePayload = (
  data: ScheduleFormData,
  usersToPromote: string[],
): SchedulesCreateRequest => {
  return {
    createRequestBody: {
      name: data.name,
      external_schedule_id: data.external_schedule_id,
      timezone: data.timezone,
      holidays_public_config: {
        country_codes: (data.holidays_public_config?.country_codes ?? []).map(
          (c) => c.id,
        ),
      },
      config: {
        // Our logic is a bit simpler than update, given we don't need to worry about existing rotations
        rotations: flattenRotationsRecord(data.rotations).map((x) =>
          rotaFormDataToPayload(x, null),
        ),
      },
      user_ids_to_promote: usersToPromote,
    },
  };
};

export const scheduleFormDataToUpdatePayload = (
  id: string,
  formData: ScheduleFormData,
  existingSchedule: Schedule,
  configVersion: number,
  usersToPromote: string[],
  now: DateTime,
): SchedulesUpdateRequest => {
  return {
    id,
    updateRequestBody: {
      name: formData.name,
      holidays_public_config: {
        country_codes: formData.holidays_public_config.country_codes.map(
          (c) => c.id,
        ),
      },
      config: {
        rotations: buildRotationsPayload(
          formData,
          now,
          existingSchedule.config?.rotations,
        ),
        version: configVersion,
      },
      user_ids_to_promote: usersToPromote,
    },
  };
};

// buildRotationsPayload takes the form data and returns a list of rotations to actually
// create on the backend. Our form data mostly maps 1:1 to our list of rotations on the backend (in record format),
// the only difference being that for the current version of a rota, if someone makes it effective from later, we
// let them edit inline and then mark it as an upcoming change.
export const buildRotationsPayload = (
  formData: ScheduleFormData,
  now: DateTime,
  existingRotations?: ScheduleRotation[],
) => {
  const activeRotations = getCurrentlyActiveRotas({
    rotas: existingRotations ?? [],
    now,
  });

  const processedRotations = Object.entries(formData.rotations).flatMap(
    ([rotaId, versions]) => {
      const existingVersion = existingRotations?.find(
        (rota) => rota.id === rotaId,
      );

      const activeRota = activeRotations.find((r) => r.id === rotaId);

      return Object.entries(versions).flatMap(([_, rotaFormData]) => {
        if (!existingVersion) {
          // This is a brand-new rotation, just return it
          return [rotaFormDataToPayload(rotaFormData, null)];
        }

        if (!activeRota) {
          // If we have an existing rota but no active version, something is wrong.
          captureException("Excepted to have active version for existing rota");
          return [];
        }

        if (isCurrentVersion(rotaFormData.version_id)) {
          if (!isEffectiveFromEqual(activeRota, rotaFormData)) {
            // We've made an upcoming change on our currently active rota, so append it.
            return [
              // Keep the current version unchanged
              activeRota,
              // Create a new version with the changes and effective_from date
              rotaFormDataToPayload(rotaFormData, existingVersion),
            ];
          }
        }

        // Otherwise, just simply marshall!
        return [rotaFormDataToPayload(rotaFormData, existingVersion)];
      });
    },
  );

  return _.sortBy(processedRotations, [
    // First sort key: rotation id to group related rotations
    "id",
    // Second sort key: whether effective_from exists (undefined goes first)
    (rotation) => (rotation.effective_from ? 1 : 0),
    // Third sort key: the effective_from date itself
    "effective_from",
  ]);
};

const isEffectiveFromEqual = (
  a: { effective_from?: Date } | undefined,
  b: { effective_from?: Date } | undefined,
) => {
  if (!a?.effective_from && !b?.effective_from) {
    return true;
  }

  if (!a?.effective_from || !b?.effective_from) {
    return false;
  }
  return a.effective_from.getTime() === b.effective_from.getTime();
};

// If we have more than one handover it's definitely custom and if we have one
// that repeats more than once it's also custom.
export const hasCustomOrHourlyHandover = (
  handovers: CustomHandoverRule[],
): boolean => {
  if (handovers.length === 0) {
    throw new Error("We should always have at least one handover");
  } else if (handovers.length > 1) {
    return true;
  } else {
    return (
      Number(handovers[0].handover_interval) > 1 ||
      handovers[0].handover_interval_type === CustomHandoverRuleType.Hourly
    );
  }
};

export const rotaToFormData = ({
  rota,
  isCurrentVersion,
}: {
  rota: ScheduleRotation | ScheduleRotationPayload;
  isCurrentVersion?: boolean;
}): RotaFormData => {
  const handovers =
    rota.handovers?.map((r): CustomHandoverRule => {
      return {
        handover_interval_type:
          r.interval_type as unknown as CustomHandoverRuleType,
        handover_interval: r.interval.toString(),
      };
    }) ?? [];

  let handoverType: RotaHandoverType;
  if (hasCustomOrHourlyHandover(handovers)) {
    handoverType = RotaHandoverType.Custom;
  } else {
    if (handovers[0].handover_interval_type === CustomHandoverRuleType.Daily) {
      handoverType = RotaHandoverType.Daily;
    } else if (
      handovers[0].handover_interval_type === CustomHandoverRuleType.Weekly
    ) {
      handoverType = RotaHandoverType.Weekly;
    } else {
      throw new Error(
        "Should never happen, if the type is hourly we have custom handovers",
      );
    }
  }

  return {
    id: rota.id,
    version_id: getVersionId({
      effectiveFrom: rota.effective_from,
      isCurrentVersion: isCurrentVersion,
    }),
    name: rota.name,
    users: rota.user_ids.map((id) => ({ id })),
    handover_start_at: rota.handover_start_at,
    layer_count: rota.layers?.length ?? 1,
    has_working_intervals:
      rota.working_intervals.length > 0 ? "specific_times" : "all_day",
    working_intervals: parseWorkingIntervalsResponse(rota),
    custom_handovers: handovers,
    rota_handover_type: handoverType,
    effective_from: rota.effective_from,
    is_deferred: isCurrentVersion ? "false" : "true",
  };
};

const buildRotationVersions = ({
  rotations,
  currentlyActiveRotas = [],
}: {
  rotations: ScheduleRotation[] | ScheduleRotationPayload[];
  currentlyActiveRotas?: ScheduleRotation[];
}): Record<RotaId, RotationVersions> => {
  const groupedRotations = _.groupBy(rotations, "id");

  return _.mapValues(groupedRotations, (rotaVersions, rotaId) => {
    const currentlyActiveVersionForRota = currentlyActiveRotas.find(
      (rota) => rota.id === rotaId,
    );
    return rotaVersions.reduce((acc, version) => {
      const isCurrentVersion =
        !currentlyActiveVersionForRota ||
        isEffectiveFromEqual(currentlyActiveVersionForRota, version);
      const formData = rotaToFormData({
        rota: version,
        isCurrentVersion,
      });

      acc[formData.version_id] = formData;
      return acc;
    }, {} as RotationVersions);
  });
};

export const scheduleToFormData = ({
  schedule,
  now,
  isDuplicating,
}: {
  schedule: Schedule;
  now: DateTime;
  isDuplicating?: boolean;
}): ScheduleFormData => {
  let rotations: ScheduleRotation[] = schedule.config?.rotations ?? [];
  if (isDuplicating) {
    rotations = replaceRotaIds(rotations);
  }

  const currentlyActiveRotas = getCurrentlyActiveRotas({
    rotas: rotations,
    now: now,
  });
  const rotationVersions = buildRotationVersions({
    rotations,
    currentlyActiveRotas: currentlyActiveRotas,
  });
  const flattenedActiveRotationVersions = flattenRotationsRecord(
    rotationVersions,
  ).filter((rota) => isCurrentVersion(rota.version_id));
  const defaultUpcomingChangeValues = flattenedActiveRotationVersions.reduce(
    (acc, rota) => {
      acc[rota.id || ""] = rota.version_id;
      return acc;
    },
    {},
  );

  return {
    name: `${schedule.name}${isDuplicating ? " (Copy)" : ""}`,
    timezone: schedule.timezone,
    holidays_public_config: {
      country_codes: (schedule.holidays_public_config?.country_codes ?? []).map(
        (c) => ({ id: c }),
      ),
    },
    rotations: buildRotationVersions({
      rotations,
      currentlyActiveRotas: currentlyActiveRotas,
    }),
    selected_rotation_versions: defaultUpcomingChangeValues,
  };
};

// replaceRotaIds replaces the IDs of rotations with new IDs. This is useful
// when duplicating a schedule, where we want to maintain the same relationship
// between versions of a rotation, but give them new IDs.
const replaceRotaIds = (rotations: ScheduleRotation[]): ScheduleRotation[] => {
  const replacementMap = new Map<string, string>();
  return rotations.map((rota) => {
    let newId = replacementMap.get(rota.id);
    if (!newId) {
      newId = ulid();
      replacementMap.set(rota.id, newId);
    }

    return {
      ...rota,
      id: newId,
    };
  });
};

export const externalScheduleToFormData = (
  schedule: ExternalSchedule,
): ScheduleFormData => {
  const rotations = schedule?.native_config?.rotations ?? [];
  const rotationVersions = buildRotationVersions({
    rotations,
  });
  const flattenedActiveRotationVersions = flattenRotationsRecord(
    rotationVersions,
  ).filter((rota) => isCurrentVersion(rota.version_id));
  const defaultUpcomingChangeValues = flattenedActiveRotationVersions.reduce(
    (acc, rota) => {
      acc[rota.id || ""] = rota.version_id;
      return acc;
    },
    {},
  );

  return {
    name: schedule.name,
    timezone: schedule.timezone,
    external_schedule_id: schedule.id,
    holidays_public_config: {
      country_codes: timezoneToCountries[schedule.timezone] || [],
    },
    rotations: rotationVersions,
    selected_rotation_versions: defaultUpcomingChangeValues,
  };
};

const generateFormInterval = (
  activeDays: string[],
  startTime: string,
  endTime: string,
): WorkingInterval => {
  // First make an interval with the right start and end that is disabled every day
  const formInterval = {
    days: Object.values(WeekdayIntervalWeekdayEnum).reduce(
      (acc, day) => {
        acc[day] = false;
        return acc;
      },
      {} as {
        [day in WeekdayIntervalWeekdayEnum]: boolean;
      },
    ),
    start_time: startTime,
    end_time: endTime,
  };
  // Enable it for the required days
  activeDays.forEach((day) => (formInterval.days[day] = true));

  return formInterval;
};

const parseWorkingIntervalsResponse = (
  rota: ScheduleRotation | ScheduleRotationPayload,
): IntervalData => {
  // We have a set of objects that look like: {weekday: "monday", start_time: "09:00", end_time: "17:00"}
  // We want {days: {monday: true, tuesday: false..., etc}, start: "09:00", end: "17:00"}
  // So we're going to concat start and end time and use that to group the intervals
  const hasWorkingIntervals = rota.working_intervals.length > 0;
  if (hasWorkingIntervals) {
    const formIntervals = [] as IntervalData;
    const groupedIntervals = groupBy(
      rota.working_intervals,
      (interval) => `${interval.start_time}-${interval.end_time}`,
    );
    Object.keys(groupedIntervals).forEach((key) => {
      const formInterval = generateFormInterval(
        flatMap(groupedIntervals[key], (x) => x.weekday),
        key.split("-")[0],
        key.split("-")[1],
      );
      formIntervals.push(formInterval);
    });
    return formIntervals;
  } else {
    // If we've got no existing intervals, default to a single 0900-1700 interval mon-fri
    return [
      {
        days: Object.values(WeekdayIntervalWeekdayEnum)
          .filter(
            (day) =>
              ![
                WeekdayIntervalWeekdayEnum.Saturday,
                WeekdayIntervalWeekdayEnum.Sunday,
              ].includes(day),
          )
          .reduce(
            (acc, day) => {
              acc[day] = true;
              return acc;
            },
            {} as {
              [day in WeekdayIntervalWeekdayEnum]: boolean;
            },
          ),
        start_time: "09:00",
        end_time: "17:00",
      },
    ];
  }
};

export const CURRENT_VERSION_ID = "current";

export const getVersionId = ({
  effectiveFrom,
  isCurrentVersion,
}: {
  effectiveFrom: Date | undefined | null;
  isCurrentVersion?: boolean;
}): string => {
  if (isCurrentVersion) {
    return CURRENT_VERSION_ID;
  }
  // If our initial "no effective from" is no longer the current, than we don't get a string from it.
  return effectiveFrom ? effectiveFrom.getTime().toString() : "0";
};

export const isCurrentVersion = (versionId: string): boolean => {
  return versionId === CURRENT_VERSION_ID;
};
