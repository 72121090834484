/* tslint:disable */
/* eslint-disable */
/**
 * incident.io
 * API that powers the incident.io dashboard, webhook receiver and general backend services. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AIRequestEvalFixture,
    AIRequestEvalFixtureFromJSON,
    AIRequestEvalFixtureFromJSONTyped,
    AIRequestEvalFixtureToJSON,
} from './AIRequestEvalFixture';

/**
 * 
 * @export
 * @interface AIRequestEvals
 */
export interface AIRequestEvals {
    /**
     * Name of the eval case
     * @type {string}
     * @memberof AIRequestEvals
     */
    case_name?: string;
    /**
     * A copiable eval case
     * @type {string}
     * @memberof AIRequestEvals
     */
    eval: string;
    /**
     * If needed, a string of the fixtures that should be used for this eval
     * @type {Array<AIRequestEvalFixture>}
     * @memberof AIRequestEvals
     */
    fixtures: Array<AIRequestEvalFixture>;
    /**
     * ID of the request
     * @type {string}
     * @memberof AIRequestEvals
     */
    id: string;
    /**
     * Which prompt is this request for
     * @type {string}
     * @memberof AIRequestEvals
     */
    prompt: string;
}

export function AIRequestEvalsFromJSON(json: any): AIRequestEvals {
    return AIRequestEvalsFromJSONTyped(json, false);
}

export function AIRequestEvalsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AIRequestEvals {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'case_name': !exists(json, 'case_name') ? undefined : json['case_name'],
        'eval': json['eval'],
        'fixtures': ((json['fixtures'] as Array<any>).map(AIRequestEvalFixtureFromJSON)),
        'id': json['id'],
        'prompt': json['prompt'],
    };
}

export function AIRequestEvalsToJSON(value?: AIRequestEvals | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'case_name': value.case_name,
        'eval': value.eval,
        'fixtures': ((value.fixtures as Array<any>).map(AIRequestEvalFixtureToJSON)),
        'id': value.id,
        'prompt': value.prompt,
    };
}

