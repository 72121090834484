import { EscalationPreview } from "@incident-io/api";
import { StackedList } from "@incident-ui";
import { EscalationStackedListItem } from "src/components/escalations/EscalationStackedListItem";

export const EscalationSection = ({
  escalations,
}: {
  escalations: EscalationPreview[];
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="text-sm-bold">Escalations </div>
      <StackedList>
        {escalations.length ? (
          escalations
            .sort((a, b) => (a.id < b.id ? -1 : 1))
            .slice(0, 10)
            .map((escalation) => {
              return (
                <EscalationStackedListItem
                  key={escalation.id}
                  escalation={escalation}
                  hrefInTitle={true}
                />
              );
            })
        ) : (
          <div className="px-4 py-3 border border-stroke-primary rounded-2 text-content-tertiary">
            No escalations
          </div>
        )}
      </StackedList>
    </div>
  );
};
